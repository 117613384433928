import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Group, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconEdit, IconEye } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import CustomActionIconFile from '../../../../components/CustomActionIconFile';
import CustomTooltip from '../../../../components/CustomTooltip';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import { CompanyResponse } from '../../../../types/api/response/company';
import {
  ExpenseReportObject,
  ExpenseStatusState,
} from '../../../../types/types';
import { handleOpenExpenseReportAttachmentFile } from '../../../../utils/attachmentFile';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import ExpenseReportRequestForm from '../../../expense-reports/components/ExpenseReportRequestForm';
import StatusCell from './StatusCell';

const componentName = 'ExpenseReportsList';

type Props = {
  expenseReports: ExpenseReportObject[];
  userFullname: string;
  company: CompanyResponse;
};

export default function ExpenseReportsList({
  expenseReports,
  userFullname,
  company,
}: Props) {
  const { t, lang } = useI18n();
  const { access_token, user } = useAuth();

  function getAmountHT(additionValueVATList: number[] | null, amount: number) {
    const sum = additionValueVATList?.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    const amountHT = amount - (!!sum ? sum : 0);
    return amountHT.toFixed(2);
  }

  function getAmountVAT(additionValueVATList: number[] | null) {
    const sum = additionValueVATList?.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    if (!!sum) return sum.toFixed(2);

    return 0;
  }

  function handleOpenExpenseReportModalClick(
    expenseReport: ExpenseReportObject,
    edit: boolean
  ) {
    modals.open({
      transitionProps: { transition: 'slide-down' },
      modalId: `expense-report-${expenseReport.id}-to-see-or-update-modal`,
      title: (
        <Title size={'h3'} component="p">{`${t(
          'w.expenseReport'
        )} - ${userFullname}`}</Title>
      ),
      fullScreen: true,
      children: (
        <ExpenseReportRequestForm
          closeModal={() =>
            modals.close(
              `expense-report-${expenseReport.id}-to-see-or-update-modal`
            )
          }
          expenseReport={expenseReport}
          edit={edit}
          userProfile={user}
        />
      ),
      zIndex: 203,
    });
  }

  const columns: DataTableColumn<ExpenseReportObject>[] = useMemo(
    () => [
      {
        accessor: 'dateOfExpense',
        title: 'Date',
        sortable: true,
        ellipsis: true,
        render: ({ dateOfExpense }) =>
          dayjs(dateOfExpense).format('DD/MM/YYYY'),
      },
      {
        accessor: 'type',
        title: 'Type',
        sortable: true,
        ellipsis: true,
        render: ({ type }) => t(`expenseReport.type.${type.toLowerCase()}`),
      },
      {
        accessor: 'label',
        title: t('w.wording'),
        sortable: true,
        ellipsis: true,
        render: ({ label }) => t(`expenseReport.label.${label}`),
      },
      {
        accessor: 'comment',
        title: t('w.comment'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'attachment',
        title: t('expenseReport.receipt'),
        sortable: true,
        ellipsis: true,
        render: ({ attachment, id }) => (
          <Group>
            {attachment && (
              <CustomActionIconFile
                fileName={attachment}
                onClick={() =>
                  handleOpenExpenseReportAttachmentFile(
                    company.id,
                    id,
                    attachment.split('.').pop() || '',
                    access_token,
                    t
                  )
                }
              />
            )}
          </Group>
        ),
      },
      {
        accessor: 'amountHT',
        title: t('w.amountHT'),
        sortable: true,
        ellipsis: true,
        render: ({ additionValueVATList, amount }) =>
          getAmountHT(additionValueVATList, amount),
      },
      {
        accessor: 'amountVAT',
        title: `${t('w.amount')} ${t('w.vat').slice(0, -1).toUpperCase()}`,
        sortable: true,
        ellipsis: true,
        render: ({ additionValueVATList }) =>
          getAmountVAT(additionValueVATList),
      },
      {
        accessor: 'amount',
        title: t('w.amountTTC'),
        sortable: true,
        ellipsis: true,
        render: ({ amount }) => amount.toFixed(2),
      },
      {
        accessor: 'status.state',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ status, id }) => (
          <StatusCell
            companyId={company.id}
            expenseReportId={id}
            state={status.state}
          />
        ),
      },
      {
        accessor: 'status.stateDate',
        title: t('w.validatedOrReimburseDate'),
        sortable: true,
        ellipsis: true,
        render: ({ status }) =>
          status.state === ExpenseStatusState.VALID ||
          status.state === ExpenseStatusState.REFUNDED
            ? dayjs(status.stateDate).format('llll')
            : '',
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        sortable: true,
        ellipsis: true,
        align: 'right',
        width: '0%',
        render: (record) => (
          <Group gap={4} justify="end" wrap={'nowrap'}>
            <CustomTooltip label={t('w.see')}>
              <ActionIcon
                size="sm"
                color="green"
                variant={'subtle'}
                onClick={() => handleOpenExpenseReportModalClick(record, false)}
              >
                <IconEye />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.modify')}>
              <ActionIcon
                size="sm"
                color="blue"
                variant={'subtle'}
                onClick={() => handleOpenExpenseReportModalClick(record, true)}
              >
                <IconEdit />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    []
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'status.state',
          label: t('w.status'),
          choices: [
            {
              value: 'PROCESSED',
              label: t(`expenseReport.status.state.PROCESSED`),
            },
            {
              value: 'REFUNDED',
              label: t(`expenseReport.status.state.REFUNDED`),
            },
            {
              value: 'REJECTED',
              label: t(`expenseReport.status.state.REJECTED`),
            },
            { value: 'VALID', label: t(`expenseReport.status.state.VALID`) },
            {
              value: 'WAITING',
              label: t(`expenseReport.status.state.WAITING`),
            },
          ],
          selectedChoices: [],
        },
        {
          accessor: 'type',
          label: 'Type',
          choices: [
            {
              value: 'DISPLACEMENT',
              label: t(`expenseReport.type.DISPLACEMENT`),
            },
            {
              value: 'MISSION',
              label: t(`expenseReport.type.MISSION`),
            },
            {
              value: 'OTHER',
              label: t(`expenseReport.type.OTHER`),
            },
            {
              value: 'RECEPTION',
              label: t(`expenseReport.type.RECEPTION`),
            },
          ],
          selectedChoices: [],
        },
        {
          accessor: 'label',
          label: t('w.wording'),
          choices: [
            {
              value: 'BIKERENTAL',
              label: t(`expenseReport.label.BIKERENTAL`),
            },
            {
              value: 'CARRENTAL',
              label: t(`expenseReport.label.CARRENTAL`),
            },
            {
              value: 'FUEL',
              label: t(`expenseReport.label.FUEL`),
            },
            {
              value: 'KILOMETRICALLOWANCE',
              label: t(`expenseReport.label.KILOMETRICALLOWANCE`),
            },
            {
              value: 'LODGING',
              label: t(`expenseReport.label.LODGING`),
            },
            {
              value: 'MEAL',
              label: t(`expenseReport.label.MEAL`),
            },
            {
              value: 'OTHER',
              label: t(`expenseReport.label.OTHER`),
            },
            {
              value: 'PARKING',
              label: t(`expenseReport.label.PARKING`),
            },
            {
              value: 'TAXI',
              label: t(`expenseReport.label.TAXI`),
            },
            {
              value: 'TOLL',
              label: t(`expenseReport.label.TOLL`),
            },
            {
              value: 'TRANSITPASS',
              label: t(`expenseReport.label.TRANSITPASS`),
            },
          ],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <Table
      rows={expenseReports}
      columns={columns}
      options={options}
      idAccessor={'id'}
      lang={lang}
      height={'calc(100vh - 240px)'}
    />
  );
}
