import Page from '../../components/Page/Page';
import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import useCompany from '../../hooks/useCompany';
import { BuiltInPermissions, ScopeEnum } from '../../types/api/response/role';
import { DocumentPropertySpace } from '../../types/types';
import { hasPermission } from '../../utils/authorization';
import { toDateOfDayString } from '../../utils/format';
import DocumentsTable from './components/DocumentsTable';

export default function DocumentsShared() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId, company } = useCompany(user);

  return (
    <Page
      title={t('w.companyDocuments')}
      parent={{ label: company ? company.name : toDateOfDayString() }}
    >
      <DocumentsTable
        hasPermissionToEditSpace={hasPermission(
          {
            permission: BuiltInPermissions.AuthorizedReadCommonSpace,
            scope: ScopeEnum.ALL,
          },
          user,
          companyId
        )}
        space={DocumentPropertySpace.SHARED}
        userId={user.id}
        companyId={companyId}
        accountantCompanyId={null}
      />
    </Page>
  );
}
