import { Fieldset, Group, Stack, TextInput } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import * as React from 'react';

import CustomDropzone from '../../../../../components/CustomDropzone/CustomDropzone';
import LabelText from '../../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../../contexts/I18nProvider';
import { UserPersonalSensitiveDocument } from '../../../../../types/api/payload/user';
import type { UserResponse } from '../../../../../types/api/response/user';
import UserPersonalSensitiveDocumentViewer from '../UserPersonalSensitiveDocumentViewer/UserPersonalSensitiveDocumentViewer';

type Props = {
  form: any;
  disabled: boolean;
  employee: UserResponse;
};

export default function ForeignWorkerForm({ form, disabled, employee }: Props) {
  const { t } = useI18n();
  return (
    <Stack>
      <TextInput
        label={<LabelText text={t('w.residenceNumber')} />}
        placeholder={t('w.noValue')}
        {...form.getInputProps('residencePermitNumber')}
        disabled={disabled}
      />

      <Fieldset legend={t('w.residencePermitFile')}>
        <Group grow preventGrowOverflow={false} wrap="nowrap">
          <UserPersonalSensitiveDocumentViewer
            userId={employee.id}
            type={UserPersonalSensitiveDocument.residencePermit}
            fileName={t('w.residencePermitFile')}
          />
          <CustomDropzone
            onDrop={(files) =>
              form.setFieldValue('residencePermitFiles', files)
            }
            files={form.values.residencePermitFiles}
            mimeType={'pdf'}
            resetFile={() => form.setFieldValue('residencePermitFiles', [])}
            disabled={disabled}
          />
        </Group>
      </Fieldset>
      <DateInput
        label={<LabelText text={t('w.residencePermitExpirationDate')} />}
        valueFormat={'D MMMM YYYY'}
        placeholder={t('w.noValue')}
        clearable
        {...form.getInputProps('residencePermitExpirationDate')}
        disabled={disabled}
      />
    </Stack>
  );
}
