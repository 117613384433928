import { Center, Stack, Title } from '@mantine/core';
import React from 'react';

import useResponsive from '../../hooks/useResponsive';
import { LOGO_PATH, LOGO_SQUARE_PATH } from '../../variables/GlobalVariables';
import maintenanceSVG from './maintenance.svg';

export default function UnderMaintenance() {
  const { isMobile } = useResponsive();

  return (
    <Center w="100vw" h="100vh" p="xl">
      <Stack align="center" justify="center">
        <img
          alt="logo"
          src={!isMobile ? LOGO_PATH : LOGO_SQUARE_PATH}
          width={!isMobile ? 400 : 80}
          height={!isMobile ? 80 : 80}
        />
        <img src={maintenanceSVG} width={'50%'} alt={'maintenance'} />

        <Title order={1}>
          Désolé pour le dérangement, nous comprenons que c’est frustrant.
        </Title>
        <Title order={1}>
          Merci de patienter pendant cette maintenance nécessaire.
        </Title>
      </Stack>
    </Center>
  );
}
