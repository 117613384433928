import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import PlanningService from '../services/PlanningService';
import { PlanningObject } from '../types/types';

type Props = {
  companyId: string;
  divisionIds: string[];
  start: Date;
  end: Date;
  userId?: string;
};

export default function useFetchPlanning({
  divisionIds,
  companyId,
  start,
  end,
  userId,
}: Props) {
  const queryClient = useQueryClient();
  const [debouncedDivisionIds, setDebouncedDivisionIds] = useState<string[]>(
    []
  );
  const startDate = dayjs(start).subtract(1, 'week').valueOf();
  const endDate = dayjs(end).add(1, 'week').valueOf();

  const {
    data: userPlanning,
    refetch: refetchUserPlanning,
    isLoading: isUserPlanningLoading,
  } = useQuery({
    enabled: !!userId,
    queryKey: [
      'PlanningService.getPlanningFromStartAndEndDateByUser',
      companyId,
      userId,
      startDate,
      endDate,
    ],
    queryFn: () =>
      PlanningService.getPlanningFromStartAndEndDateByUser(
        companyId,
        userId!,
        startDate,
        endDate
      ),
  });

  const queries = useQueries({
    queries: debouncedDivisionIds.map((divisionId) => {
      return {
        enabled: !userId,
        queryKey: [
          'PlanningService.getPlanningFromStartAndEndDateByDivision',
          companyId,
          divisionId,
          startDate,
          endDate,
        ],
        queryFn: () =>
          PlanningService.getPlanningFromStartAndEndDateByDivision(
            companyId,
            [divisionId],
            startDate,
            endDate
          ),
      };
    }),
  });

  useEffect(() => {
    const debouncedUpdate = debounce(() => {
      setDebouncedDivisionIds(divisionIds);
    }, 1000);

    debouncedUpdate();

    return () => {
      debouncedUpdate.cancel();
    };
  }, [divisionIds]);

  const dataArray = queries
    .filter((item) => !!item && !!item.data)
    .flatMap((item) => item.data!);

  const allQueriesCompleted = queries.every(
    (query) => !query.isLoading && !query.isFetching && !query.isError
  );

  const data: PlanningObject[] = useMemo(() => {
    if (!dataArray?.length) return [];

    return dataArray;
  }, [debouncedDivisionIds, allQueriesCompleted]);

  const userData = useMemo(() => {
    if (!userPlanning) return [];

    return [userPlanning];
  }, [userPlanning]);

  function refetch() {
    if (!!userId) {
      refetchUserPlanning();
    } else {
      queryClient.invalidateQueries({
        queryKey: ['PlanningService.getPlanningFromStartAndEndDateByDivision'],
      });
    }
  }

  return {
    data: !!userId ? userData : data,
    refetch,
    loading: !!userId
      ? isUserPlanningLoading
      : debouncedDivisionIds.length !== dataArray.length,
  };
}
