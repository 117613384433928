import { Flex, Skeleton } from '@mantine/core';
import {
  DatePicker,
  type DatePickerProps,
  type DateValue,
} from '@mantine/dates';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';

import Page from '../../../../components/Page/Page';
import { useI18n } from '../../../../contexts/I18nProvider';
import DailyWorkService from '../../../../services/DailyWorkService';
import type { DailyWorkDetails } from '../../../../types/api/response/dailyWork';
import type { UserResponse } from '../../../../types/api/response/user';
import DashboardItem from './DashboardItem';
import EditWorkingDay from './EditWorkingDay';

type Props = {
  userProfile: UserResponse;
};

export default function FixedPriceManagerMode({ userProfile }: Props) {
  const { t } = useI18n();
  const [selectedDate, setSelectedDate] = useState<DateValue>(new Date());
  const { data, refetch } = useQuery({
    queryKey: [
      'DailyWorkService.getUserDailyWork',
      userProfile.id,
      dayjs(selectedDate).month() + 1,
      dayjs(selectedDate).year(),
    ],
    queryFn: () =>
      DailyWorkService.getUserDailyWork(
        userProfile.id,
        dayjs(selectedDate).month() + 1,
        dayjs(selectedDate).year()
      ),
  });

  const monthWorkedDays = useMemo(() => {
    if (!data?.length) return 0;

    return data.reduce(
      (sum, row) => sum + (row.workingTimeDay === 0 ? 0 : 1),
      0
    );
  }, [data]);

  const selectedDateData: DailyWorkDetails | undefined = useMemo(() => {
    if (!data?.length) return undefined;

    return data.find(
      (item) => dayjs(item.date).format('L') === dayjs(selectedDate).format('L')
    );
  }, [data, selectedDate]);

  const getDayProps: DatePickerProps['getDayProps'] = (date) => {
    if (
      Boolean(
        data?.find(
          (item) =>
            item.workingTimeDay !== 0 &&
            dayjs(item.date).format('L') === dayjs(date).format('L')
        )
      )
    ) {
      return {
        style: {
          backgroundColor: 'var(--mantine-color-green-filled)',
          color: 'var(--mantine-color-white)',
        },
      };
    }

    return {};
  };

  return (
    <Page
      title={t('w.fixedPriceManager')}
      parent={{ label: userProfile.fullname! }}
    >
      <Flex mt="md" gap="md" wrap="wrap" justify="center">
        <div>
          <DashboardItem
            title={t(
              'fixedPriceManager.totalWorkedDaysDuringMonth',
              dayjs(selectedDate).format('MMMM YYYY').toLowerCase()
            )}
            value={monthWorkedDays}
          />
          <DatePicker
            mt="md"
            hideOutsideDates
            getDayProps={(date: Date) => getDayProps(date)}
            value={selectedDate}
            onChange={setSelectedDate}
            onMonthSelect={setSelectedDate}
            onNextMonth={setSelectedDate}
            onPreviousMonth={setSelectedDate}
          />
        </div>

        {selectedDateData ? (
          <EditWorkingDay
            key={selectedDate?.toISOString()}
            selectedDate={selectedDate}
            data={selectedDateData}
            refresh={refetch}
            companyId={userProfile.company?.id!}
          />
        ) : (
          <Skeleton visible w={260} />
        )}
      </Flex>
    </Page>
  );
}
