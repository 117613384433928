import { useQuery } from '@tanstack/react-query';
import React from 'react';

import Page from '../../../components/Page/Page';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import useCompany from '../../../hooks/useCompany';
import { useQueryParams } from '../../../hooks/useQueryParams';
import LeaveService from '../../../services/LeaveService';
import type { CompanyResponse } from '../../../types/api/response/company';
import { toDateOfDayString } from '../../../utils/format';
import LeavesPendingValidationsList from './components/LeavesPendingValidationsList';

type Props = {
  divisionId?: string;
  company?: CompanyResponse;
};

export default function LeavesAndAbsencesPendingRequests({
  divisionId,
  company,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId, company: companyFromHook } = useCompany(
    user,
    company?.id
  );
  let query = useQueryParams();
  const userIdQueryParams = query.get('user');

  const {
    data: leavesList,
    isLoading: leaveslistIsLoading,
    refetch: refetchLeavesList,
  } = useQuery({
    queryKey: ['LeaveService.getLeavesPendingValidation', companyId],
    queryFn: () => LeaveService.getLeavesPendingValidation(companyId),
  });

  function filterLeavesList() {
    let result = leavesList;
    if (userIdQueryParams) {
      result = result?.filter((item) => item.who === userIdQueryParams);
    }

    if (divisionId) {
      return result?.filter((leave) => leave.division?.id === divisionId);
    }
    return result;
  }

  return (
    <Page
      title={t('w.leavesAndAbsences')}
      parent={{
        label: companyFromHook ? companyFromHook.name : toDateOfDayString(),
      }}
    >
      <LeavesPendingValidationsList
        leavesList={filterLeavesList()}
        isLoading={leaveslistIsLoading}
        refetchLeavesList={refetchLeavesList}
      />
    </Page>
  );
}
