import { Button, MultiSelect, Popover } from '@mantine/core';
import { IconFilter } from '@tabler/icons-react';
import * as React from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import { LeaveTypeTag } from '../../../../types/api/response/leaveType';
import { ColorIconType } from '../../../../types/api/response/module';
import type { IconName } from '../../../company/period-types/components/IconSelector';
import s from './LeaveTypeFilter.module.sass';

export type LeaveTypeFilterOption = {
  selected: boolean;
  color: string;
  icon: IconName;
  label: string;
  key: ColorIconType | LeaveTypeTag;
};

type Props = {
  options: LeaveTypeFilterOption[];
  onChange: (selectedLabels: string[]) => void;
};

export default function LeaveTypeFilter({ options, onChange }: Props) {
  const { t } = useI18n();

  const value = options
    .filter((item) => item.selected)
    .map((item) => item.label);
  const data = options.map((item) => item.label);

  return (
    <Popover position="bottom" withArrow shadow="md">
      <Popover.Target>
        <Button
          radius="xl"
          variant="outline"
          leftSection={
            <IconFilter style={{ width: '70%', height: '70%' }} stroke={1.5} />
          }
          size="md"
        >
          {t('w.typeOfPeriod')}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <MultiSelect
          value={value}
          data={data}
          onChange={onChange}
          classNames={{ input: s.input }}
          clearable
          hidePickedOptions
        />
      </Popover.Dropdown>
    </Popover>
  );
}
