import { Modal, type ModalProps, Title } from '@mantine/core';
import { MantineSize } from '@mantine/core/lib/core';
import { ReactNode } from 'react';

type Props = {
  opened: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
  size?: MantineSize | (string & {}) | number;
  centered?: boolean;
  fullScreen?: boolean;
};

export default function CustomModal({
  opened,
  onClose,
  children,
  title,
  size = 'auto',
  centered = true,
  fullScreen,
  ...restProps
}: Props & ModalProps) {
  return (
    <Modal
      title={
        <span>
          <Title size={'h3'} component="p">
            {title}
          </Title>
        </span>
      }
      opened={opened}
      onClose={onClose}
      size={size}
      centered={centered}
      fullScreen={fullScreen}
      transitionProps={{
        transition: 'slide-down',
        duration: 300,
      }}
      {...restProps}
    >
      {children}
    </Modal>
  );
}
