import { Button, Code, rem, Text } from '@mantine/core';
import { openSpotlight } from '@mantine/spotlight';
import { IconSearch } from '@tabler/icons-react';
import React from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import useResponsive from '../../../../hooks/useResponsive';
import s from './SearchButton.module.css';

export default function SearchButton() {
  const { t } = useI18n();
  const { isMobile } = useResponsive();

  return (
    <Button
      size="sm"
      c="dimmed"
      variant="default"
      radius="md"
      leftSection={
        !isMobile ? (
          <IconSearch
            style={{ width: rem(12), height: rem(12) }}
            stroke={1.5}
          />
        ) : undefined
      }
      onClick={openSpotlight}
      rightSection={
        !isMobile ? <Code className={s.searchCode}>Ctrl + K</Code> : undefined
      }
    >
      <Text size="sm">{t('w.search')}</Text>
    </Button>
  );
}
