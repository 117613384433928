import { ActionIcon, Box, Card, Group, Tooltip } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconArrowsMaximize, IconDownload } from '@tabler/icons-react';
import * as React from 'react';
import { useEffect, useState } from 'react';

import FileViewer from '../../../../../components/FileViewer/FileViewer';
import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import ProfileService from '../../../../../services/ProfileService';
import { UserPersonalSensitiveDocument } from '../../../../../types/api/payload/user';

type Props = {
  userId: string;
  type: UserPersonalSensitiveDocument;
  fileName: string;
};

export default function UserPersonalSensitiveDocumentViewer({
  userId,
  type,
  fileName,
}: Props) {
  const { t } = useI18n();
  const { access_token } = useAuth();
  const [filePath, setFilePath] = useState<string | null>(null);

  useEffect(() => {
    openImage(userId, type, fileName);
  }, []);

  function openImage(
    userId: string,
    type: UserPersonalSensitiveDocument,
    fileName: string
  ) {
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';

    xhr.onload = function () {
      if (xhr.status === 200) {
        const newBlob = new Blob([this.response], {
          type: 'application/pdf',
        });
        const _filePath = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = _filePath;
        link.download = fileName;
        link.target = '_blank';
        setFilePath(_filePath);
      }
    };

    xhr.open(
      'GET',
      ProfileService.getUserPersonalSensitiveDocument(userId, type),
      true
    );

    xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
    xhr.send();
  }

  function onMaximizeClick() {
    if (!filePath) {
      return;
    }

    let link = document.createElement('a');
    link.href = filePath;
    link.download = `${fileName}.pdf`;
    link.target = '_blank';

    modals.openConfirmModal({
      modalId: `document-${fileName}-attachment-modal`,
      fullScreen: true,
      size: 'xl',
      children: (
        <FileViewer
          id={`document-${fileName}`}
          srcUrl={filePath}
          title={`${fileName}.pdf`}
        />
      ),
      labels: { cancel: t('w.cancel'), confirm: t('w.download') },
      onConfirm: () => link.click(),
    });
  }

  function onDownloadClick() {
    if (!filePath) {
      return;
    }
    let link = document.createElement('a');
    link.href = filePath;
    link.download = `${fileName}.pdf`;
    link.target = '_blank';
    link.click();
  }

  if (!filePath) return null;

  return (
    <Box>
      <Card w="fit-content">
        <FileViewer
          id={`document-${fileName}`}
          srcUrl={filePath}
          title={`${fileName}.pdf`}
          size="md"
        />
        <Group mt="xs" align="center" justify="center" gap="xs">
          <Tooltip label={t('w.download')}>
            <ActionIcon variant="light" size="sm" onClick={onDownloadClick}>
              <IconDownload
                color="var(--mantine-color-hifivework-5)"
                stroke={1.5}
              />
            </ActionIcon>
          </Tooltip>
          <Tooltip label={t('w.maximize')} onClick={onMaximizeClick}>
            <ActionIcon variant="light" size="sm">
              <IconArrowsMaximize
                color="var(--mantine-color-hifivework-5)"
                stroke={1.5}
              />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Card>
    </Box>
  );
}
