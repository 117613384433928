import { Options, Table } from '@ckprivate/ckf-ui';
import { Button } from '@mantine/core';
import { IconDeviceFloppy } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useState } from 'react';

import { useI18n } from '../../../../../../../contexts/I18nProvider';
import VariablePayrollService from '../../../../../../../services/VariablePayrollService';
import { VariablePayroll } from '../../../../../../../types/api/response/module';
import {
  getHiddenColumns,
  saveHiddenColumns,
} from '../../../../../../../utils/optionsPreferences';

type Props = {
  variablesPayroll: VariablePayroll[];
  targetCompanyId: string;
  onClose: () => void;
};

const componentName = 'ChooseVariablesToNotSync';

export default function ChooseVariablesToNotSync({
  variablesPayroll,
  targetCompanyId,
  onClose,
}: Props) {
  const { t, lang } = useI18n();
  const [selectedVariablePayrollIds, setSelectedVariablePayrollIds] = useState<
    string[]
  >([]);

  const { mutate, isLoading } = useMutation({
    mutationFn: () =>
      VariablePayrollService.deleteVariablePayroll(
        targetCompanyId,
        selectedVariablePayrollIds
      ),
    onSuccess: onClose,
  });

  const columns: DataTableColumn<VariablePayroll>[] = [
    {
      accessor: 'label',
      title: t('w.name'),
      sortable: true,
      ellipsis: true,
    },
  ];

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'label',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
  });

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <Table
      onSelect={(variablesPayroll) =>
        setSelectedVariablePayrollIds(
          (variablesPayroll as VariablePayroll[]).map((item) => item.id!)
        )
      }
      columns={columns}
      rows={variablesPayroll}
      options={options}
      lang={lang}
      rightCustomActions={[
        <Button
          leftSection={<IconDeviceFloppy />}
          key={'delete-several-variables-payroll-button'}
          disabled={selectedVariablePayrollIds.length === 0}
          onClick={() => mutate()}
          loading={isLoading}
        >
          {t('w.save')}
        </Button>,
      ]}
    />
  );
}
