import { Button, MultiSelect, Popover } from '@mantine/core';
import { IconFilter } from '@tabler/icons-react';
import React from 'react';

import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useCompany from '../../../../hooks/useCompany';
import { NO_WORK_UNIT } from '../../../../variables/GlobalVariables';
import s from './UnitFilter.module.sass';

type Props = {
  value: string[];
  divisions: string[];
  onChange: (value: string[]) => void;
};

export default function UnitFilter({ value, divisions, onChange }: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { company } = useCompany(user);

  return (
    <Popover position="bottom" withArrow shadow="md">
      <Popover.Target>
        <Button
          radius="xl"
          variant="outline"
          leftSection={
            <IconFilter style={{ width: '70%', height: '70%' }} stroke={1.5} />
          }
          size="md"
        >
          {company?.divisionLabel ? company.divisionLabel : t('w.unit')}
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <MultiSelect
          value={value}
          data={divisions.map((name) => ({
            value: name,
            label: name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : name,
          }))}
          onChange={onChange}
          classNames={{ input: s.input }}
          clearable
          hidePickedOptions
        />
      </Popover.Dropdown>
    </Popover>
  );
}
