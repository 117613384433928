import {
  Badge,
  Divider,
  HoverCard,
  ScrollArea,
  Stack,
  Text,
} from '@mantine/core';
import * as React from 'react';
import { PropsWithChildren } from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import { EndOfMonthDataResponseFront } from '../../../../types/front/endMonth';

type Props = {
  selectedEmployees: EndOfMonthDataResponseFront[];
};

export default function SelectedEmployeesHoverCard({
  selectedEmployees,
  children,
}: Props & PropsWithChildren) {
  const { t } = useI18n();
  return (
    <HoverCard shadow="md" withArrow>
      <HoverCard.Target>{children}</HoverCard.Target>
      <HoverCard.Dropdown>
        <Text fw="bold">
          {selectedEmployees.length} {t('w.employees')}
        </Text>
        <Divider my="sm" />
        <ScrollArea h={300}>
          <Stack gap="xs">
            {selectedEmployees.map((elt) => (
              <Badge
                variant="outline"
                key={elt.employee}
                styles={{
                  root: { textTransform: 'none' },
                }}
              >
                {elt.employeeObject.fullname}
              </Badge>
            ))}
          </Stack>
        </ScrollArea>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}
