import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Badge, Button, Group } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconChecks,
  IconEye,
  IconFileSpreadsheet,
  IconInfoCircle,
  IconTableExport,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import ExpenseStatusBadge from '../../../../components/badges/ExpenseStatusBadge';
import UnitBadge from '../../../../components/badges/UnitBadge';
import CustomMenu from '../../../../components/CustomMenu';
import CustomModal from '../../../../components/CustomModal';
import CustomTooltip from '../../../../components/CustomTooltip';
import GoToUserButton from '../../../../components/GoToUserButton/GoToUserButton';
import { useI18n } from '../../../../contexts/I18nProvider';
import ExpenseReportService from '../../../../services/ExpenseReportService';
import { CompanyResponse } from '../../../../types/api/response/company';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import saveDownloadedfile from '../../../../utils/saveDownloadedFile';
import EndOfMonthValidationExpenseReports from '../../validations/components/EndOfMonthValidationExpenseReports';
import { EndOfMonthExpenseReportRow } from './ByMonth';

type Props = {
  rows: EndOfMonthExpenseReportRow[];
  isExpenseReportsLoading: boolean;
  selectedDate: any;
  company: CompanyResponse;
};

const componentName = 'EndOfMonthExpenseReportsTable';

export default function EndOfMonthExpenseReportsTable({
  rows,
  isExpenseReportsLoading,
  selectedDate,
  company,
}: Props) {
  const { t, lang } = useI18n();
  const queryClient = useQueryClient();
  const [openedUserId, setOpenedUserId] = useState<string | null>(null);
  const modalData = useMemo(() => {
    if (!openedUserId) return undefined;
    return rows.find((item) => item.userId === openedUserId);
  }, [openedUserId, rows]);

  const { mutate: reimburse } = useMutation({
    mutationFn: (ids: string[]) =>
      ExpenseReportService.reimburse(company.id, ids),
    onSuccess: (_data, ids) => {
      queryClient.invalidateQueries({
        queryKey: [
          'ExpenseReportService.getExpenseReportsByDivisionsAndMonth',
          company.id,
        ],
      });
      setSelectedRows([]);
      showNotification({
        id: `reimburse-expense-reports-${company.id}-${ids.toString()}`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
  });

  const { mutate: exportExpenseReports, isLoading: isExportLoading } =
    useMutation({
      mutationFn: (_selectedRows: EndOfMonthExpenseReportRow[]) => {
        const userIds = _selectedRows.map((item) => item.userId);
        return ExpenseReportService.exportByUserIds(company.id, {
          monthConcerned: dayjs(selectedDate)
            .set('date', 15)
            .format('DD/MM/YYYY'),
          userIds,
        });
      },
      onSuccess: (data) => saveDownloadedfile(data),
      onError: (error) => {
        showNotification({
          id: 'export-expense-reports-error',
          title: t('w.error'),
          message: handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        });
      },
    });

  const columns: DataTableColumn<EndOfMonthExpenseReportRow>[] = useMemo(
    () => [
      {
        accessor: 'userFullname',
        title: t('w.employee'),
        sortable: true,
        ellipsis: true,
        render: ({ userId, userFullname }) => (
          <GoToUserButton userId={userId} userFullname={userFullname} />
        ),
      },
      {
        accessor: 'userDivisionName',
        title: t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: ({ userDivisionName }) => <UnitBadge unit={userDivisionName} />,
      },
      {
        accessor: 'statusState',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ statusState }) => (
          <ExpenseStatusBadge status={{ state: statusState }} />
        ),
      },
      {
        accessor: 'inProgress',
        title: t('w.inProgress'),
        sortable: true,
        ellipsis: true,
        render: ({ processed, refundProcessing, waiting }) =>
          processed + refundProcessing + waiting,
      },
      {
        accessor: 'refunded',
        title: t('expenseReport.status.state.REFUNDED'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'rejected',
        title: t('expenseReport.status.state.REJECTED'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        render: ({ userId }) => (
          <Group gap={4} justify="end" wrap={'nowrap'}>
            <CustomTooltip label={t('w.see')}>
              <ActionIcon
                size="sm"
                color="green"
                variant={'subtle'}
                onClick={() => {
                  setOpenedUserId(userId);
                }}
              >
                <IconEye size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    []
  );

  const stillExpenseReportsToReimburse = useMemo(
    () =>
      !rows.every(
        (item) =>
          item.statusState === 'REFUNDED' || item.statusState === 'REJECTED'
      ),
    [rows]
  );

  const [selectedRows, setSelectedRows] = useState<
    EndOfMonthExpenseReportRow[]
  >([]);

  function reimburseMultiple(_selectedRows: EndOfMonthExpenseReportRow[]) {
    const ids: string[] = [];
    _selectedRows.forEach((item) => {
      item.values.forEach((elt) => {
        if (
          elt.status?.state !== 'REFUNDED' &&
          elt.status?.state !== 'REJECTED'
        ) {
          ids.push(elt.id);
        }
      });
    });
    reimburse(ids);
  }

  const leftCustomActions = useMemo(() => {
    if (stillExpenseReportsToReimburse) {
      return [
        <Button
          key={'reimburse-user-expense-reports'}
          leftSection={<IconChecks />}
          disabled={!selectedRows.length}
          onClick={() => reimburseMultiple(selectedRows)}
        >
          {t('w.markedAsReimbursed')}
        </Button>,
        <Badge
          key={'thereAreStillExpenseReportsToReimburse'}
          leftSection={<IconInfoCircle color={'#E70D4F'} />}
          size="xl"
          radius="md"
          color={'#E70D4F1A'}
          styles={{ label: { textTransform: 'none', color: '#E70D4F' } }}
        >
          {t('w.thereAreStillExpenseReportsToReimburse')}
        </Badge>,
      ];
    }

    return [
      <Badge
        key={'allExpensesAreReimbursed'}
        leftSection={<IconCheck color={'#0A9182'} />}
        size="xl"
        radius="md"
        color={'#CEE9E6'}
        styles={{ label: { textTransform: 'none', color: '#0A9182' } }}
      >
        {t('w.allExpensesAreReimbursed')}
      </Badge>,
    ];
  }, [stillExpenseReportsToReimburse, selectedRows]);

  const rightCustomActions = useMemo(
    () => [
      <CustomMenu
        key={'end-of-month-export'}
        buttonLabel={t('w.export')}
        buttonVariant={'filled'}
        leftSection={<IconTableExport />}
        menuLabel={t('w.entityVia', t('w.expenseReports'))}
        menuItems={[
          {
            label: 'XLSX',
            icon: <IconFileSpreadsheet />,
            onClick: () => exportExpenseReports(selectedRows),
          },
        ]}
        buttonLoading={isExportLoading}
        buttonDisabled={!selectedRows.length}
      />,
    ],
    [selectedRows, isExportLoading]
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: 'userFullname', direction: 'asc' },
    search: {
      accessor: 'userFullname',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <>
      <Table
        pinFirstColumn
        rows={rows}
        options={options}
        lang={lang}
        withTableBorder={false}
        columns={columns}
        fetching={isExpenseReportsLoading}
        onSelect={(users) =>
          setSelectedRows(users as EndOfMonthExpenseReportRow[])
        }
        idAccessor={'userId'}
        onRowClick={({ record }) => setOpenedUserId(record.userId)}
        leftCustomActions={leftCustomActions}
        rightCustomActions={rightCustomActions}
        height={'calc(100vh - 210px)'}
      />
      {!!modalData && (
        <CustomModal
          fullScreen
          opened={!!modalData}
          onClose={() => setOpenedUserId(null)}
          title={`${t('w.expenseReports')} - ${modalData.userFullname} - ${t(
            'w.unit'
          )} : ${modalData.userDivisionName}`}
          size={'xl'}
          zIndex={202}
        >
          <EndOfMonthValidationExpenseReports
            expenseReports={modalData.values}
            userFullname={modalData.userFullname}
            company={company}
          />
        </CustomModal>
      )}
    </>
  );
}
