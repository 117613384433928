import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useAuth } from '../contexts/AuthProvider';
import { useModule } from '../contexts/ModuleProvider';
import ExpenseReportService from '../services/ExpenseReportService';
import LeaveService from '../services/LeaveService';
import { ModuleName } from '../types/api/response/module';
import { BuiltInPermissions, ScopeEnum } from '../types/api/response/role';
import { hasPermission } from '../utils/authorization';
import useCompany from './useCompany';

export default function usePendingValidations() {
  const { user } = useAuth();
  const { getModule } = useModule();
  const { id: companyId } = useCompany(user);

  const {
    data: leavesPendingValidation,
    isLoading: leavesPendingValidationIsLoading,
  } = useQuery({
    queryKey: ['LeaveService.getLeavesPendingValidation', companyId],
    queryFn: () => LeaveService.getLeavesPendingValidation(companyId),
    enabled: hasPermission(
      {
        permission: BuiltInPermissions.ValidateLeavesAbsences,
        scope: ScopeEnum.ALL,
      },
      user,
      companyId
    ),
  });

  const {
    data: expenseReportPendingValidation,
    isLoading: expenseReportPendingValidationIsLoading,
  } = useQuery({
    queryKey: ['ExpenseReportService.getPendingValidations', companyId],
    queryFn: () => ExpenseReportService.getPendingValidations(companyId),
    enabled:
      getModule(ModuleName.ExpenseReport)?.active &&
      hasPermission(
        {
          permission: BuiltInPermissions.ValidateExpenseReports,
          scope: ScopeEnum.ALL,
        },
        user,
        companyId
      ),
  });

  const pendingValidations: PendingValidationsCount = useMemo(() => {
    return {
      leaves: leavesPendingValidation?.length || 0,
      expenseReports: expenseReportPendingValidation?.length || 0,
      total:
        (leavesPendingValidation?.length || 0) +
        (expenseReportPendingValidation?.length || 0),
    };
  }, [leavesPendingValidation, expenseReportPendingValidation]);

  const loading: boolean =
    expenseReportPendingValidationIsLoading || leavesPendingValidationIsLoading;

  return { pendingValidations, loading };
}

type PendingValidationsCount = {
  leaves: number;
  expenseReports: number;
  total: number;
};
