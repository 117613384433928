import { FileWithPath } from '@mantine/dropzone';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import ButtonsDpae from '../../../components/ButtonsDpae';
import { NotificationCard } from '../../../components/NotificationCard/NotificationCard';
import Page, { PageLink } from '../../../components/Page/Page';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import useUserCompanyModules from '../../../hooks/useUserCompanyModules';
import ProfileService from '../../../services/ProfileService';
import {
  CountryAreaEnum,
  CountryEnum,
} from '../../../types/api/response/company';
import {
  type DailyWorkParams,
  ModuleName,
} from '../../../types/api/response/module';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../types/api/response/role';
import { UserResponse } from '../../../types/api/response/user';
import { DocumentPropertySpace } from '../../../types/types';
import { hasPermission } from '../../../utils/authorization';
import { NO_WORK_UNIT } from '../../../variables/GlobalVariables';
import UserActivities from '../../activities/my-activities/components/UserActivities';
import OnBoardingStatusModal from '../../company/employees/components/OnBoardingStatusModal';
import DocumentsTable from '../../documents/components/DocumentsTable';
import ExpenseReportsUser from '../../expense-reports/components/ExpenseReportsUser';
import Payslips from '../../payslips/Payslips';
import Notifications from '../../user-account/component/notifications/Notifications';
import UserProfileLeaves from '../components/UserProfileLeaves';
import UserProfileView from '../components/UserProfileView';
import UserWeekRepartitionForm from '../components/UserWeekRepartitionForm';

dayjs.extend(utc);

export type ContractInfo = {
  userId?: string | null;
  professionalQualifications: string;
  contractType: string;
  otherContractType: string;
  payslipName: string;
  regime: string;
  regimeLevel: string;
  otherRegime: string;
  weeklyHours: number | string;
  monthlyHours: number | string;
  yearlyHours: number | string;
  grossPay: number | string;
  entryDate: string;
  exitDate: string;
  employmentAuthorizationDate: string;
  layoffAuthorizationDate: string;
  comment: string;
  contractFile: string;
  file: FileWithPath[];
  employment: string;
  countryCode: CountryEnum;
  countryArea?: CountryAreaEnum;
};

export default function UserProfileSettings() {
  const { user, access_token } = useAuth();
  const { t } = useI18n();
  let location = useLocation();
  const [activeLink, setActiveLink] = useState<string>(t('w.collaboratorCard'));
  const { userId } = useParams();
  const { getModule } = useUserCompanyModules(userId!);
  const navigate = useNavigate();

  const { data: userProfile } = useQuery({
    queryKey: ['ProfileService.getUserProfile', userId],
    queryFn: () => ProfileService.getUserProfile(access_token, userId),
    onError: () => navigate('/'),
  });
  const { data: dpae } = useQuery({
    enabled: !!userId,
    queryKey: ['ProfileService.getDpae', userId],
    queryFn: () => ProfileService.getDpae(userId!),
  });
  const { data: userContractualInfo, refetch: refetchUserContractualInfo } =
    useQuery({
      enabled:
        userId === user.id ||
        hasPermission(
          [
            {
              permission: BuiltInPermissions.UserContractualInfoEdit,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.UserContractualInfoShow,
              scope: ScopeEnum.ALL,
            },
          ],
          user,
          userProfile?.companyId
        ),
      queryKey: ['ProfileService.getUserContractualInfo', userId],
      queryFn: () => ProfileService.getUserContractualInfo(userId),
    });
  const { data: userPersonalFamily, refetch: refetchUserFamilySituation } =
    useQuery({
      enabled:
        userId === user.id ||
        hasPermission(
          [
            {
              permission: BuiltInPermissions.UserPersonalInfoEdit,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.UserPersonalInfoShow,
              scope: ScopeEnum.ALL,
            },
          ],
          user,
          userProfile?.companyId
        ),
      queryKey: ['ProfileService.getUserPersonalFamilySituation', userId],
      queryFn: () => ProfileService.getUserPersonalFamilySituation(userId),
    });
  const { data: userPersonalInfo, refetch: refetchUserPersonalInfo } = useQuery(
    {
      enabled:
        userId === user.id ||
        hasPermission(
          [
            {
              permission: BuiltInPermissions.UserPersonalInfoEdit,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.UserPersonalInfoShow,
              scope: ScopeEnum.ALL,
            },
          ],
          user,
          userProfile?.companyId
        ),
      queryKey: ['ProfileService.getUserPersonalInfo', userId],
      queryFn: () => ProfileService.getUserPersonalInfo(userId!),
    }
  );
  const { data: userSensitiveInfo, refetch: refetchUserSensitiveInfo } =
    useQuery({
      enabled:
        userId === user.id ||
        hasPermission(
          [
            {
              permission: BuiltInPermissions.UserPersonalInfoEdit,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.UserPersonalInfoShow,
              scope: ScopeEnum.ALL,
            },
          ],
          user,
          userProfile?.companyId
        ),
      queryKey: ['ProfileService.getUserSensitiveInfo', userId],
      queryFn: () => ProfileService.getUserSensitiveInfo(userId),
    });
  const { data: userEmergencyContact, refetch: refetchUserEmergencyContact } =
    useQuery({
      enabled:
        userId === user.id ||
        hasPermission(
          [
            {
              permission: BuiltInPermissions.UserEmergencyContactEdit,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.UserEmergencyContactShow,
              scope: ScopeEnum.ALL,
            },
          ],
          user,
          userProfile?.companyId
        ),
      queryKey: ['ProfileService.getUserEmergencyContact', userId],
      queryFn: () => ProfileService.getUserEmergencyContact(userId),
    });
  const { data: onBoardingInfos, refetch: refetchOBInfos } = useQuery({
    enabled: !!userId,
    queryKey: ['ProfileService.employeeOnBoardingInfos', userId],
    queryFn: () => ProfileService.employeeOnBoardingInfos(userId!),
  });
  const isModuleExpenseReportActiveForCompany = getModule(
    ModuleName.ExpenseReport
  )?.active;
  const isModuleDailyWorkActiveForCompany = getModule(
    ModuleName.DailyWork
  )?.active;
  const isModuleDailyWorkActiveForAll = (
    getModule(ModuleName.DailyWork)?.configuration?.params as DailyWorkParams
  )?.activeForAll;
  const isActiveForUser = Boolean(
    userProfile?.paidFeatures?.find((item) => item.active)
  );

  if (!userId)
    return <Navigate to="/error404" state={{ from: location }} replace />;

  function getLinks(_userProfile: UserResponse) {
    const links: PageLink[] = [
      {
        label: t('w.collaboratorCard'),
        onClick: () => {
          setActiveLink(t('w.collaboratorCard'));
        },
        active: activeLink === t('w.collaboratorCard'),
      },
      {
        label: t('w.leavesAndAbsences'),
        onClick: () => {
          setActiveLink(t('w.leavesAndAbsences'));
        },
        active: activeLink === t('w.leavesAndAbsences'),
      },
    ];

    if (
      isModuleDailyWorkActiveForCompany &&
      hasPermission(
        [
          {
            permission: BuiltInPermissions.ValidateLeavesAbsences,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.AuthorizedUpsertDailyWork,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.AuthorizedReadDailyWork,
            scope: ScopeEnum.ALL,
          },
        ],
        user,
        userProfile?.companyId
      ) &&
      (isModuleDailyWorkActiveForAll || isActiveForUser)
    ) {
      links.push({
        label: t('w.activities'),
        onClick: () => {
          setActiveLink(t('w.activities'));
        },
        active: activeLink === t('w.activities'),
      });
    }

    if (
      (hasPermission(
        [
          {
            permission: BuiltInPermissions.AuthorizedReadEmployeesPayslips,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.AuthorizedReadAllUsersInfo,
            scope: ScopeEnum.ALL,
          },
        ],
        user,
        userProfile?.companyId
      ) ||
        user.id === _userProfile.id) &&
      _userProfile.division?.name !== NO_WORK_UNIT
    ) {
      links.push({
        label: t('w.payslips'),
        onClick: () => {
          setActiveLink(t('w.payslips'));
        },
        active: activeLink === t('w.payslips'),
      });
    }

    if (
      isModuleExpenseReportActiveForCompany &&
      hasPermission(
        {
          permission: BuiltInPermissions.ValidateExpenseReports,
          scope: ScopeEnum.ALL,
        },
        user,
        userProfile?.companyId
      )
    ) {
      links.push({
        label: t('w.expenseReports'),
        onClick: () => {
          setActiveLink(t('w.expenseReports'));
        },
        active: activeLink === t('w.expenseReports'),
      });
    }

    if (
      hasPermission(
        [
          {
            permission: BuiltInPermissions.AuthorizedEditEmployeesPersonalSpace,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.AuthorizedReadEmployeesPersonalSpace,
            scope: ScopeEnum.ALL,
          },
        ],
        user,
        userProfile?.companyId
      )
    ) {
      links.push({
        label: t('w.personalDocuments'),
        onClick: () => {
          setActiveLink(t('w.personalDocuments'));
        },
        active: activeLink === t('w.personalDocuments'),
      });
    }

    links.push({
      label: t('w.weeklySchedule'),
      onClick: () => {
        setActiveLink(t('w.weeklySchedule'));
      },
      active: activeLink === t('w.weeklySchedule'),
    });

    if (userId === user.id) {
      if (
        hasPermission(
          {
            permission: BuiltInPermissions.AuthorizedNotificationReceive,
            scope: ScopeEnum.ALL,
          },
          user,
          userProfile?.companyId
        )
      ) {
        links.push({
          label: t('w.companyNotifications'),
          onClick: () => {
            setActiveLink(t('w.companyNotifications'));
          },
          active: activeLink === t('w.companyNotifications'),
        });
      }

      if (
        hasPermission(
          {
            permission: BuiltInPermissions.ConfigureEmployees,
            scope: ScopeEnum.ALL,
          },
          user,
          userProfile?.companyId
        )
      ) {
        links.push({
          label: t('w.profileNotifications'),
          onClick: () => {
            setActiveLink(t('w.profileNotifications'));
          },
          active: activeLink === t('w.profileNotifications'),
        });
      }
    }

    if (
      onBoardingInfos &&
      !Boolean(userProfile?.onBoardingId) &&
      (hasPermission(
        [
          {
            permission: BuiltInPermissions.AuthorizedReadAllUsersInfo,
            scope: ScopeEnum.ALL,
          },
        ],
        user,
        userProfile?.companyId
      ) ||
        user.id === userProfile?.id)
    ) {
      links.push({
        label: t('w.onBoardingInformations'),
        onClick: () => {
          setActiveLink(t('w.onBoardingInformations'));
        },
        active: activeLink === t('w.onBoardingInformations'),
      });
    }

    if (dpae) {
      links.push({
        label: t('w.dpaeInfo'),
        onClick: () => {
          setActiveLink(t('w.dpaeInfo'));
        },
        active: activeLink === t('w.dpaeInfo'),
      });
    }

    return links;
  }

  function renderContent(_userProfile: UserResponse) {
    switch (activeLink) {
      case t('w.leavesAndAbsences'):
        return (
          <UserProfileLeaves userProfile={_userProfile} showTakeALeaveButton />
        );
      case t('w.activities'):
        return <UserActivities userProfile={_userProfile} />;
      case t('w.payslips'):
        return <Payslips />;
      case t('w.expenseReports'):
        return <ExpenseReportsUser userProfile={_userProfile} />;
      case t('w.weeklySchedule'):
        return <UserWeekRepartitionForm userProfile={_userProfile} />;
      case t('w.companyNotifications'):
        return <Notifications type="company" />;
      case t('w.profileNotifications'):
        return <NotificationCard userId={_userProfile.id} type="user" />;
      case t('w.collaboratorCard'):
        return (
          <UserProfileView
            userProfile={_userProfile}
            userContractualInfo={userContractualInfo}
            userFamilySituation={userPersonalFamily}
            userPersonalInfo={userPersonalInfo}
            userSensitiveInfo={userSensitiveInfo}
            userEmergencyContact={userEmergencyContact}
            refetchUserEmergencyContact={refetchUserEmergencyContact}
            refetchUserPersonalInfo={refetchUserPersonalInfo}
            refetchUserSensitiveInfo={refetchUserSensitiveInfo}
            refetchUserFamilySituation={refetchUserFamilySituation}
            refetchUserContractualInfo={refetchUserContractualInfo}
            refetchOBInfos={refetchOBInfos}
          />
        );
      case t('w.onBoardingInformations'):
        return (
          <>
            <OnBoardingStatusModal
              onBoarding={onBoardingInfos}
              refetchOBInfos={refetchOBInfos}
            />
          </>
        );
      case t('w.dpaeInfo'):
        return (
          <>
            {dpae && (
              <ButtonsDpae dpae={dpae} employee={_userProfile} edit={false} />
            )}
          </>
        );
      case t('w.personalDocuments'):
        return (
          <DocumentsTable
            hasPermissionToEditSpace={hasPermission(
              {
                permission:
                  BuiltInPermissions.AuthorizedEditEmployeesPersonalSpace,
                scope: ScopeEnum.ALL,
              },
              user,
              userProfile?.companyId
            )}
            space={DocumentPropertySpace.PERSONAL}
            userId={_userProfile.id}
            companyId={_userProfile.companyId!}
            accountantCompanyId={null}
          />
        );
      default:
        return null;
    }
  }

  return (
    <>
      {userProfile && (
        <Page
          title={userProfile?.fullname!}
          links={getLinks(userProfile)}
          parent={{
            action: hasPermission(
              {
                permission: BuiltInPermissions.ConfigureEmployees,
                scope: ScopeEnum.ALL,
              },
              user,
              userProfile?.companyId
            )
              ? () => {
                  navigate(`/company/${userProfile.company?.id!}/employees`);
                }
              : undefined,
            label: t('w.employees'),
          }}
        >
          {renderContent(userProfile)}
        </Page>
      )}
    </>
  );
}
