import {
  DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import * as React from 'react';
import { useState } from 'react';

import CustomMonthPickerInput from '../../../components/CustomMonthPickerInput';
import Page from '../../../components/Page/Page';
import { useI18n } from '../../../contexts/I18nProvider';
import { CompanyResponse } from '../../../types/api/response/company';
import { toDateOfDayString } from '../../../utils/format';
import { initPickerDate } from '../../../utils/initPickerDate';
import ByMonth from './byMonth/ByMonth';
import ToReimburse from './toReimburse/ToReimburse';

type Props = {
  company: CompanyResponse;
};

export default function ExpensesTable({ company }: Props) {
  const { t } = useI18n();
  const [activeLink, setActiveLink] = useState<string>(t('w.byMonth'));
  const [selectedDate, setSelectedDate] = useState<
    DateValue | DatesRangeValue | Date[]
  >(initPickerDate());

  function renderContent() {
    switch (activeLink) {
      case t('w.byMonth'):
        return <ByMonth selectedDate={selectedDate} company={company} />;
      case t('w.toReimburse'):
        return <ToReimburse company={company} />;
      default:
        return null;
    }
  }

  return (
    <Page
      title={t('w.expenseReports')}
      parent={{ label: company ? company.name : toDateOfDayString() }}
      links={[
        {
          label: t('w.byMonth'),
          onClick: () => {
            setActiveLink(t('w.byMonth'));
          },
          active: activeLink === t('w.byMonth'),
        },
        {
          label: t('w.toReimburse'),
          onClick: () => {
            setActiveLink(t('w.toReimburse'));
          },
          active: activeLink === t('w.toReimburse'),
        },
      ]}
      headerRightSection={
        activeLink === t('w.byMonth') ? (
          <CustomMonthPickerInput
            key={'month-picker-end-of-month-expense-reports'}
            value={selectedDate}
            onChange={setSelectedDate}
          />
        ) : undefined
      }
    >
      {renderContent()}
    </Page>
  );
}
