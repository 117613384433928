import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Group, List, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconEdit,
  IconEye,
  IconTrash,
  IconUsersGroup,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useEffect, useState } from 'react';

import UnitBadge from '../../../components/badges/UnitBadge';
import CustomTooltip from '../../../components/CustomTooltip';
import GoToUserButton from '../../../components/GoToUserButton/GoToUserButton';
import ImportModal from '../../../components/ImportModal';
import Page, { PageLink } from '../../../components/Page/Page';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import useCompany from '../../../hooks/useCompany';
import useFetchDivisions from '../../../hooks/useFetchDivisions';
import DivisionService from '../../../services/DivisionService';
import ProfileService from '../../../services/ProfileService';
import type { CompanyResponse } from '../../../types/api/response/company';
import { DivisionResponse } from '../../../types/api/response/division';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../types/api/response/role';
import { hasPermission } from '../../../utils/authorization';
import { milliToFullDateString } from '../../../utils/format';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../utils/optionsPreferences';
import saveDownloadedfile from '../../../utils/saveDownloadedFile';
import { NO_WORK_UNIT } from '../../../variables/GlobalVariables';
import WorkUnitDetails from '../../work-units/components/WorkUnitDetails';
import AssignEmployeesToDivisionTable from './components/AssignEmployeesToDivisionTable';
import EditWorkUnitForm from './components/EditWorkUnitForm';
import WorkUnitLabelForm from './components/WorkUnitLabelForm';

const componentName = 'WorkUnits';
export default function WorkUnits() {
  const { user } = useAuth();
  const { t, lang } = useI18n();
  const {
    id: companyId,
    company,
    refetch: refetchCompanyData,
  } = useCompany(user);
  const divisionLabel = company?.divisionLabel;
  const { divisions, isLoading, refetch } = useFetchDivisions({ companyId });
  const [activeLink, setActiveLink] = useState<string | undefined>(undefined);

  const [
    isImportModalOpened,
    { open: openImportModal, close: closeImportModal },
  ] = useDisclosure(false);
  const { mutate: exportDivisions } = useMutation({
    mutationFn: (type: 'XLSX' | 'CSV') =>
      DivisionService.exportDivisions(companyId, type),
    onSuccess: (data, type: 'XLSX' | 'CSV') => {
      saveDownloadedfile(data);
      showNotification({
        id: 'export-divisions-success',
        title: t('w.success'),
        message: t('success.exportDivision', type),
        icon: <IconCheck />,
        color: 'green',
      });
    },
    onError: (error) =>
      showNotification({
        id: 'export-divisions-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconX />,
        color: 'red',
      }),
  });
  const [columns, setColumns] = useState<DataTableColumn<DivisionResponse>[]>(
    []
  );
  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'name',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({ componentName }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'n1User.fullname',
          label: t('w.n1Validator'),
          choices: [],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });
  useEffect(() => {
    const _columns: DataTableColumn<DivisionResponse>[] = [
      {
        accessor: 'name',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
        render: ({ name }) => <UnitBadge unit={name} />,
      },
      {
        accessor: 'nbUsers',
        title: t('w.nbUsers'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'n1User.fullname',
        title: t('w.n1Validator'),
        sortable: true,
        ellipsis: true,
        render: ({ n1User }) =>
          !!n1User?.id && !!n1User?.fullname ? (
            <GoToUserButton userId={n1User.id} userFullname={n1User.fullname} />
          ) : null,
      },
      {
        accessor: 'updated',
        title: t('w.lastUpdated'),
        sortable: true,
        ellipsis: true,
        render: ({ updated }) =>
          updated ? milliToFullDateString(updated) : '',
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: '0%',
        render: (division) => (
          <Group gap={4} justify="end" wrap={'nowrap'}>
            <CustomTooltip label={t('w.see')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="green"
                onClick={
                  !!company ? () => openView(division, company) : undefined
                }
              >
                <IconEye size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.edit')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="blue"
                disabled={!hasAuthority(division)}
                onClick={
                  !!company ? () => openEdit(division, company) : undefined
                }
              >
                <IconEdit size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip
              label={t(
                'division.message.assign',
                division.name === NO_WORK_UNIT
                  ? t('NO_WORK_UNIT')
                  : division.name
              )}
            >
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="blue"
                disabled={!hasAuthority(division)}
                onClick={
                  !!company ? () => openAssign(division, company) : undefined
                }
              >
                <IconUsersGroup size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip
              label={
                division.name === NO_WORK_UNIT
                  ? t('w.notDeletable')
                  : t('w.delete')
              }
            >
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="red"
                disabled={
                  division.name === NO_WORK_UNIT || !hasAuthority(division)
                }
                onClick={() => openDelete(division)}
              >
                <IconTrash size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ];
    const _options: Options = { ...options };
    if (company?.useN2) {
      _columns.splice(3, 0, {
        accessor: 'n2User.fullname',
        title: t('w.n2Validator'),
        sortable: true,
        ellipsis: true,
        render: ({ n2User }) => (n2User?.fullname ? n2User.fullname : ''),
      });

      _options?.filter?.splice(1, 1);

      _options?.filter?.splice(1, 0, {
        accessor: 'n2User.fullname',
        label: t('w.n2Validator'),
        choices: [],
        selectedChoices: [],
      });
    }
    if (company?.useAssistant) {
      _columns.splice(4, 0, {
        accessor: 'assistantUser.fullname',
        title: t('w.assistant'),
        sortable: true,
        ellipsis: true,
        render: ({ assistantUser }) =>
          assistantUser?.fullname ? assistantUser.fullname : '',
      });

      _options?.filter?.splice(2, 1);

      _options?.filter?.splice(2, 0, {
        accessor: 'assistantUser.fullname',
        label: t('w.assistant'),
        choices: [],
        selectedChoices: [],
      });
    }

    setColumns(_columns);
  }, [company, divisions, options]);

  useEffect(() => {
    if (divisions?.length) {
      let _options: Options = { ...options };
      const validatorsN1 = divisions
        .filter((item) => item.n1)
        .map((item) => item.n1User?.fullname!);
      _options = buildOptionsFilter(validatorsN1, 0);

      if (company?.useN2) {
        const validatorsN2 = divisions
          .filter((item) => item.n2)
          .map((item) => item.n2User?.fullname!);
        _options = buildOptionsFilter(validatorsN2, 1);
      }

      if (company?.useAssistant) {
        const validatorsAssistant = divisions
          .filter((item) => item.assistant)
          .map((item) => item.assistantUser?.fullname!);
        _options = buildOptionsFilter(validatorsAssistant, 2);
      }

      setOptions(_options);
    }
  }, [divisions, company]);
  function buildOptionsFilter(array: string[], filterIndex: number): Options {
    const uniqueArr = array.filter(
      (item: string, index: number) => array.indexOf(item) === index
    );

    const result = uniqueArr.map((item: string) => {
      return {
        value: item,
        label: item,
      };
    });

    const _options: Options = { ...options };
    if (_options?.filter?.[filterIndex]?.choices) {
      _options.filter[filterIndex].choices = result;
    }
    return _options;
  }
  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }
  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  const { mutate: openDelete } = useMutation({
    mutationFn: (division: DivisionResponse) =>
      ProfileService.listUsersByDivision(division.id, ['valid']),
    onSuccess: (data, division: DivisionResponse) => {
      const activeEmployees = data.filter((employee) => employee.valid);
      if (activeEmployees.length === 0) {
        modals.openConfirmModal({
          title: (
            <Title size={'h3'} component="p">
              {`${t('w.delete')} ${division.name}`}
            </Title>
          ),
          labels: { confirm: t('w.delete'), cancel: t('w.cancel') },
          size: 'xl',
          onConfirm: () => deleteDivision(division),
        });
      } else {
        modals.openConfirmModal({
          title: (
            <Title size={'h3'} component="p">
              {`${t('w.delete')} ${division.name}`}
            </Title>
          ),
          children: (
            <Text c={'dimmed'}>
              {t('division.message.delete', division.name)}
            </Text>
          ),
          labels: { confirm: t('w.delete'), cancel: t('w.cancel') },
          size: 'xl',
          onConfirm: () => deleteDivision(division),
        });
      }
    },
    onError: (error, division: DivisionResponse) =>
      showNotification({
        id: `usersListFromDivision-${division.id}-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: deleteDivision } = useMutation({
    mutationFn: (division: DivisionResponse) =>
      DivisionService.deleteDivision(division.id),
    onSuccess: (data) => {
      showNotification({
        id: `delete-division-${data?.id}-success`,
        title: t('w.success'),
        message: `${data?.name} ${t('w.deleted').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
    },
    onError: (error, division: DivisionResponse) =>
      showNotification({
        id: `delete-division-${division?.id}-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function openView(division: DivisionResponse, _company: CompanyResponse) {
    modals.open({
      modalId: `view-division-${division.id}`,
      title: (
        <Title component="p">
          {division.name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : division.name}
        </Title>
      ),
      fullScreen: true,
      children: (
        <WorkUnitDetails
          divisions={divisions}
          company={_company}
          divisionConsultedId={division.id}
        />
      ),
    });
  }

  function openAssign(division: DivisionResponse, _company: CompanyResponse) {
    modals.open({
      modalId: `assign-division-${division.id}`,
      title: (
        <Title component="p">
          {t(
            'division.message.assign',
            division.name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : division.name
          )}
        </Title>
      ),
      fullScreen: true,
      children: (
        <AssignEmployeesToDivisionTable
          division={division}
          company={_company}
        />
      ),
    });
  }

  function openEdit(division: DivisionResponse, _company: CompanyResponse) {
    modals.open({
      modalId: `edit-division-${division.id}`,
      title: (
        <Title component="p">
          {`${t('w.edit')} ${
            division.name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : division.name
          }`}
        </Title>
      ),
      size: 'xl',
      children: (
        <EditWorkUnitForm
          onClose={() => modals.close(`edit-division-${division.id}`)}
          refetch={refetch}
          divisionId={division.id}
          company={_company}
        />
      ),
    });
  }

  function hasAuthority(division: DivisionResponse) {
    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigureWorkUnit,
          scope: ScopeEnum.ALL,
        },
        user,
        companyId
      )
    ) {
      return true;
    }

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigureWorkUnit,
          scope: ScopeEnum.TEAM,
        },
        user,
        companyId
      )
    ) {
      return (
        division.n1 === user.id ||
        division.n2 === user.id ||
        division.assistant === user.id
      );
    }

    return false;
  }

  function getLinks() {
    const links: PageLink[] = [];

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigureWorkUnit,
          scope: ScopeEnum.ALL,
        },
        user,
        companyId
      )
    ) {
      links.push({
        active: false,
        label: t('division.import.xlsxOrCsv'),
        onClick: () => {
          setActiveLink(undefined);
          openImportModal();
        },
      });
    }

    links.push({
      active: false,
      label: t('division.export.xlsx'),
      onClick: () => {
        setActiveLink(undefined);
        exportDivisions('XLSX');
      },
    });

    links.push({
      active: false,
      label: t('division.export.csv'),
      onClick: () => {
        setActiveLink(undefined);
        exportDivisions('CSV');
      },
    });

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigureWorkUnit,
          scope: ScopeEnum.ALL,
        },
        user,
        companyId
      )
    ) {
      links.push({
        active: activeLink === t('division.rename'),
        label: t('division.rename'),
        onClick: () => setActiveLink(t('division.rename')),
        children: (
          <Stack gap="sm">
            <Text fz="xs" c="dimmed">
              {t('division.message.edit')}
            </Text>
            <List withPadding>
              <List.Item fz="xs" c="dimmed">
                {t('w.shop')}
              </List.Item>
              <List.Item fz="xs" c="dimmed">
                {t('w.department')}
              </List.Item>
              <List.Item fz="xs" c="dimmed">
                {t('w.factory')}
              </List.Item>
              <List.Item fz="xs" c="dimmed">
                {t('w.company')} etc...
              </List.Item>
            </List>
            <Text fz="xs" c="dimmed">
              {t('division.message.noWorkUnitInfo')}
            </Text>

            <WorkUnitLabelForm
              label={divisionLabel ? divisionLabel : t('w.units')}
              refetchCompanyData={refetchCompanyData}
            />
          </Stack>
        ),
      });
    }

    return links;
  }

  return (
    <Page
      title={divisionLabel ? divisionLabel : t('w.units')}
      parent={{ label: t('w.configuration') }}
      links={getLinks()}
      add={
        hasPermission(
          {
            permission: BuiltInPermissions.ConfigureWorkUnit,
            scope: ScopeEnum.ALL,
          },
          user,
          companyId
        ) && !!company
          ? {
              label: `${t('w.add')} ${
                divisionLabel ? divisionLabel : t('w.unit')
              }`,
              onClick: () => {
                setActiveLink(undefined);
                modals.open({
                  modalId: 'create-division',
                  title: (
                    <Title component="p">{`${t('w.add')} ${
                      divisionLabel ? divisionLabel : t('w.unit')
                    }`}</Title>
                  ),
                  size: 'xl',
                  children: (
                    <EditWorkUnitForm
                      onClose={() => modals.close('create-division')}
                      refetch={refetch}
                      company={company}
                    />
                  ),
                });
              },
            }
          : undefined
      }
    >
      <Group grow preventGrowOverflow={false} wrap="nowrap">
        <Table
          pinFirstColumn
          pinLastColumn
          rows={divisions}
          withTableBorder={false}
          options={options}
          columns={columns}
          lang={lang}
          fetching={isLoading}
          width="0vw" // autoscale width
        />
      </Group>
      <ImportModal
        opened={isImportModalOpened}
        onClose={closeImportModal}
        title={t('w.import')}
        companyId={companyId}
        refetch={refetch}
        importType={'divisions'}
      />
    </Page>
  );
}
