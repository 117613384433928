import {
  Box,
  Fieldset,
  LoadingOverlay,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import {
  type DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';

import CustomMonthPickerInput from '../../../components/CustomMonthPickerInput';
import Page from '../../../components/Page/Page';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import useCompany from '../../../hooks/useCompany';
import useCompanyModules from '../../../hooks/useCompanyModules';
import useFetchDivisions from '../../../hooks/useFetchDivisions';
import EndOfMonthService from '../../../services/EndOfMonthService';
import PayslipService from '../../../services/PayslipService';
import {
  ModuleName,
  SynchroOpenpayeParams,
} from '../../../types/api/response/module';
import { BuiltInRoles } from '../../../types/api/response/role';
import { PayslipMonthResponseFront } from '../../../types/types';
import { hasRole } from '../../../utils/authorization';
import { toDateOfDayString } from '../../../utils/format';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import { initPickerDate } from '../../../utils/initPickerDate';
import ImportPayslipsFormStepper from './components/ImportPayslipsFormStepper';
import PayslipsStatusTabs from './components/PayslipsStatusTabs';
import PayslipsTreatmentsBadge from './components/treatments/PayslipsTreatmentsBadge';

type Props = {
  accountantCompanyId?: string;
  customerCompanyId?: string;
  customerSelectedDate?: DateValue | DatesRangeValue | Date[];
};

export default function EndOfMonthPayslips({
  accountantCompanyId,
  customerCompanyId,
  customerSelectedDate,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyIdCustomer, company } = useCompany(
    user,
    customerCompanyId
  );
  const { id: companyIdAccountant } = useCompany(user, accountantCompanyId);
  const { getModule } = useCompanyModules(companyIdAccountant);
  const moduleOpenpaye = getModule(ModuleName.SynchroOpenPaye);
  const moduleOpenpayeParams = moduleOpenpaye?.configuration
    ?.params as SynchroOpenpayeParams;
  const [selectedDate, setSelectedDate] = useState<
    DateValue | DatesRangeValue | Date[]
  >(customerSelectedDate ? customerSelectedDate : initPickerDate());
  const [key, setKey] = useState(0);
  const { divisions } = useFetchDivisions({ companyId: companyIdCustomer });

  const {
    data: payslipsMonthInfoData,
    refetch: refetchPayslipsMonthInfoData,
    isLoading,
    isFetching,
    isSuccess,
  } = useQuery({
    enabled: false,
    queryKey: ['PayslipService.getPayslipsMonthInfo', companyIdCustomer],
    queryFn: () =>
      PayslipService.getPayslipsMonthInfo(
        companyIdCustomer,
        // @ts-ignore
        dayjs(selectedDate).month() + 1,
        // @ts-ignore
        dayjs(selectedDate).year()
      ),
  });

  const { data: payslipTreatmentsData, refetch: refetchPayslipTreatmentsData } =
    useQuery({
      queryKey: [
        'PayslipService.getPayslipsTreatmentForCompanyId',
        companyIdCustomer,
      ],
      queryFn: () =>
        PayslipService.getPayslipsTreatmentForCompanyId(companyIdCustomer),
      onSuccess: () => refetchPayslipsMonthInfoData(),
    });

  const { mutate: synchroOpenpayePayslips } = useMutation({
    mutationFn: () =>
      EndOfMonthService.synchroOpenpayePayslips(
        companyIdAccountant,
        companyIdCustomer,
        // @ts-ignore
        dayjs(selectedDate).month() + 1,
        // @ts-ignore
        dayjs(selectedDate).year()
      ),
    onSuccess: () => {
      showNotification({
        id: 'success-synchroOpenpayePayslips',
        message: t('w.success'),
        color: 'green',
        icon: <IconX />,
      });
    },
    onError: (error) => {
      showNotification({
        id: 'error-synchroOpenpayePayslips',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      });
    },
  });

  const pendingTreatment = useMemo(() => {
    if (!payslipTreatmentsData) {
      return [];
    }
    return payslipTreatmentsData.filter(
      (treatment) => treatment.state === 'PROCESSING'
    );
  }, [payslipTreatmentsData]);

  const successTreatment = useMemo(() => {
    if (!payslipTreatmentsData) {
      return [];
    }
    return payslipTreatmentsData.filter(
      (treatment) => treatment.state === 'SUCCESS'
    );
  }, [payslipTreatmentsData]);

  const errorTreatment = useMemo(() => {
    if (!payslipTreatmentsData) {
      return [];
    }
    return payslipTreatmentsData.filter(
      (treatment) => treatment.state === 'ERROR'
    );
  }, [payslipTreatmentsData]);

  const payslipsMonthInfo: PayslipMonthResponseFront | undefined =
    useMemo(() => {
      if (!payslipsMonthInfoData) {
        return undefined;
      }

      const _payslipsMonthInfoData: PayslipMonthResponseFront = cloneDeep(
        payslipsMonthInfoData
      );

      _payslipsMonthInfoData.ok = _payslipsMonthInfoData.ok.map(
        (userPayslip) => ({
          ...userPayslip,
          division: cloneDeep(
            divisions?.find((elt) => elt.id === userPayslip.divisionId)
          ),
        })
      );

      _payslipsMonthInfoData.notFound = _payslipsMonthInfoData.notFound.map(
        (userPayslip) => ({
          ...userPayslip,
          division: cloneDeep(
            divisions?.find((elt) => elt.id === userPayslip.divisionId)
          ),
        })
      );

      _payslipsMonthInfoData.new = _payslipsMonthInfoData.new.map(
        (userPayslip) => ({
          ...userPayslip,
          division: cloneDeep(
            divisions?.find((elt) => elt.id === userPayslip.divisionId)
          ),
        })
      );

      if (
        !hasRole(BuiltInRoles.ADMINISTRATOR, user, companyIdCustomer) &&
        !hasRole(BuiltInRoles.DIRECTION, user, companyIdCustomer)
      ) {
        _payslipsMonthInfoData.ok = _payslipsMonthInfoData.ok.filter(
          (item) =>
            item.division?.n1 === user.id ||
            item.division?.n2 === user.id ||
            item.division?.assistant === user.id
        );
        _payslipsMonthInfoData.notFound =
          _payslipsMonthInfoData.notFound.filter(
            (item) =>
              item.division?.n1 === user.id ||
              item.division?.n2 === user.id ||
              item.division?.assistant === user.id
          );
        _payslipsMonthInfoData.new = _payslipsMonthInfoData.new.filter(
          (item) =>
            item.division?.n1 === user.id ||
            item.division?.n2 === user.id ||
            item.division?.assistant === user.id
        );
      }

      return _payslipsMonthInfoData;
    }, [payslipsMonthInfoData, divisions]);

  useEffect(() => {
    refetchPayslipTreatmentsData();
  }, [selectedDate]);

  return (
    <Page
      title={t('w.payslips')}
      parent={{ label: company ? company.name : toDateOfDayString() }}
      links={
        Boolean(moduleOpenpaye?.active) && Boolean(moduleOpenpayeParams)
          ? [
              {
                active: false,
                label: t('openpaye.getPayslips'),
                onClick: () => {
                  showNotification({
                    id: 'loading-synchroOpenpayePayslips',
                    message: t('w.isLoading'),
                    color: 'green',
                    icon: <IconX />,
                  });
                  synchroOpenpayePayslips();
                },
              },
            ]
          : undefined
      }
      headerRightSection={
        <CustomMonthPickerInput
          key={'month-picker-end-of-month-payslips'}
          value={selectedDate}
          onChange={setSelectedDate}
        />
      }
    >
      <Fieldset
        legend={
          <Text size="xl" fw="bold">
            {t('w.import')}
          </Text>
        }
      >
        <Box pos="relative" style={{ zIndex: 9 }}>
          <ImportPayslipsFormStepper
            key={`${key}-import-payslips-modal}`}
            companyId={companyIdCustomer}
            closeModal={() => setKey((prevKey) => prevKey + 1)}
          />
        </Box>
      </Fieldset>
      <LoadingOverlay visible={isLoading || isFetching} />
      <Fieldset
        variant="filled"
        mt="md"
        legend={
          <Text size="xl" fw="bold">
            {t('w.importResult')}
          </Text>
        }
      >
        <Stack>
          <PayslipsTreatmentsBadge
            pendingTreatment={pendingTreatment}
            successTreatment={successTreatment}
            errorTreatment={errorTreatment}
            companyId={companyIdCustomer}
            setSelectedDate={setSelectedDate}
          />
          {!isFetching && !isLoading && isSuccess ? (
            <PayslipsStatusTabs
              companyId={companyIdCustomer}
              payslipsMonthInfo={payslipsMonthInfo}
              selectedDate={selectedDate}
            />
          ) : (
            <Stack align={'center'} mt={'xl'} gap={'md'}>
              <Title size={'h3'} c={'hifivework.3'}>
                {t('w.noPayslipsImported')}
              </Title>
            </Stack>
          )}
        </Stack>
      </Fieldset>
    </Page>
  );
}
