import { Group } from '@mantine/core';

import Page from '../../../components/Page/Page';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import useCompany from '../../../hooks/useCompany';
import ShuttleSheetsMonitoringTable from '../../shuttle-sheets/components/ShuttleSheetsMonitoringTable';

export default function AccountantCustomerShuttleSheet() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  return (
    <Page
      title={t('w.customersShuttleSheets')}
      parent={{ label: t('w.configuration') }}
    >
      <Group grow preventGrowOverflow={false} wrap="nowrap">
        <ShuttleSheetsMonitoringTable companyId={companyId} isAccountant />
      </Group>
    </Page>
  );
}
