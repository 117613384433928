import { Space, Text } from '@mantine/core';
import { useMemo } from 'react';

import CustomModal from '../../../../components/CustomModal';
import { useI18n } from '../../../../contexts/I18nProvider';
import type { CompanyAccountantInfoViewClient } from '../../../../types/api/response/accountant';
import { UserResponse } from '../../../../types/api/response/user';
import AccountantDivisionsCustomerCompanyTable from './AccountantDivisionsCustomerCompanyTable';
import AssignGeneralManagersForm from './AssignGeneralManagersForm';

type Props = {
  opened: boolean;
  onClose: () => void;
  accountantsToConfigure: CompanyAccountantInfoViewClient | null;
  refetchAccountantCustomersData: () => void;
  accountantEmployeesData: UserResponse[];
};

export default function AccountantConfigModal({
  opened,
  onClose,
  accountantsToConfigure,
  refetchAccountantCustomersData,
  accountantEmployeesData,
}: Props) {
  const { t } = useI18n();

  const accountantEmployees = useMemo(() => {
    if (!accountantEmployeesData) {
      return [];
    }
    return accountantEmployeesData
      .filter((item) => item.valid)
      .map((employee) => {
        return {
          value: employee.id,
          label: employee.fullname as string,
        };
      });
  }, [accountantEmployeesData]);

  return (
    <CustomModal
      opened={opened}
      onClose={onClose}
      fullScreen
      title={accountantsToConfigure?.customerCompany?.name}
    >
      <AssignGeneralManagersForm
        accountantsToConfigure={accountantsToConfigure}
        accountantEmployees={accountantEmployees}
        refetchAccountantCustomersData={refetchAccountantCustomersData}
      />
      <Text fz={'xl'} fw={'bold'} c={'dimmed'} mt="md">
        {accountantsToConfigure?.customerCompany?.divisionLabel
          ? accountantsToConfigure.customerCompany.divisionLabel
          : t('w.unit')}
      </Text>
      <Space h="xl" />
      <AccountantDivisionsCustomerCompanyTable
        accountantsToConfigure={accountantsToConfigure}
        accountantEmployees={accountantEmployees}
        refetchAccountantCustomersData={refetchAccountantCustomersData}
      />
    </CustomModal>
  );
}
