import { Button, Group, LoadingOverlay, Title } from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconCircleArrowLeft,
  IconCircleArrowRight,
  IconCopyCheck,
  IconDeviceFloppy,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React from 'react';

import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import useResponsive from '../../../../../hooks/useResponsive';
import ProfileService from '../../../../../services/ProfileService';
import { UserPersonalSensitiveDocument } from '../../../../../types/api/payload/user';
import type {
  UserPersonalInfo,
  UserPersonalSensitive,
  UserResponse,
} from '../../../../../types/api/response/user';
import SensitiveInfoStepper from './SensitiveInfoStepper';

type Props = {
  onClose?: () => void;
  userProfile: UserResponse;
  refetch: () => void;
  userPersonalInfo: UserPersonalInfo | null;
  userSensitiveInfo?: UserPersonalSensitive | null;
  setActive: (number: number) => void;
  active: number;
  setSensitiveInfo: (data: UserPersonalSensitive) => void;
  refetchUserSensitiveInfo?: () => void;
  validateEmployeeOnBoarding: (variables: {
    userId: string;
    employee: boolean;
    accountant: boolean;
  }) => void;
  refetchOBInfos?: () => void;
  finalValidateEmployeeOnBoarding: (variables: { userId: string }) => void;
};

export interface AddEmployeeSensitiveFormValues {
  id?: string | null;
  userId?: string;
  identityCardNumber?: string;
  identityCardFiles?: FileWithPath[];
  drivingLicenseFiles?: FileWithPath[];
  ssNumber?: string;
  ssFiles?: FileWithPath[];
  handicappedWorker?: boolean;
  iban?: string;
  bic?: string;
  residencePermitNumber?: string;
  residencePermitFiles?: FileWithPath[];
  residencePermitExpirationDate?: Date | string | null;
  ibanFiles?: FileWithPath[];
  medicalCheckupDate?: Date | string | null;
  medicalCheckupResultFiles?: FileWithPath[];
  medicalCheckupFiles?: FileWithPath[];
}

export default function AddEmployeeSensitiveForm({
  userProfile,
  refetch,
  onClose,
  userPersonalInfo,
  userSensitiveInfo,
  setActive,
  active,
  setSensitiveInfo,
  refetchUserSensitiveInfo,
  validateEmployeeOnBoarding,
  refetchOBInfos,
  finalValidateEmployeeOnBoarding,
}: Props) {
  const { t } = useI18n();
  const { user, access_token } = useAuth();
  const { isDesktop } = useResponsive();

  const form = useForm<AddEmployeeSensitiveFormValues>({
    initialValues: {
      id:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.id
            ? userSensitiveInfo.id
            : null
          : null,
      userId: userProfile.id,
      identityCardNumber:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.identityCardNumber
            ? userSensitiveInfo.identityCardNumber
            : ''
          : '',
      identityCardFiles: [],
      drivingLicenseFiles: [],
      ssNumber:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.ssNumber
            ? userSensitiveInfo.ssNumber
            : ''
          : '',
      ssFiles: [],
      handicappedWorker:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.handicappedWorker
            ? userSensitiveInfo.handicappedWorker
            : false
          : false,
      iban:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.iban
            ? userSensitiveInfo.iban
            : ''
          : '',
      bic:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.bic
            ? userSensitiveInfo.bic
            : ''
          : '',
      residencePermitNumber:
        userSensitiveInfo !== null
          ? userSensitiveInfo?.residencePermitNumber
            ? userSensitiveInfo.residencePermitNumber
            : ''
          : '',
      residencePermitFiles: [],
      residencePermitExpirationDate:
        userSensitiveInfo?.residencePermitExpirationDate
          ? dayjs(userSensitiveInfo.residencePermitExpirationDate).toDate()
          : null,
      ibanFiles: [],
      medicalCheckupDate: userSensitiveInfo?.medicalCheckupDate
        ? dayjs(userSensitiveInfo.medicalCheckupDate).toDate()
        : null,
      medicalCheckupResultFiles: [],
      medicalCheckupFiles: [],
    },
  });

  const {
    mutate: createOrUpdateUserSensitiveInfo,
    isLoading: isLoadingCreateOrUpdateUserSensitiveInfo,
  } = useMutation({
    mutationFn: (payload: AddEmployeeSensitiveFormValues) =>
      ProfileService.createOrUpdateUserSensitiveInfo(
        userProfile.id,
        payload,
        access_token
      ),
    onSuccess: (data, _payload) => {
      if (!!_payload.residencePermitFiles?.length) {
        updateUserPersonalSensitiveDocument({
          type: UserPersonalSensitiveDocument.residencePermit,
          file: _payload.residencePermitFiles[0],
        });
      }
      if (!!_payload.ssFiles?.length) {
        updateUserPersonalSensitiveDocument({
          type: UserPersonalSensitiveDocument.securitySocial,
          file: _payload.ssFiles[0],
        });
      }
      if (!!_payload.drivingLicenseFiles?.length) {
        updateUserPersonalSensitiveDocument({
          type: UserPersonalSensitiveDocument.drivingLicense,
          file: _payload.drivingLicenseFiles[0],
        });
      }
      if (!!_payload.identityCardFiles?.length) {
        updateUserPersonalSensitiveDocument({
          type: UserPersonalSensitiveDocument.identityCard,
          file: _payload.identityCardFiles[0],
        });
      }
      if (!!_payload.ibanFiles?.length) {
        updateUserPersonalSensitiveDocument({
          type: UserPersonalSensitiveDocument.iban,
          file: _payload.ibanFiles[0],
        });
      }
      if (!!_payload.medicalCheckupFiles?.length) {
        updateUserPersonalSensitiveDocument({
          type: UserPersonalSensitiveDocument.medicalCheckup,
          file: _payload.medicalCheckupFiles[0],
        });
      }
      if (!!_payload.medicalCheckupResultFiles?.length) {
        updateUserPersonalSensitiveDocument({
          type: UserPersonalSensitiveDocument.medicalCheckupResult,
          file: _payload.medicalCheckupResultFiles[0],
        });
      }
      if (refetchOBInfos) {
        refetchOBInfos();
      }
      showNotification({
        id: 'udpate-added-employee-work-contract-success',
        title: t('w.success'),
        message: t('success.informationUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
      setSensitiveInfo(data);
      if (refetchUserSensitiveInfo) {
        refetchUserSensitiveInfo();
      }
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-work-contract-error',
        title: t('w.error'),
        message: t('error.updateUserProfile', userProfile.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: updateUserPersonalSensitiveDocument } = useMutation({
    mutationFn: (payload: {
      type: UserPersonalSensitiveDocument;
      file: FileWithPath;
    }) =>
      ProfileService.updateUserPersonalSensitiveDocument(
        userProfile.id,
        payload.file,
        access_token,
        payload.type
      ),
    onSuccess: () => {
      refetch();
    },
    onError: () =>
      showNotification({
        id: 'updateUserPersonalSensitiveDocument-error',
        title: t('w.error'),
        message: t('error.updateUserProfile', userProfile.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleSensitiveSubmitForm(values: AddEmployeeSensitiveFormValues) {
    createOrUpdateUserSensitiveInfo({
      ...values,
      id: values.id !== null ? values.id : null,
      medicalCheckupDate: values.medicalCheckupDate
        ? dayjs(values.medicalCheckupDate).format('YYYY-MM-DDTHH:mm:ss')
        : null,
      residencePermitExpirationDate: values.residencePermitExpirationDate
        ? dayjs(values.residencePermitExpirationDate).format('YYYY-MM-DD')
        : null,
    });
  }

  function openConfirmOnBoarding(values: AddEmployeeSensitiveFormValues) {
    modals.openConfirmModal({
      modalId: 'confirm-valid-on-boarding',
      title:
        user.id === userProfile.id ? (
          <Title size={'h3'} component="p">
            {t('w.confirmOnBoardingInfos')}
          </Title>
        ) : userProfile.onBoarding?.onBoardingEmployee?.validationDate !==
            null &&
          userProfile.onBoarding?.onBoardingCompany !== null &&
          userProfile.onBoarding?.onBoardingCompany?.validationDate &&
          userProfile.onBoarding?.onBoardingCompany?.validationDate
            ?.dateOfUpdate !== null ? (
          <Title size={'h3'} component="p">
            {'Vous allez finaliser la pré-embauche de votre collaborateur'}
          </Title>
        ) : (
          <Title size={'h3'} component="p">
            {t('w.confirmOnBoardingInfosCompany')}
          </Title>
        ),
      size: 'xl',
      labels: { cancel: t('w.cancel'), confirm: t('w.confirm') },
      onConfirm: () => validateOnBoarding(values),
    });
  }

  function validateOnBoarding(values: AddEmployeeSensitiveFormValues) {
    if (userProfile !== null) {
      if (user.id !== userProfile.id) {
        if (
          userProfile.onBoarding?.onBoardingEmployee?.validationDate !== null &&
          userProfile?.onBoarding?.onBoardingCompany !== null &&
          userProfile.onBoarding?.onBoardingCompany?.validationDate &&
          userProfile?.onBoarding?.onBoardingCompany?.validationDate
            ?.dateOfUpdate !== null
        ) {
          finalValidateEmployeeOnBoarding({ userId: userProfile.id });
        } else {
          validateEmployeeOnBoarding({
            userId: userProfile.id,
            employee: false,
            accountant: false,
          });
        }
      } else {
        validateEmployeeOnBoarding({
          userId: userProfile.id,
          employee: true,
          accountant: false,
        });
      }
      handleSensitiveSubmitForm(values);
    }
  }

  function isDisabledConfirmOnBoardingButton() {
    const isRequiredInfoMissing =
      form.values.identityCardNumber === null ||
      form.values.identityCardNumber === '' ||
      form.values.ssNumber === null ||
      form.values.ssNumber === '' ||
      userPersonalInfo?.address === null ||
      userPersonalInfo?.birthPlace === null ||
      userPersonalInfo?.birthDepartment === null ||
      userPersonalInfo?.birthday === null;

    const isMyProfileAndAlreadyValidated =
      userProfile?.id === user.id &&
      userProfile?.onBoarding?.onBoardingEmployee?.validationDate !== null;

    const isNotMyProfileAndNotValidatedYet =
      userProfile?.id !== user.id &&
      userProfile?.onBoarding?.onBoardingEmployee?.validationDate === null;

    return (
      isRequiredInfoMissing ||
      isMyProfileAndAlreadyValidated ||
      isNotMyProfileAndNotValidatedYet
    );
  }

  return (
    <form
      onSubmit={form.onSubmit((values) => handleSensitiveSubmitForm(values))}
    >
      <LoadingOverlay visible={isLoadingCreateOrUpdateUserSensitiveInfo} />
      {!isDesktop && <Title order={3}>{t('w.administrativeInfo')}</Title>}
      <SensitiveInfoStepper form={form} employee={userProfile} />
      <Group justify={'flex-end'} mt={'51px'} gap={'30px'}>
        {Boolean(userProfile.onBoardingId) && (
          <Button
            onClick={() => openConfirmOnBoarding(form.values)}
            color={'#0A9182'}
            leftSection={<IconCopyCheck />}
            disabled={isDisabledConfirmOnBoardingButton()}
          >
            {userProfile.onBoarding?.onBoardingEmployee?.validationDate !==
              null &&
            userProfile.onBoarding?.onBoardingCompany !== null &&
            userProfile.onBoarding?.onBoardingCompany?.validationDate &&
            userProfile.onBoarding?.onBoardingCompany?.validationDate
              ?.dateOfUpdate !== null
              ? t('w.finalizeOnboarding')
              : t('w.validateInfos')}
          </Button>
        )}
        <Button
          disabled={
            Boolean(userProfile.onBoardingId) &&
            userProfile.id === user.id &&
            userProfile.onBoarding?.onBoardingEmployee?.validationDate !== null
          }
          leftSection={<IconDeviceFloppy />}
          type={'submit'}
        >
          {t('w.save')}
        </Button>
        <Button
          onClick={() => setActive(active - 1)}
          leftSection={<IconCircleArrowLeft />}
          variant={'outline'}
        >
          {t('w.back')}
        </Button>
        <Button
          onClick={() => setActive(active + 1)}
          leftSection={<IconCircleArrowRight />}
          variant={'outline'}
          type={'submit'}
        >
          {t('w.next')}
        </Button>

        {onClose && (
          <Button
            onClick={onClose}
            variant={'outline'}
            disabled={
              Boolean(userProfile.onBoardingId) && userProfile.id === user.id
            }
          >
            {t('w.close')}
          </Button>
        )}
      </Group>
    </form>
  );
}
