import {
  ActionIcon,
  Flex,
  Group,
  Indicator,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconEdit,
  IconFileTypeDocx,
  IconFileTypePdf,
  IconSelect,
  IconTrash,
  IconVariable,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as React from 'react';

import ButtonBack from '../../../../../components/ButtonBack';
import { useI18n } from '../../../../../contexts/I18nProvider';
import DocumentGeneratorService from '../../../../../services/DocumentGeneratorService';
import { Template } from '../../../../../types/api/response/documentGenerator';
import { isDocxFile, isPdfFile } from '../../../../../utils/attachmentFile';
import ContractTemplateEditForm from './ContractTemplateEditForm';
import s from './GenerateDocuments.module.sass';
import KeysMapping from './KeysMapping';

type Props = {
  companyId: string;
  refetch: () => void;
  template: Template;
  activeTemplate: Template | null;
  setActiveTemplate: React.Dispatch<React.SetStateAction<Template | null>>;
};

export default function TemplateCard({
  companyId,
  refetch,
  template,
  activeTemplate,
  setActiveTemplate,
}: Props) {
  const { t } = useI18n();

  const { data: mappings } = useQuery({
    queryKey: [
      'DocumentGeneratorService.getTemplateMappings',
      companyId,
      template.id,
    ],
    queryFn: () =>
      DocumentGeneratorService.getTemplateMappings(companyId, template.id),
  });

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation({
    mutationFn: () =>
      DocumentGeneratorService.deleteTemplate(companyId, template.id),
    onSuccess: () => {
      showNotification({
        id: 'DocumentGeneratorService.deleteTemplate-success',
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
    },
    onError: () => {
      showNotification({
        id: 'DocumentGeneratorService.deleteTemplate-error',
        message: t('w.error'),
        color: 'red',
        icon: <IconX />,
      });
    },
  });

  function handleEditTemplateClick() {
    const modalId = 'edit-template-modal';
    modals.open({
      modalId,
      title: (
        <Group>
          <ButtonBack onClose={() => modals.close(modalId)} />
          <Title size={'h3'} component="p">
            {t('w.generateDocuments')} - {template.name} - {t('w.edit')}
          </Title>
        </Group>
      ),
      fullScreen: true,
      children: (
        <ContractTemplateEditForm
          companyId={companyId}
          template={template}
          refetch={refetch}
          onClose={() => modals.close(modalId)}
        />
      ),
    });
  }

  function handleSeeTemplateVariablesClick() {
    const modalId = 'see-template-variables-modal';
    modals.open({
      modalId,
      title: (
        <Group>
          <ButtonBack onClose={() => modals.close(modalId)} />
          <Title size={'h3'} component="p">
            {t('w.generateDocuments')} - {template.name} - {t('w.variables')}
          </Title>
        </Group>
      ),
      fullScreen: true,
      children: <KeysMapping companyId={companyId} template={template} />,
    });
  }

  const notMappedKeysNb =
    mappings?.map((m) => m.entityKey).filter((m) => !m)?.length || 0;

  return (
    <Indicator
      label={<IconSelect size={20} color={'var(--mantine-color-green-4)'} />}
      offset={13}
      size={16}
      color="transparent"
      disabled={activeTemplate?.id !== template.id}
    >
      <Flex
        direction={'column'}
        className={s.overviewWrapper}
        gap={'xs'}
        onClick={() =>
          setActiveTemplate(
            activeTemplate?.id === template.id ? null : template
          )
        }
        w={'10rem'}
        styles={{
          root: {
            backgroundColor:
              activeTemplate?.name === template.name
                ? 'var(--mantine-color-hifivework-0)'
                : 'var(--mantine-color-body)',
            cursor: 'pointer',
            border: `1px solid ${
              activeTemplate?.name === template.name
                ? 'var(--mantine-color-hifivework-2)'
                : 'var(--mantine-primary-color-light)'
            }`,
          },
        }}
      >
        <Flex align="center" gap="sm">
          {isDocxFile(template.file!) && <IconFileTypeDocx color="grey" />}
          {isPdfFile(template.file!) && <IconFileTypePdf color="grey" />}
          <Text fz={'xs'} fw={'bold'} c={'dimmed'} truncate={'end'}>
            {template.name}
          </Text>
        </Flex>
        <Group justify={'space-around'}>
          <Tooltip label={t('w.edit')} position={'left'}>
            <ActionIcon
              loading={isLoadingDelete}
              variant={'subtle'}
              size={'sm'}
              onClick={handleEditTemplateClick}
            >
              {<IconEdit />}
            </ActionIcon>
          </Tooltip>
          <Tooltip
            label={
              !notMappedKeysNb
                ? t('docgen.seeTemplateVars')
                : t('docgen.xKeysNotConfigured', notMappedKeysNb)
            }
            position={'left'}
          >
            <Indicator
              disabled={!notMappedKeysNb}
              inline
              processing
              color="red"
              size={12}
              label={notMappedKeysNb}
            >
              <ActionIcon
                loading={isLoadingDelete}
                variant={'subtle'}
                size={'sm'}
                onClick={handleSeeTemplateVariablesClick}
              >
                {<IconVariable />}
              </ActionIcon>
            </Indicator>
          </Tooltip>
          <Tooltip label={t('w.delete')} position={'right'}>
            <ActionIcon
              loading={isLoadingDelete}
              variant={'subtle'}
              size={'sm'}
              color={'red'}
              onClick={() => mutateDelete()}
            >
              <IconTrash />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Flex>
    </Indicator>
  );
}
