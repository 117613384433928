import {
  Badge,
  Divider,
  HoverCard,
  ScrollArea,
  Stack,
  Text,
} from '@mantine/core';
import * as React from 'react';
import { PropsWithChildren } from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import type { DivisionResponse } from '../../../../types/api/response/division';
import { EndOfMonthValidationResponse } from '../../../../types/api/response/endMonth';

type Props = {
  endOfMonthValidations: EndOfMonthValidationResponse[];
  divisions: DivisionResponse[];
};

export default function SelectedEmployeesHoverCard({
  endOfMonthValidations,
  divisions,
  children,
}: Props & PropsWithChildren) {
  const { t } = useI18n();

  function getDivisionName(divisionId: string): string | undefined {
    const findDivision = divisions.find(
      (division) => division.id === divisionId
    );
    if (findDivision) {
      return findDivision.name;
    }
  }

  return (
    <HoverCard shadow="md" withArrow>
      <HoverCard.Target>{children}</HoverCard.Target>
      <HoverCard.Dropdown>
        <Text fw="bold">
          {endOfMonthValidations.length} {t('w.units')}
        </Text>
        <Divider my="sm" />
        <ScrollArea h={300}>
          <Stack gap="xs">
            {endOfMonthValidations.map((elt) => (
              <Badge
                variant="outline"
                key={elt.id}
                styles={{
                  root: { textTransform: 'none' },
                }}
              >
                {getDivisionName(elt.divisionId)}
              </Badge>
            ))}
          </Stack>
        </ScrollArea>
      </HoverCard.Dropdown>
    </HoverCard>
  );
}
