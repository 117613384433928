import React from 'react';

import s from './FileView.module.sass';

type Props = {
  id: string;
  srcUrl: string;
  title: string;
  size?: 'sm' | 'md' | 'lg';
};
export default function FileViewer({ id, srcUrl, title, size = 'lg' }: Props) {
  return (
    <div className={s[`root-${size}`]}>
      <iframe
        id={id}
        src={srcUrl}
        title={title}
        className={s[`iframe-${size}`]}
      />
    </div>
  );
}
