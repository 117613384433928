import { useParams } from 'react-router-dom';

import { useAuth } from '../../../contexts/AuthProvider';
import { useModule } from '../../../contexts/ModuleProvider';
import { ModuleName } from '../../../types/api/response/module';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../types/api/response/role';
import { hasPermission } from '../../../utils/authorization';

export default function useDisplayTeamActivities(): boolean {
  const { getModule } = useModule();
  const { companyId } = useParams();
  const { user } = useAuth();

  return Boolean(
    getModule(ModuleName.DailyWork)?.active &&
      hasPermission(
        {
          permission: BuiltInPermissions.ValidateLeavesAbsences,
          scope: ScopeEnum.ALL,
        },
        user,
        companyId
      )
  );
}
