import { Group, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';

import Page, { PageLink } from '../../components/Page/Page';
import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import useCompany from '../../hooks/useCompany';
import { BuiltInPermissions, ScopeEnum } from '../../types/api/response/role';
import { hasPermission } from '../../utils/authorization';
import { toDateOfDayString } from '../../utils/format';
import ShuttleSheetForm from './components/create/ShuttleSheetForm';
import MyShuttleSheetsTable from './components/MyShuttleSheetsTable';
import ShuttleSheetsMonitoringTable from './components/ShuttleSheetsMonitoringTable';

export default function ShuttleSheets() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const [activeLink, setActiveLink] = useState(t('w.myShuttleSheets'));
  const queryClient = useQueryClient();

  function getLinks() {
    const links: PageLink[] = [
      {
        active: activeLink === t('w.myShuttleSheets'),
        label: t('w.myShuttleSheets'),
        onClick: () => setActiveLink(t('w.myShuttleSheets')),
      },
    ];

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ValidateShuttleSheets,
          scope: ScopeEnum.ALL,
        },
        user,
        companyId
      )
    ) {
      links.push({
        active: activeLink === t('shuttleSheet.monitoring'),
        label: t('shuttleSheet.monitoring'),
        onClick: () => setActiveLink(t('shuttleSheet.monitoring')),
      });
    }
    return links;
  }

  function renderContent() {
    switch (activeLink) {
      case t('w.myShuttleSheets'):
        return <MyShuttleSheetsTable companyId={companyId} userId={user.id} />;
      case t('shuttleSheet.monitoring'):
        return (
          <ShuttleSheetsMonitoringTable
            companyId={companyId}
            isAccountant={false}
          />
        );
      default:
        return null;
    }
  }

  return (
    <Page
      title={t('w.shuttleSheets')}
      parent={{ label: toDateOfDayString() }}
      links={getLinks()}
      add={
        activeLink === t('w.myShuttleSheets')
          ? {
              label: t('w.createNewShuttleSheet'),
              onClick: () => {
                modals.open({
                  modalId: 'create-shuttle-sheet-modal',
                  title: (
                    <Title size={'h3'} component="p">
                      {t('w.createNewShuttleSheet')}
                    </Title>
                  ),
                  size: '90%',
                  children: (
                    <ShuttleSheetForm
                      companyId={companyId}
                      modalId={'create-shuttle-sheet-modal'}
                      refetch={() =>
                        queryClient.invalidateQueries({
                          queryKey: [
                            'ShuttleSheetService.userShuttleSheetsList',
                            companyId,
                            user.id,
                          ],
                        })
                      }
                    />
                  ),
                });
              },
            }
          : undefined
      }
    >
      <Group grow preventGrowOverflow={false} wrap="nowrap">
        {renderContent()}
      </Group>
    </Page>
  );
}
