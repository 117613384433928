import { WhatsNew } from '../types/api/response/notification';
import type { NotificationUser } from '../types/types';
import { MS_NOTIFCATION_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

function allList(userId: string) {
  return request
    .get(`${MS_NOTIFCATION_ENDPOINT}/v2/notification/definition/${userId}`)
    .then((res) => res?.data);
}

export type NotificationConfig = {
  notificationDefinitionId: string;
  userId: string | undefined;
  mail: boolean;
  app: boolean;
  mobile: boolean;
  companyId?: string;
};

function updateUserNotificationConfig(
  userId: string | undefined,
  data: NotificationConfig
) {
  return request
    .post(
      `${MS_NOTIFCATION_ENDPOINT}/v2/notification/user/config/${userId}`,
      data
    )
    .then((res) => res?.data);
}

function allListForMyCompany(userId: string) {
  return request
    .get(
      `${MS_NOTIFCATION_ENDPOINT}/v2/notification/definition/companies/${userId}`
    )
    .then((res) => res?.data);
}

function updateCompanyNotificationConfig(
  userId: string | undefined,
  data: NotificationConfig
) {
  return request
    .post(
      `${MS_NOTIFCATION_ENDPOINT}/v2/notification/company/config/${userId}`,
      data
    )
    .then((res) => res?.data);
}

function allAccountantNotification(companyId: string) {
  return request
    .get(
      `${MS_NOTIFCATION_ENDPOINT}/v2/notification/definition/accountant/${companyId}`
    )
    .then((res) => res?.data);
}

export type AccountantNotificationConfig = {
  accountantCompanyId: string;
  notificationDefinitionId: string;
  mail: boolean;
  app: boolean;
  mobile: boolean;
};

function updateAccountantNotification(
  companyId: string,
  data: AccountantNotificationConfig
) {
  return request
    .post(
      `${MS_NOTIFCATION_ENDPOINT}/v2/notification/accountant/config/${companyId}`,
      data
    )
    .then((res) => res?.data);
}

function getUserNotificationList(userId: string): Promise<NotificationUser[]> {
  return request
    .get(`${MS_NOTIFCATION_ENDPOINT}/v2/notification/user/${userId}?tag=APP`)
    .then((res) => {
      return res?.data.sort(
        (a: NotificationUser, b: NotificationUser) =>
          new Date(b.created).valueOf() - new Date(a.created).valueOf()
      );
    });
}

function markUserNotificationAsViewed(notificationId: string, userId: string) {
  return request
    .post(
      `${MS_NOTIFCATION_ENDPOINT}/v2/notification/user/${userId}/view/${notificationId}`
    )
    .then((res) => res?.data);
}

function deleteUserNotification(notificationId: string, userId: string) {
  return request
    .delete(
      `${MS_NOTIFCATION_ENDPOINT}/v2/notification/user/${userId}/delete/${notificationId}`
    )
    .then((res) => res?.data);
}

function getWhatsNew(): Promise<WhatsNew> {
  return request
    .get(`${MS_NOTIFCATION_ENDPOINT}/v2/notification/whats-new`)
    .then((res) => res?.data);
}

const NotificationService = {
  allList,
  updateUserNotificationConfig,
  allListForMyCompany,
  updateCompanyNotificationConfig,
  allAccountantNotification,
  updateAccountantNotification,
  getUserNotificationList,
  markUserNotificationAsViewed,
  deleteUserNotification,
  getWhatsNew,
};

export default NotificationService;
