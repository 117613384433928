import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Group, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { IconEdit } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useEffect, useMemo, useState } from 'react';

import CustomDrawer from '../../../components/CustomDrawer';
import CustomTooltip from '../../../components/CustomTooltip';
import { useI18n } from '../../../contexts/I18nProvider';
import ShuttleSheetService from '../../../services/ShuttleSheetService';
import { ShuttleSheetRequest } from '../../../types/types';
import { milliToFullDateString } from '../../../utils/format';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../utils/optionsPreferences';
import ShuttleSheetForm, {
  requestStatuses,
  requestStatusesOwner,
} from './create/ShuttleSheetForm';
import ShuttleSheetComments from './ShuttleSheetComments';
import ShuttleSheetCommentsActionIcon from './ShuttleSheetCommentsActionIcon';
import ShuttleSheetCommentsDrawerTitle from './ShuttleSheetCommentsDrawerTitle';
import ShuttleSheetCompanyWrapper from './ShuttleSheetCompanyWrapper';
import ShuttleSheetCreatorWrapper from './ShuttleSheetCreatorWrapper';
import ShuttleSheetDivisionName from './ShuttleSheetDivisionName';
import ShuttleSheetStatusBadge from './ShuttleSheetStatusBadge';

type Props = {
  companyId: string;
  isAccountant?: boolean;
};

export default function ShuttleSheetsMonitoringTable({
  companyId,
  isAccountant = false,
}: Props) {
  const { t, lang } = useI18n();
  const [
    openedAddCommentDrawer,
    { open: handleOpenAddCommentDrawer, close: handleCloseAddCommentDrawer },
  ] = useDisclosure(false);
  const [shuttleSheetRequest, setShuttleSheetRequest] = useState<
    ShuttleSheetRequest | undefined
  >(undefined);

  const componentName: string = useMemo(() => {
    if (isAccountant) {
      return 'AccountantShuttleSheetsMonitoringTable';
    } else {
      return 'ShuttleSheetsMonitoringTable';
    }
  }, [isAccountant]);

  const {
    data: shuttleSheetsToValidateListData,
    refetch: refetchShuttleSheetsToValidateListData,
  } = useQuery({
    enabled: false,
    queryKey: ['ShuttleSheetService.shuttleSheetsToValidateList', companyId],
    queryFn: () => ShuttleSheetService.shuttleSheetsToValidateList(companyId),
  });

  const shuttleSheetsToValidateList = useMemo(() => {
    if (!shuttleSheetsToValidateListData) {
      return [];
    }
    return shuttleSheetsToValidateListData;
  }, [shuttleSheetsToValidateListData]);

  const {
    data: shuttleSheetsFollowedByAccountantListData,
    refetch: refetchShuttleSheetsFollowedByAccountantListData,
  } = useQuery({
    enabled: false,
    queryKey: [
      'ShuttleSheetService.shuttleSheetsFollowedByAccountantList',
      companyId,
    ],
    queryFn: () =>
      ShuttleSheetService.shuttleSheetsFollowedByAccountantList(companyId),
  });

  const shuttleSheetsFollowedByAccountantList = useMemo(() => {
    if (!shuttleSheetsFollowedByAccountantListData) {
      return [];
    }
    return shuttleSheetsFollowedByAccountantListData;
  }, [shuttleSheetsFollowedByAccountantListData]);

  useEffect(() => {
    if (isAccountant) {
      refetchShuttleSheetsFollowedByAccountantListData();
    } else {
      refetchShuttleSheetsToValidateListData();
    }
  }, []);

  const defaultColumns: DataTableColumn<ShuttleSheetRequest>[] = useMemo(
    () => [
      {
        accessor: 'creatorId',
        title: t('w.requester'),
        sortable: true,
        ellipsis: true,
        render: ({ creatorId }) => (
          <ShuttleSheetCreatorWrapper userId={creatorId} />
        ),
      },
      {
        accessor: 'shuttleSheetType.name',
        title: 'Type',
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'status',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ status, statusOwner, shuttleSheetType }) => (
          <ShuttleSheetStatusBadge
            status={status}
            statusOwner={statusOwner}
            transmittedToAccountant={shuttleSheetType.transmittedToAccountant}
          />
        ),
      },
      {
        accessor: 'comments',
        title: `${t('w.comment')}s`,
        sortable: true,
        ellipsis: true,
        render: (shuttleSheet) => (
          <ShuttleSheetCommentsActionIcon
            onClick={() => {
              handleOpenAddCommentDrawer();
              if (shuttleSheet.comments) {
                setShuttleSheetRequest(shuttleSheet);
              }
            }}
            commentsNumber={
              shuttleSheet?.comments ? shuttleSheet.comments.length : 0
            }
          />
        ),
      },
      {
        accessor: 'created',
        title: t('w.created'),
        sortable: true,
        ellipsis: true,
        render: ({ created }) =>
          created ? milliToFullDateString(created) : '',
      },
      {
        accessor: 'updated',
        title: t('w.lastUpdated'),
        sortable: true,
        ellipsis: true,
        render: ({ updated }) =>
          updated ? milliToFullDateString(updated) : '',
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: '0%',
        render: (shuttleSheetRequest) => (
          <Group gap={4} justify="end" wrap={'nowrap'}>
            <CustomTooltip label={t('w.modify')}>
              <ActionIcon
                size="sm"
                color="green"
                variant={'subtle'}
                onClick={() =>
                  handleOpenEditShuttleSheetModal(shuttleSheetRequest)
                }
              >
                <IconEdit />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    []
  );

  const accountantColumns: DataTableColumn<ShuttleSheetRequest>[] = useMemo(
    () => [
      {
        accessor: 'companyId',
        title: t('w.company'),
        sortable: true,
        ellipsis: true,
        render: ({ companyId }) => (
          <ShuttleSheetCompanyWrapper companyId={companyId} />
        ),
      },
      {
        accessor: 'divisionId',
        title: t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: ({ divisionId }) => (
          <ShuttleSheetDivisionName divisionId={divisionId} />
        ),
      },
      {
        accessor: 'creatorId',
        title: t('w.requester'),
        sortable: true,
        ellipsis: true,
        render: ({ creatorId }) => (
          <ShuttleSheetCreatorWrapper userId={creatorId} />
        ),
      },
      {
        accessor: 'shuttleSheetType.name',
        title: 'Type',
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'status',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ status, statusOwner, shuttleSheetType }) => (
          <ShuttleSheetStatusBadge
            status={status}
            statusOwner={statusOwner}
            transmittedToAccountant={shuttleSheetType.transmittedToAccountant}
          />
        ),
      },
      {
        accessor: 'comments',
        title: `${t('w.comment')}s`,
        sortable: true,
        ellipsis: true,
        render: (shuttleSheet) => (
          <ShuttleSheetCommentsActionIcon
            onClick={() => {
              handleOpenAddCommentDrawer();
              if (shuttleSheet.comments) {
                setShuttleSheetRequest(shuttleSheet);
              }
            }}
            commentsNumber={
              shuttleSheet?.comments ? shuttleSheet.comments.length : 0
            }
          />
        ),
      },
      {
        accessor: 'created',
        title: t('w.created'),
        sortable: true,
        ellipsis: true,
        render: ({ created }) =>
          created ? milliToFullDateString(created) : '',
      },
      {
        accessor: 'updated',
        title: t('w.lastUpdated'),
        sortable: true,
        ellipsis: true,
        render: ({ updated }) =>
          updated ? milliToFullDateString(updated) : '',
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: '0%',
        render: (shuttleSheetRequest) => (
          <Group gap={4} justify="end" wrap={'nowrap'}>
            <CustomTooltip label={t('w.modify')}>
              <ActionIcon
                size="sm"
                color="green"
                variant={'subtle'}
                onClick={() =>
                  handleOpenEditShuttleSheetModal(shuttleSheetRequest)
                }
              >
                <IconEdit />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    []
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'shuttleSheetType.name',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'status',
          label: t('w.status'),
          choices: requestStatuses.map((status) => ({
            value: status,
            label: t(`shuttleSheet.statusEnum.${status}`),
          })),
          selectedChoices: [],
        },
        {
          accessor: 'statusOwner',
          label: t('w.role'),
          choices: requestStatusesOwner.map((status) => ({
            value: status,
            label: t(`shuttleSheet.statusOwnerEnum.${status}`),
          })),
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  function handleOpenEditShuttleSheetModal(
    shuttleSheetRequest: ShuttleSheetRequest
  ): void {
    modals.open({
      transitionProps: { transition: 'slide-down' },
      modalId: `edit-shuttle-sheet-${shuttleSheetRequest.id}-modal`,
      title: (
        <Title size={'h3'} component="p">{`${t('w.modify')} ${
          shuttleSheetRequest.shuttleSheetType.name
        }`}</Title>
      ),
      size: '70%',
      children: (
        <ShuttleSheetForm
          companyId={shuttleSheetRequest.companyId}
          modalId={`edit-shuttle-sheet-${shuttleSheetRequest.id}-modal`}
          shuttleSheetRequest={shuttleSheetRequest}
          refetch={
            isAccountant
              ? refetchShuttleSheetsFollowedByAccountantListData
              : refetchShuttleSheetsToValidateListData
          }
        />
      ),
    });
  }

  return (
    <>
      <Table
        pinFirstColumn
        pinLastColumn
        options={options}
        rows={
          isAccountant
            ? shuttleSheetsFollowedByAccountantList
            : shuttleSheetsToValidateList
        }
        columns={isAccountant ? accountantColumns : defaultColumns}
        lang={lang}
        height={'calc(100vh - 220px)'}
        width="0vw" // autoscale width
      />
      <CustomDrawer
        opened={openedAddCommentDrawer}
        onClose={handleCloseAddCommentDrawer}
        title={<ShuttleSheetCommentsDrawerTitle />}
      >
        <ShuttleSheetComments
          companyId={companyId}
          shuttleSheetId={shuttleSheetRequest?.id ? shuttleSheetRequest.id : ''}
          refetch={
            isAccountant
              ? refetchShuttleSheetsFollowedByAccountantListData
              : refetchShuttleSheetsToValidateListData
          }
        />
      </CustomDrawer>
    </>
  );
}
