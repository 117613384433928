import {
  FilterOption,
  FilterOptionChoice,
  Options,
  Table,
} from '@ckprivate/ckf-ui';
import { ActionIcon, Badge, Group, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconAlertTriangle,
  IconCheck,
  IconEdit,
  IconMailForward,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { DataTableColumn } from 'mantine-datatable';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ActiveInactiveBadge from '../../../components/badges/ActiveInactiveBadge';
import RoleBadge from '../../../components/badges/RoleBadge';
import UnitBadge from '../../../components/badges/UnitBadge';
import CustomTooltip from '../../../components/CustomTooltip';
import GoToUserButton from '../../../components/GoToUserButton/GoToUserButton';
import ImportModal from '../../../components/ImportModal';
import Page, { PageLink } from '../../../components/Page/Page';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import { useModule } from '../../../contexts/ModuleProvider';
import useCompany from '../../../hooks/useCompany';
import useFetchUsers from '../../../hooks/useFetchUsers';
import CompanyService from '../../../services/CompanyService';
import ProfileService from '../../../services/ProfileService';
import type { CompanyResponse } from '../../../types/api/response/company';
import { ModuleName } from '../../../types/api/response/module';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../types/api/response/role';
import type {
  IdentifiantCompany,
  OnBoardingResponse,
  UserResponse,
} from '../../../types/api/response/user';
import { ACCOUNTANT_MODE } from '../../../types/types';
import { hasPermission } from '../../../utils/authorization';
import { milliToFullDateString } from '../../../utils/format';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import { isWhiteLabelGma } from '../../../utils/isWhiteLabelGma';
import { getPersistedObject } from '../../../utils/localStorage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../utils/optionsPreferences';
import saveDownloadedfile from '../../../utils/saveDownloadedFile';
import { APP_NAME, NO_WORK_UNIT } from '../../../variables/GlobalVariables';
import AddEmployeeModal from './components/AddEmployeeModal';
import GenerateDocuments from './components/documentGenerator/GenerateDocuments';
import OnBoardingStatusModal from './components/OnBoardingStatusModal';

const componentName = 'Employees';

type Props = {
  divisionId?: string;
  company?: CompanyResponse;
  importModalInPortal?: boolean;
};

export default function Employees({
  divisionId,
  company,
  importModalInPortal = true,
}: Props) {
  const { getModule } = useModule();
  const navigate = useNavigate();
  const { user, access_token } = useAuth();
  const { t, lang } = useI18n();
  const { id: companyId } = useCompany(user, company?.id);
  const {
    users,
    divisions,
    roles,
    loading: isUsersLoading,
    refetchUsers,
  } = useFetchUsers(companyId);
  const [importEmployeeModal, setImportEmployeeModal] = useState({
    opened: false,
    title: '',
  });
  const [opened, handlers] = useDisclosure(false);
  const [selectedEmployees, setSelectedEmployees] = useState<UserResponse[]>(
    []
  );

  const isHfwAzureSyncModuleActive = getModule(ModuleName.HfwAzureSync)?.active;

  function handleNavigateToUserProfile(companyId: string, id: string): void {
    if (getPersistedObject('mode') === ACCOUNTANT_MODE) {
      navigate(`/company/${companyId}/profile/user/${id}`);
    } else {
      navigate(`/profile/user/${id}`);
    }
  }

  function getOnboardingStatusLabel(
    onBoarding: OnBoardingResponse | undefined
  ): ReactNode {
    if (!onBoarding?.onBoardingEmployee?.inscriptionDate) {
      return "Collaborateur en cours d'inscription";
    } else if (!onBoarding?.onBoardingEmployee?.validationDate) {
      return 'Onboarding collaborateur en cours... ';
    } else if (!onBoarding?.onBoardingCompany?.validationDate) {
      return 'Onboarding en cours de validation';
    } else {
      return 'Onboarding validé';
    }
  }

  const columns: DataTableColumn<UserResponse>[] = useMemo(
    () => [
      {
        accessor: 'fullname',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
        render: ({ id, fullname }) => (
          <GoToUserButton userId={id} userFullname={fullname!} />
        ),
      },
      {
        accessor: 'mail',
        title: t('w.email'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'employment',
        title: t('w.jobType'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'role.name',
        title: t('w.role'),
        sortable: true,
        ellipsis: true,
        render: ({ role }) => role && <RoleBadge role={role.name} />,
      },
      {
        accessor: 'division.name',
        title: user.company?.divisionLabel
          ? user.company.divisionLabel
          : t('w.unit'),
        sortable: true,
        ellipsis: true,
        render: ({ division }) => <UnitBadge unit={division?.name} />,
      },
      {
        accessor: 'identifiantsCompany',
        title: 'Matricule',
        sortable: true,
        ellipsis: true,
        render: ({ identifiantsCompany }) => {
          const _matricule = identifiantsCompany || [];
          return (
            <Text fz={'xs'} fw={'bold'}>
              {_matricule
                .reverse()
                .map(
                  (item: IdentifiantCompany) =>
                    `${
                      item.codeCompany
                        ? `${item.codeCompany} - ${item.matricule}`
                        : item.matricule
                    }`
                )
                .join(' / ')}
            </Text>
          );
        },
      },
      {
        accessor: 'valid',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ valid, onBoarding, onBoardingId }) =>
          !Boolean(onBoardingId) ? (
            <ActiveInactiveBadge value={valid!} />
          ) : (
            <CustomTooltip label={getOnboardingStatusLabel(onBoarding)}>
              <Badge
                color={valid ? '#CEE9E6' : '#E2E2E2'}
                styles={{
                  label: {
                    textTransform: 'none',
                    color: valid
                      ? '#0A9182'
                      : !onBoarding?.onBoardingEmployee?.inscriptionDate
                      ? '#E70D4F'
                      : !onBoarding?.onBoardingEmployee?.validationDate
                      ? '#EDB464'
                      : !onBoarding?.onBoardingCompany?.validationDate
                      ? '#10069F'
                      : '#0A9182',
                  },
                  root: { cursor: 'pointer' },
                }}
                variant="filled"
                onClick={() =>
                  modals.open({
                    modalId: `on-boarding-status`,
                    title: (
                      <Title size={'h3'} component="p">
                        {t(
                          'w.onBoardingStatus',
                          dayjs(onBoarding?.created).format('DD/MM/YYYY HH:mm'),
                          onBoarding?.nameOfCreator
                        )}
                      </Title>
                    ),
                    size: '70%',
                    children: <OnBoardingStatusModal onBoarding={onBoarding} />,
                  })
                }
              >
                {valid ? t('w.active') : t('w.onBoarding')}
              </Badge>
            </CustomTooltip>
          ),
      },
      {
        accessor: 'lastLoginDate',
        title: t('w.lastLoginDate'),
        sortable: true,
        ellipsis: true,
        render: ({ lastLoginDate }) =>
          lastLoginDate ? milliToFullDateString(lastLoginDate) : '',
      },
      {
        accessor: 'updated',
        title: t('w.lastUpdated'),
        sortable: true,
        ellipsis: true,
        render: ({ updated }) =>
          updated ? milliToFullDateString(updated) : '',
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: '0%',
        render: (employee) => {
          return (
            <Group gap={4} justify="end" wrap={'nowrap'}>
              <CustomTooltip label={t('w.edit')}>
                <ActionIcon
                  size="sm"
                  color="green"
                  variant={'subtle'}
                  onClick={() =>
                    handleNavigateToUserProfile(
                      employee.companyId!,
                      employee.id
                    )
                  }
                >
                  <IconEdit size={16} />
                </ActionIcon>
              </CustomTooltip>
              {!isWhiteLabelGma() && (
                <CustomTooltip label={t('w.invite')}>
                  <ActionIcon
                    size="sm"
                    variant={'subtle'}
                    color="blue"
                    onClick={() =>
                      inviteUser({
                        companyId: employee.companyId!,
                        id: employee.id,
                        mail: employee.mail!,
                      })
                    }
                  >
                    <IconMailForward size={16} />
                  </ActionIcon>
                </CustomTooltip>
              )}
            </Group>
          );
        },
      },
    ],
    [users, divisions]
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'fullname',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: ['updated'],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'valid',
          label: t('w.status'),
          choices: [
            { value: 'true', label: t('w.active') },
            { value: 'false', label: t('w.inactive') },
          ],
          selectedChoices: [],
        },
        {
          accessor: 'division.name',
          label: user.company?.divisionLabel
            ? user.company.divisionLabel
            : t('w.unit'),
          choices: [],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  const { mutate: inviteUser } = useMutation({
    mutationFn: (variables: { id: string; companyId: string; mail: string }) =>
      CompanyService.inviteUser(variables.companyId, variables.id),
    onSuccess: (_data, variables) => {
      showNotification({
        id: `success-invite-user-${variables.id}`,
        title: t('w.success'),
        message: t('w.mailSent', variables.mail),
        color: 'green',
        icon: <IconCheck />,
      });
      refetchUsers();
    },
    onError: (error, variables) => {
      showNotification({
        id: `error-invite-user-${variables.id}`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      });
    },
  });

  const { mutate: changeUserStatus, isError: isChangeStatusError } =
    useMutation({
      mutationFn: (variables: { userId: string; valid: boolean }) =>
        ProfileService.updateUserProfile(variables.userId, {
          valid: variables.valid,
        }),
      onSuccess: (data) => {
        showNotification({
          id: `change-status-userId-${data?.id}-successful`,
          title: t('w.success'),
          message: t('success.updateUserProfile', data?.fullname),
          color: 'green',
          icon: <IconCheck />,
        });
        refetchUsers();
        const _selectedEmployees = [...selectedEmployees];
        _selectedEmployees.forEach((employee: any) => {
          employee.valid = data?.valid;
        });
        setSelectedEmployees(_selectedEmployees);
      },
    });

  useEffect(() => {
    if (isChangeStatusError) {
      showNotification({
        id: 'update-user-profile-error',
        title: t('w.error'),
        message: t('error.updateUserStatus'),
        color: 'red',
        icon: <IconX />,
      });
    }
  }, [isChangeStatusError]);

  const { mutate: exportEmployees } = useMutation({
    mutationFn: (payload: {
      type: string;
      selectValid: boolean;
      selectInvalid: boolean;
      divisionsId: string[];
    }) =>
      CompanyService.exportEmployees(
        companyId,
        payload.selectValid,
        payload.selectInvalid,
        payload.type,
        access_token,
        payload.divisionsId
      ),
    onSuccess: (data, variables) => {
      saveDownloadedfile(data);
      showNotification({
        id: 'export-employees-success',
        title: t('w.success'),
        message: t('success.exportEmployees', variables.type),
        icon: <IconCheck />,
        color: 'green',
      });
    },
    onError: (error) =>
      showNotification({
        id: 'export-employees-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconCheck />,
        color: 'red',
      }),
  });

  function onChangeSelectedEmployeesStatusClick() {
    selectedEmployees.forEach((employee) => {
      changeUserStatus({
        userId: employee.id,
        valid: !employee.valid,
      });
    });
  }

  function onInviteSelectedEmployeesClick() {
    selectedEmployees.forEach((employee) => {
      inviteUser({
        id: employee.id,
        companyId: employee.companyId!,
        mail: employee.mail!,
      });
    });
  }

  function onGenerateDocumentsClick() {
    modals.open({
      modalId: 'generate-documents-modal',
      title: (
        <Title size={'h3'} component="p">
          {t('w.generateDocuments')}
        </Title>
      ),
      fullScreen: true,
      children: (
        <GenerateDocuments users={selectedEmployees} companyId={companyId} />
      ),
    });
  }

  function handleOpenImportEmployeeModalClick(title: string): void {
    if (!isHfwAzureSyncModuleActive) {
      setImportEmployeeModal({ opened: true, title: title });
    } else {
      showNotification({
        id: 'azureUserSync-module-active',
        title: t('w.warning'),
        message: t('module.azureUserSyncImportantMessage'),
        icon: <IconAlertTriangle />,
        color: 'orange',
      });
    }
  }

  function handleCloseImportEmployeeModalClick(): void {
    setImportEmployeeModal({ opened: false, title: '' });
  }

  function onExportEmployeesClick(type: string): void {
    let selectValid = !!options?.filter
      ?.find((item) => item.accessor === 'valid')
      ?.selectedChoices?.find((item) => item.label === t('w.active'));

    let selectInvalid = !!options?.filter
      ?.find((item) => item.accessor === 'valid')
      ?.selectedChoices?.find((item) => item.label === t('w.inactive'));

    if (!selectValid && !selectInvalid) {
      selectValid = true;
      selectInvalid = true;
    }

    let divisions = options.filter?.find(
      (item) => item.accessor === 'division.name'
    )?.selectedChoices;

    let divisionsId: string[] = [];
    if (divisions && divisions?.length > 0) {
      divisionsId = divisions?.map((item) => item.value);
    }

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigureEmployees,
          scope: ScopeEnum.ALL,
        },
        user,
        companyId
      )
    ) {
      exportEmployees({ type, selectValid, selectInvalid, divisionsId });
    } else {
      showNotification({
        id: 'no-permission-to-configure-employees',
        title: t('w.warning'),
        message: t('permission.noConfigureEmployees'),
        icon: <IconAlertTriangle />,
        color: 'orange',
      });
    }
  }

  useEffect(() => {
    if (divisions?.length) {
      const _unitNames: FilterOptionChoice[] = [];
      divisions.forEach((item) => {
        _unitNames.push({
          value: item.name,
          label: item.name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : item.name,
        });
      });
      const _options: Options = { ...options };
      if (!!_options?.filter?.[1].choices) {
        _options.filter[1].choices = _unitNames;
        _options.filter[1].selectedChoices = _unitNames.filter((choice) =>
          _options?.filter?.[1]?.selectedChoices?.some(
            (selected) => selected.value === choice.value
          )
        );
      }
      setOptions(_options);
    }
  }, [divisions]);

  function filterUsers() {
    if (divisionId) {
      return users.filter((user) => user.divisionId === divisionId);
    }
    return users;
  }

  function getLinks() {
    if (isWhiteLabelGma()) return undefined;

    const links: PageLink[] = [
      {
        active: false,
        label: t('collaborator.import.xlsx'),
        onClick: () => handleOpenImportEmployeeModalClick(APP_NAME),
      },
      {
        active: false,
        label: t('collaborator.export.xlsx'),
        onClick: () => onExportEmployeesClick('XLSX'),
      },
      {
        active: false,
        label: t('collaborator.export.csv'),
        onClick: () => onExportEmployeesClick('CSV'),
      },
      {
        active: false,
        label: t('w.changingStatus'),
        onClick: onChangeSelectedEmployeesStatusClick,
        disabled: selectedEmployees.length === 0,
      },
      {
        active: false,
        label: t('w.invite'),
        onClick: onInviteSelectedEmployeesClick,
        disabled: selectedEmployees.length === 0,
      },
      {
        active: false,
        label: t('w.generateDocuments'),
        onClick: onGenerateDocumentsClick,
        disabled: selectedEmployees.length === 0,
      },
    ];

    return links;
  }

  return (
    <Page
      title={t('w.employees')}
      parent={{
        label: t('w.configuration'),
      }}
      links={getLinks()}
      add={{
        label: t('w.addEmployee'),
        onClick: handlers.open,
      }}
    >
      <Group grow preventGrowOverflow={false} wrap="nowrap">
        <Table
          pinFirstColumn
          pinLastColumn
          onSelect={(employee) =>
            setSelectedEmployees(employee as UserResponse[])
          }
          rows={filterUsers()}
          options={options}
          columns={columns}
          lang={lang}
          fetching={isUsersLoading}
          withTableBorder={false}
          width="0vw" // autoscale width
        />
      </Group>
      <ImportModal
        opened={importEmployeeModal.opened}
        onClose={handleCloseImportEmployeeModalClick}
        title={importEmployeeModal.title}
        companyId={companyId}
        refetch={refetchUsers}
        importType={'employees'}
        withinPortal={importModalInPortal}
      />
      <AddEmployeeModal
        opened={opened}
        onClose={handlers.close}
        divisions={divisions ? divisions : []}
        roles={roles ? roles : []}
        refetch={refetchUsers}
      />
    </Page>
  );
}
