import { LoadingOverlay, Space } from '@mantine/core';
import { IconBeach, IconBuildingStore, IconUsers } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import CustomStatsGrid from '../../../components/CustomStatsGrid/CustomStatsGrid';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ScreenWrapper from '../../../core/layouts/components/ScreenWrapper';
import useCompany from '../../../hooks/useCompany';
import CompanyService from '../../../services/CompanyService';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../types/api/response/role';
import { hasPermission } from '../../../utils/authorization';

export default function CompanyHome() {
  const { user } = useAuth();
  const { id: companyId, company } = useCompany(user);
  const { t } = useI18n();

  const { data, isLoading } = useQuery({
    queryKey: ['CompanyService.getCompanyHomeData', companyId],
    queryFn: () => CompanyService.getCompanyHomeData(companyId),
  });

  const companyHomeData = useMemo(() => {
    if (!data) {
      return [];
    }
    return [
      {
        title: `${t('w.numberOf')} ${
          user.company?.divisionLabel
            ? user.company.divisionLabel
            : t('workUnit')
        }`,
        icon: IconBuildingStore,
        value: data[0],
        url: hasPermission(
          [
            {
              permission: BuiltInPermissions.ConfigureWorkUnit,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureWorkUnit,
              scope: ScopeEnum.TEAM,
            },
          ],
          user,
          companyId
        )
          ? `/company/${companyId}/work-units`
          : undefined,
      },
      {
        title: t('w.numberOfEmployees'),
        icon: IconUsers,
        value: data[1],
        url: hasPermission(
          [
            {
              permission: BuiltInPermissions.ConfigureEmployees,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureEmployees,
              scope: ScopeEnum.TEAM,
            },
          ],
          user,
          companyId
        )
          ? `/company/${companyId}/employees`
          : undefined,
      },
      {
        title: t('w.typesOfPeriod'),
        icon: IconBeach,
        value: data[2],
        url: hasPermission(
          [
            {
              permission: BuiltInPermissions.ConfigureLeaveTypes,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureLeaveTypes,
              scope: ScopeEnum.TEAM,
            },
          ],
          user,
          companyId
        )
          ? `/company/${companyId}/period-types`
          : undefined,
      },
    ];
  }, [data]);

  return (
    <ScreenWrapper title={`${company?.name} : Chiffres clés`}>
      <LoadingOverlay visible={isLoading} />
      <Space h={'xl'} />
      <Space h={'xl'} />
      <CustomStatsGrid data={companyHomeData} />
    </ScreenWrapper>
  );
}
