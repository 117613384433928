import { Image, LoadingOverlay, Stack, Title } from '@mantine/core';
import { useEffect, useState } from 'react';

import Page, { PageLink } from '../../components/Page/Page';
import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import useCompany from '../../hooks/useCompany';
import useFetchAccountants from '../../hooks/useFetchAccountants';
import { CharteredAccountantView } from '../../types/api/response/accountant';
import { BuiltInPermissions, ScopeEnum } from '../../types/api/response/role';
import { DocumentPropertySpace } from '../../types/types';
import { hasPermission } from '../../utils/authorization';
import { toDateOfDayString } from '../../utils/format';
import DocumentsTable from './components/DocumentsTable';

export default function DocumentsAccountants() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const [activeAccountant, setActiveAccountant] = useState<
    CharteredAccountantView | undefined
  >(undefined);

  const { accountantsListData, isAccountantsListDataLoading } =
    useFetchAccountants();

  useEffect(() => {
    if (!!accountantsListData?.length) {
      setActiveAccountant(accountantsListData[0].accountant);
    }
  }, [accountantsListData]);

  function getLinks() {
    if (accountantsListData) {
      const links: PageLink[] = [];
      accountantsListData.forEach((item) => {
        links.push({
          label: item.accountant.name,
          active: item.accountant.name === activeAccountant?.name,
          onClick: () => setActiveAccountant(item.accountant),
        });
      });
      return links;
    }

    return undefined;
  }

  function renderContent() {
    if (activeAccountant) {
      return (
        <DocumentsTable
          key={activeAccountant.id}
          hasPermissionToEditSpace={hasPermission(
            {
              permission: BuiltInPermissions.AuthorizedEditAccountantSpace,
              scope: ScopeEnum.ALL,
            },
            user,
            companyId
          )}
          space={DocumentPropertySpace.ACCOUNTANT}
          userId={user.id}
          companyId={companyId}
          accountantCompanyId={activeAccountant.id}
        />
      );
    }

    return (
      <Stack align={'center'}>
        <Title size={'h2'}>{t('w.none')}</Title>
        <Image w={'50%'} src={'/images/documents/no_data.png'} />
      </Stack>
    );
  }

  return (
    <Page
      title={t('w.payrollOfficersDocument')}
      parent={{
        label: activeAccountant
          ? `${t('w.payrollOfficer')} : ${activeAccountant.name}`
          : toDateOfDayString(),
      }}
      links={getLinks()}
    >
      <LoadingOverlay visible={isAccountantsListDataLoading} />
      {renderContent()}
    </Page>
  );
}
