import './TLPlanning.sass';
import 'moment/locale/fr';

import {
  Button,
  Group,
  rem,
  SegmentedControl,
  Skeleton,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconSearch } from '@tabler/icons-react';
import dayjs, { type Dayjs } from 'dayjs';
import isoWeeksInYear from 'dayjs/plugin/isoWeeksInYear';
import utc from 'dayjs/plugin/utc';
import moment from 'moment-timezone';
import React, { type ReactNode } from 'react';
import Timeline, {
  DateHeader,
  TimelineHeaders,
  /*TimelineMarkers,
  TodayMarker,*/
} from 'react-calendar-timeline';
// @ts-ignore
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container';

import { useI18n } from '../../contexts/I18nProvider';
import LoadingBadge from '../LoadingBadge';
import s from './TLPlanning.module.sass';
dayjs.extend(utc);
dayjs.extend(isoWeeksInYear);
moment.tz.setDefault('UTC');

export type TLContent = string | ReactNode;

export type TLItem = {
  id: string;
  group: string;
  title: TLContent;
  start_time: Dayjs;
  end_time: Dayjs;
  props?: any;
};

export type TLGroup = {
  id: string;
  title: TLContent;
};

export type TLData = {
  items: TLItem[];
  groups: TLGroup[];
};

export type TLVisibleTimes = {
  start: Date;
  end: Date;
  unit: 'week' | 'month';
};

type TLPlanningProps = {
  idPrintPdf: string;
  data: TLData;
  visibleTimes: TLVisibleTimes;
  setVisibleTimes: React.Dispatch<React.SetStateAction<TLVisibleTimes>>;
  onEmptyCellClick: (date: Date, id: string) => void;
  onItemClick: (
    id: string,
    title: string,
    subtitle: string | undefined,
    description: string | undefined
  ) => void;
  loading?: boolean;
};

export default function TLPlanning({
  data,
  visibleTimes,
  setVisibleTimes,
  onEmptyCellClick,
  onItemClick,
  idPrintPdf,
  loading = false,
}: TLPlanningProps) {
  const { t } = useI18n();
  const form = useForm({
    initialValues: {
      search: '',
    },
  });

  function handleTimeButtonChange(
    unit: 'week' | 'month' | 'next' | 'prev' | 'today'
  ) {
    if (unit === 'prev') {
      setVisibleTimes((prevState) => {
        const _unit = prevState.unit === 'week' ? 'week' : 'month';
        const refDate = dayjs(prevState.start)
          .endOf(_unit)
          .subtract(1, _unit)
          .toDate();
        return {
          ...prevState,
          start: dayjs(refDate).utc().startOf(prevState.unit).toDate(),
          end: dayjs(refDate).utc().endOf(prevState.unit).toDate(),
        };
      });
      return;
    }

    if (unit === 'next') {
      setVisibleTimes((prevState) => {
        const _unit = prevState.unit === 'week' ? 'week' : 'month';
        const refDate = dayjs(prevState.start)
          .endOf(_unit)
          .add(1, _unit)
          .toDate();
        return {
          ...prevState,
          start: dayjs(refDate).utc().startOf(prevState.unit).toDate(),
          end: dayjs(refDate).utc().endOf(prevState.unit).toDate(),
        };
      });
      return;
    }

    if (unit === 'today') {
      setVisibleTimes((prevState) => ({
        ...prevState,
        start: dayjs().utc().startOf(prevState.unit).toDate(),
        end: dayjs().utc().endOf(prevState.unit).toDate(),
      }));
      return;
    }

    setVisibleTimes((prevState) => ({
      start: dayjs(prevState.start).utc().startOf(unit).toDate(),
      end: dayjs(prevState.start).utc().endOf(unit).toDate(),
      unit,
    }));
  }

  function onCanvasClick(groupId: string, time: number) {
    onEmptyCellClick(dayjs(time).utc().toDate(), groupId);
  }

  function filterData() {
    if (!!form.values.search?.trim()) {
      return {
        items: data.items,
        groups: data.groups.filter((group) => {
          try {
            const groupObj = JSON.parse(group.id);
            if (groupObj?.userFullname) {
              return groupObj.userFullname
                .trim()
                .toLowerCase()
                .includes(form.values.search.trim().toLowerCase());
            } else {
              return false;
            }
          } catch (_e) {
            return false;
          }
        }),
      };
    }
    return data;
  }

  return (
    <div className={s.root}>
      <Group>
        {filterData().groups.length === 1 ? null : (
          <form>
            <TextInput
              leftSectionPointerEvents="none"
              leftSection={
                <IconSearch style={{ width: rem(16), height: rem(16) }} />
              }
              size="sm"
              variant="filled"
              placeholder={t('w.search')}
              {...form.getInputProps('search')}
            />
          </form>
        )}
        <LoadingBadge loading={loading} />
        <Group>
          <Button
            size="sm"
            variant="default"
            onClick={() => handleTimeButtonChange('prev')}
          >
            &lt;
          </Button>
          <Button
            size="sm"
            variant="default"
            onClick={() => handleTimeButtonChange('today')}
          >
            {t('w.today')}
          </Button>
          <Button
            size="sm"
            variant="default"
            onClick={() => handleTimeButtonChange('next')}
          >
            &gt;
          </Button>
        </Group>
        <SegmentedControl
          value={visibleTimes.unit}
          onChange={(value) =>
            handleTimeButtonChange(value as 'month' | 'week')
          }
          data={[
            { label: t('w.week'), value: 'week' },
            { label: t('w.month'), value: 'month' },
          ]}
        />
      </Group>

      <div id={idPrintPdf}>
        <Timeline
          key={`${visibleTimes.start.valueOf()}-${visibleTimes.end.valueOf()}`}
          {...filterData()}
          resizeDetector={containerResizeDetector}
          visibleTimeStart={visibleTimes.start.valueOf()}
          visibleTimeEnd={visibleTimes.end.valueOf()}
          canMove={false}
          canChangeGroup={false}
          canResize={false}
          itemHeightRatio={0.95}
          buffer={1}
          onCanvasClick={onCanvasClick}
          itemRenderer={({ item, getItemProps }) => {
            return (
              <div
                {...getItemProps({
                  style: {
                    background: item.props.bgColor,
                    border: 'none',
                    overflow: 'hidden',
                    textAlign: 'center',
                  },
                  onMouseDown: () => {
                    const { id, title, subtitle, description } = item.props;
                    onItemClick(id, title, subtitle, description);
                  },
                })}
              >
                {item.props.content}
              </div>
            );
          }}
        >
          <TimelineHeaders className={s.timelineHeaders}>
            {visibleTimes.unit === 'month' && (
              <DateHeader unit="primaryHeader" />
            )}
            <DateHeader
              className={s.isoWeek}
              unit="isoWeek"
              labelFormat="\S\e\m\a\i\n\e W"
            />
            <DateHeader className={s.isoWeek} />
          </TimelineHeaders>
          {/*<TimelineMarkers>
          <TodayMarker date={dayjs().utc().toDate()} />
        </TimelineMarkers>*/}
        </Timeline>
        {loading && <Skeleton height={30} />}
      </div>
    </div>
  );
}
