import { Fieldset, Group, Stack, TextInput } from '@mantine/core';
import * as React from 'react';

import CustomDropzone from '../../../../../components/CustomDropzone/CustomDropzone';
import LabelText from '../../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../../contexts/I18nProvider';
import { UserPersonalSensitiveDocument } from '../../../../../types/api/payload/user';
import type { UserResponse } from '../../../../../types/api/response/user';
import UserPersonalSensitiveDocumentViewer from '../UserPersonalSensitiveDocumentViewer/UserPersonalSensitiveDocumentViewer';

type Props = {
  form: any;
  disabled: boolean;
  employee: UserResponse;
};

export default function IdentityForm({ form, disabled, employee }: Props) {
  const { t } = useI18n();

  return (
    <Stack>
      <TextInput
        label={<LabelText text={t('w.nationalIdentityCardNumber')} />}
        placeholder={t('w.noValue')}
        {...form.getInputProps('identityCardNumber')}
        disabled={disabled}
        required={Boolean(employee?.onBoardingId)}
      />
      <Fieldset legend={t('w.identityDocument')}>
        <Group grow preventGrowOverflow={false} wrap="nowrap">
          <UserPersonalSensitiveDocumentViewer
            userId={employee.id}
            type={UserPersonalSensitiveDocument.identityCard}
            fileName={t('w.identityDocument')}
          />
          <CustomDropzone
            onDrop={(files) => form.setFieldValue('identityCardFiles', files)}
            files={form.values.identityCardFiles}
            mimeType={'pdf'}
            resetFile={() => form.setFieldValue('identityCardFiles', [])}
            disabled={disabled}
          />
        </Group>
      </Fieldset>
      <Fieldset legend={t('w.drivingLicense')}>
        <Group grow preventGrowOverflow={false} wrap="nowrap">
          <UserPersonalSensitiveDocumentViewer
            userId={employee.id}
            type={UserPersonalSensitiveDocument.drivingLicense}
            fileName={t('w.drivingLicense')}
          />
          <CustomDropzone
            onDrop={(files) => form.setFieldValue('drivingLicenseFiles', files)}
            files={form.values.drivingLicenseFiles}
            mimeType={'pdf'}
            resetFile={() => form.setFieldValue('drivingLicenseFiles', [])}
            disabled={disabled}
          />
        </Group>
      </Fieldset>
    </Stack>
  );
}
