import React from 'react';

import Page from '../../../components/Page/Page';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import useCompany from '../../../hooks/useCompany';
import { toDateOfDayString } from '../../../utils/format';
import HolidayCounters from './components/HolidayCounters';

export default function EndOfMonthHolidayCounter() {
  const { user } = useAuth();
  const { t } = useI18n();
  const { id: companyId, company } = useCompany(user);

  return (
    <Page
      title={t('w.holidayCounters')}
      parent={{ label: company ? company.name : toDateOfDayString() }}
    >
      <HolidayCounters companyId={companyId} />
    </Page>
  );
}
