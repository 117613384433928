import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Flex, Group, Text, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconEdit, IconTrash, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import CustomTooltip from '../../../components/CustomTooltip';
import Page from '../../../components/Page/Page';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import useCompany from '../../../hooks/useCompany';
import CompanyService from '../../../services/CompanyService';
import type { CompanyGroup } from '../../../types/api/payload/company';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../types/api/response/role';
import { hasPermission } from '../../../utils/authorization';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import CompanyCountry from './components/CompanyCountry';
import CompanyGroupForm from './components/CompanyGroupForm';
import CompanyLogoForm from './components/CompanyLogoForm';
import CompanySettingsForm from './components/CompanySettingsForm';

export default function CompanySettings() {
  const { t, lang } = useI18n();
  const { user } = useAuth();
  const { id: companyId, company } = useCompany(user);
  const [activeLink, setActiveLink] = useState(t('w.informations'));

  const {
    data: companyGroupList,
    refetch: refetchCompanyGroupList,
    isLoading: isCompanyGroupLoading,
  } = useQuery({
    queryKey: ['CompanyService.listCompanyGroup', companyId],
    queryFn: () => CompanyService.listCompanyGroup(companyId),
  });

  const { mutate: deleteCompanyGroup } = useMutation({
    mutationFn: (variables: { companyId: string; companyGroupId: string }) =>
      CompanyService.deleteCompanyGroup(
        variables.companyId,
        variables.companyGroupId
      ),
    onSuccess: () => {
      showNotification({
        id: `delete-company-group-successful`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetchCompanyGroupList();
    },
    onError: (error) =>
      showNotification({
        id: `delete-company-group-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const columns: DataTableColumn<CompanyGroup>[] = useMemo(
    () => [
      {
        accessor: 'label',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'siret',
        title: t('w.siret'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'codeApe',
        title: t('w.companyCodeApe'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'healthService',
        title: t('w.healthService'),
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'address',
        title: t('w.address'),
        sortable: true,
        ellipsis: true,
        render: ({ address }) => (
          <Text>
            {address.address1} {address.address2} {address.zipCode}{' '}
            {address.city}
          </Text>
        ),
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: '0%',
        render: (companyGroup) =>
          companyGroup.siret !== company?.siret ? (
            <Group gap={4} justify="end" wrap={'nowrap'}>
              <CustomTooltip label={t('w.edit')}>
                <ActionIcon
                  size="sm"
                  color="green"
                  variant={'subtle'}
                  onClick={() =>
                    modals.open({
                      modalId: `add-new-company-group-modal`,
                      title: (
                        <Title size={'h3'} component="p">
                          {t('w.edit')}
                        </Title>
                      ),
                      size: '90%',
                      children: company && (
                        <CompanyGroupForm
                          company={company}
                          companyGroup={companyGroup}
                          refetch={refetchCompanyGroupList}
                        />
                      ),
                    })
                  }
                  disabled={
                    !hasPermission(
                      [
                        {
                          permission: BuiltInPermissions.ConfigureCompanyGroup,
                          scope: ScopeEnum.ALL,
                        },
                      ],
                      user,
                      companyId
                    )
                  }
                >
                  <IconEdit size={16} />
                </ActionIcon>
              </CustomTooltip>
              <CustomTooltip label={t('w.delete')}>
                <ActionIcon
                  size="sm"
                  color="red"
                  variant={'subtle'}
                  onClick={() =>
                    modals.openConfirmModal({
                      modalId: 'confirm-delete-company-group',
                      title: (
                        <Title size={'h3'} component="p">
                          {
                            "Êtes-vous sûr de vouloir supprimer l'entreprise rattachée ?"
                          }
                        </Title>
                      ),
                      size: 'xl',
                      labels: { cancel: t('w.cancel'), confirm: t('w.delete') },
                      onConfirm: () =>
                        deleteCompanyGroup({
                          companyId: company?.id!,
                          companyGroupId: companyGroup.id!,
                        }),
                    })
                  }
                  disabled={
                    !hasPermission(
                      [
                        {
                          permission: BuiltInPermissions.ConfigureCompanyGroup,
                          scope: ScopeEnum.ALL,
                        },
                      ],
                      user,
                      companyId
                    )
                  }
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </CustomTooltip>
            </Group>
          ) : (
            <></>
          ),
      },
    ],
    [company]
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'label',
      label: t('w.name'),
    },
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    setOptions(_options);
  }

  function renderContent() {
    switch (activeLink) {
      case t('w.informations'):
        return (
          <>
            <Flex justify={'space-between'}>
              <CompanyLogoForm companyId={companyId} />
              {company && <CompanyCountry company={company} />}
            </Flex>
            {company && <CompanySettingsForm company={company} />}
          </>
        );
      case t('w.companyGroups'):
        return (
          <Group grow preventGrowOverflow={false} wrap="nowrap">
            {company && companyGroupList ? (
              <Table
                pinFirstColumn
                pinLastColumn
                rows={companyGroupList}
                options={options}
                columns={columns}
                lang={lang}
                fetching={isCompanyGroupLoading}
                withTableBorder={false}
                width="0vw" // autoscale width
              />
            ) : (
              <Text c="dimmed" size="sm">
                {t('w.noLinkedCompanies')}
              </Text>
            )}
          </Group>
        );
      default:
        return null;
    }
  }

  return (
    <Page
      title={t('w.settings')}
      parent={{ label: activeLink }}
      links={[
        {
          active: activeLink === t('w.informations'),
          label: t('w.informations'),
          onClick: () => setActiveLink(t('w.informations')),
        },
        {
          active: activeLink === t('w.companyGroups'),
          label: t('w.companyGroups'),
          onClick: () => setActiveLink(t('w.companyGroups')),
        },
      ]}
      add={
        hasPermission(
          [
            {
              permission: BuiltInPermissions.ConfigureCompanyGroup,
              scope: ScopeEnum.ALL,
            },
          ],
          user,
          companyId
        ) &&
        !!company &&
        activeLink === t('w.companyGroups')
          ? {
              label: t('companyGroup.add'),
              onClick: () =>
                modals.open({
                  modalId: 'add-new-company-group-modal',
                  title: (
                    <Title size={'h3'} component="p">
                      {t('companyGroup.add')}
                    </Title>
                  ),
                  size: '90%',
                  children: (
                    <CompanyGroupForm
                      company={company}
                      refetch={refetchCompanyGroupList}
                    />
                  ),
                }),
            }
          : undefined
      }
    >
      {renderContent()}
    </Page>
  );
}
