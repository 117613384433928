import { Group, SimpleGrid, Stack, Text, UnstyledButton } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconDeviceLaptop, IconExternalLink } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import NotificationService from '../../../../services/NotificationService';
import {
  getPersistedObject,
  persistData,
} from '../../../../utils/localStorage';
import s from './WhatsNew.module.sass';

export const WHATS_NEW_KEY = 'hfw-whats-new';

export default function WhatsNew() {
  const { t } = useI18n();
  const { data } = useQuery({
    queryKey: ['NotificationService.getWhatsNew'],
    queryFn: () => NotificationService.getWhatsNew(),
  });

  function openLink(url: string) {
    window.open(url, '_blank', 'noreferrer');
  }

  useEffect(() => {
    if (!!data) {
      const storedWhatsNew = getPersistedObject(WHATS_NEW_KEY);
      if (!storedWhatsNew || storedWhatsNew !== data.id) {
        modals.open({
          id: 'whats-new',
          transitionProps: { transition: 'slide-down' },
          title: (
            <Group>
              🌟
              <Text c="var(--mantine-color-hifivework-7)" fw="bold" size="xl">
                {data.title}
              </Text>
              🌟
            </Group>
          ),
          centered: false,
          size: 'xl',
          children: (
            <Stack>
              <Text style={{ textAlign: 'justify' }}>
                <span dangerouslySetInnerHTML={{ __html: data.content }} />
              </Text>
              {(!!data.video || !!data.link) && (
                <SimpleGrid cols={2} p="md" bg="var(--mantine-color-gray-0)">
                  {!!data.video && (
                    <UnstyledButton
                      className={s.item}
                      onClick={() => openLink(data.video!)}
                    >
                      <IconDeviceLaptop
                        color="var(--mantine-color-green-6)"
                        size={40}
                      />
                      <Text fw="bold" size="lg" mt={7} c="dimmed">
                        {t('w.video')}
                      </Text>
                    </UnstyledButton>
                  )}
                  {!!data.link && (
                    <UnstyledButton
                      className={s.item}
                      onClick={() => openLink(data.link!)}
                    >
                      <IconExternalLink
                        color="var(--mantine-color-cyan-6)"
                        size={40}
                      />
                      <Text fw="bold" size="lg" mt={7} c="dimmed">
                        {t('w.link')}
                      </Text>
                    </UnstyledButton>
                  )}
                </SimpleGrid>
              )}
            </Stack>
          ),
          onClose: () => {
            persistData(WHATS_NEW_KEY, data.id);
          },
        });
      }
    }

    return () => {
      modals.close('whats-new');
    };
  }, [data]);

  return null;
}
