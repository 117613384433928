import { Container, Group, Paper, Title } from '@mantine/core';
import React, { ReactNode } from 'react';

type Props = {
  title: string | undefined;
  children: ReactNode;
  actionButtons?: ReactNode[];
  paper?: boolean;
};

export default function ScreenWrapper({
  title,
  actionButtons,
  children,
  paper,
}: Props) {
  return (
    <Container fluid mt={'md'} miw="100%" maw="100%">
      <Group justify={'space-between'} mb={'md'}>
        <Title size={'36px'} order={1}>
          {title}
        </Title>

        {actionButtons && (
          <Group gap="xs" align="flex-end">
            {actionButtons}
          </Group>
        )}
      </Group>
      {paper ? (
        <Paper p="md" radius={'md'} withBorder mt={'sm'}>
          {children}
        </Paper>
      ) : (
        children
      )}
    </Container>
  );
}
