import { Group } from '@mantine/core';
import {
  type DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import CustomMonthPickerInput from '../../../components/CustomMonthPickerInput';
import Page from '../../../components/Page/Page';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import useCompany from '../../../hooks/useCompany';
import CharteredAccountantService from '../../../services/CharteredAccountantService';
import { initPickerDate } from '../../../utils/initPickerDate';
import AccountantCustomersEndOfMonthTable from './components/AccountantCustomersEndOfMonthTable';

export default function AccountantCustomerEndOfMonth() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);

  const [selectedDate, setSelectedDate] = useState<
    DateValue | DatesRangeValue | Date[]
  >(initPickerDate());

  const {
    data: customersListData,
    refetch,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [
      'CharteredAccountantService.accountantCustomersList',
      companyId,
      // @ts-ignore
      dayjs(selectedDate).format('MM-YYYY'),
    ],
    queryFn: () =>
      CharteredAccountantService.accountantCustomersList(
        companyId,
        // @ts-ignore
        dayjs(selectedDate).format('MM-YYYY')
      ),
  });

  useEffect(() => {
    if (selectedDate) {
      refetch();
    }
  }, [selectedDate]);

  const rows =
    !customersListData || !user.myCompanies?.length
      ? []
      : customersListData.filter(
          (item) =>
            user.myCompanies?.includes(item.clientCompanyId) && item.active
        );

  return (
    <Page
      title={`${t('w.endOfMonth')} ${t('w.customers').toLowerCase()}`}
      parent={{ label: t('w.configuration') }}
      headerRightSection={
        <CustomMonthPickerInput
          key={'month-picker-end-of-month-customers'}
          value={selectedDate}
          onChange={setSelectedDate}
        />
      }
    >
      <Group grow preventGrowOverflow={false} wrap="nowrap">
        <AccountantCustomersEndOfMonthTable
          selectedDate={selectedDate}
          rows={rows.filter(
            (customer) =>
              !!customer?.divisionAccountant?.length ||
              !!customer?.generalManagers?.length
          )}
          isLoading={isLoading || isFetching}
        />
      </Group>
    </Page>
  );
}
