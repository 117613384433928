import {
  ActionIcon,
  Group,
  Indicator,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { IconEdit, IconEye, IconSelect, IconTrash } from '@tabler/icons-react';
import React from 'react';

import CustomTooltip from '../../../../components/CustomTooltip';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import classes from './WorkTemplate.module.sass';

type Props = {
  template: any;
  handleOpenTemplateClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleDeleteTemplateClick: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
  setSelectedTemplate: () => void;
  selectedTemplate?: any;
};

export default function WorkTemplate({
  template,
  handleOpenTemplateClick,
  handleDeleteTemplateClick,
  setSelectedTemplate,
  selectedTemplate,
}: Props) {
  const { user } = useAuth();
  const { t } = useI18n();

  return (
    <Tooltip label={t('w.clickToSelectTemplate', template.name)}>
      <Indicator
        w="100%"
        label={<IconSelect size={20} color={'var(--mantine-color-green-4)'} />}
        offset={13}
        size={16}
        color="transparent"
        disabled={selectedTemplate?.name !== template.name}
      >
        <Stack
          className={classes.overviewWrapper}
          onClick={setSelectedTemplate}
          p="xs"
          styles={{
            root: {
              backgroundColor:
                selectedTemplate?.name === template.name
                  ? 'var(--mantine-color-hifivework-0)'
                  : 'var(--mantine-color-body)',
              border: `1px solid ${
                selectedTemplate?.name === template.name
                  ? 'var(--mantine-color-hifivework-2)'
                  : 'var(--mantine-primary-color-light)'
              }`,
            },
          }}
        >
          <Text fz={'xs'} fw={'bold'} c={'dimmed'} truncate={'end'}>
            {template.name}
          </Text>
          <Group justify={'space-between'}>
            <CustomTooltip
              label={template.creatorId === user.id ? t('w.edit') : t('w.see')}
              position={'left'}
            >
              <ActionIcon
                variant={'subtle'}
                size={'sm'}
                onClick={(event) => handleOpenTemplateClick(event)}
              >
                {template.shared ? <IconEye /> : <IconEdit />}
              </ActionIcon>
            </CustomTooltip>
            {template.creatorId === user.id && (
              <CustomTooltip label={t('w.delete')} position={'right'}>
                <ActionIcon
                  variant={'subtle'}
                  size={'sm'}
                  color={'red'}
                  onClick={(event) => handleDeleteTemplateClick(event)}
                >
                  <IconTrash />
                </ActionIcon>
              </CustomTooltip>
            )}
          </Group>
        </Stack>
      </Indicator>
    </Tooltip>
  );
}
