import {
  Button,
  Fieldset,
  Group,
  SimpleGrid,
  Textarea,
  TextInput,
} from '@mantine/core';
import { isEmail, isNotEmpty, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconChevronDown,
  IconMail,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React from 'react';

import LabelText from '../../../../components/LabelText/LabelText';
import PhoneField from '../../../../components/PhoneField/PhoneField';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import ProfileService from '../../../../services/ProfileService';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../../types/api/response/role';
import type {
  UserEmergencyContactType,
  UserResponse,
} from '../../../../types/api/response/user';
import { hasPermission } from '../../../../utils/authorization';
import s from './AddEmployeeSteppers/AddEmployeeSteppers.module.sass';

type Props = {
  employee: UserResponse | null;
  refetchUserEmergencyContact?: () => void;
  emergencyContact?: UserEmergencyContactType;
  parentForm: any;
  setUserEmergencyContact: (data: UserEmergencyContactType[]) => void;
};

export default function EditEmergencyContactForm({
  employee,
  refetchUserEmergencyContact,
  emergencyContact,
  parentForm,
  setUserEmergencyContact,
}: Props) {
  const { t } = useI18n();
  const { access_token, user } = useAuth();

  const disabled =
    !hasPermission(
      {
        permission: BuiltInPermissions.UserEmergencyContactEdit,
        scope: ScopeEnum.ALL,
      },
      user,
      employee?.companyId
    ) || user.id !== employee?.id;

  const form = useForm<UserEmergencyContactType>({
    initialValues: {
      id: emergencyContact
        ? emergencyContact?.id
          ? emergencyContact.id
          : null
        : null,
      userId: emergencyContact
        ? emergencyContact?.userId
          ? emergencyContact.userId
          : employee
          ? employee.id
          : ''
        : employee
        ? employee.id
        : '',
      lastname: emergencyContact
        ? emergencyContact?.lastname
          ? emergencyContact.lastname
          : ''
        : '',
      firstname: emergencyContact
        ? emergencyContact?.firstname
          ? emergencyContact.firstname
          : ''
        : '',
      comment: emergencyContact
        ? emergencyContact?.comment
          ? emergencyContact.comment
          : ''
        : '',
      email: emergencyContact
        ? emergencyContact?.email
          ? emergencyContact.email
          : ''
        : '',
      phone: emergencyContact
        ? emergencyContact?.phone
          ? emergencyContact.phone
          : ''
        : '',
    },
    validate: {
      lastname: isNotEmpty(t('w.required')),
      firstname: isNotEmpty(t('w.required')),
      email: isEmail(t('invalid.email')),
    },
  });

  const { mutate: updateUserEmergencyContact } = useMutation({
    mutationFn: (variables: any) =>
      ProfileService.createOrUpdateUserEmergencyContact(
        employee?.id,
        variables,
        access_token
      ),
    onSuccess: (data) => {
      setUserEmergencyContact(data);
      parentForm.setFieldValue('personsToContact', data);
      if (refetchUserEmergencyContact) {
        refetchUserEmergencyContact();
      }
      showNotification({
        id: 'udpate-added-employee-emergency-contact-success',
        title: t('w.success'),
        message: t('success.emergencyContactUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-personal-info-error',
        title: t('w.error'),
        message: t('error.updateUserEmergencyContact', employee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleUpdateUserProfileFormSubmit(values: UserEmergencyContactType) {
    form.validate();
    if (form.isValid()) {
      if (values.id !== null) {
        updateUserEmergencyContact({
          id: values.id,
          userId: values.userId,
          firstname: values.firstname,
          lastname: values.lastname,
          email: values.email,
          phone: values.phone,
          comment: values.comment,
        });
      } else {
        updateUserEmergencyContact({
          userId: values.userId,
          firstname: values.firstname,
          lastname: values.lastname,
          email: values.email,
          phone: values.phone,
          comment: values.comment,
        });
      }
    }
  }

  return (
    <Fieldset legend={t('w.addEmergencyContactForm')} variant="filled" mb="sm">
      <SimpleGrid cols={{ base: 1, md: 2, lg: 4 }}>
        <TextInput
          required
          label={<LabelText text={t('w.name')} />}
          placeholder={t('w.noValue')}
          {...form.getInputProps('lastname')}
          disabled={disabled}
        />
        <TextInput
          required
          label={<LabelText text={t('w.firstname')} />}
          placeholder={t('w.noValue')}
          {...form.getInputProps('firstname')}
          disabled={disabled}
        />
        <TextInput
          required
          label={<LabelText text={t('w.emailAddress')} />}
          rightSection={<IconMail className={s.icon} />}
          placeholder={t('w.noValue')}
          {...form.getInputProps('email')}
          disabled={disabled}
        />
        <PhoneField
          value={form.values.phone}
          onChange={(value) => form.setFieldValue(`phone`, value)}
        />
      </SimpleGrid>
      <Textarea
        label={<LabelText text={t('w.comment')} />}
        placeholder={t('w.noValue')}
        {...form.getInputProps('comment')}
        disabled={disabled}
      />
      <Group justify="center" mt="md">
        <Button
          onClick={() => handleUpdateUserProfileFormSubmit(form.values)}
          variant="light"
          leftSection={<IconChevronDown />}
          disabled={disabled}
        >
          {t('w.add')}
        </Button>
      </Group>
    </Fieldset>
  );
}
