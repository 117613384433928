import React from 'react';

import { useAuth } from '../../../contexts/AuthProvider';
import useCompany from '../../../hooks/useCompany';
import ExpensesTable from './ExpensesTable';

export default function EndOfMonthExpenseReports() {
  const { user } = useAuth();
  const { company } = useCompany(user);

  if (!company) return null;

  return <ExpensesTable company={company} />;
}
