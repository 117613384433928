import { NotificationCard } from '../../../components/NotificationCard/NotificationCard';
import Page from '../../../components/Page/Page';
import { useI18n } from '../../../contexts/I18nProvider';

export default function AccountantConfigurations() {
  const { t } = useI18n();

  return (
    <Page title={t('w.notifications')} parent={{ label: t('w.configuration') }}>
      <NotificationCard type={'accountant'} />
    </Page>
  );
}
