import { Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs, { type Dayjs } from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';

import LoadingBadge from '../../../../components/LoadingBadge';
import Page, { PageLink } from '../../../../components/Page/Page';
import { useAppBar } from '../../../../contexts/AppBarProvider';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useActivities from '../../../../hooks/useActivities';
import useCompany from '../../../../hooks/useCompany';
import useFetchDivisions from '../../../../hooks/useFetchDivisions';
import CompanyService from '../../../../services/CompanyService';
import DailyWorkService from '../../../../services/DailyWorkService';
import {
  BuiltInPermissions,
  BuiltInRoles,
  ScopeEnum,
} from '../../../../types/api/response/role';
import type { UserDailyWork } from '../../../../types/front/dailyWork';
import type { Period } from '../../../../types/types';
import { hasPermission, hasRole } from '../../../../utils/authorization';
import { toDateOfDayString } from '../../../../utils/format';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import saveDownloadedfile from '../../../../utils/saveDownloadedFile';
import { NO_WORK_UNIT } from '../../../../variables/GlobalVariables';
import ImportActivitiesForm from '../../my-activities/components/ImportActivitiesForm';
import DailyWorkList from './DailyWorkList';

type Props = {
  companyId: string;
  withUnitFilter?: boolean;
};

export default function TeamDailyWorks({
  companyId,
  withUnitFilter = true,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { company } = useCompany(user, companyId);
  const { lang } = useI18n();
  dayjs.locale(lang);
  const [currentMonth, setCurrentMonth] = useState<Dayjs>(
    dayjs().startOf('month')
  );
  const [currentWeek, setCurrentWeek] = useState<Dayjs>(
    dayjs().startOf('week')
  );
  const [weekOrMonthPeriodShown, setWeekOrMonthPeriodShown] = useState('month');
  const [expandedUserIds, setExpandedUserIds] = useState<string[]>([]);
  const { divisions } = useFetchDivisions({ companyId });
  const {
    setIsAppBarUsed,
    setDivisionsToSelect,
    setAppBarSelectedDivisions,
    appBarSelectedDivisions,
  } = useAppBar();
  useEffect(() => {
    if (!withUnitFilter) return;
    setIsAppBarUsed(true);
    return function cleanup() {
      setIsAppBarUsed(false);
    };
  }, []);
  useEffect(() => {
    if (!withUnitFilter) return;
    buildAppbarDivisions();
  }, [hasRole(BuiltInRoles.ADMINISTRATOR, user, companyId), divisions]);
  function buildAppbarDivisions() {
    let _divisions = divisions.filter(
      (division) => division.name !== NO_WORK_UNIT
    );
    if (!hasRole(BuiltInRoles.ADMINISTRATOR, user, companyId)) {
      _divisions = _divisions.filter(
        (division) =>
          division.n1 === user.id ||
          division.n2 === user.id ||
          division.assistant === user.id
      );
    }
    const divisionsNames = _divisions.map((division) => division.name);
    setDivisionsToSelect(divisionsNames);
    setAppBarSelectedDivisions(divisionsNames);
  }
  const selectedDivisionIds: string[] = useMemo(() => {
    if (!divisions) return [];

    if (!appBarSelectedDivisions?.length) {
      return divisions.map((item) => item.id);
    }

    const result: string[] = [];
    appBarSelectedDivisions.forEach((selectedDivision: string) => {
      const findDivision = divisions.find(
        (division) => division.name === selectedDivision
      );
      if (findDivision) {
        result.push(findDivision.id);
      }
    });
    return result;
  }, [divisions, appBarSelectedDivisions]);

  const period: Period = {
    start:
      weekOrMonthPeriodShown === 'week'
        ? currentWeek.startOf('week')
        : currentMonth,
    end:
      weekOrMonthPeriodShown === 'week'
        ? currentWeek.endOf('week')
        : currentMonth.endOf('month'),
  };

  const { data: users } = useQuery({
    queryKey: ['CompanyService.listEmployees', companyId],
    queryFn: () =>
      CompanyService.listEmployees(companyId, [
        'fullname',
        'firstname',
        'lastname',
        'securityRoleId',
        'divisionId',
        'companyId',
        'valid',
        'mail',
        'employment',
        'paidFeatures',
        'fixedPriceManager',
      ]),
  });

  const {
    usersActivities,
    refetch: refetchDailyWorkListOnPeriod,
    loading,
  } = useActivities({
    users: !!users
      ? users.filter((_user) => selectedDivisionIds.includes(_user.divisionId!))
      : [],
    period,
  });

  const { mutate: exportUserDailyWorkByDivisions } = useMutation({
    mutationFn: (variables: {
      divisionIds: string[];
      month: number;
      year: number;
    }) =>
      DailyWorkService.exportUserDailyWorkByDivisions(
        variables.divisionIds,
        variables.month,
        variables.year
      ),
    onSuccess: (data) => {
      saveDownloadedfile(data);
    },
    onError: (error) =>
      showNotification({
        id: 'export-dailywork-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconX />,
        color: 'red',
      }),
  });

  function openImport(userDailyWorks: UserDailyWork[], date: Dayjs) {
    modals.open({
      modalId: `import-user-activities`,
      title: (
        <Title size={'h3'} component="p">
          {t('w.import')}
        </Title>
      ),
      size: 'xl',
      children: (
        <ImportActivitiesForm
          exportClick={() => onExportClick(userDailyWorks, date)}
          refresh={refetchDailyWorkListOnPeriod}
        />
      ),
    });
  }

  function onExportClick(userDailyWorks: UserDailyWork[], date: Dayjs) {
    const divisionIds = userDailyWorks.map(
      (userDailyWork) => userDailyWork.dailyWorks[0].user.division.id
    );
    const uniqueDivisionIds = divisionIds.filter(
      (item: string, index: number) => divisionIds.indexOf(item) === index
    );
    exportUserDailyWorkByDivisions({
      divisionIds: uniqueDivisionIds,
      month: date.month() + 1,
      year: date.year(),
    });
  }

  function filterUsersActivities() {
    return usersActivities.filter((item) => {
      if (!item.dailyWorks?.length) {
        return true;
      }

      return selectedDivisionIds.includes(item.dailyWorks[0].user.division.id);
    });
  }

  function getLinks() {
    const links: PageLink[] = [];
    if (
      hasPermission(
        {
          permission: BuiltInPermissions.AuthorizedUpsertDailyWork,
          scope: ScopeEnum.ALL,
        },
        user,
        companyId
      )
    ) {
      links.push({
        active: false,
        label: `${t('w.import')} ${t('w.activities').toLowerCase()}`,
        onClick: () =>
          openImport(
            usersActivities,
            weekOrMonthPeriodShown === 'week' ? currentWeek : currentMonth
          ),
        disabled: !usersActivities?.length,
      });
    }

    links.push({
      active: false,
      label: `${t('w.export')} ${t('w.activities').toLowerCase()}`,
      onClick: () =>
        onExportClick(
          usersActivities,
          weekOrMonthPeriodShown === 'week' ? currentWeek : currentMonth
        ),
      disabled: !usersActivities?.length,
    });
    return links;
  }

  return (
    <Page
      title={t('w.teamActivities')}
      parent={{ label: company ? company.name : toDateOfDayString() }}
      links={getLinks()}
      headerRightSection={<LoadingBadge loading={loading} />}
    >
      <DailyWorkList
        dailyWorkList={filterUsersActivities()}
        refetchDailyWorkListOnPeriod={refetchDailyWorkListOnPeriod}
        currentMonth={currentMonth}
        onMonthChange={setCurrentMonth}
        currentWeek={currentWeek}
        onWeekChange={setCurrentWeek}
        weekOrMonthPeriodShown={weekOrMonthPeriodShown}
        onChangeWeekOrMonthPeriodShown={setWeekOrMonthPeriodShown}
        expandedUserIds={expandedUserIds}
        onChangeExpandedUserIds={setExpandedUserIds}
        key={`${currentWeek.format('DD-MM')} ${currentMonth.format(
          'YYYY-MM-DD'
        )}`}
        companyId={companyId}
      />
    </Page>
  );
}
