import {
  AppShell as MantineAppShell,
  Box,
  Burger,
  Group,
  ScrollArea,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconBrandDaysCounter,
  IconBuildingStore,
  IconCalendarDue,
  IconCalendarEvent,
  IconCalendarTime,
  IconClipboardCheck,
  IconClipboardList,
  IconFileDescription,
  IconFileDollar,
  IconFolders,
  IconHome,
  IconInbox,
  IconListDetails,
  IconReceipt,
  IconReceiptTax,
  IconReport,
  IconSettings,
  IconUsers,
} from '@tabler/icons-react';
import React, { ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import IndicatorBadge from '../../../components/badges/IndicatorBadge';
import CompanyBadge from '../../../components/CompanyBadge/CompanyBadge';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import { useModule } from '../../../contexts/ModuleProvider';
import useDisplayTeamActivities from '../../../hooks/accessChecking/activities/useDisplayTeamActivities';
import useDisplayUserActivities from '../../../hooks/accessChecking/activities/useDisplayUserActivities';
import useCompany from '../../../hooks/useCompany';
import usePendingValidations from '../../../hooks/usePendingValidations';
import { ModuleName } from '../../../types/api/response/module';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../types/api/response/role';
import { ACCOUNTANT_MODE, type Link } from '../../../types/types';
import { hasPermission } from '../../../utils/authorization';
import { isWhiteLabel } from '../../../utils/isWhiteLabel';
import { getPersistedObject } from '../../../utils/localStorage';
import { isHiFiveWorkProject } from '../../../utils/projects';
import { NO_WORK_UNIT } from '../../../variables/GlobalVariables';
import Appbar from '../Appbar';
import NavbarLinksGroup from '../NavBarLinksGroup/NavbarLinksGroup';
import UserMenu from '../UserMenu/UserMenu';

type Props = {
  children: ReactNode;
};

export default function AppShell({ children }: Props) {
  const { user } = useAuth();
  const { t } = useI18n();
  const { pathname } = useLocation();
  const { id: companyId, company } = useCompany(user);
  const { getModule } = useModule();
  const [opened, { toggle }] = useDisclosure();
  const { pendingValidations: pendingValidationsCount } =
    usePendingValidations();
  const isExpenseReportModuleActive = Boolean(
    getModule(ModuleName.ExpenseReport)?.active
  );
  const isShuttleSheetModuleActive = Boolean(
    getModule(ModuleName.ShuttleSheet)?.active
  );
  const isDisplayMyActivities = useDisplayUserActivities();
  const isDisplayTeamActivities = useDisplayTeamActivities();
  const hasPermissionToAccessToAccountantNotificationConfig = hasPermission(
    {
      permission: BuiltInPermissions.AccountantManageTeam,
      scope: ScopeEnum.ALL,
    },
    user,
    companyId
  );
  const hasPermissionToReadMyPersonalSpace = hasPermission(
    {
      permission: BuiltInPermissions.AuthorizedReadMyPersonalSpace,
      scope: ScopeEnum.ALL,
    },
    user,
    companyId
  );
  const hasPermissionToReadCommonSpace = hasPermission(
    {
      permission: BuiltInPermissions.AuthorizedReadCommonSpace,
      scope: ScopeEnum.ALL,
    },
    user,
    companyId
  );
  const isAuthorizedEditAccountantSpace = hasPermission(
    {
      permission: BuiltInPermissions.AuthorizedEditAccountantSpace,
      scope: ScopeEnum.ALL,
    },
    user,
    companyId
  );
  const showNavBar =
    !pathname.includes('accept/link') &&
    !pathname.includes('chartered-accountant/accept/customer/invitation');

  const isAccountantMode = getPersistedObject('mode') === ACCOUNTANT_MODE;
  const settingsLinks: Link[] = useMemo(
    () => [
      {
        label: t('w.employees'),
        link: `/company/${companyId}/employees`,
        isDisplayed: hasPermission(
          {
            permission: BuiltInPermissions.ConfigureEmployees,
            scope: ScopeEnum.ALL,
          },
          user,
          companyId
        ),
      },
      {
        label: company?.divisionLabel ? company.divisionLabel : t('w.units'),
        link: `/company/${companyId}/work-units`,
        isDisplayed: hasPermission(
          [
            {
              permission: BuiltInPermissions.ConfigureWorkUnit,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureWorkUnit,
              scope: ScopeEnum.TEAM,
            },
          ],
          user,
          companyId
        ),
      },
      {
        label: t('w.counterTypes'),
        link: `/company/${companyId}/counter-types`,
        isDisplayed: hasPermission(
          {
            permission: BuiltInPermissions.ConfigureCompany,
            scope: ScopeEnum.ALL,
          },
          user,
          companyId
        ),
      },
      {
        label: t('w.typesOfPeriod'),
        link: `/company/${companyId}/period-types`,
        isDisplayed: hasPermission(
          {
            permission: BuiltInPermissions.ConfigureLeaveTypes,
            scope: ScopeEnum.ALL,
          },
          user,
          companyId
        ),
      },
      {
        label: t('w.periods'),
        link: `/company/${companyId}/periods`,
        isDisplayed: hasPermission(
          [
            {
              permission: BuiltInPermissions.ConfigureBlockingPeriods,
              scope: ScopeEnum.ALL,
            },
            {
              permission:
                BuiltInPermissions.ConfigurePublicHolidaysCompanyLeaves,
              scope: ScopeEnum.ALL,
            },
          ],
          user,
          companyId
        ),
      },
      {
        label: t('w.publicHolidays'),
        link: `/company/${companyId}/public-holidays-and-leave`,
        isDisplayed: hasPermission(
          {
            permission: BuiltInPermissions.ConfigurePublicHolidaysCompanyLeaves,
            scope: ScopeEnum.ALL,
          },
          user,
          companyId
        ),
      },
      {
        label: t('w.shuttleSheetTypes'),
        link: `/company/${companyId}/shuttle-sheet-type`,
        isDisplayed:
          isShuttleSheetModuleActive &&
          hasPermission(
            {
              permission: BuiltInPermissions.ConfigureShuttleSheetTypes,
              scope: ScopeEnum.ALL,
            },
            user,
            companyId
          ),
      },
      {
        label: t('w.payrollOfficers'),
        link: `/company/${companyId}/payroll-officer`,
        isDisplayed:
          user.company?.id === companyId &&
          hasPermission(
            {
              permission: BuiltInPermissions.ConfigureAccountant,
              scope: ScopeEnum.ALL,
            },
            user,
            companyId
          ),
      },
      {
        label: t('w.marketPlace'),
        link: `/company/${companyId}/market-place`,
        isDisplayed:
          hasPermission(
            [
              {
                permission: BuiltInPermissions.ConfigureFeatures,
                scope: ScopeEnum.ALL,
              },
              {
                permission: BuiltInPermissions.ConfigureTools,
                scope: ScopeEnum.ALL,
              },
            ],
            user,
            companyId
          ) && !isWhiteLabel(),
      },
      {
        label: t('w.features'),
        link: `/company/${companyId}/features`,
        isDisplayed: hasPermission(
          {
            permission: BuiltInPermissions.ConfigureFeatures,
            scope: ScopeEnum.ALL,
          },
          user,
          companyId
        ),
      },
      {
        label: t('w.roles'),
        link: `/company/${companyId}/roles`,
        isDisplayed: hasPermission(
          {
            permission: BuiltInPermissions.ConfigurePermissions,
            scope: ScopeEnum.ALL,
          },
          user,
          companyId
        ),
      },
      {
        label: t('w.billing'),
        link: `/company/${companyId}/billing`,
        isDisplayed:
          hasPermission(
            {
              permission: BuiltInPermissions.ConfigurePayment,
              scope: ScopeEnum.ALL,
            },
            user,
            companyId
          ) && isHiFiveWorkProject(),
      },
      {
        label: t('w.settings'),
        link: `/company/${companyId}/settings`,
        isDisplayed: hasPermission(
          {
            permission: BuiltInPermissions.ConfigureCompany,
            scope: ScopeEnum.ALL,
          },
          user,
          companyId
        ),
      },
    ],
    [user, companyId, company?.divisionLabel, isShuttleSheetModuleActive]
  );
  const isClientCompany = companyId !== user.companyId;

  const companyLinks: Link[] = useMemo(
    () => [
      {
        label: <CompanyBadge companyId={companyId} />,
        divider: true,
        isDisplayed: true,
        links: hasPermission(
          [
            {
              permission: BuiltInPermissions.ConfigureWorkUnit,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureWorkUnit,
              scope: ScopeEnum.TEAM,
            },
            {
              permission: BuiltInPermissions.ConfigureEmployees,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureLeaveTypes,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureBlockingPeriods,
              scope: ScopeEnum.ALL,
            },
            {
              permission:
                BuiltInPermissions.ConfigurePublicHolidaysCompanyLeaves,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureShuttleSheetTypes,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureAccountant,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureFeatures,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigurePermissions,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ConfigureCompany,
              scope: ScopeEnum.ALL,
            },
          ],
          user,
          companyId
        )
          ? settingsLinks
          : undefined,
      },
      {
        label: t('w.home'),
        icon: IconHome,
        link: '/home',
        divider: false,
        isDisplayed: true,
      },
      {
        label: t('w.planning'),
        icon: IconCalendarEvent,
        link: '/planning',
        divider: false,
        isDisplayed: hasPermission(
          [
            {
              permission: BuiltInPermissions.AuthorizedReadPlanning,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.AuthorizedReadPlanning,
              scope: ScopeEnum.TEAM,
            },
            {
              permission: BuiltInPermissions.AuthorizedReadPlanning,
              scope: ScopeEnum.MINE,
            },
            {
              permission: BuiltInPermissions.AuthorizedReadAllUsersInfo,
              scope: ScopeEnum.ALL,
            },
          ],
          user,
          companyId
        ),
      },
      {
        label: user.company?.divisionLabel
          ? user.company.divisionLabel
          : t('w.units'),
        icon: IconClipboardList,
        link: '/work-units',
        divider: false,
        isDisplayed: hasPermission(
          [
            {
              permission: BuiltInPermissions.AuthorizedReadAllUsersInfo,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.AuthorizedReadAllUsersInfo,
              scope: ScopeEnum.TEAM,
            },
            {
              permission: BuiltInPermissions.ConfigureEmployees,
              scope: ScopeEnum.ALL,
            },
          ],
          user,
          companyId
        ),
      },
      {
        label: t('w.activities'),
        icon: IconCalendarTime,
        divider: false,
        isDisplayed: isDisplayTeamActivities || isDisplayMyActivities,
        links: [
          {
            label: t('w.myActivities'),
            link: '/activities/user',
            divider: false,
            icon: IconReport,
            isDisplayed: isDisplayMyActivities,
          },
          {
            label: t('w.teamActivities'),
            link: '/activities/team',
            divider: false,
            icon: IconReport,
            isDisplayed: isDisplayTeamActivities,
          },
        ],
      },
      {
        label: (
          <Box component={'div'}>
            {t('w.validations')}
            {pendingValidationsCount.total > 0 && (
              <IndicatorBadge value={pendingValidationsCount.total} />
            )}
          </Box>
        ),
        icon: IconClipboardCheck,
        divider: false,
        isDisplayed:
          hasPermission(
            [
              {
                permission: BuiltInPermissions.ValidateLeavesAbsences,
                scope: ScopeEnum.ALL,
              },
            ],
            user,
            companyId
          ) ||
          (getModule(ModuleName.ExpenseReport)?.active &&
            hasPermission(
              [
                {
                  permission: BuiltInPermissions.ValidateExpenseReports,
                  scope: ScopeEnum.ALL,
                },
              ],
              user,
              companyId
            )),
        links: [
          {
            label: (
              <Box component={'div'}>
                {t('w.leavesAndAbsences')}
                {pendingValidationsCount.leaves > 0 && (
                  <IndicatorBadge value={pendingValidationsCount.leaves} />
                )}
              </Box>
            ),
            link: '/validation/leaves',
            divider: false,
            icon: IconReport,
            isDisplayed: hasPermission(
              [
                {
                  permission: BuiltInPermissions.ValidateLeavesAbsences,
                  scope: ScopeEnum.ALL,
                },
              ],
              user,
              companyId
            ),
          },
          {
            label: (
              <Box component={'div'}>
                {t('w.expenseReports')}
                {pendingValidationsCount.expenseReports > 0 && (
                  <IndicatorBadge
                    value={pendingValidationsCount.expenseReports}
                  />
                )}
              </Box>
            ),
            link: '/validation/expense-reports',
            divider: false,
            icon: IconReport,
            isDisplayed:
              getModule(ModuleName.ExpenseReport)?.active &&
              hasPermission(
                [
                  {
                    permission: BuiltInPermissions.ValidateExpenseReports,
                    scope: ScopeEnum.ALL,
                  },
                ],
                user,
                companyId
              ),
          },
        ],
      },
      {
        label: t('w.endOfMonth'),
        icon: IconCalendarDue,
        divider: true,
        isDisplayed: hasPermission(
          [
            {
              permission: BuiltInPermissions.ValidateLeavesAbsences,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ValidateExpenseReports,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ValidateEndOfMonth,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ValidatePayrollVariables,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ValidateHolidayCounter,
              scope: ScopeEnum.ALL,
            },
            {
              permission: BuiltInPermissions.ValidatePayslips,
              scope: ScopeEnum.ALL,
            },
          ],
          user,
          companyId
        ),
        links: [
          {
            label: t('w.validations'),
            link: '/end-of-month/validation',
            divider: false,
            icon: IconReport,
            isDisplayed: hasPermission(
              [
                {
                  permission: BuiltInPermissions.ValidateLeavesAbsences,
                  scope: ScopeEnum.ALL,
                },
                {
                  permission: BuiltInPermissions.ValidateEndOfMonth,
                  scope: ScopeEnum.ALL,
                },
                {
                  permission: BuiltInPermissions.ValidatePayrollVariables,
                  scope: ScopeEnum.ALL,
                },
              ],
              user,
              companyId
            ),
          },
          {
            label: t('w.holidayCounters'),
            link: '/end-of-month/holiday-counter',
            divider: false,
            icon: IconBrandDaysCounter,
            isDisplayed: hasPermission(
              [
                {
                  permission: BuiltInPermissions.ValidateHolidayCounter,
                  scope: ScopeEnum.ALL,
                },
              ],
              user,
              companyId
            ),
          },
          {
            label: t('w.payslips'),
            link: '/end-of-month/payslips',
            divider: false,
            icon: IconFileDollar,
            isDisplayed: hasPermission(
              [
                {
                  permission: BuiltInPermissions.ValidatePayslips,
                  scope: ScopeEnum.ALL,
                },
              ],
              user,
              companyId
            ),
          },
          {
            label: t('w.expenseReports'),
            link: '/end-of-month/expense-reports',
            divider: false,
            icon: IconReceiptTax,
            isDisplayed:
              isExpenseReportModuleActive &&
              hasPermission(
                [
                  {
                    permission: BuiltInPermissions.ValidateExpenseReports,
                    scope: ScopeEnum.ALL,
                  },
                ],
                user,
                companyId
              ),
          },
        ],
      },
      {
        label: t('w.documents'),
        icon: IconFolders,
        divider: false,
        isDisplayed:
          hasPermissionToReadMyPersonalSpace ||
          hasPermissionToReadCommonSpace ||
          isAuthorizedEditAccountantSpace,
        links: [
          {
            label: t('w.personal'),
            link: `/documents/personal`,
            divider: false,
            icon: IconReport,
            isDisplayed: hasPermissionToReadMyPersonalSpace,
          },
          {
            label: t('w.company'),
            link: `/documents/shared`,
            divider: false,
            icon: IconBrandDaysCounter,
            isDisplayed: hasPermissionToReadCommonSpace,
          },
          {
            label: t('w.payrollOfficers'),
            link: `/documents/accountant`,
            divider: false,
            icon: IconFileDollar,
            isDisplayed: isAuthorizedEditAccountantSpace,
          },
        ],
      },
      {
        label: t('w.myExpenseReports'),
        icon: IconReceipt,
        link: `/expense-reports/user/${user.id}`,
        divider: false,
        isDisplayed: isExpenseReportModuleActive,
      },
      {
        label: t('w.shuttleSheets'),
        icon: IconListDetails,
        link: `/shuttle-sheets/user/${user.id}`,
        divider: false,
        isDisplayed: isShuttleSheetModuleActive,
      },
      {
        label: t('w.myPayslips'),
        icon: IconFileDescription,
        link: `/payslips/user/${user.id}`,
        divider: false,
        isDisplayed: user.division?.name !== NO_WORK_UNIT,
      },
    ],
    [
      user,
      isExpenseReportModuleActive,
      isDisplayMyActivities,
      isDisplayTeamActivities,
      pendingValidationsCount,
      isShuttleSheetModuleActive,
      hasPermissionToReadMyPersonalSpace,
      hasPermissionToReadCommonSpace,
      isAuthorizedEditAccountantSpace,
    ]
  );

  const accountantLinks: Link[] = [
    {
      label: <CompanyBadge companyId={companyId} />,
      divider: true,
      isDisplayed: true,
      links: isClientCompany ? settingsLinks : undefined,
    },
    {
      icon: IconBuildingStore,
      label: t('w.accountantHome'),
      link: '/chartered-accountant/market-place',
      divider: false,
      isDisplayed: true,
    },
    {
      icon: IconUsers,
      label: t('w.firmClients'),
      link: '/chartered-accountant/customer-management',
      divider: false,
      isDisplayed: true,
    },
    {
      icon: IconCalendarDue,
      label: `${t('w.endOfMonth')} ${t('w.customers').toLowerCase()}`,
      link: '/chartered-accountant/customer-end-of-month',
      divider: false,
      isDisplayed: true,
    },
    {
      icon: IconInbox,
      label: `${t('w.shuttleSheets')} ${t('w.customers').toLowerCase()}`,
      link: '/chartered-accountant/customer-shuttle-sheet',
      divider: false,
      isDisplayed: true,
    },
    {
      icon: IconSettings,
      label: 'Configurations',
      link: '/chartered-accountant/configurations',
      divider: false,
      isDisplayed: hasPermissionToAccessToAccountantNotificationConfig,
    },
  ];

  return (
    <MantineAppShell
      header={{ height: 60 }}
      navbar={{ width: 280, breakpoint: 'md', collapsed: { mobile: !opened } }}
      padding="md"
    >
      <MantineAppShell.Header>
        <Group w="100%" h="100%" px="md" wrap="nowrap">
          <Burger opened={opened} onClick={toggle} hiddenFrom="md" size="sm" />
          <Appbar />
        </Group>
      </MantineAppShell.Header>
      <MantineAppShell.Navbar>
        {showNavBar && (
          <MantineAppShell.Section grow component={ScrollArea}>
            {!Boolean(user.onBoardingId) && (
              <>
                {(isAccountantMode
                  ? accountantLinks.filter((link) => link.isDisplayed)
                  : companyLinks.filter((link) => link.isDisplayed)
                ).map((item, index) => (
                  <NavbarLinksGroup {...item} key={`${item.link}-${index}`} />
                ))}
              </>
            )}
          </MantineAppShell.Section>
        )}

        <MantineAppShell.Section>
          <UserMenu />
        </MantineAppShell.Section>
      </MantineAppShell.Navbar>
      <MantineAppShell.Main>{children}</MantineAppShell.Main>
    </MantineAppShell>
  );
}
