import * as React from 'react';
import { useRef } from 'react';

import { UserResponse } from '../../../types/api/response/user';
import PlanningScheduler, {
  PlanningSchedulerRef,
} from '../../planning/components/PlanningScheduler';

type Props = {
  user: UserResponse;
};

export default function UserPlanning({ user }: Props) {
  const planningSchedulerRef = useRef<PlanningSchedulerRef>(null);

  return (
    <PlanningScheduler
      planningId={'user-planning-id'}
      selectedDivisions={[user.division?.id!]}
      divisions={[user.division!]}
      company={user.company!}
      ref={planningSchedulerRef}
      userIdToView={user.id}
    />
  );
}
