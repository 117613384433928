import {
  ActionIcon,
  Button,
  ColorInput,
  Container,
  DEFAULT_THEME,
  Drawer,
  Flex,
  Grid,
  Group,
  MultiSelect,
  NumberInput,
  SimpleGrid,
  Space,
  Stepper,
  Switch,
  TextInput,
  Title,
} from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconInfoCircle, IconX } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';

import ButtonSave from '../../../../components/ButtonSave';
import CustomCheckBox from '../../../../components/CustomCheckBox';
import InputSkeleton from '../../../../components/InputSkeleton';
import LabelText from '../../../../components/LabelText/LabelText';
import MoreInfoButtonModal from '../../../../components/MoreInfoButtonModal';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import { useModule } from '../../../../contexts/ModuleProvider';
import useCompany from '../../../../hooks/useCompany';
import useResponsive from '../../../../hooks/useResponsive';
import CounterTypeService from '../../../../services/CounterTypeService';
import SecurityRoleService from '../../../../services/SecurityRoleService';
import type { CounterType } from '../../../../types/api/response/counterType';
import { ModuleName } from '../../../../types/api/response/module';
import type { HfwSecurityRoleResponse } from '../../../../types/api/response/role';
import type {
  AssociateCounter,
  LeaveTypeFormValues,
} from '../../../../types/front/leaveType';
import { getTranslatedKey } from '../../../../utils/counterTypesFormatter';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import { roleName } from '../../../../utils/roleName';
import IconSelector, { getIcon } from './IconSelector';

type Props = {
  form: UseFormReturnType<LeaveTypeFormValues>;
  isEdit: boolean;
};

export default function PeriodTypeStepper({ form, isEdit }: Props) {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const { getModule } = useModule();
  const [active, setActive] = useState(0);
  const [
    openedSelectLeaveTypeIcon,
    {
      open: handleOpenSelectLeaveTypeIcon,
      close: handleCloseSelectLeaveTypeIcon,
    },
  ] = useDisclosure(false);
  const { isMobile, isDesktop } = useResponsive();

  const { data, isLoading, isSuccess } = useQuery({
    queryKey: ['SecurityRoleService.list', companyId],
    queryFn: () => SecurityRoleService.list(companyId),
    onError: (err) => {
      form.setFieldError('securityRoles', t('error.securityRoles'));
      showNotification({
        id: 'error-list-security-roles',
        title: t('w.error'),
        message: handleErrorMessage(err, t),
        color: 'red',
        icon: <IconX />,
      });
    },
  });

  const { data: counterTypes } = useQuery({
    enabled: !!companyId,
    queryKey: ['CounterTypeService.list', companyId],
    queryFn: () => CounterTypeService.list(companyId),
  });

  const securityRoles: HfwSecurityRoleResponse[] = useMemo(() => {
    if (isSuccess) {
      form.clearFieldError('securityRoles');
      return data;
    }
    return [];
  }, [isSuccess]);

  const checksBox = useMemo(
    () => [
      {
        label: t('w.autoValid'),
        checked: form.values.autoValid,
        value: 'autoValid',
        description: t('leaveType.description.autoValid'),
      },
      {
        label: t('w.requireComment'),
        checked: form.values.requiredComment,
        value: 'requiredComment',
        description: t('leaveType.description.requiredComment'),
      },
      {
        label: t('w.requireAttachment'),
        checked: form.values.requiredAttachment,
        value: 'requiredAttachment',
        description: t('leaveType.description.requiredAttachment'),
      },
      {
        label: t('w.blockingPeriodAllowed'),
        checked: form.values.blockingPeriodAllowed,
        value: 'blockingPeriodAllowed',
        description: t('leaveType.description.blockingPeriodAllowed'),
      },
      {
        label: t('w.useAllDays'),
        checked: form.values.useAllDays,
        value: 'useAllDays',
        description: t('leaveType.description.useAllDays'),
      },
      {
        label: t('w.showLabel'),
        checked: form.values.showLabel,
        value: 'showLabel',
        description: t('leaveType.description.showLabel'),
      },
      {
        label: t('w.excludeEndMonth'),
        checked: form.values.excludeEndMonth,
        value: 'excludeEndMonth',
        description: t('leaveType.description.excludeEndMonth'),
      },
      {
        label: t('w.repeatable'),
        checked: form.values.repeatable,
        value: 'repeatable',
        description: t('leaveType.description.repeatable'),
      },
      {
        label: t('w.cancelOthersLeaves'),
        checked: form.values.cancelOthersLeaves,
        value: 'cancelOthersLeaves',
        description: t('leaveType.description.cancelOthersLeaves'),
      },
    ],
    [form.values]
  );

  function handleValidateFirsStepFormClick() {
    if (
      form.isValid('name') &&
      form.isValid('maxDays') &&
      form.isValid('legalDelay')
    ) {
      form.clearFieldError('name');
      form.clearFieldError('maxDays');
      form.clearFieldError('legalDelay');
      setActive(active + 1);
    } else {
      form.validate();
    }
  }

  function openLegalDelayInfoClick() {
    modals.open({
      title: (
        <Title size={'h3'} component="p">
          {t('w.legalDelay')}
        </Title>
      ),
      children: t('w.legalDelayHelperText'),
      size: 'xl',
    });
  }

  function getAssociateCountersSelectData() {
    if (!counterTypes?.length) return [];

    let _counterTypes = [...counterTypes];
    const firstSelectedId = form.values.associateCounter?.[0]?.counterTypeId;
    let firstSelected: CounterType | undefined = undefined;
    if (firstSelectedId) {
      firstSelected = counterTypes.find(
        (item) => item.counterType.id === firstSelectedId
      )?.counterType;
    }
    return _counterTypes
      .filter((item) => item.counterType.active)
      .map((item) => ({
        value: item.counterType.id,
        label: getTranslatedKey(t, item.counterType.key),
        disabled:
          firstSelected && item.counterType.rule !== firstSelected?.rule,
      }));
  }

  function handleAssociateCounterChange(counterTypeIds: string[]) {
    const _associateCounter: AssociateCounter[] = counterTypeIds.map(
      (item, index) => ({ order: index, counterTypeId: item })
    );

    form.setFieldValue('associateCounter', _associateCounter);
  }

  return (
    <Container fluid mt={'lg'}>
      <Stepper
        active={active}
        styles={{
          separator: {
            display: isMobile ? undefined : 'none',
            width: isMobile ? undefined : 20,
          },
          steps: {
            justifyContent: isMobile ? undefined : 'space-between',
          },
        }}
      >
        <Stepper.Step label={isDesktop ? t('w.general') : ''}>
          <SimpleGrid cols={{ base: 1, md: 3 }}>
            <TextInput
              required
              label={<LabelText text={t('w.name')} />}
              placeholder={t('w.name')}
              disabled={
                isEdit &&
                (form.values.name === 'Présence' ||
                  form.values.name === 'Arrêt Maladie')
              }
              {...form.getInputProps('name')}
            />
            <TextInput
              label={<LabelText text={'Description'} />}
              placeholder={'Description'}
              {...form.getInputProps('description')}
            />
            <NumberInput
              label={<LabelText text={t('w.maxDays')} />}
              placeholder={t('w.maxDays')}
              min={0}
              {...form.getInputProps('maxDays')}
            />
            <NumberInput
              label={
                <Group gap={'xs'}>
                  <LabelText text={t('w.legalDelay')} />
                  <ActionIcon
                    size={'xs'}
                    variant={'subtle'}
                    onClick={openLegalDelayInfoClick}
                  >
                    <IconInfoCircle />
                  </ActionIcon>
                </Group>
              }
              placeholder={t('w.legalDelay')}
              min={0}
              {...form.getInputProps('legalDelay')}
            />
            <MultiSelect
              label={
                <Group gap={'xs'}>
                  <LabelText text={t('w.associateCounters')} />
                  <MoreInfoButtonModal
                    title={t('w.associateCounters')}
                    message={t('counterType.associateCountersMessage')}
                  />
                </Group>
              }
              value={form.values.associateCounter?.map(
                (item) => item.counterTypeId
              )}
              onChange={handleAssociateCounterChange}
              clearable
              data={getAssociateCountersSelectData()}
              hidePickedOptions
              disabled={!counterTypes?.length}
            />
            {getModule(ModuleName.ExportQuadra)?.active && (
              <TextInput
                label={'Code Quadra'}
                placeholder={'Code Quadra'}
                {...form.getInputProps('codeQuadra')}
              />
            )}
            {getModule(ModuleName.ExportDIAPAIE)?.active && (
              <TextInput
                label={'Code DIAPAIE'}
                placeholder={'Code DIAPAIE'}
                {...form.getInputProps('codeDiapaie')}
              />
            )}
            {getModule(ModuleName.ExportSilae)?.active && (
              <TextInput
                label={'Code Silae'}
                placeholder={'Code Silae'}
                {...form.getInputProps('codeSilae')}
              />
            )}
            {getModule(ModuleName.ExportISAPAYE)?.active && (
              <TextInput
                label={'Code ISAPAYE'}
                placeholder={'Code ISAPAYE'}
                {...form.getInputProps('codeIsapaye')}
              />
            )}
            {getModule(ModuleName.SynchroOpenPaye)?.active && (
              <TextInput
                label={'Code Openpaye'}
                placeholder={'Code Openpaye'}
                {...form.getInputProps('codeOpenpaye')}
              />
            )}
            {getModule(ModuleName.ExportCegidExpert)?.active && (
              <TextInput
                label={'Code Cegid Expert'}
                placeholder={'Code Cegid Expert'}
                {...form.getInputProps('codeCegidExpert')}
              />
            )}
          </SimpleGrid>
        </Stepper.Step>
        <Stepper.Step label={isDesktop ? t('w.advanced') : ''}>
          <SimpleGrid
            cols={{ base: 1, sm: 2, md: 3, lg: 4 }}
            my={'md'}
            verticalSpacing={'xl'}
          >
            {checksBox.map((box, index) => (
              <CustomCheckBox
                key={`${box.value}-${index}`}
                box={box}
                {...form.getInputProps(box.value)}
              />
            ))}
          </SimpleGrid>
        </Stepper.Step>
        <Stepper.Step label={isDesktop ? t('w.view') : ''}>
          <Grid grow align={'flex-end'} my={'md'}>
            <Grid.Col span={{ base: 12, sm: 4 }}>
              <NumberInput
                label={<LabelText text={t('w.order')} />}
                placeholder={t('w.order')}
                {...form.getInputProps('order')}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 4 }}>
              <ColorInput
                label={<LabelText text={t('w.color')} />}
                disallowInput
                withEyeDropper={false}
                swatches={[
                  ...DEFAULT_THEME.colors.red,
                  ...DEFAULT_THEME.colors.green,
                  ...DEFAULT_THEME.colors.blue,
                ]}
                {...form.getInputProps('color')}
              />
            </Grid.Col>
            <Grid.Col span={{ base: 12, sm: 4 }}>
              <Button
                variant={'subtle'}
                w={'100%'}
                leftSection={getIcon({
                  color: form.values.color ? form.values.color : 'black',
                  name: form.values.icon ? form.values.icon : 'beach',
                })}
                onClick={handleOpenSelectLeaveTypeIcon}
              >
                Choisissez une icône
              </Button>
            </Grid.Col>
          </Grid>
        </Stepper.Step>
        <Stepper.Step label={isDesktop ? t('w.rightOfUse') : ''}>
          <Flex justify={'flex-end'} align={'center'} my={'md'}>
            <Switch
              label={
                <LabelText
                  text={t(`w.${form.values.status ? 'active' : 'inactive'}`)}
                />
              }
              checked={form.values.status}
              {...form.getInputProps('status')}
            />
          </Flex>
          {isLoading ? (
            <InputSkeleton />
          ) : (
            <MultiSelect
              label={<LabelText text={t('w.rightOfUse')} />}
              data={securityRoles.map((role) => {
                return {
                  value: role.id,
                  label: roleName(t, role.name),
                };
              })}
              disabled={!form.values.status}
              {...form.getInputProps('securityRoles')}
            />
          )}
        </Stepper.Step>
      </Stepper>
      <Group justify={'flex-end'} mt={'xl'}>
        {active > 0 && (
          <Button onClick={() => setActive(active - 1)}>{t('w.back')}</Button>
        )}
        {active < 3 ? (
          <Button
            onClick={() => {
              if (active === 0) {
                handleValidateFirsStepFormClick();
              }
              if (active === 1 || active === 2) {
                setActive(active + 1);
              }
            }}
          >
            {t('w.next')}
          </Button>
        ) : (
          <ButtonSave />
        )}
      </Group>
      <Drawer
        title={
          <Title size={'h3'} component="p">
            Liste d'icônes
          </Title>
        }
        position={'right'}
        opened={openedSelectLeaveTypeIcon}
        onClose={handleCloseSelectLeaveTypeIcon}
      >
        <Space h={'xl'} />
        <Space h={'xl'} />
        <Space h={'xl'} />
        <IconSelector
          selected={form.values.icon}
          onClick={(newIconName) => form.setFieldValue('icon', newIconName)}
        />
      </Drawer>
    </Container>
  );
}
