import { Group, Text } from '@mantine/core';
import React from 'react';

import CustomCompanyAvatar from '../../../components/CustomAvatar/CustomCompanyAvatar';
import useResponsive from '../../../hooks/useResponsive';

type Props = {
  companyId: string;
  companyName: string | undefined;
};

export default function CompanyInfoWrapper({ companyId, companyName }: Props) {
  const { isMobile } = useResponsive();

  return (
    <Group>
      {!isMobile && <CustomCompanyAvatar companyId={companyId} size={'md'} />}
      <Text>{companyName}</Text>
    </Group>
  );
}
