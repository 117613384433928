import { type FilterOption, type Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Group, Title, Tooltip } from '@mantine/core';
import {
  type DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { modals } from '@mantine/modals';
import {
  IconBrandDaysCounter,
  IconCalendarEvent,
  IconFileDollar,
  IconReceipt,
  IconReport,
} from '@tabler/icons-react';
import { type DataTableColumn } from 'mantine-datatable';
import React, { useState } from 'react';

import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useCompany from '../../../../hooks/useCompany';
import type {
  CompanyAccountantInfoViewClient,
  MonthStatus,
} from '../../../../types/api/response/accountant';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import TeamDailyWorks from '../../../activities/team-activities/components/TeamDailyWorks';
import ExpensesTable from '../../../end-of-month/expense-reports/ExpensesTable';
import HolidayCounters from '../../../end-of-month/holiday-counter/components/HolidayCounters';
import EndOfMonthPayslips from '../../../end-of-month/payslips/EndOfMonthPayslips';
import CompanyInfoWrapper from '../../components/CompanyInfoWrapper';
import AccountantCustomersEndOfMonthStatusBadge from './AccountantCustomersEndOfMonthStatusBadge';
import CustomerPlanning from './CustomerPlanning';

const componentName = 'AccountantCustomersEndOfMonthTable';

type Props = {
  selectedDate: DateValue | DatesRangeValue | Date[];
  rows: CompanyAccountantInfoViewClient[];
  isLoading: boolean;
};

export default function AccountantCustomersEndOfMonthTable({
  selectedDate,
  rows,
  isLoading,
}: Props) {
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const { t, lang } = useI18n();

  const columns: DataTableColumn<CompanyAccountantInfoViewClient>[] = [
    {
      accessor: 'customerCompany.name',
      title: t('w.companyName'),
      sortable: true,
      ellipsis: true,
      render: ({ customerCompany }) => (
        <CompanyInfoWrapper
          companyId={customerCompany.id}
          companyName={customerCompany.name}
        />
      ),
    },
    {
      accessor: 'status',
      title: t('w.status'),
      sortable: true,
      ellipsis: true,
      render: ({
        status,
        clientCompanyId,
        customerCompany,
        divisionAccountant,
        generalManagers,
      }) => {
        const managedDivisionsIds: string[] = [];
        const isCompanyAccountant = Boolean(
          generalManagers?.find((item) => item.id === user.id)
        );
        if (!isCompanyAccountant) {
          divisionAccountant?.forEach((item) => {
            const isDivisionAccountant = Boolean(
              item.accountantUser?.find((item) => item.id === user.id)
            );
            if (isDivisionAccountant) {
              managedDivisionsIds.push(item.id);
            }
          });
        }

        return (
          <AccountantCustomersEndOfMonthStatusBadge
            accountantCompanyId={companyId}
            managedDivisionsIds={
              isCompanyAccountant ? null : managedDivisionsIds
            }
            status={status as MonthStatus}
            clientCompanyId={clientCompanyId}
            customerCompany={customerCompany}
            selectedDate={selectedDate}
          />
        );
      },
    },
    {
      accessor: 'actions',
      title: t('w.actions'),
      textAlign: 'right',
      sortable: false,
      width: '0%',
      render: (accountants) => (
        <Group gap={4} justify="end" wrap={'nowrap'}>
          <Tooltip label={t('w.planning')}>
            <ActionIcon
              variant={'subtle'}
              size="sm"
              color="blue"
              onClick={() => openPlanning(accountants)}
            >
              <IconCalendarEvent />
            </ActionIcon>
          </Tooltip>
          <Tooltip label={t('w.holidayCounters')}>
            <ActionIcon
              variant={'subtle'}
              size="sm"
              color="blue"
              onClick={() => openHolidayCounters(accountants)}
            >
              <IconBrandDaysCounter />
            </ActionIcon>
          </Tooltip>
          <Tooltip label={t('w.payslips')}>
            <ActionIcon
              variant={'subtle'}
              size="sm"
              color="blue"
              onClick={() => openPayslips(accountants)}
            >
              <IconFileDollar />
            </ActionIcon>
          </Tooltip>
          <Tooltip label={t('w.expenseReports')}>
            <ActionIcon
              variant={'subtle'}
              size="sm"
              color="blue"
              onClick={() => openExpenseReports(accountants)}
            >
              <IconReceipt />
            </ActionIcon>
          </Tooltip>
          <Tooltip label={t('w.teamActivities')}>
            <ActionIcon
              variant={'subtle'}
              size="sm"
              color="blue"
              onClick={() => openActivities(accountants)}
            >
              <IconReport />
            </ActionIcon>
          </Tooltip>
        </Group>
      ),
    },
  ];

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'customerCompany.name',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  function openPlanning(accountants: CompanyAccountantInfoViewClient) {
    modals.open({
      transitionProps: { transition: 'slide-down' },
      modalId: `customer-planning-modal`,
      title: (
        <Title size={'h3'} component="p" mb={12}>
          {`${t('w.planning')} : ${accountants.customerCompany.name}`}
        </Title>
      ),
      fullScreen: true,
      children: <CustomerPlanning accountants={accountants} />,
    });
  }

  function openHolidayCounters(accountants: CompanyAccountantInfoViewClient) {
    modals.open({
      transitionProps: { transition: 'slide-down' },
      modalId: `customer-holiday-counters-modal`,
      title: (
        <Title size={'h3'} component="p">
          {`${t('w.holidayCounters')} : ${accountants.customerCompany.name}`}
        </Title>
      ),
      fullScreen: true,
      children: (
        <HolidayCounters
          withUnitFilter={false}
          companyId={accountants.clientCompanyId}
        />
      ),
    });
  }

  function openPayslips(accountants: CompanyAccountantInfoViewClient) {
    modals.open({
      transitionProps: { transition: 'slide-down' },
      modalId: `customer-payslips-modal`,
      title: (
        <Title size={'h3'} component="p">
          {accountants.customerCompany.name}
        </Title>
      ),
      fullScreen: true,
      children: (
        <EndOfMonthPayslips
          accountantCompanyId={companyId}
          customerCompanyId={accountants.clientCompanyId}
          customerSelectedDate={selectedDate}
        />
      ),
    });
  }

  function openActivities(accountants: CompanyAccountantInfoViewClient) {
    modals.open({
      transitionProps: { transition: 'slide-down' },
      modalId: `customer-activities-modal`,
      title: (
        <Title size={'h3'} component="p">
          {accountants.customerCompany.name}
        </Title>
      ),
      fullScreen: true,
      children: (
        <TeamDailyWorks
          withUnitFilter={false}
          companyId={accountants.clientCompanyId}
        />
      ),
    });
  }

  function openExpenseReports(accountants: CompanyAccountantInfoViewClient) {
    modals.open({
      transitionProps: { transition: 'slide-down' },
      modalId: `customer-expenses-modal`,
      title: (
        <Title size={'h3'} component="p">
          {accountants.customerCompany.name}
        </Title>
      ),
      fullScreen: true,
      children: <ExpensesTable company={accountants.customerCompany} />,
    });
  }

  return (
    <Table
      pinFirstColumn
      pinLastColumn
      idAccessor={'clientCompanyId'}
      rows={rows}
      columns={columns}
      options={options}
      lang={lang}
      fetching={isLoading}
      withTableBorder={false}
      width="0vw" // autoscale width
    />
  );
}
