import {
  ActionIcon,
  Flex,
  Group,
  ScrollArea,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import { useHotkeys } from '@mantine/hooks';
import { IconMoonStars, IconSun } from '@tabler/icons-react';
import React from 'react';

import Logo from '../../components/Logo';
import AccountantModeSwitcher from './components/AccountantModeSwitcher';
import LocaleSwitcher from './components/LocaleSwitcher';
import SearchButton from './components/SearchButton/SearchButton';
import UserAppNotifications from './components/UserAppNotifications/UserAppNotifications';
import WhatsNew from './components/WhatsNew/WhatsNew';

export default function Appbar() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();

  useHotkeys([['mod+J', () => toggleColorScheme()]]);

  return (
    <ScrollArea w="100vw">
      <Flex justify={'space-between'} align={'center'} wrap="nowrap" gap="xs">
        <Logo />
        <Group wrap="nowrap" gap="xs">
          <WhatsNew />
          <AccountantModeSwitcher />
          <SearchButton />
          <UserAppNotifications />
          <LocaleSwitcher />
          <ActionIcon
            onClick={() => toggleColorScheme()}
            variant="subtle"
            size={'lg'}
            color={'grey'}
          >
            {colorScheme === 'dark' ? (
              <IconSun color={theme.colors.yellow[5]} />
            ) : (
              <IconMoonStars />
            )}
          </ActionIcon>
        </Group>
      </Flex>
    </ScrollArea>
  );
}
