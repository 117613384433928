import { Stack } from '@mantine/core';
import type {
  DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';

import { useI18n } from '../../../../../contexts/I18nProvider';
import { PayslipMonthResponseFront } from '../../../../../types/types';
import ImportPayslipsTitle from '../ImportPayslipsTitle';
import EmployeeWithoutPayslipTable from './EmployeeWithoutPayslipTable';

type Props = {
  payslipsMonthInfo: PayslipMonthResponseFront | undefined;
  selectDate: DateValue | DatesRangeValue | Date[];
  companyId: string;
};

export default function EmployeeWithoutPayslip({
  payslipsMonthInfo,
  selectDate,
  companyId,
}: Props) {
  const { t } = useI18n();
  return (
    <Stack gap={'xl'}>
      <ImportPayslipsTitle title={t('w.listOfKnownEmployeesWithoutPayslips')} />
      <EmployeeWithoutPayslipTable
        payslipsMonthInfo={payslipsMonthInfo}
        selectDate={selectDate}
        companyId={companyId}
      />
    </Stack>
  );
}
