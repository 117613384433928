import {
  Box,
  Button,
  Center,
  Group,
  LoadingOverlay,
  NumberInput,
  Select,
  SimpleGrid,
  Stepper,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconAddressBook,
  IconCheck,
  IconCircleArrowLeft,
  IconCircleArrowRight,
  IconCopyCheck,
  IconDeviceFloppy,
  IconPhone,
  IconUser,
  IconUsers,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';

import ConfidentialOverlay from '../../../../components/ConfidentialOverlay';
import LabelText from '../../../../components/LabelText/LabelText';
import MoreInfoButtonModal from '../../../../components/MoreInfoButtonModal';
import PhoneField from '../../../../components/PhoneField/PhoneField';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useResponsive from '../../../../hooks/useResponsive';
import ProfileService from '../../../../services/ProfileService';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../../types/api/response/role';
import type {
  FamilyState,
  Gender,
  UserEmergencyContactType,
  UserPersonalFamily,
  UserPersonalInfo,
  UserPersonalSensitive,
  UserResponse,
} from '../../../../types/api/response/user';
import { hasPermission } from '../../../../utils/authorization';
import { familyState } from '../../../../utils/familyState';
import { gender } from '../../../../utils/gender';
import { isHiFiveWorkProject } from '../../../../utils/projects';
import EmergencyContactTable from '../../../user-profile/components/EmergencyContactTable';
import s from './AddEmployeeSteppers/AddEmployeeSteppers.module.sass';
import EditEmergencyContactForm from './EditEmergencyContactForm';

type Props = {
  onClose?: () => void;
  employee: UserResponse | null;
  active: number;
  setActive: (number: number) => void;
  refetch: () => void;
  setUserPersonalInfo: (data: UserPersonalInfo) => void;
  setUserFamilySituation: (data: UserPersonalFamily) => void;
  setUserEmergencyContact: (data: UserEmergencyContactType[]) => void;
  userPersonalInfo?: UserPersonalInfo | null;
  userFamilySituation?: UserPersonalFamily | null;
  userEmergencyContact?: UserEmergencyContactType[] | [];
  refetchUserEmergencyContact?: () => void;
  refetchUserPersonalInfo?: () => void;
  refetchUserFamilySituation?: () => void;
  validateEmployeeOnBoarding: (variables: {
    userId: string;
    employee: boolean;
    accountant: boolean;
  }) => void;
  finalValidateEmployeeOnBoarding: (variables: { userId: string }) => void;
  refetchOBInfos?: () => void;
  userSensitiveInfo?: UserPersonalSensitive | null;
};

interface FormValues {
  personalInfoId?: string | null;
  gender?: Gender | string;
  nationality: string;
  birthday?: Date | string | number | null | undefined;
  birthPlace?: string;
  birthDepartment?: string;
  phoneNumber?: string | undefined;
  personsToContact?:
    | {
        id?: string | null;
        lastname?: string;
        firstname?: string;
        comment?: string;
        email?: string;
        phone?: string | undefined;
        userId?: string;
      }[]
    | [];
  familySituationId?: string | null;
  familyState?: FamilyState | string;
  numberDependents?: number | string;
  numberDependentsChildren?: number | string;
  address: string | null;
  address1?: string | null;
  address2?: string | null;
  zipCode?: string | null;
  city?: string | null;
  birthCountry?: string | null;
  birthName?: string | null;
  commonName?: string | null;
}

export default function AddEmployeeInformationForm({
  onClose,
  employee,
  active,
  setActive,
  refetch,
  setUserPersonalInfo,
  setUserFamilySituation,
  userPersonalInfo,
  userFamilySituation,
  userEmergencyContact,
  refetchUserEmergencyContact,
  setUserEmergencyContact,
  refetchUserPersonalInfo,
  refetchUserFamilySituation,
  validateEmployeeOnBoarding,
  refetchOBInfos,
  userSensitiveInfo,
  finalValidateEmployeeOnBoarding,
}: Props) {
  const { t, lang } = useI18n();
  const { user, access_token } = useAuth();
  const [activeStepper, setActiveStepper] = useState(0);
  const { isDesktop } = useResponsive();
  const isOnboardingLoggedUserValidatedByUser =
    Boolean(employee?.onBoardingId) &&
    user.id === employee?.id &&
    employee?.onBoarding?.onBoardingEmployee?.validationDate !== null;
  const isNotMe = user.id !== employee?.id;
  const canReadPersonalInfo = hasPermission(
    {
      permission: BuiltInPermissions.UserPersonalInfoShow,
      scope: ScopeEnum.ALL,
    },
    user,
    employee?.companyId
  );
  const canEditPersonalInfo = hasPermission(
    {
      permission: BuiltInPermissions.UserPersonalInfoEdit,
      scope: ScopeEnum.ALL,
    },
    user,
    employee?.companyId
  );
  const canReadEmergencyContact = hasPermission(
    {
      permission: BuiltInPermissions.UserEmergencyContactShow,
      scope: ScopeEnum.ALL,
    },
    user,
    employee?.companyId
  );
  const canEditEmergencyContact = hasPermission(
    {
      permission: BuiltInPermissions.UserEmergencyContactEdit,
      scope: ScopeEnum.ALL,
    },
    user,
    employee?.companyId
  );

  const showPersonalInfoConfidential =
    !isOnboardingLoggedUserValidatedByUser &&
    isNotMe &&
    !canReadPersonalInfo &&
    !canEditPersonalInfo;

  const showEmergencyContactConfidential =
    !isOnboardingLoggedUserValidatedByUser &&
    isNotMe &&
    !canReadEmergencyContact &&
    !canEditEmergencyContact;

  const form = useForm<FormValues>({
    initialValues: {
      personalInfoId:
        userPersonalInfo !== null
          ? userPersonalInfo?.id
            ? userPersonalInfo.id
            : null
          : null,
      gender:
        userPersonalInfo !== null
          ? userPersonalInfo?.gender
            ? userPersonalInfo.gender
            : 'MALE'
          : 'MALE',
      nationality:
        userPersonalInfo !== null
          ? userPersonalInfo?.nationality
            ? userPersonalInfo.nationality
            : user.company?.countryCode
            ? user.company.countryCode
            : 'FR'
          : user.company?.countryCode
          ? user.company.countryCode
          : 'FR',
      birthday:
        userPersonalInfo !== null
          ? userPersonalInfo?.birthday
            ? dayjs(userPersonalInfo.birthday).toDate()
            : null
          : null,
      birthPlace:
        userPersonalInfo !== null
          ? userPersonalInfo?.birthPlace
            ? userPersonalInfo.birthPlace
            : ''
          : '',
      birthDepartment:
        userPersonalInfo !== null
          ? userPersonalInfo?.birthDepartment
            ? userPersonalInfo.birthDepartment
            : ''
          : '',
      phoneNumber:
        userPersonalInfo !== null
          ? userPersonalInfo?.phoneNumber
            ? userPersonalInfo.phoneNumber
            : ''
          : '',
      personsToContact:
        userEmergencyContact !== null &&
        userEmergencyContact &&
        userEmergencyContact?.length > 0
          ? userEmergencyContact?.map((item) => ({
              id: item.id,
              userId: employee?.id,
              lastname: item.lastname,
              firstname: item.firstname,
              comment: item.comment,
              email: item.email,
              phone: item.phone,
            }))
          : [],
      familySituationId:
        userFamilySituation !== null
          ? userFamilySituation?.id
            ? userFamilySituation.id
            : null
          : null,
      familyState:
        userFamilySituation !== null
          ? userFamilySituation?.familyState
            ? userFamilySituation.familyState
            : ''
          : '',
      numberDependents:
        userFamilySituation !== null
          ? userFamilySituation?.numberDependents
            ? userFamilySituation.numberDependents
            : ''
          : '',
      numberDependentsChildren:
        userFamilySituation !== null
          ? userFamilySituation?.numberDependentsChildren
            ? userFamilySituation.numberDependentsChildren
            : ''
          : '',
      address: null,
      address1:
        userPersonalInfo !== null
          ? userPersonalInfo?.address
            ? userPersonalInfo.address.address1
            : ''
          : '',
      address2:
        userPersonalInfo !== null
          ? userPersonalInfo?.address
            ? userPersonalInfo.address.address2
            : ''
          : '',
      zipCode:
        userPersonalInfo !== null
          ? userPersonalInfo?.address
            ? userPersonalInfo.address.zipCode
            : ''
          : '',
      city:
        userPersonalInfo !== null
          ? userPersonalInfo?.address
            ? userPersonalInfo.address.city
            : ''
          : '',
      birthCountry:
        userPersonalInfo !== null
          ? userPersonalInfo?.birthCountry
            ? userPersonalInfo.birthCountry
            : user.company?.countryCode
            ? user.company.countryCode
            : 'FR'
          : user.company?.countryCode
          ? user.company.countryCode
          : 'FR',
      birthName:
        userPersonalInfo != null
          ? userPersonalInfo.birthName
            ? userPersonalInfo.birthName
            : ''
          : '',
      commonName:
        userPersonalInfo != null
          ? userPersonalInfo.commonName
            ? userPersonalInfo.commonName
            : ''
          : '',
    },
  });

  const {
    mutate: updateUserFamilySituation,
    isLoading: isLoadingUserFamilySituation,
  } = useMutation({
    mutationFn: (variables: any) =>
      ProfileService.createOrUpdateUserFamilySituation(
        employee?.id,
        variables,
        access_token
      ),
    onSuccess: (data) => {
      setUserFamilySituation(data);
      if (refetchUserFamilySituation) {
        refetchUserFamilySituation();
      }
      if (refetchOBInfos) {
        refetchOBInfos();
      }
      form.setFieldValue('familySituationId', data.id);
      showNotification({
        id: 'udpate-added-employee-family-situation-success',
        title: t('w.success'),
        message: t('success.familySituationUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-family-situation-error',
        title: t('w.error'),
        message: t('error.updateUserFamilySituation', employee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const {
    mutate: updateUserPersonalInfo,
    isLoading: isLoadingUserPersonalInfo,
  } = useMutation({
    mutationFn: (variables: UserPersonalInfo) =>
      ProfileService.createOrUpdateUserPersonalInfo(
        employee?.id!,
        variables,
        access_token
      ),
    onSuccess: (data) => {
      setUserPersonalInfo(data);
      if (refetchUserPersonalInfo) {
        refetchUserPersonalInfo();
      }
      if (refetchOBInfos) {
        refetchOBInfos();
      }

      form.setFieldValue('personalInfoId', data.id);
      showNotification({
        id: 'udpate-added-employee-personal-info-success',
        title: t('w.success'),
        message: t('success.personalInfoUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-personal-info-error',
        title: t('w.error'),
        message: t('error.updateUserPersonalInfo', employee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleUpdateEmployeeAddedFormSubmit(values: FormValues) {
    if (!employee) return;

    updateUserPersonalInfo({
      id: values.personalInfoId,
      userId: employee.id,
      gender: values.gender === '' ? null : values.gender,
      birthday: values.birthday
        ? dayjs(values.birthday).format('YYYY-MM-DD')
        : undefined,
      nationality: values.nationality,
      phoneNumber: values.phoneNumber,
      birthCountry: values.birthCountry,
      birthPlace: values.birthPlace,
      birthDepartment: values.birthDepartment,
      address: {
        address1: values.address1,
        address2: values.address2,
        zipCode: values.zipCode,
        city: values.city,
      },
      birthName: values.birthName,
      commonName: values.commonName,
    });

    if (
      values.familyState !== '' ||
      values.numberDependentsChildren !== '' ||
      values.numberDependents !== ''
    ) {
      if (values.familySituationId !== null) {
        updateUserFamilySituation({
          id: values.familySituationId,
          familyState: values.familyState === '' ? null : values.familyState,
          numberDependents:
            values.numberDependents === '' ? null : values.numberDependents,
          numberDependentsChildren:
            values.numberDependentsChildren === ''
              ? null
              : values.numberDependentsChildren,
          userId: employee?.id,
        });
      } else {
        updateUserFamilySituation({
          familyState: values.familyState === '' ? null : values.familyState,
          numberDependents:
            values.numberDependents === '' ? null : values.numberDependents,
          numberDependentsChildren:
            values.numberDependentsChildren === ''
              ? null
              : values.numberDependentsChildren,
          userId: employee?.id,
        });
      }
    }
  }

  function openConfirmOnBoarding(values: FormValues) {
    modals.openConfirmModal({
      modalId: 'confirm-valid-on-boarding',
      title:
        user.id === employee?.id ? (
          <Title size={'h3'} component="p">
            {t('w.confirmOnBoardingInfos')}
          </Title>
        ) : employee?.onBoarding?.onBoardingEmployee?.validationDate !== null &&
          employee?.onBoarding?.onBoardingCompany !== null &&
          employee?.onBoarding?.onBoardingCompany?.validationDate &&
          employee?.onBoarding?.onBoardingCompany?.validationDate
            ?.dateOfUpdate !== null ? (
          <Title size={'h3'} component="p">
            {t('onboarding.finalize')}
          </Title>
        ) : (
          <Title size={'h3'} component="p">
            {t('w.confirmOnBoardingInfosCompany')}
          </Title>
        ),
      size: 'xl',
      labels: { cancel: t('w.cancel'), confirm: t('w.confirm') },
      onConfirm: () => validateOnBoarding(values),
    });
  }

  function validateOnBoarding(values: FormValues) {
    if (employee !== null) {
      if (user.id !== employee.id) {
        if (
          employee.onBoarding?.onBoardingEmployee?.validationDate !== null &&
          employee?.onBoarding?.onBoardingCompany !== null &&
          employee?.onBoarding?.onBoardingCompany?.validationDate &&
          employee?.onBoarding?.onBoardingCompany?.validationDate
            ?.dateOfUpdate !== null
        ) {
          finalValidateEmployeeOnBoarding({ userId: employee.id });
        } else {
          validateEmployeeOnBoarding({
            userId: employee.id,
            employee: false,
            accountant: false,
          });
        }
      } else {
        validateEmployeeOnBoarding({
          userId: employee.id,
          employee: true,
          accountant: false,
        });
      }
      handleUpdateEmployeeAddedFormSubmit(values);
    }
  }

  function renderComponent() {
    switch (activeStepper) {
      case 0:
        return (
          <Box pos="relative">
            {showPersonalInfoConfidential && <ConfidentialOverlay />}
            {!isDesktop && (
              <Title mb={'lg'} order={4}>
                {t('w.personalInformation')}
              </Title>
            )}
            <SimpleGrid cols={{ base: 1, sm: 2 }}>
              <PhoneField
                value={form.values.phoneNumber}
                onChange={(value) => form.setFieldValue('phoneNumber', value)}
                disabled={
                  isOnboardingLoggedUserValidatedByUser ||
                  (!canEditPersonalInfo && isNotMe)
                }
              />
              <Select
                label={<LabelText text={t('w.civilStatus')} />}
                data={gender(t)}
                {...form.getInputProps('gender')}
                disabled={
                  isOnboardingLoggedUserValidatedByUser ||
                  (!canEditPersonalInfo && isNotMe)
                }
              />
              <TextInput
                label={
                  <>
                    <LabelText text={t('w.birthName')} />
                    <MoreInfoButtonModal
                      title={t('w.birthName')}
                      message={t('personalInfo.birthNameInfo')}
                    />
                  </>
                }
                placeholder={t('w.noValue')}
                {...form.getInputProps('birthName')}
                disabled={
                  isOnboardingLoggedUserValidatedByUser ||
                  (!canEditPersonalInfo && isNotMe)
                }
              />
              <TextInput
                label={
                  <>
                    <LabelText text={t('w.commonName')} />
                    <MoreInfoButtonModal
                      title={t('w.commonName')}
                      message={t('personalInfo.commonNameInfo')}
                    />
                  </>
                }
                placeholder={t('w.noValue')}
                {...form.getInputProps('commonName')}
                disabled={
                  isOnboardingLoggedUserValidatedByUser ||
                  (!canEditPersonalInfo && isNotMe)
                }
              />
              <DateInput
                label={<LabelText text={t('w.dateOfBirth')} />}
                valueFormat={'D MMMM YYYY'}
                locale={lang}
                placeholder={t('w.noValue')}
                clearable
                {...form.getInputProps('birthday')}
                required={Boolean(employee?.onBoardingId)}
                disabled={
                  isOnboardingLoggedUserValidatedByUser ||
                  (!canEditPersonalInfo && isNotMe)
                }
              />
              <TextInput
                label={<LabelText text={t('w.placeOfBirth')} />}
                placeholder={t('w.noValue')}
                {...form.getInputProps('birthPlace')}
                required={Boolean(employee?.onBoardingId)}
                disabled={
                  isOnboardingLoggedUserValidatedByUser ||
                  (!canEditPersonalInfo && isNotMe)
                }
              />
              {isHiFiveWorkProject() && (
                <NumberInput
                  allowNegative={false}
                  label={<LabelText text={t('w.departmentOfBirth')} />}
                  placeholder={t('w.noValue')}
                  {...form.getInputProps('birthDepartment')}
                  required={Boolean(employee?.onBoardingId)}
                  disabled={
                    isOnboardingLoggedUserValidatedByUser ||
                    (!canEditPersonalInfo && isNotMe)
                  }
                />
              )}
              <Box>
                <LabelText text={t('w.countryOfBirth')} mb="xs" />
                <ReactFlagsSelect
                  selected={form.values.birthCountry!}
                  onSelect={(code) => form.setFieldValue('birthCountry', code)}
                  searchable
                  searchPlaceholder={t('w.search')}
                  disabled={
                    isOnboardingLoggedUserValidatedByUser ||
                    (!canEditPersonalInfo && isNotMe)
                  }
                  placeholder={t('w.selectACountry')}
                />
              </Box>
              <Box>
                <LabelText text={t('w.nationality')} mb="xs" />
                <ReactFlagsSelect
                  selected={form.values.nationality!}
                  onSelect={(code) => form.setFieldValue('nationality', code)}
                  searchable
                  searchPlaceholder={t('w.search')}
                  disabled={
                    isOnboardingLoggedUserValidatedByUser ||
                    (!canEditPersonalInfo && isNotMe)
                  }
                  placeholder={t('w.selectACountry')}
                />
              </Box>
            </SimpleGrid>
          </Box>
        );
      case 1:
        return (
          <Box pos="relative">
            {showPersonalInfoConfidential && <ConfidentialOverlay />}
            {!isDesktop && (
              <Title mb={'lg'} order={4}>
                {t('w.employeeAddress')}
              </Title>
            )}

            <SimpleGrid cols={{ base: 1, sm: 2 }}>
              <TextInput
                label={<LabelText text={t('w.postalAddress')} />}
                placeholder={t('w.noValue')}
                {...form.getInputProps('address1')}
                required={Boolean(employee?.onBoardingId)}
                disabled={
                  isOnboardingLoggedUserValidatedByUser ||
                  (!canEditPersonalInfo && isNotMe)
                }
              />
              <TextInput
                label={
                  <LabelText
                    text={`${t('w.additionalAddress')} (${t(
                      'w.optional'
                    ).toLowerCase()})`}
                  />
                }
                placeholder={t('w.noValue')}
                {...form.getInputProps('address2')}
                disabled={
                  isOnboardingLoggedUserValidatedByUser ||
                  (!canEditPersonalInfo && isNotMe)
                }
              />
              <TextInput
                required={Boolean(employee?.onBoardingId)}
                label={<LabelText text={t('w.zipCode')} />}
                placeholder={t('w.noValue')}
                {...form.getInputProps('zipCode')}
                disabled={
                  isOnboardingLoggedUserValidatedByUser ||
                  (!canEditPersonalInfo && isNotMe)
                }
              />
              <TextInput
                required={Boolean(employee?.onBoardingId)}
                label={<LabelText text={t('w.city')} />}
                placeholder={t('w.noValue')}
                {...form.getInputProps('city')}
                disabled={
                  isOnboardingLoggedUserValidatedByUser ||
                  (!canEditPersonalInfo && isNotMe)
                }
              />
            </SimpleGrid>
          </Box>
        );
      case 2:
        return (
          <Box pos="relative">
            {showPersonalInfoConfidential && <ConfidentialOverlay />}
            {!isDesktop && (
              <Title mb={'lg'} order={4}>
                {t('w.familySituation')}
              </Title>
            )}

            <SimpleGrid cols={{ base: 1, sm: 2 }}>
              <Select
                placeholder={t('w.noValue')}
                data={familyState(t)}
                label={<LabelText text={t('w.familySituation')} />}
                {...form.getInputProps('familyState')}
                disabled={
                  isOnboardingLoggedUserValidatedByUser ||
                  (!canEditPersonalInfo && isNotMe)
                }
              />
              <NumberInput
                min={0}
                label={<LabelText text={t('w.numberOfDependentChildren')} />}
                {...form.getInputProps('numberDependentsChildren')}
                disabled={
                  isOnboardingLoggedUserValidatedByUser ||
                  (!canEditPersonalInfo && isNotMe)
                }
              />
              <NumberInput
                min={0}
                description={t('w.neitherSpouseNorDependentChildren')}
                label={<LabelText text={t('w.numberOfDependants')} />}
                {...form.getInputProps('numberDependents')}
                disabled={
                  isOnboardingLoggedUserValidatedByUser ||
                  (!canEditPersonalInfo && isNotMe)
                }
              />
            </SimpleGrid>
          </Box>
        );
      case 3:
        return (
          <Box pos="relative">
            {showEmergencyContactConfidential && <ConfidentialOverlay />}
            {!isDesktop && (
              <Title mb={'lg'} order={4}>
                {t('w.personToContact')}
              </Title>
            )}
            <EditEmergencyContactForm
              employee={employee}
              refetchUserEmergencyContact={refetchUserEmergencyContact}
              parentForm={form}
              setUserEmergencyContact={setUserEmergencyContact}
            />
            {form.values?.personsToContact?.length === 0 ? (
              <Center>
                <Text fw={'bold'} c={'dimmed'}>
                  {t('w.noOneToContact')}
                </Text>
              </Center>
            ) : (
              <EmergencyContactTable
                userEmergencyContact={userEmergencyContact}
                refetchUserEmergencyContact={refetchUserEmergencyContact}
                userProfile={employee}
              />
            )}
          </Box>
        );
      default:
        return null;
    }
  }

  function isDisabledConfirmOnBoardingButton() {
    const isRequiredInfoMissing =
      userSensitiveInfo?.identityCardNumber === null ||
      userSensitiveInfo?.ssNumber === null ||
      form.values.address1 === null ||
      form.values.address1 === '' ||
      form.values.zipCode === '' ||
      form.values.zipCode === null ||
      form.values.city === '' ||
      form.values.city === null ||
      form.values.nationality === null ||
      form.values.nationality === '' ||
      form.values.birthday === null ||
      form.values.birthday === '' ||
      form.values.birthPlace === '' ||
      form.values.birthDepartment === '';

    const isMyProfileAndAlreadyValidated =
      employee?.id === user.id &&
      employee?.onBoarding?.onBoardingEmployee?.validationDate !== null;

    const isNotMyProfileAndNotValidatedYet =
      employee?.id !== user.id &&
      employee?.onBoarding?.onBoardingEmployee?.validationDate === null;

    return (
      isRequiredInfoMissing ||
      isMyProfileAndAlreadyValidated ||
      isNotMyProfileAndNotValidatedYet
    );
  }

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleUpdateEmployeeAddedFormSubmit(values)
      )}
    >
      <LoadingOverlay
        visible={isLoadingUserFamilySituation && isLoadingUserPersonalInfo}
      />
      {!isDesktop && <Title order={3}>{t('w.personalInformation')}</Title>}
      <Group grow preventGrowOverflow={false} wrap="nowrap" align="start">
        <Stepper
          size="sm"
          className={isDesktop ? s.stepper : undefined}
          active={activeStepper}
          orientation={'vertical'}
          onStepClick={setActiveStepper}
        >
          <Stepper.Step
            icon={<IconUser />}
            label={isDesktop ? t('w.informations') : ''}
            description={isDesktop ? t('w.individuals') : ''}
          />
          <Stepper.Step
            icon={<IconAddressBook />}
            label={isDesktop ? t('w.address') : ''}
            description={isDesktop ? t('w.employee') : ''}
          />
          <Stepper.Step
            icon={<IconUsers />}
            label={isDesktop ? t('w.situation') : ''}
            description={isDesktop ? t('w.family') : ''}
          />
          <Stepper.Step
            icon={<IconPhone />}
            label={
              isDesktop
                ? `${t('w.personToContact').split(' ')[0]} ${
                    t('w.personToContact').split(' ')[1]
                  } ${t('w.personToContact').split(' ')[2]}`
                : ''
            }
            description={
              isDesktop
                ? `${t('w.personToContact').split(' ')[3]} ${
                    t('w.personToContact').split(' ')[4]
                  } ${t('w.personToContact').split(' ')[5]}`
                : ''
            }
          />
        </Stepper>
        {renderComponent()}
      </Group>
      <Group justify={'flex-end'} mt={'lg'} gap={'30px'}>
        {employee && Boolean(employee?.onBoardingId) && (
          <Button
            onClick={() => openConfirmOnBoarding(form.values)}
            color={'#0A9182'}
            leftSection={<IconCopyCheck />}
            disabled={isDisabledConfirmOnBoardingButton()}
          >
            {employee.onBoarding?.onBoardingEmployee?.validationDate !== null &&
            employee?.onBoarding?.onBoardingCompany !== null &&
            employee?.onBoarding?.onBoardingCompany?.validationDate &&
            employee?.onBoarding?.onBoardingCompany?.validationDate
              ?.dateOfUpdate !== null
              ? t('w.finalizeOnboarding')
              : t('w.validateInfos')}
          </Button>
        )}
        <Button
          disabled={!Boolean(employee) || isOnboardingLoggedUserValidatedByUser}
          leftSection={<IconDeviceFloppy />}
          type={'submit'}
        >
          {t('w.save')}
        </Button>
        <Button
          onClick={() => setActive(active - 1)}
          leftSection={<IconCircleArrowLeft />}
          variant={'outline'}
        >
          {t('w.back')}
        </Button>
        <Button
          onClick={() => setActive(active + 1)}
          leftSection={<IconCircleArrowRight />}
          variant={'outline'}
          type={'submit'}
        >
          {t('w.next')}
        </Button>
        {onClose && (
          <Button
            onClick={onClose}
            variant={'outline'}
            disabled={
              Boolean(employee?.onBoardingId) && employee?.id === user.id
            }
          >
            {t('w.close')}
          </Button>
        )}
      </Group>
    </form>
  );
}
