import {
  Button,
  Group,
  LoadingOverlay,
  Select,
  SimpleGrid,
  Switch,
  TextInput,
  Title,
} from '@mantine/core';
import { FileWithPath } from '@mantine/dropzone';
import { isEmail, useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconCircleArrowRight,
  IconCopyCheck,
  IconDeviceFloppy,
  IconHelpHexagon,
  IconMail,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useMemo } from 'react';

import CustomUserAvatar from '../../../../components/CustomAvatar/CustomUserAvatar';
import CustomDropzone from '../../../../components/CustomDropzone/CustomDropzone';
import CustomTooltip from '../../../../components/CustomTooltip';
import LabelText from '../../../../components/LabelText/LabelText';
import MoreInfoButtonModal from '../../../../components/MoreInfoButtonModal';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useCompany from '../../../../hooks/useCompany';
import useFetchManagers from '../../../../hooks/useFetchManagers';
import useResponsive from '../../../../hooks/useResponsive';
import CompanyService from '../../../../services/CompanyService';
import ProfileService from '../../../../services/ProfileService';
import {
  OnBoardingStatus,
  UserPayload,
} from '../../../../types/api/payload/user';
import type { DivisionResponse } from '../../../../types/api/response/division';
import {
  BuiltInPermissions,
  HfwSecurityRoleResponse,
  ScopeEnum,
} from '../../../../types/api/response/role';
import {
  type UserPersonalInfo,
  type UserPersonalSensitive,
  type UserResponse,
} from '../../../../types/api/response/user';
import { Language } from '../../../../types/i18n';
import { hasPermission } from '../../../../utils/authorization';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import { roleName } from '../../../../utils/roleName';
import { NO_WORK_UNIT } from '../../../../variables/GlobalVariables';
import AddEmployeeGroupRadio from './AddEmployeeGroupRadio';
import s from './AddEmployeeSteppers/AddEmployeeSteppers.module.sass';

type Props = {
  divisions: DivisionResponse[];
  roles: HfwSecurityRoleResponse[];
  onClose?: () => void;
  creationTypeValue: string;
  setCreationTypeValue: (value: string) => void;
  active: number;
  setActive: (number: number) => void;
  refetch: () => void;
  setAddedEmployee: (data: UserResponse) => void;
  employee: UserResponse | null;
  validateEmployeeOnBoarding: (variables: {
    userId: string;
    employee: boolean;
    accountant: boolean;
  }) => void;
  finalValidateEmployeeOnBoarding: (variables: { userId: string }) => void;
  userPersonalInfo: UserPersonalInfo | null;
  userSensitiveInfo?: UserPersonalSensitive | null;
};

interface FormValues {
  firstname: string;
  lastname: string;
  mail: string;
  lang: Language;
  securityRoleId: string;
  divisionId: string;
  valid: boolean;
  avatar: FileWithPath[];
  filename?: string | null;
  firstManager: string;
  secondManager: string;
}

export default function AddEmployeeProfileForm({
  roles,
  divisions,
  onClose,
  creationTypeValue,
  setCreationTypeValue,
  active,
  setActive,
  refetch,
  setAddedEmployee,
  employee,
  validateEmployeeOnBoarding,
  finalValidateEmployeeOnBoarding,
  userPersonalInfo,
  userSensitiveInfo,
}: Props) {
  const { t } = useI18n();
  const { user, access_token } = useAuth();
  const { id: companyId, company } = useCompany(user);
  const { managers } = useFetchManagers();
  const { isDesktop } = useResponsive();
  const isOnboardingLoggedUser =
    Boolean(employee?.onBoardingId) && user.id === employee?.id;
  const dontHavePermission = !hasPermission(
    {
      permission: BuiltInPermissions.ConfigureEmployees,
      scope: ScopeEnum.ALL,
    },
    user,
    employee?.companyId
  );
  const isNotMe = user.id !== employee?.id;
  const isEditForm = !!employee;

  const form = useForm<FormValues>({
    initialValues: {
      firstname: employee?.firstname ? employee.firstname : '',
      lastname: employee?.lastname ? employee.lastname : '',
      mail: employee?.mail ? employee.mail : '',
      lang: employee?.lang ? employee.lang : Language.fr,
      securityRoleId: employee?.securityRoleId ? employee.securityRoleId : '',
      divisionId: employee?.divisionId ? employee.divisionId : '',
      valid: employee ? (employee?.valid ? employee.valid : false) : true,
      avatar: [],
      filename: null,
      firstManager: employee?.firstManager ? employee.firstManager : '',
      secondManager: employee?.secondManager ? employee.secondManager : '',
    },
    validate: {
      mail: isEmail(t('invalid.email')),
    },
  });

  const {
    mutate: createNewUserOnboarding,
    isLoading: isCreateNewUserOnboardingLoading,
  } = useMutation({
    mutationFn: (variables: {
      firstname: string;
      lastname: string;
      mail: string;
      lang: string;
      securityRoleId: string;
      divisionId: string;
      valid: boolean;
      firstManager: string;
      secondManager: string;
    }) =>
      CompanyService.createUser(
        companyId,
        creationTypeValue !== 'create-employee',
        variables,
        access_token
      ),
    onSuccess: (data) => {
      if (creationTypeValue === 'onBoarding') {
        onClose && onClose();
        refetch();
        showNotification({
          id: 'add-employee-profile-success',
          title: t('w.success'),
          message: t('user.invitationSentTo', data.firstname),
          color: 'green',
          icon: <IconCheck />,
        });
      } else {
        showNotification({
          id: 'add-employee-profile-success',
          title: t('w.success'),
          message: t('user.hasBeenAdded', data.firstname),
          color: 'green',
          icon: <IconCheck />,
        });
        refetch();
        setActive(active + 1);
        setAddedEmployee(data);
      }
    },
    onError: (error) =>
      showNotification({
        id: 'add-employee-profile-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const {
    mutate: updateEmployeeAdded,
    isLoading: isLoadingUpdateEmmployeeAdded,
  } = useMutation({
    mutationFn: (variables: UserPayload) =>
      ProfileService.updateUserProfile(
        employee?.id,
        variables,
        creationTypeValue !== 'onBoarding' ? null : OnBoardingStatus.ONBOARDING
      ),
    onSuccess: (data) => {
      showNotification({
        id: 'udpate-added-employee-information-success',
        title: t('w.success'),
        message: t('success.profileUpdated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
      setAddedEmployee(data);
      onClose && onClose();
    },
    onError: () =>
      showNotification({
        id: 'update-employee-added-error',
        title: t('w.error'),
        message: t('error.updateUserProfile', employee?.firstname),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const langsSelect = useMemo(
    () => [
      { value: 'fr', label: t('w.french') },
      { value: 'en', label: t('w.english') },
    ],
    []
  );

  const divisionsSelect = useMemo(() => {
    if (!divisions) {
      return [];
    }
    return divisions.map((division) => {
      return {
        value: division.id,
        label:
          division.name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : division.name,
      };
    });
  }, [divisions]);

  const rolesSelect = useMemo(() => {
    if (!roles) {
      return [];
    }
    return roles.map((role) => {
      return {
        value: role.id,
        label: roleName(t, role.name),
      };
    });
  }, [roles]);

  useEffect(() => {
    if (!employee) {
      if (creationTypeValue === 'create-employee') {
        form.setFieldValue('valid', true);
      } else {
        form.setFieldValue('valid', false);
      }
    }
  }, [creationTypeValue]);

  function handleAddEmployeeProfileFormSubmit(
    values: ReturnType<(values: FormValues) => FormValues>
  ) {
    if (Boolean(employee)) {
      setActive(active + 1);
    } else {
      createNewUserOnboarding({
        firstname: values.firstname,
        lastname: values.lastname,
        mail: values.mail,
        lang: values.lang,
        securityRoleId: values.securityRoleId,
        divisionId: values.divisionId,
        valid: values.valid,
        firstManager: values.firstManager,
        secondManager: values.secondManager,
      });
    }
  }

  function handleCreateOrUpdateEmployee(values: FormValues) {
    if (!Boolean(employee)) {
      createNewUserOnboarding({
        firstname: values.firstname,
        lastname: values.lastname,
        mail: values.mail,
        lang: values.lang,
        securityRoleId: values.securityRoleId,
        divisionId: values.divisionId,
        valid: values.valid,
        firstManager: values.firstManager,
        secondManager: values.secondManager,
      });
    } else {
      let data;
      if (employee?.mail !== values.mail) {
        data = {
          firstname: values.firstname,
          lastname: values.lastname,
          mail: values.mail,
          lang: values.lang,
          securityRoleId: values.securityRoleId,
          divisionId: values.divisionId,
          valid: values.valid,
          firstManager: values.firstManager,
          secondManager: values.secondManager,
          avatar: form.values.avatar?.length > 0 ? form.values.avatar[0] : null,
          filename:
            form.values.avatar.length > 0 ? form.values.avatar[0].name : null,
        };
      } else {
        data = {
          firstname: values.firstname,
          lastname: values.lastname,
          lang: values.lang,
          securityRoleId: values.securityRoleId,
          divisionId: values.divisionId,
          valid: values.valid,
          firstManager: values.firstManager,
          secondManager: values.secondManager,
          avatar: form.values.avatar?.length > 0 ? form.values.avatar[0] : null,
          filename:
            form.values.avatar.length > 0 ? form.values.avatar[0].name : null,
        };
      }
      updateEmployeeAdded(data);
    }
  }

  function openConfirmOnBoarding(values: FormValues) {
    modals.openConfirmModal({
      modalId: 'confirm-valid-on-boarding',
      title:
        user.id === employee?.id ? (
          <Title size={'h3'} component="p">
            {t('w.confirmOnBoardingInfos')}
          </Title>
        ) : employee?.onBoarding?.onBoardingEmployee?.validationDate !== null &&
          employee?.onBoarding?.onBoardingCompany !== null &&
          employee?.onBoarding?.onBoardingCompany?.validationDate &&
          employee?.onBoarding?.onBoardingCompany?.validationDate
            ?.dateOfUpdate !== null ? (
          <Title size={'h3'} component="p">
            {t('onboarding.finalize')}
          </Title>
        ) : (
          <Title size={'h3'} component="p">
            {t('w.confirmOnBoardingInfosCompany')}
          </Title>
        ),
      size: 'xl',
      labels: { cancel: t('w.cancel'), confirm: t('w.confirm') },
      onConfirm: () => validateOnBoarding(values),
    });
  }

  function validateOnBoarding(values: FormValues) {
    if (employee !== null) {
      if (user.id !== employee.id) {
        if (
          employee.onBoarding?.onBoardingEmployee?.validationDate !== null &&
          employee?.onBoarding?.onBoardingCompany !== null &&
          employee?.onBoarding?.onBoardingCompany?.validationDate &&
          employee?.onBoarding?.onBoardingCompany?.validationDate
            ?.dateOfUpdate !== null
        ) {
          finalValidateEmployeeOnBoarding({ userId: employee.id });
        } else {
          validateEmployeeOnBoarding({
            employee: false,
            accountant: false,
            userId: employee.id,
          });
        }
      } else {
        validateEmployeeOnBoarding({
          employee: true,
          accountant: false,
          userId: employee.id,
        });
      }
      handleCreateOrUpdateEmployee(values);
    }
  }

  function isDisabledConfirmOnBoardingButton() {
    const isRequiredInfoMissing =
      userSensitiveInfo?.identityCardNumber === null ||
      userSensitiveInfo?.identityCardNumber === '' ||
      userSensitiveInfo?.ssNumber === null ||
      userSensitiveInfo?.ssNumber === '' ||
      userPersonalInfo?.address === null ||
      userPersonalInfo?.birthPlace === null ||
      userPersonalInfo?.birthDepartment === null ||
      userPersonalInfo?.birthday === null;

    const isMyProfileAndAlreadyValidated =
      employee?.id === user.id &&
      employee?.onBoarding?.onBoardingEmployee?.validationDate !== null;

    const isNotMyProfileAndNotValidatedYet =
      employee?.id !== user.id &&
      employee?.onBoarding?.onBoardingEmployee?.validationDate === null;

    return (
      isRequiredInfoMissing ||
      isMyProfileAndAlreadyValidated ||
      isNotMyProfileAndNotValidatedYet
    );
  }

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleAddEmployeeProfileFormSubmit(values)
      )}
    >
      <LoadingOverlay
        visible={
          isCreateNewUserOnboardingLoading || isLoadingUpdateEmmployeeAdded
        }
      />
      {!isDesktop && <Title order={3}>{t('w.profile')}</Title>}

      <Switch
        label={<LabelText text={t('w.active')} />}
        labelPosition={'left'}
        checked={form.values.valid}
        {...form.getInputProps('valid')}
        disabled={(isOnboardingLoggedUser || dontHavePermission) && isEditForm}
        mb="md"
      />
      <SimpleGrid cols={{ base: 1, sm: 2 }}>
        {employee && (
          <>
            <CustomDropzone
              disabled={isNotMe && dontHavePermission && isEditForm}
              onDrop={(files) => form.setFieldValue('avatar', files)}
              files={form.values.avatar}
              mimeType={'img'}
              resetFile={() => form.setFieldValue('avatar', [])}
            >
              <CustomUserAvatar userId={employee.id} size={80} />
            </CustomDropzone>
          </>
        )}
        <TextInput
          required
          label={<LabelText text={t('w.emailAddress')} />}
          leftSection={
            <CustomTooltip
              label={t('w.temporaryEmailThatCanBeChangedLater')}
              position={'bottom'}
            >
              <IconHelpHexagon className={s.icon} />
            </CustomTooltip>
          }
          rightSection={<IconMail className={s.icon} />}
          placeholder={t('w.noValue')}
          {...form.getInputProps('mail')}
          disabled={isNotMe && dontHavePermission && isEditForm}
        />
        <TextInput
          required
          label={
            <>
              <LabelText text={t('w.name')} />
              <MoreInfoButtonModal
                title={t('w.familyName')}
                message={t('personalInfo.familyNameInfo')}
              />
            </>
          }
          placeholder={t('w.noValue')}
          {...form.getInputProps('lastname')}
          disabled={isNotMe && dontHavePermission && isEditForm}
        />
        <TextInput
          required
          label={<LabelText text={t('w.firstname')} />}
          placeholder={t('w.noValue')}
          {...form.getInputProps('firstname')}
          disabled={isNotMe && dontHavePermission && isEditForm}
        />
        <Select
          required
          allowDeselect={false}
          data={rolesSelect}
          label={<LabelText text={t('w.role')} />}
          placeholder={t('w.selectRole')}
          {...form.getInputProps('securityRoleId')}
          disabled={
            (isOnboardingLoggedUser || dontHavePermission) && isEditForm
          }
        />
        <Select
          required
          allowDeselect={false}
          data={divisionsSelect}
          label={
            <LabelText
              text={
                user.company?.divisionLabel
                  ? user.company.divisionLabel
                  : t('w.unit')
              }
            />
          }
          placeholder={t('w.select')}
          {...form.getInputProps('divisionId')}
          disabled={
            (isOnboardingLoggedUser || dontHavePermission) && isEditForm
          }
        />
        <Select
          required
          allowDeselect={false}
          data={langsSelect}
          label={<LabelText text={t('w.lang')} />}
          placeholder={t('w.select')}
          {...form.getInputProps('lang')}
          disabled={isNotMe && dontHavePermission && isEditForm}
        />
        <Select
          allowDeselect={false}
          data={managers}
          label={<LabelText text={t('w.n1Validator')} />}
          placeholder={t('w.select')}
          {...form.getInputProps('firstManager')}
          disabled={
            (isOnboardingLoggedUser || dontHavePermission) && isEditForm
          }
        />
        {company?.useN2 && (
          <Select
            allowDeselect={false}
            data={managers}
            label={<LabelText text={t('w.n2Validator')} />}
            placeholder={t('w.select')}
            {...form.getInputProps('secondManager')}
            disabled={
              (isOnboardingLoggedUser || dontHavePermission) && isEditForm
            }
          />
        )}
      </SimpleGrid>
      {employee === null && (
        <AddEmployeeGroupRadio
          value={creationTypeValue}
          onChange={setCreationTypeValue}
          radioItems={[
            {
              value: 'create-employee',
              label: t('w.fillInTheInformationByCompany'),
            },
            {
              value: 'onBoarding',
              label: t('w.fillInTheInformationByEmployee'),
            },
          ]}
          name={'creationType'}
          label={t('w.methodForAddingAnEmployee')}
          description={t('w.directlyInTheListOfEmployeesOrViaOnBoarding')}
        />
      )}
      <Group
        styles={{
          root: {
            position: 'sticky',
            bottom: 0,
            backgroundColor: 'var(--mantine-color-body)',
          },
        }}
        mt={'51px'}
        gap={'30px'}
        justify={'flex-end'}
      >
        {employee && Boolean(employee?.onBoardingId) && (
          <Button
            onClick={() => openConfirmOnBoarding(form.values)}
            color={'#0A9182'}
            leftSection={<IconCopyCheck />}
            disabled={isDisabledConfirmOnBoardingButton()}
          >
            {employee.onBoarding?.onBoardingEmployee?.validationDate !== null &&
            employee?.onBoarding?.onBoardingCompany !== null &&
            employee?.onBoarding?.onBoardingCompany?.validationDate &&
            employee?.onBoarding?.onBoardingCompany?.validationDate
              ?.dateOfUpdate !== null
              ? t('w.finalizeOnboarding')
              : t('w.validateInfos')}
          </Button>
        )}
        <Button
          disabled={
            !form.isValid() ||
            (Boolean(employee?.onBoardingId) &&
              employee?.id === user.id &&
              employee?.onBoarding?.onBoardingEmployee?.validationDate !== null)
          }
          leftSection={<IconDeviceFloppy />}
          onClick={() => handleCreateOrUpdateEmployee(form.values)}
        >
          {t('w.save')}
        </Button>
        {employee === null && (
          <Button
            leftSection={<IconCircleArrowRight />}
            disabled={!form.isValid()}
            type={'submit'}
          >
            {creationTypeValue === 'create-employee'
              ? t('w.next')
              : t('w.sendInvitation')}
          </Button>
        )}

        {!Boolean(employee?.onBoardingId) ||
          (Boolean(employee?.onBoardingId) && user.id !== employee?.id && (
            <Button variant={'subtle'} onClick={onClose}>
              {t('w.close')}
            </Button>
          ))}
      </Group>
    </form>
  );
}
