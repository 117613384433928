import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Group, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconEdit, IconTrash, IconX } from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import YesNoBadge from '../../../components/badges/YesNoBadge';
import CustomTooltip from '../../../components/CustomTooltip';
import Page from '../../../components/Page/Page';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import useCompany from '../../../hooks/useCompany';
import CounterTypeService from '../../../services/CounterTypeService';
import type {
  CounterType,
  CounterTypeResponse,
} from '../../../types/api/response/counterType';
import { getTranslatedKey } from '../../../utils/counterTypesFormatter';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../utils/optionsPreferences';
import CreateEditForm from './components/CreateEditForm';

const componentName = 'CounterTypes';

export default function CounterTypes() {
  const { user } = useAuth();
  const { t, lang } = useI18n();
  const { id: companyId } = useCompany(user);
  const queryClient = useQueryClient();

  const { data } = useQuery({
    enabled: !!companyId,
    queryKey: ['CounterTypeService.list', companyId],
    queryFn: () => CounterTypeService.list(companyId),
  });

  const { mutate: deleteCounterType } = useMutation({
    mutationFn: (counterKeyToDelete: string) =>
      CounterTypeService.remove(companyId, counterKeyToDelete),
    onSuccess: () => {
      showNotification({
        id: `delete-counter-type-successful`,
        title: t('w.success'),
        message: t('w.deleted'),
        color: 'green',
        icon: <IconCheck />,
      });
      queryClient.invalidateQueries({
        queryKey: ['CounterTypeService.list', companyId],
      });
    },
    onError: (error) =>
      showNotification({
        id: `delete-counter-type-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [],
    }),
    updateFilter,
  });

  const columns: DataTableColumn<CounterTypeResponse>[] = useMemo(
    () => [
      {
        accessor: 'key',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
        render: ({ counterType }) => getTranslatedKey(t, counterType.key),
      },
      {
        accessor: 'active',
        title: t('w.active'),
        sortable: true,
        ellipsis: true,
        render: ({ counterType }) => <YesNoBadge value={counterType.active} />,
      },
      {
        accessor: 'rule',
        title: t('w.type'),
        sortable: true,
        ellipsis: true,
        render: ({ counterType }) => t(`counterType.rule.${counterType.rule}`),
      },
      {
        accessor: 'extractToPayslip',
        title: t('w.extractToPayslip'),
        sortable: true,
        ellipsis: true,
        render: ({ counterType }) => (
          <YesNoBadge value={counterType.extractToPayslip} />
        ),
      },
      {
        accessor: 'useHalfDay',
        title: t('w.authorizeToUseHalfDay'),
        sortable: true,
        ellipsis: true,
        render: ({ counterType }) => (
          <YesNoBadge value={counterType.useHalfDay} />
        ),
      },
      {
        accessor: 'returnOnRestDay',
        title: t('w.backOnRestDay'),
        sortable: true,
        ellipsis: true,
        render: ({ counterType }) => (
          <YesNoBadge value={counterType.returnOnRestDay} />
        ),
      },
      {
        accessor: 'update',
        title: t('counterType.update'),
        sortable: true,
        ellipsis: true,
        render: ({ counterType }) =>
          counterType.update ? counterType.update.replace('-', '/') : null,
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: '0%',
        render: ({ counterType }) => (
          <Group gap={4} justify="end" wrap={'nowrap'}>
            <CustomTooltip label={t('w.edit')}>
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="blue"
                onClick={() => openEditModal(counterType)}
              >
                <IconEdit size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip
              label={
                counterType.key === 'acquisN' || counterType.key === 'acquisN1'
                  ? t('w.notDeletable')
                  : t('w.delete')
              }
            >
              <ActionIcon
                variant={'subtle'}
                size="sm"
                color="red"
                disabled={
                  counterType.key === 'acquisN' ||
                  counterType.key === 'acquisN1'
                }
                onClick={() => openConfirmDelete(counterType.key)}
              >
                <IconTrash size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    []
  );

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  function openEditModal(counterType: CounterType) {
    modals.open({
      modalId: `edit-counter-type-${counterType.id}-modal`,
      title: (
        <Title size={'h3'} component="p">
          {t('w.edit')}
        </Title>
      ),
      size: 'xl',
      children: (
        <CreateEditForm companyId={companyId} counterType={counterType} />
      ),
    });
  }

  function openConfirmDelete(key: string) {
    modals.openConfirmModal({
      id: `counter-type-${key}-confirm-delete-modal`,
      title: t('counterType.removeModalTitle', getTranslatedKey(t, key)),
      labels: { cancel: t('w.cancel'), confirm: t('w.delete') },
      onConfirm: () => deleteCounterType(key),
    });
  }

  return (
    <Page
      title={t('w.counterTypes')}
      parent={{ label: t('w.configuration') }}
      add={{
        label: t('counterType.add'),
        onClick: () => {
          modals.open({
            modalId: 'add-counter-type-modal',
            title: (
              <Title size={'h3'} component="p">
                {t('counterType.add')}
              </Title>
            ),
            size: 'xl',
            children: <CreateEditForm companyId={companyId} />,
          });
        },
      }}
    >
      <Group grow preventGrowOverflow={false} wrap="nowrap">
        <Table
          idAccessor="counterType.id"
          pinFirstColumn
          rows={data ? data : []}
          options={options}
          lang={lang}
          withTableBorder={false}
          columns={columns}
          width="0vw" // autoscale width
        />
      </Group>
    </Page>
  );
}
