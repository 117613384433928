import { Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconBuilding,
  IconChevronDown,
  IconClipboardList,
  IconX,
} from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import CustomMenu from '../../../components/CustomMenu';
import Page, { PageLink } from '../../../components/Page/Page';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import { useModule } from '../../../contexts/ModuleProvider';
import useDisplayTeamActivities from '../../../hooks/accessChecking/activities/useDisplayTeamActivities';
import DailyWorkService from '../../../services/DailyWorkService';
import type { CompanyResponse } from '../../../types/api/response/company';
import type { DivisionResponse } from '../../../types/api/response/division';
import { ModuleName } from '../../../types/api/response/module';
import {
  BuiltInPermissions,
  BuiltInRoles,
  ScopeEnum,
} from '../../../types/api/response/role';
import { hasPermission, hasRole } from '../../../utils/authorization';
import { toDateOfDayString } from '../../../utils/format';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import saveDownloadedfile from '../../../utils/saveDownloadedFile';
import { NO_WORK_UNIT } from '../../../variables/GlobalVariables';
import ImportActivitiesForm from '../../activities/my-activities/components/ImportActivitiesForm';
import Employees from '../../company/employees/Employees';
import HolidayCounters from '../../end-of-month/holiday-counter/components/HolidayCounters';
import PlanningScheduler, {
  PlanningSchedulerRef,
} from '../../planning/components/PlanningScheduler';
import LeavesAndAbsencesPendingRequests from '../../validations/leaves/LeavesAndAbsencesPendingRequests';
import WorkUnitsDashboard from './work-units-infos/WorkUnitsDashboard';
import WorkUnitsFixedPriceManagerTable from './WorkUnitsFixedPriceManagerTable';

type Props = {
  divisionConsultedId?: string;
  company: CompanyResponse;
  divisions: DivisionResponse[];
};

export default function WorkUnitDetails({
  divisionConsultedId,
  company,
  divisions,
}: Props) {
  const [key, setKey] = useState(0);
  const { user } = useAuth();
  const { t } = useI18n();
  const { getModule } = useModule();
  const [selectedDivision, setSelectedDivision] =
    useState<DivisionResponse | null>(null);
  const title = user.company?.divisionLabel
    ? user.company.divisionLabel
    : t('w.units');
  const dailyWorkModule = getModule(ModuleName.DailyWork);
  const canImportActivities = useDisplayTeamActivities();
  const [activeLink, setActiveLink] = useState<string>(t('w.planning'));
  const planningSchedulerRef = useRef<PlanningSchedulerRef>(null);
  const exportDate = planningSchedulerRef.current?.visibleTimes?.start;

  const handleRefetch = () => {
    if (planningSchedulerRef.current) {
      planningSchedulerRef.current.refetchPlanning();
    }
  };

  useEffect(() => {
    if (divisionConsultedId) {
      const findDivisionConsulted = divisions.find(
        (division) => division.id === divisionConsultedId
      );
      if (findDivisionConsulted) {
        setSelectedDivision(findDivisionConsulted);
      }
    } else {
      if (user.division) {
        const userWorkUnitIndex = divisions.findIndex(
          (div) => div.id === user.division?.id
        );
        if (userWorkUnitIndex !== -1) {
          setSelectedDivision(divisions[userWorkUnitIndex]);
        }
      } else {
        if (divisions.length) {
          setSelectedDivision(divisions[0]);
        }
      }
    }
  }, [user, divisions, divisionConsultedId]);

  function getLabel(divisionName: string) {
    return divisionName === NO_WORK_UNIT ? t('NO_WORK_UNIT') : divisionName;
  }

  const workUnits: { label: string; icon: ReactNode; onClick: () => void }[] =
    useMemo(() => {
      if (divisionConsultedId) {
        return divisions
          .filter(
            (division) =>
              divisionConsultedId && divisionConsultedId === division.id
          )
          .map((division) => ({
            label: getLabel(division.name),
            icon: <IconBuilding />,
            onClick: () => setSelectedDivision(division),
          }));
      } else {
        if (
          !hasRole([BuiltInRoles.ADMINISTRATOR], user, company.id) &&
          !hasPermission(
            {
              permission: BuiltInPermissions.AuthorizedReadAllUsersInfo,
              scope: ScopeEnum.ALL,
            },
            user,
            company.id
          )
        ) {
          return divisions
            .filter(
              (_div) =>
                _div.n1 === user.id ||
                _div.n2 === user.id ||
                _div.assistant === user.id ||
                _div.id === user.division?.id
            )
            .map((division) => ({
              label: getLabel(division.name),
              icon: <IconBuilding />,
              onClick: () => setSelectedDivision(division),
            }));
        } else {
          return divisions.map((division) => ({
            label: getLabel(division.name),
            icon: <IconBuilding />,
            onClick: () => setSelectedDivision(division),
          }));
        }
      }
    }, [
      hasRole([BuiltInRoles.ADMINISTRATOR], user, company.id),
      hasPermission(
        {
          permission: BuiltInPermissions.AuthorizedReadAllUsersInfo,
          scope: ScopeEnum.ALL,
        },
        user,
        company.id
      ),
      divisions,
      divisionConsultedId,
    ]);
  const { mutate: exportActivitiesDivisions } = useMutation({
    mutationFn: (payload: {
      month: number;
      year: number;
      divisionIds: string[];
    }) =>
      DailyWorkService.exportUserDailyWorkByDivisions(
        payload.divisionIds,
        payload.month,
        payload.year
      ),
    onSuccess: (data) => {
      saveDownloadedfile(data);
    },
    onError: (error) =>
      showNotification({
        id: 'export-dailywork-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconX />,
        color: 'red',
      }),
  });

  function openImportActivities(options: {
    month: number;
    year: number;
    divisionIds: string[];
  }) {
    modals.open({
      modalId: `import-user-activities`,
      title: (
        <Title size={'h3'} component="p">
          {t('w.import')}
        </Title>
      ),
      size: 'xl',
      children: (
        <ImportActivitiesForm
          exportClick={() => exportActivitiesDivisions({ ...options })}
          refresh={handleRefetch}
        />
      ),
    });
  }

  function getLinks() {
    const links: PageLink[] = [];

    if (!!selectedDivision?.id) {
      links.push({
        label: t('w.planning'),
        onClick: () => {
          setActiveLink(t('w.planning'));
        },
        active: activeLink === t('w.planning'),
      });
    }

    if (canImportActivities && !!exportDate && !!selectedDivision?.id) {
      links.push({
        active: false,
        label: `${t('w.export')} ${t('w.activities').toLowerCase()}`,
        onClick: () => {
          setTimeout(
            () =>
              exportActivitiesDivisions({
                month: exportDate.getMonth() + 1,
                year: exportDate.getFullYear(),
                divisionIds: [selectedDivision.id],
              }),
            500
          );
        },
      });

      if (
        hasPermission(
          {
            permission: BuiltInPermissions.AuthorizedUpsertDailyWork,
            scope: ScopeEnum.ALL,
          },
          user,
          company.id
        )
      ) {
        links.push({
          active: false,
          label: `${t('w.import')} ${t('w.activities').toLowerCase()}`,
          onClick: () =>
            openImportActivities({
              month: exportDate.getMonth() + 1,
              year: exportDate.getFullYear(),
              divisionIds: [selectedDivision.id],
            }),
        });
      }
    }

    links.push({
      label: t('w.employees'),
      onClick: () => {
        setActiveLink(t('w.employees'));
      },
      active: activeLink === t('w.employees'),
    });

    if (dailyWorkModule?.active) {
      links.push({
        label: t('w.fixedPriceManager'),
        onClick: () => {
          setActiveLink(t('w.fixedPriceManager'));
        },
        active: activeLink === t('w.fixedPriceManager'),
      });
    }

    links.push({
      label: t('w.leavesAndAbsences'),
      onClick: () => {
        setActiveLink(t('w.leavesAndAbsences'));
      },
      active: activeLink === t('w.leavesAndAbsences'),
    });

    links.push({
      label: t('w.holidayCounters'),
      onClick: () => {
        setActiveLink(t('w.holidayCounters'));
      },
      active: activeLink === t('w.holidayCounters'),
    });

    return links;
  }

  const refreshParentView = useCallback(() => {
    setKey((prevKey) => prevKey + 1);
  }, []);

  function renderContent() {
    if (!selectedDivision?.id) {
      return null;
    }

    switch (activeLink) {
      case t('w.planning'):
        return (
          <PlanningScheduler
            planningId="planning-id-to-print"
            selectedDivisions={[selectedDivision.id]}
            selectedTemplate={undefined}
            divisions={divisions}
            company={company}
            ref={planningSchedulerRef}
            refreshParentView={refreshParentView}
          />
        );
      case t('w.employees'):
        return (
          <Employees
            divisionId={selectedDivision?.id ? selectedDivision.id : ''}
            company={company}
          />
        );
      case t('w.fixedPriceManager'):
        return (
          <WorkUnitsFixedPriceManagerTable
            companyId={company.id}
            selectedDivisions={[
              selectedDivision?.id ? selectedDivision.id : '',
            ]}
          />
        );
      case t('w.leavesAndAbsences'):
        return (
          <LeavesAndAbsencesPendingRequests
            divisionId={selectedDivision?.id ? selectedDivision.id : ''}
            company={company}
          />
        );
      case t('w.holidayCounters'):
        return (
          <HolidayCounters
            companyId={company.id}
            withUnitFilter={false}
            selectedDivisionIds={
              selectedDivision?.id ? [selectedDivision.id] : undefined
            }
          />
        );
      default:
        return null;
    }
  }

  return (
    <Page
      title={divisionConsultedId ? '' : title}
      parent={{ label: toDateOfDayString() }}
      headerRightSection={
        <CustomMenu
          key={key}
          buttonLabel={getLabel(
            selectedDivision?.name ? selectedDivision?.name : title
          )}
          buttonVariant="outline"
          buttonRadius="xl"
          buttonSize="md"
          leftSection={<IconClipboardList />}
          rightSection={<IconChevronDown />}
          menuLabel={title}
          menuItems={workUnits}
        />
      }
      links={getLinks()}
    >
      <WorkUnitsDashboard
        selectedDivision={selectedDivision}
        company={company}
      />
      {renderContent()}
    </Page>
  );
}
