import { Stack } from '@mantine/core';
import type {
  DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';

import { useI18n } from '../../../../../contexts/I18nProvider';
import { PayslipMonthResponseFront } from '../../../../../types/types';
import ImportPayslipsTitle from '../ImportPayslipsTitle';
import ValidatedPayslipsTable from './ValidatedPayslipsTable';

type Props = {
  companyId: string;
  payslipsMonthInfo: PayslipMonthResponseFront | undefined;
  selectedDate: DateValue | DatesRangeValue | Date[];
};

export default function ValidatedPayslips({
  companyId,
  payslipsMonthInfo,
  selectedDate,
}: Props) {
  const { t } = useI18n();

  console.log(payslipsMonthInfo);

  return (
    <Stack gap={'xl'}>
      <ImportPayslipsTitle title={t('w.listOKnownEmployeesWithPayslips')} />
      <ValidatedPayslipsTable
        payslipsMonthInfo={payslipsMonthInfo}
        companyId={companyId}
        selectedDate={selectedDate}
      />
    </Stack>
  );
}
