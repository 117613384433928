import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import {
  ActionIcon,
  Button,
  Fieldset,
  Flex,
  Group,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconClipboardPlus,
  IconDeviceFloppy,
  IconRefresh,
} from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useState } from 'react';

import ButtonCancel from '../../../../../components/ButtonCancel';
import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import CharteredAccountantService from '../../../../../services/CharteredAccountantService';
import EndOfMonthService from '../../../../../services/EndOfMonthService';
import type {
  DossierResponseFromOpenpaye,
  SynchroOpenpayeParams,
} from '../../../../../types/api/response/module';
import handleErrorMessage from '../../../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../../utils/optionsPreferences';
import CompanyInfoWrapper from '../../../../accountant/components/CompanyInfoWrapper';
import LastSynchroOpenpaye from './components/LastSynchroOpenpaye';

type Props = {
  moduleParams: SynchroOpenpayeParams;
  companyId: string;
  isAccountant: boolean;
};
const componentName = 'SynchroOpenpayeFeatureDossierForm';

export default function SynchroOpenpayeFeatureDossierForm({
  moduleParams,
  companyId,
  isAccountant,
}: Props) {
  const { user } = useAuth();
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const { data: accountantCustomers } = useQuery({
    enabled: isAccountant,
    queryKey: ['CharteredAccountantService.accountantCustomersList', companyId],
    queryFn: () =>
      CharteredAccountantService.accountantCustomersList(companyId),
  });

  const {
    mutate: createClientCompanyFromOpenpayeDossier,
    isLoading: createClientCompanyFromOpenpayeDossierLoading,
  } = useMutation({
    mutationFn: (payload: { accountantCompanyId: string; dossierId: string }) =>
      EndOfMonthService.createClientCompanyFromOpenpayeDossier(
        payload.accountantCompanyId,
        payload.dossierId
      ),
    onSuccess: () => {
      showNotification({
        id: 'createClientFromDossier-success',
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });

      queryClient.invalidateQueries({
        queryKey: [
          'CharteredAccountantService.accountantCustomersList',
          companyId,
        ],
      });

      modals.closeAll();
    },
    onError: (error) => {
      showNotification({
        id: 'createClientCompanyFromOpenpayeDossier-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconCheck />,
        color: 'red',
      });
    },
  });

  const {
    mutate: synchroOpenpayeDossierEmployeesToExistingClientCompany,
    isLoading: synchroOpenpayeDossierEmployeesToExistingClientCompanyLoading,
  } = useMutation({
    mutationFn: (payload: {
      accountantCompanyId: string;
      clientCompanyId: string;
      dossierId: string;
    }) =>
      EndOfMonthService.synchroOpenpayeDossierEmployeesToExistingClientCompany(
        payload.accountantCompanyId,
        payload.clientCompanyId,
        payload.dossierId
      ),
    onSuccess: () => {
      showNotification({
        id: 'createClientFromDossier-success',
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });

      queryClient.invalidateQueries({
        queryKey: [
          'CharteredAccountantService.accountantCustomersList',
          companyId,
        ],
      });

      modals.closeAll();
    },
    onError: (error) => {
      showNotification({
        id: 'createClientCompanyFromOpenpayeDossier-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconCheck />,
        color: 'red',
      });
    },
  });

  const columns: DataTableColumn<DossierResponseFromOpenpaye>[] = [
    {
      accessor: 'dossierId',
      title: t('w.id'),
      sortable: true,
      ellipsis: true,
    },
    {
      accessor: 'code',
      title: t('w.code'),
      sortable: true,
      ellipsis: true,
    },
    {
      accessor: 'nomDossier',
      title: t('w.name'),
      sortable: true,
      ellipsis: true,
    },
    {
      accessor: 'siret',
      title: t('w.siret'),
      sortable: true,
      ellipsis: true,
    },
    {
      accessor: 'nbEmployees',
      title: t('w.nbUsers'),
      sortable: true,
      ellipsis: true,
    },
    {
      accessor: 'actions',
      title: t('w.actions'),
      textAlign: 'right',
      sortable: false,
      width: '0%',
      render: (dossier) => (
        <Group gap={4} justify="end" wrap={'nowrap'}>
          {isAccountant ? (
            <>
              <Tooltip label={t('openpaye.createClientFromDossier')}>
                <ActionIcon
                  variant="transparent"
                  onClick={() =>
                    createClientFromDossierClick(dossier, companyId)
                  }
                  disabled={dossier.nbEmployees === 0}
                  loading={
                    createClientCompanyFromOpenpayeDossierLoading ||
                    synchroOpenpayeDossierEmployeesToExistingClientCompanyLoading
                  }
                >
                  <IconClipboardPlus />
                </ActionIcon>
              </Tooltip>
              <Tooltip
                label={t('openpaye.synchroDossierUsersToExistingClient')}
              >
                <ActionIcon
                  variant="transparent"
                  onClick={() =>
                    synchroDossierEmployeesToExistingClientClick(
                      dossier,
                      companyId
                    )
                  }
                  disabled={
                    dossier.nbEmployees === 0 || !accountantCustomers?.length
                  }
                  loading={
                    createClientCompanyFromOpenpayeDossierLoading ||
                    synchroOpenpayeDossierEmployeesToExistingClientCompanyLoading
                  }
                >
                  <IconRefresh />
                </ActionIcon>
              </Tooltip>
            </>
          ) : (
            <Tooltip
              label={t(
                'openpaye.synchroDossierUsersToMyCompany',
                user.company?.name
              )}
            >
              <ActionIcon
                variant="transparent"
                onClick={() =>
                  synchroOpenpayeDossierEmployeesToExistingClientCompany({
                    accountantCompanyId: companyId,
                    clientCompanyId: companyId,
                    dossierId: dossier.dossierId + '',
                  })
                }
                disabled={dossier.nbEmployees === 0}
                loading={
                  createClientCompanyFromOpenpayeDossierLoading ||
                  synchroOpenpayeDossierEmployeesToExistingClientCompanyLoading
                }
              >
                <IconRefresh />
              </ActionIcon>
            </Tooltip>
          )}
        </Group>
      ),
    },
  ];

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: 'nomDossier', direction: 'asc' },
    search: {
      accessor: 'nomDossier',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({ componentName }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  function synchroDossierEmployeesToExistingClientClick(
    item: DossierResponseFromOpenpaye,
    _accountantCompanyId: string
  ) {
    modals.open({
      modalId: 'synchroDossierEmployeesToExistingClient-chose-company-modal',
      title: (
        <Title size={'h3'} component="p">
          {t(
            'openpaye.clickOnClientCompanyToStartTheSynchro',
            `(${item.dossierId} - ${item.code} - ${item.nomDossier})`
          )}
        </Title>
      ),
      size: 'xl',
      zIndex: 203,
      children: (
        <Group>
          {accountantCustomers?.map((customer) => (
            <Button
              key={customer.customerCompany.id}
              size="xl"
              variant="light"
              radius="xs"
              onClick={() => {
                showNotification({
                  id: 'synchroDossierEmployeesToExistingClient-loading',
                  title: t('w.success'),
                  message: t('w.treatmentInProgress'),
                  color: 'green',
                  icon: <IconCheck />,
                });
                synchroOpenpayeDossierEmployeesToExistingClientCompany({
                  accountantCompanyId: _accountantCompanyId,
                  clientCompanyId: customer.clientCompanyId,
                  dossierId: item.dossierId.toString(),
                });
              }}
            >
              <CompanyInfoWrapper
                companyId={customer.customerCompany.id}
                companyName={customer.customerCompany.name}
              />
            </Button>
          ))}
        </Group>
      ),
    });
  }

  function createClientFromDossierClick(
    item: DossierResponseFromOpenpaye,
    _accountantCompanyId: string
  ) {
    modals.open({
      modalId: 'createClientFromDossierConfirmMessage-modal',
      title: (
        <Title size={'h3'} component="p">{`${t(
          'w.pleaseConfirmYourAction'
        )}`}</Title>
      ),
      size: 'xl',
      zIndex: 203,
      children: (
        <Group>
          <Text size="sm">
            {t(
              'openpaye.createClientFromDossierConfirmMessage',
              `(${item.dossierId} - ${item.code} - ${item.nomDossier})`
            )}
          </Text>
          <Flex
            justify={'flex-end'}
            gap={'md'}
            direction="row"
            wrap="wrap"
            mt={'md'}
            w="100%"
          >
            <ButtonCancel
              onClose={() =>
                modals.close('createClientFromDossierConfirmMessage-modal')
              }
            />
            <Button
              leftSection={<IconDeviceFloppy />}
              onClick={() => {
                showNotification({
                  id: 'createClientFromDossier-loading',
                  title: t('w.success'),
                  message: t('w.treatmentInProgress'),
                  color: 'green',
                  icon: <IconCheck />,
                });
                createClientCompanyFromOpenpayeDossier({
                  accountantCompanyId: _accountantCompanyId,
                  dossierId: item.dossierId.toString(),
                });
              }}
            >
              {t('w.confirm')}
            </Button>
          </Flex>
        </Group>
      ),
    });
  }

  return (
    <Fieldset variant="filled" legend={t('openpaye.dossiersOnOpenpaye')}>
      <Table
        options={options}
        rows={moduleParams.dossiers || []}
        columns={columns}
        height={400}
        rightCustomActions={
          !isAccountant
            ? [
                <Button
                  key="last-synchro-button"
                  variant={'outline'}
                  size={'sm'}
                  onClick={() =>
                    modals.open({
                      modalId: `get-last-synchro-openpaye-modal`,
                      title: (
                        <Title size={'h3'} component="p">
                          {t('module.lastSynchro')}
                        </Title>
                      ),
                      size: '100%',
                      zIndex: 203,
                      children: <LastSynchroOpenpaye companyId={companyId} />,
                    })
                  }
                >
                  {t('module.lastSynchro')}
                </Button>,
              ]
            : undefined
        }
      />
    </Fieldset>
  );
}
