import { Container } from '@mantine/core';
import {
  type DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { useEffect, useState } from 'react';

import CustomMonthPickerInput from '../../../components/CustomMonthPickerInput';
import Page from '../../../components/Page/Page';
import { useAppBar } from '../../../contexts/AppBarProvider';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import useCompany from '../../../hooks/useCompany';
import useFetchDivisions from '../../../hooks/useFetchDivisions';
import useResponsive from '../../../hooks/useResponsive';
import { BuiltInRoles } from '../../../types/api/response/role';
import { hasRole } from '../../../utils/authorization';
import { initPickerDate } from '../../../utils/initPickerDate';
import { NO_WORK_UNIT } from '../../../variables/GlobalVariables';
import EndOfMonthValidationTable from './components/EndOfMonthValidationTable';

export default function EndOfMonthValidations() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId, company } = useCompany(user);
  const { divisions, refetch: refetchDivisionsData } = useFetchDivisions({
    companyId,
  });
  const { setIsAppBarUsed, setDivisionsToSelect, setAppBarSelectedDivisions } =
    useAppBar();
  const { isDesktop } = useResponsive();

  const [selectedDate, setSelectedDate] = useState<
    DateValue | DatesRangeValue | Date[]
  >(initPickerDate());

  useEffect(() => {
    setIsAppBarUsed(true);
    refetchDivisionsData();
    return function cleanup() {
      setIsAppBarUsed(false);
    };
  }, [selectedDate]);

  useEffect(() => {
    buildAppbarDivisions();
  }, [hasRole(BuiltInRoles.ADMINISTRATOR, user, companyId), divisions]);

  function buildAppbarDivisions() {
    let _divisions = divisions.filter(
      (division) => division.name !== NO_WORK_UNIT
    );
    if (!hasRole(BuiltInRoles.ADMINISTRATOR, user, companyId)) {
      _divisions = _divisions.filter(
        (division) =>
          division.n1 === user.id ||
          division.n2 === user.id ||
          division.assistant === user.id
      );
    }
    const divisionsNames = _divisions.map((division) => division.name);
    setDivisionsToSelect(divisionsNames);
    setAppBarSelectedDivisions(divisionsNames);
  }

  if (!company) return null;

  return (
    <Page
      title={t('w.endOfMonthValidation')}
      parent={{ label: company.name }}
      headerRightSection={
        <CustomMonthPickerInput
          key={'month-picker-end-of-month-validation'}
          value={selectedDate}
          onChange={setSelectedDate}
        />
      }
    >
      <Container
        p={0}
        fluid
        w={
          isDesktop
            ? 'calc(100dvw - var(--app-shell-navbar-width) - 3rem)'
            : 'calc(100dvw - 3rem)'
        }
      >
        <EndOfMonthValidationTable
          selectedDate={selectedDate}
          divisions={divisions}
          company={company}
        />
      </Container>
    </Page>
  );
}
