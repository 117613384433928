import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Group, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconEdit, IconTrash, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';

import CustomDrawer from '../../../components/CustomDrawer';
import CustomTooltip from '../../../components/CustomTooltip';
import { useI18n } from '../../../contexts/I18nProvider';
import ShuttleSheetService from '../../../services/ShuttleSheetService';
import { ShuttleSheetRequest } from '../../../types/types';
import { milliToFullDateString } from '../../../utils/format';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../utils/optionsPreferences';
import ShuttleSheetForm, {
  requestStatuses,
  requestStatusesOwner,
} from './create/ShuttleSheetForm';
import ShuttleSheetComments from './ShuttleSheetComments';
import ShuttleSheetCommentsActionIcon from './ShuttleSheetCommentsActionIcon';
import ShuttleSheetCommentsDrawerTitle from './ShuttleSheetCommentsDrawerTitle';
import ShuttleSheetStatusBadge from './ShuttleSheetStatusBadge';

type Props = {
  companyId: string;
  userId: string;
};

const componentName = 'MyShuttleSheetsTable';

export default function MyShuttleSheetsTable({ companyId, userId }: Props) {
  const { t, lang } = useI18n();
  const [
    openedAddCommentDrawer,
    { open: handleOpenAddCommentDrawer, close: handleCloseAddCommentDrawer },
  ] = useDisclosure(false);
  const [shuttleSheetRequest, setShuttleSheetRequest] = useState<
    ShuttleSheetRequest | undefined
  >(undefined);

  const {
    data: userShuttleSheetsListData,
    isLoading: isUserShuttleSheetsListLoading,
    refetch,
  } = useQuery({
    queryKey: ['ShuttleSheetService.userShuttleSheetsList', companyId, userId],
    queryFn: () => ShuttleSheetService.userShuttleSheetsList(companyId, userId),
  });

  const { mutate: deleteShuttleSheet } = useMutation({
    mutationFn: (variables: string) =>
      ShuttleSheetService.deleteShuttleSheet(companyId, variables),
    onSuccess: () => {
      showNotification({
        id: 'delete-shuttle-sheet-successful',
        title: t('w.success'),
        message: t('w.deleted'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetch();
    },
    onError: (error) =>
      showNotification({
        id: 'delete-shuttle-sheet-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const userShuttleSheetsList = useMemo(() => {
    if (!userShuttleSheetsListData) {
      return [];
    }
    return userShuttleSheetsListData;
  }, [userShuttleSheetsListData]);

  const columns: DataTableColumn<ShuttleSheetRequest>[] = useMemo(
    () => [
      {
        accessor: 'shuttleSheetType.name',
        title: 'Type',
        sortable: true,
        ellipsis: true,
      },
      {
        accessor: 'status',
        title: t('w.status'),
        sortable: true,
        ellipsis: true,
        render: ({ status, statusOwner, shuttleSheetType }) => (
          <ShuttleSheetStatusBadge
            status={status}
            statusOwner={statusOwner}
            transmittedToAccountant={shuttleSheetType.transmittedToAccountant}
          />
        ),
      },
      {
        accessor: 'comments',
        title: `${t('w.comment')}s`,
        sortable: true,
        ellipsis: true,
        render: (shuttleSheet) => (
          <ShuttleSheetCommentsActionIcon
            onClick={() => {
              handleOpenAddCommentDrawer();
              if (shuttleSheet.comments) {
                setShuttleSheetRequest(shuttleSheet);
              }
            }}
            commentsNumber={
              shuttleSheet?.comments ? shuttleSheet.comments.length : 0
            }
          />
        ),
      },
      {
        accessor: 'created',
        title: t('w.requestedOn'),
        sortable: true,
        ellipsis: true,
        render: ({ created }) =>
          created ? milliToFullDateString(created) : '',
      },
      {
        accessor: 'updated',
        title: t('w.lastUpdated'),
        sortable: true,
        ellipsis: true,
        render: ({ updated }) =>
          updated ? milliToFullDateString(updated) : '',
      },
      {
        accessor: 'actions',
        title: t('w.actions'),
        textAlign: 'right',
        sortable: false,
        width: '0%',
        render: (shuttleSheetRequest) => (
          <Group gap={4} justify="end" wrap={'nowrap'}>
            <CustomTooltip label={t('w.modify')}>
              <ActionIcon
                size="sm"
                color="green"
                variant={'subtle'}
                onClick={() =>
                  handleOpenEditShuttleSheetModal(shuttleSheetRequest)
                }
              >
                <IconEdit size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.delete')}>
              <ActionIcon
                size="sm"
                color="red"
                variant={'subtle'}
                onClick={() =>
                  handleOpenConfirmDeleteShuttleSheet(shuttleSheetRequest)
                }
              >
                <IconTrash size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    []
  );

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'shuttleSheetType.name',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'status',
          label: t('w.status'),
          choices: requestStatuses.map((status) => ({
            value: status,
            label: t(`shuttleSheet.statusEnum.${status}`),
          })),
          selectedChoices: [],
        },
        {
          accessor: 'statusOwner',
          label: t('w.role'),
          choices: requestStatusesOwner.map((status) => ({
            value: status,
            label: t(`shuttleSheet.statusOwnerEnum.${status}`),
          })),
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  function handleOpenEditShuttleSheetModal(
    shuttleSheetRequest: ShuttleSheetRequest
  ) {
    modals.open({
      modalId: `edit-shuttle-sheet-${shuttleSheetRequest.id}-modal`,
      title: (
        <Title size={'h3'} component="p">{`${t('w.modify')} ${
          shuttleSheetRequest.shuttleSheetType.name
        }`}</Title>
      ),
      size: '70%',
      children: (
        <ShuttleSheetForm
          companyId={companyId}
          modalId={`edit-shuttle-sheet-${shuttleSheetRequest.id}-modal`}
          shuttleSheetRequest={shuttleSheetRequest}
          refetch={refetch}
        />
      ),
    });
  }

  function handleOpenConfirmDeleteShuttleSheet(
    shuttleSheetRequest: ShuttleSheetRequest
  ) {
    modals.openConfirmModal({
      modalId: `open-confirm-delete-shuttle-sheet-${shuttleSheetRequest.id}-modal`,
      title: (
        <Title size={'h3'} component="p">
          {t('w.confirmDeletion')}
        </Title>
      ),
      children: (
        <Group>
          <Text fz={'sm'} fw={'bold'} c={'hifivework.5'}>
            {shuttleSheetRequest.shuttleSheetType.name}
          </Text>
          -
          {shuttleSheetRequest.created && (
            <Text fz={'sm'} fw={'bold'} c={'dimmed'}>
              {milliToFullDateString(shuttleSheetRequest.created)}
            </Text>
          )}
        </Group>
      ),
      labels: { confirm: t('w.delete'), cancel: t('w.cancel') },
      onConfirm: () =>
        deleteShuttleSheet(
          shuttleSheetRequest.id ? shuttleSheetRequest.id : ''
        ),
    });
  }

  return (
    <>
      <Table
        pinFirstColumn
        pinLastColumn
        options={options}
        rows={userShuttleSheetsList}
        columns={columns}
        lang={lang}
        fetching={isUserShuttleSheetsListLoading}
        height={'calc(100vh - 220px)'}
        width="0vw" // autoscale width
      />
      <CustomDrawer
        opened={openedAddCommentDrawer}
        onClose={handleCloseAddCommentDrawer}
        title={<ShuttleSheetCommentsDrawerTitle />}
      >
        <ShuttleSheetComments
          companyId={companyId}
          shuttleSheetId={shuttleSheetRequest?.id ? shuttleSheetRequest.id : ''}
          refetch={refetch}
        />
      </CustomDrawer>
    </>
  );
}
