import {
  Button,
  Flex,
  PasswordInput,
  SimpleGrid,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';

import ButtonSave from '../../../../../components/ButtonSave';
import LabelText from '../../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../../contexts/I18nProvider';
import ModuleWithParamsService from '../../../../../services/ModuleWithParamsService';
import type {
  ModuleDetails,
  SynchroOpenpayeModuleResponse,
  SynchroOpenpayeParams,
} from '../../../../../types/api/response/module';

interface FormValues {
  username: string;
  password: string;
}

type Props = {
  module: ModuleDetails | null;
  moduleParams: SynchroOpenpayeParams;
  setModuleParams: React.Dispatch<React.SetStateAction<SynchroOpenpayeParams>>;
  companyId: string;
};

export default function SynchroOpenpayefeatureConnectionForm({
  module,
  moduleParams,
  setModuleParams,
  companyId,
}: Props) {
  const { t } = useI18n();
  const queryClient = useQueryClient();

  const form = useForm<FormValues>({
    initialValues: {
      username: moduleParams?.username || '',
      password: moduleParams?.password || '',
    },
  });

  function handleUpdateOpenpayeParamsSubmit(
    values: ReturnType<(values: FormValues) => FormValues>,
    isTest: boolean
  ) {
    if (module) {
      updateModuleParamsOpenpaye({
        apiUrl: module.description.url,
        payload: {
          active: module?.active ? module.active : false,
          username: values.username,
          password: values.password,
          isTest,
          dossiers: moduleParams?.dossiers,
        },
      });
    }
  }

  const { mutate: updateModuleParamsOpenpaye, isLoading } = useMutation({
    mutationFn: (variables: { apiUrl: string; payload: any }) =>
      ModuleWithParamsService.updateModuleParams(
        variables.apiUrl,
        companyId,
        variables.payload
      ),
    onSuccess: (_data: SynchroOpenpayeModuleResponse, _variables) => {
      showNotification({
        id: `success-update-module-params`,
        message: Boolean(_variables?.payload?.isTest)
          ? t('openpaye.dontForgotToSaveCredentials')
          : t('w.success'),
        color: Boolean(_variables?.payload?.isTest) ? 'orange' : 'green',
        icon: <IconCheck />,
      });
      queryClient.invalidateQueries({
        queryKey: ['CkModuleManagerServiceMS.list', companyId],
      });
      setModuleParams(_data.params);
    },
    onError: (error: any) => {
      if (error?.response?.data?.error_key === 'credentialInvalid') {
        showNotification({
          id: `error-update-module-params`,
          title: t('w.error'),
          message: t('openpaye.credentialsNotValid'),
          color: 'red',
          icon: <IconX />,
        });
      } else {
        showNotification({
          id: `error-update-module-params`,
          title: t('w.error'),
          message: t('error.updateModule'),
          color: 'red',
          icon: <IconX />,
        });
      }
    },
  });

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        handleUpdateOpenpayeParamsSubmit(
          {
            username: values.username,
            password: values.password,
          },
          false
        )
      )}
    >
      <SimpleGrid cols={2}>
        <TextInput
          required
          label={<LabelText text={t('w.username')} />}
          {...form.getInputProps('username')}
        />
        <PasswordInput
          required
          label={<LabelText text={t('w.password')} />}
          placeholder={t('w.yourPassword')}
          value={form.values.password}
          onChange={(event) =>
            form.setFieldValue('password', event.currentTarget.value)
          }
        />
      </SimpleGrid>
      <Flex
        justify={'flex-end'}
        gap={'md'}
        direction="row"
        wrap="wrap"
        mt={'md'}
      >
        <Button
          variant={'outline'}
          onClick={() =>
            handleUpdateOpenpayeParamsSubmit({ ...form.values }, true)
          }
          loading={isLoading}
        >
          {t('w.test')}
        </Button>
        <ButtonSave isLoading={isLoading} />
      </Flex>
    </form>
  );
}
