import {
  FilterOption,
  FilterOptionChoice,
  Options,
  Table,
} from '@ckprivate/ckf-ui';
import {
  Badge,
  Button,
  Group,
  HoverCard,
  ScrollArea,
  Stack,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import {
  type DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { upperFirst, useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCalendarCheck,
  IconCheck,
  IconChecks,
  IconFileExport,
  IconFileSpreadsheet,
  IconInfoCircle,
  IconTableExport,
  IconUserExclamation,
  IconX,
} from '@tabler/icons-react';
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import dayjs from 'dayjs';
import { cloneDeep, debounce } from 'lodash';
import { DataTableColumn } from 'mantine-datatable';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';

import IdentifiantsCompanyBadge from '../../../../components/badges/IdentifiantsCompanyBadge';
import UnitBadge from '../../../../components/badges/UnitBadge';
import CustomMenu from '../../../../components/CustomMenu';
import CustomTooltip from '../../../../components/CustomTooltip';
import GoToUserButton from '../../../../components/GoToUserButton/GoToUserButton';
import LoadingBadge from '../../../../components/LoadingBadge';
import ModuleToEditModal from '../../../../components/ModuleToEditModal';
import { useAppBar } from '../../../../contexts/AppBarProvider';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useFetchAccountants from '../../../../hooks/useFetchAccountants';
import CharteredAccountantService from '../../../../services/CharteredAccountantService';
import CkModuleManagerServiceMS from '../../../../services/CkModuleManagerServiceMS';
import CounterTypeService from '../../../../services/CounterTypeService';
import EndOfMonthService from '../../../../services/EndOfMonthService';
import ExpenseReportService from '../../../../services/ExpenseReportService';
import ModuleWithParamsService from '../../../../services/ModuleWithParamsService';
import VariablePayrollService from '../../../../services/VariablePayrollService';
import type { EndMonthPrepareRequest } from '../../../../types/api/payload/accountant';
import {
  EndMonthExportType,
  type ValidateListEmployeesEndOfMonthQuery,
} from '../../../../types/api/payload/endMonth';
import { ViewModule } from '../../../../types/api/payload/module';
import type { CompanyResponse } from '../../../../types/api/response/company';
import type { DivisionResponse } from '../../../../types/api/response/division';
import {
  type EndOfMonthValidationResponse,
  Payroll,
} from '../../../../types/api/response/endMonth';
import {
  type LeaveResponse,
  LeaveStatusState,
} from '../../../../types/api/response/leave';
import {
  DailyWorkParams,
  type ExpenseReportParams,
  type ModuleDetails,
  ModuleName,
  type VariablePayroll,
  VariablePayrollParams,
  VariablePayrollType,
} from '../../../../types/api/response/module';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../../types/api/response/role';
import type { UserResponse } from '../../../../types/api/response/user';
import type {
  EndOfMonthDataResponseFront,
  VariablePayrollInfoFront,
} from '../../../../types/front/endMonth';
import type { ExpenseReportObject } from '../../../../types/types';
import { hasPermission } from '../../../../utils/authorization';
import { getTranslatedKey } from '../../../../utils/counterTypesFormatter';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import saveDownloadedfile from '../../../../utils/saveDownloadedFile';
import { NO_WORK_UNIT } from '../../../../variables/GlobalVariables';
import EditVariablePayrollFileInput from './EditVariablePayrollFileInput';
import EditVariablePayrollTextInput from './EditVariablePayrollTextInput';
import EndOfMonthCommentTextInput from './EndOfMonthCommentTextInput';
import EndOfMonthValidationExpenseReports from './EndOfMonthValidationExpenseReports';
import EndOfMonthValidationLeaveRequestsTable from './EndOfMonthValidationLeaveRequestsTable';
import SelectedEmployeesHoverCard from './SelectedEmployeesHoverCard';
import UnitsToCloseHoverCard from './UnitsToCloseHoverCard';

type Props = {
  selectedDate: DateValue | DatesRangeValue | Date[];
  divisions: DivisionResponse[];
  company: CompanyResponse;
  accountantCompanyId?: string;
  globalValidation?: boolean;
};

const componentName = 'EndOfMonthValidationTable';

type EndOfMonthValidationVariablePayroll = {
  employee: string;
  variable: VariablePayrollInfoFront;
};

export default function EndOfMonthValidationTable({
  selectedDate,
  divisions,
  company,
  accountantCompanyId,
  globalValidation,
}: Props) {
  const { t, lang } = useI18n();
  const { user } = useAuth();
  const queryClient = useQueryClient();
  const { appBarSelectedDivisions } = useAppBar();
  const { accountantsListData } = useFetchAccountants();
  const isAccountantEndOfMonth = !!accountantCompanyId;

  const { data: modules, refetch: refetchModules } = useQuery({
    queryKey: ['CkModuleManagerServiceMS.list', company.id],
    queryFn: () =>
      CkModuleManagerServiceMS.list(company.id, ViewModule.companyFeature),
  });

  const { data: accountantModules } = useQuery({
    enabled: !!accountantCompanyId,
    queryKey: ['CkModuleManagerServiceMS.list', accountantCompanyId],
    queryFn: () =>
      CkModuleManagerServiceMS.list(
        accountantCompanyId!,
        ViewModule.companyFeature
      ),
  });

  const { data: counterTypes } = useQuery({
    queryKey: ['CounterTypeService.list', company.id],
    queryFn: () => CounterTypeService.list(company.id),
  });

  function getModule(moduleName: ModuleName): ModuleDetails | undefined {
    if (!modules?.length) return undefined;
    return modules.find((module) => module.name === moduleName);
  }
  const [editVariablePayrollOpened, editVariablePayrollOpenedHandlers] =
    useDisclosure(false);
  const [selectedEmployees, setSelectedEmployees] = useState<
    EndOfMonthDataResponseFront[]
  >([]);
  const expenseReportModule = getModule(ModuleName.ExpenseReport);
  const isExportQuadraModuleActive = Boolean(
    getModule(ModuleName.ExportQuadra)?.active
  );
  const isExportSilaeModuleActive = Boolean(
    getModule(ModuleName.ExportSilae)?.active
  );
  const isExportDiapaieModuleActive = Boolean(
    getModule(ModuleName.ExportDIAPAIE)?.active
  );
  const isExportIsapayeModuleActive = Boolean(
    getModule(ModuleName.ExportISAPAYE)?.active
  );
  const isSynchroOpenPayeModuleActive = accountantCompanyId
    ? Boolean(
        accountantModules?.find(
          (module) => module.name === ModuleName.SynchroOpenPaye
        )?.active
      )
    : Boolean(getModule(ModuleName.SynchroOpenPaye)?.active);
  const isExportCegidExpertModuleActive = Boolean(
    getModule(ModuleName.ExportCegidExpert)?.active
  );
  const isSilaeConnectModuleActive = Boolean(
    getModule(ModuleName.SilaeConnect)?.active
  );
  const dailyWorkModule = getModule(ModuleName.DailyWork);
  const isDailyWorkModuleActive = Boolean(dailyWorkModule?.active);
  const counterTypeIdOvertime = (
    getModule(ModuleName.DailyWork)?.configuration?.params as DailyWorkParams
  )?.counterTypeIdOvertime;
  const variablePayrollModule = getModule(ModuleName.VariablePayroll);
  const isVariablePayrollModuleActive = Boolean(variablePayrollModule?.active);
  const expenseReportModuleParams = expenseReportModule?.configuration
    ?.params as ExpenseReportParams;
  const isDisplayedExpenseReports =
    Boolean(expenseReportModule?.active) &&
    expenseReportModuleParams?.method !== 'DIRECTLY';
  const [triggeredBySelectedDate, setTriggeredBySelectedDate] = useState(false);

  const divisionIds: string[] = useMemo(() => {
    if (!divisions) return [];

    if (!appBarSelectedDivisions?.length) {
      return divisions.map((item) => item.id);
    }

    const result: string[] = [];
    appBarSelectedDivisions.forEach((selectedDivision: string) => {
      const findDivision = divisions.find(
        (division) => division.name === selectedDivision
      );
      if (findDivision) {
        result.push(findDivision.id);
      }
    });
    return result;
  }, [divisions, appBarSelectedDivisions]);

  const enMonthValidationQueries = useQueries({
    queries: divisionIds.map((divisionId) => {
      return {
        queryKey: [
          'EndOfMonthService.getEndOfMonthValidation',
          company.id,
          divisionId,
          // @ts-ignore
          dayjs(selectedDate).set('date', 15).format('DD/MM/YYYY'),
        ],
        queryFn: () =>
          EndOfMonthService.getEndOfMonthValidation(company.id, {
            // @ts-ignore
            monthConcerned: dayjs(selectedDate)
              .set('date', 15)
              .format('DD/MM/YYYY'),
            divisionIds: [divisionId],
          }),
      };
    }),
  });

  const endOfMonthValidationData: EndOfMonthValidationResponse[] =
    enMonthValidationQueries
      .filter((item) => item.isSuccess && !!item.data)
      .flatMap((item) => item.data as EndOfMonthValidationResponse[]);

  const isEndOfMonthValidationDataLoading = enMonthValidationQueries.some(
    (result) => result.isLoading
  );
  const isEndOfMonthValidationDataFetching = enMonthValidationQueries.some(
    (result) => result.isFetching
  );

  const allQueriesFinished = enMonthValidationQueries.every(
    (result) => result.isSuccess || result.isError
  );

  function refetchEndOfMonthValidationData() {
    queryClient.invalidateQueries({
      queryKey: ['EndOfMonthService.getEndOfMonthValidation'],
    });
  }

  useEffect(() => {
    if (divisionIds.length > 0) {
      divisionIds.forEach((divId) => {
        queryClient.invalidateQueries([
          'EndOfMonthService.getEndOfMonthValidation',
          company.id,
          divId,
          // @ts-ignore
          dayjs(selectedDate).set('date', 15).format('DD/MM/YYYY'),
        ]);
      });
    }
  }, [divisionIds, queryClient, selectedDate]);

  useEffect(() => {
    if (allQueriesFinished && triggeredBySelectedDate) {
      setTriggeredBySelectedDate(false);
    }
  }, [allQueriesFinished, triggeredBySelectedDate, queryClient]);

  const {
    data: variablesPayrollData,
    isLoading: isVariablesPayrollDataLoading,
    isFetching: isVariablesPayrollDataFetching,
    refetch: refetchVariablesPayrollData,
  } = useQuery({
    queryKey: ['VariableParollService.getVariables', company.id],
    queryFn: () => VariablePayrollService.getVariables(company.id),
  });

  const variablesPayroll: VariablePayroll[] = useMemo(() => {
    if (!variablesPayrollData?.variablesPayroll) {
      return [];
    }
    return variablesPayrollData.variablesPayroll;
  }, [variablesPayrollData]);

  const { data: expenseReportListData, refetch: refetchExpenseReportListData } =
    useQuery({
      enabled: isDisplayedExpenseReports,
      queryKey: [
        'ExpenseReportService.getExpenseReportsByDivisionsAndMonth',
        company.id,
      ],
      queryFn: () =>
        ExpenseReportService.getExpenseReportsByDivisionsAndMonth(company.id, {
          // @ts-ignore
          monthConcerned: dayjs(selectedDate)
            .set('date', 15)
            .format('DD/MM/YYYY'),
          divisionIds: divisionIds,
        }),
    });

  const { mutate: exportEndOfMonth, isLoading: isEndOfMonthExportLoading } =
    useMutation({
      mutationFn: (exportType: EndMonthExportType) =>
        EndOfMonthService.getEndOfMonthExport(
          company.id,
          accountantCompanyId ? accountantCompanyId : company.id,
          exportType,
          {
            // @ts-ignore
            monthConcerned: dayjs(selectedDate)
              .set('date', 15)
              .format('DD/MM/YYYY'),
            divisionIds: divisionIds,
          }
        ),
      onSuccess: (data, _exportType) => {
        if (_exportType === EndMonthExportType.SynchroOpenPaye) {
          showNotification({
            id: 'export-end-of-month-success',
            message: t('w.success'),
            color: 'green',
            icon: <IconCheck />,
          });
        } else {
          saveDownloadedfile(data);
        }
      },
      onError: (error) => {
        showNotification({
          id: 'export-end-of-month-error',
          title: t('w.error'),
          message:
            // @ts-ignore
            error.response.status === 400
              ? t('ms.endofmonth.error.exportcontroller.getExport.codeNotFound')
              : // @ts-ignore
              error.response.status === 404
              ? t(
                  'ms.endofmonth.error.exportcontroller.getExport.openpayeNotFound'
                )
              : handleErrorMessage(error, t),
          color: 'red',
          icon: <IconX />,
        });
      },
    });

  function refetchAllData() {
    refetchEndOfMonthValidationData();
    refetchVariablesPayrollData();
  }
  const prevSelectedDate = useRef(selectedDate);
  useEffect(() => {
    const debouncedRefetch = debounce(() => {
      if (selectedDate || !!divisionIds.length) {
        if (prevSelectedDate.current !== selectedDate) {
          setTriggeredBySelectedDate(true);
          prevSelectedDate.current = selectedDate;
        } else {
          setTriggeredBySelectedDate(false);
        }

        refetchAllData();
        if (isDisplayedExpenseReports) {
          refetchExpenseReportListData();
        }
      }
    }, 1000);

    debouncedRefetch();
    return () => {
      debouncedRefetch.cancel();
    };
  }, [selectedDate, divisionIds, isDisplayedExpenseReports]);

  const endOfMonthValidation: EndOfMonthValidationResponse[] = useMemo(() => {
    if (!endOfMonthValidationData) {
      return [];
    }
    return endOfMonthValidationData;
  }, [endOfMonthValidationData]);

  const expenseReportsList: ExpenseReportObject[] = useMemo(() => {
    const result: ExpenseReportObject[] = [];
    if (!expenseReportListData) {
      return [];
    } else {
      expenseReportListData.forEach((item) =>
        item.values.forEach((expenseReport) => result.push(expenseReport))
      );
      return result;
    }
  }, [expenseReportListData]);

  function convertVariablesPayrollToArray(
    variablesPayroll: any
  ): VariablePayrollInfoFront[] {
    let array: any[] = [];
    Object.keys(variablesPayroll).forEach((key) => {
      const element = variablesPayroll[key];
      let obj = {
        id: key,
        value: element.value,
        editable: element.editable,
      };
      array.push(obj);
    });
    return array;
  }

  const rows: EndOfMonthDataResponseFront[] = useMemo(() => {
    if (endOfMonthValidation || expenseReportsList) {
      const result: EndOfMonthDataResponseFront[] = [];
      endOfMonthValidation.forEach((item) => {
        item.data.forEach((data) => {
          const _expenseReports = isDisplayedExpenseReports
            ? expenseReportsList.filter(
                (expenseReport) => expenseReport.creator === data.employee
              )
            : [];
          result.push({
            ...data,
            expenseReports: _expenseReports,
            variablesPayrollArray: convertVariablesPayrollToArray(
              data.variablesPayroll
            ),
            endOfMonthId: item.id,
            division: divisions.find(
              (division) => division.id === data.employeeObject.divisionId
            ),
          });
        });
      });
      return result;
    } else {
      return [];
    }
  }, [
    endOfMonthValidation,
    expenseReportsList,
    isDisplayedExpenseReports,
    divisions,
  ]);

  const endOfMonthVariablesPayroll:
    | EndOfMonthValidationVariablePayroll[]
    | undefined = useMemo(() => {
    if (rows) {
      const result: EndOfMonthValidationVariablePayroll[] = [];
      rows.forEach((row) =>
        row.variablesPayrollArray.forEach((variable) =>
          result.push({ employee: row.employee, variable: variable })
        )
      );
      return result;
    }
  }, [rows]);

  const {
    mutate: validateEmployeesEndOfMonth,
    isLoading: validateEmployeesEndOfMonthLoading,
  } = useMutation({
    mutationFn: (variables: ValidateListEmployeesEndOfMonthQuery) =>
      EndOfMonthService.validateEmployeesEndOfMonth(company.id, variables),
    onSuccess: () => {
      showNotification({
        id: `validate-employees-end-of-month-successful`,
        title: t('w.success'),
        message: t('w.endOfMonthValidatedForConcernedEmployee'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetchAllData();
    },
    onError: (error) =>
      showNotification({
        id: `validate-employees-end-of-month-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: updateModuleParams } = useMutation({
    mutationFn: (variables: { apiUrl: string; payload: any }) =>
      ModuleWithParamsService.updateModuleParams(
        variables.apiUrl,
        company.id,
        variables.payload
      ),
    onSuccess: () => {
      showNotification({
        id: `success-update-module-params`,
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetchModules();
    },
    onError: (err) =>
      showNotification({
        id: `error-update-module-params`,
        title: t('w.error'),
        message: handleErrorMessage(err, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const {
    mutate: validateDivisionEndOfMonth,
    isLoading: isValidateDivisionEndOfMonthLoading,
  } = useMutation({
    mutationFn: (variables: string) =>
      EndOfMonthService.validateDivisionEndOfMonth(company.id, variables),
    onSuccess: (_data) => {
      showNotification({
        id: `validate-division-end-of-month-successful`,
        title: t('w.success'),
        message: t('w.endOfMonthValidated'),
        color: 'green',
        icon: <IconCheck />,
      });
      refetchAllData();
      if (isAccountantEndOfMonth) {
        queryClient.invalidateQueries({
          queryKey: [
            'CharteredAccountantService.accountantCustomersList',
            company.id,
            // @ts-ignore
            dayjs(selectedDate).format('MM-YYYY'),
          ],
        });
      }
    },
    onError: (error) =>
      showNotification({
        id: 'validate-division-end-of-month-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const {
    mutate: updateCustomerUsersPayslipStatusEndOfMonth,
    isLoading: updatePayslipStatusLoading,
  } = useMutation({
    mutationFn: (variables: EndMonthPrepareRequest[]) =>
      CharteredAccountantService.updateCustomerUsersPayslipStatusEndOfMonth(
        company.id,
        variables
      ),
    onSuccess: () => {
      refetchAllData();
      showNotification({
        id: 'update-payslip-status-success',
        title: t('w.success'),
        message: `${t('w.status')} ${t('w.updated').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: 'update-payslip-status-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'green',
        icon: <IconCheck />,
      }),
  });

  function handleUpdateUsersPayslipStatus(payslipStatus: Payroll) {
    const data: EndMonthPrepareRequest[] = selectedEmployees
      .map((employee) => ({
        endOfMonthValidationId: employee.endOfMonthId,
        employeeIds: selectedEmployees
          .filter(
            (_employee) => _employee.endOfMonthId === employee.endOfMonthId
          )
          .map((_employee) => ({
            userId: _employee.employee,
            status: payslipStatus,
          })),
      }))
      .filter(
        (
          value: EndMonthPrepareRequest,
          index: number,
          self: EndMonthPrepareRequest[]
        ) =>
          index ===
          self.findIndex(
            (item) =>
              item.endOfMonthValidationId === value.endOfMonthValidationId
          )
      );
    updateCustomerUsersPayslipStatusEndOfMonth(data);
  }

  function markPayslipAsDone(item: EndOfMonthDataResponseFront) {
    const data: EndMonthPrepareRequest[] = [
      {
        endOfMonthValidationId: item.endOfMonthId,
        employeeIds: [{ userId: item.employee, status: Payroll.DONE }],
      },
    ];
    updateCustomerUsersPayslipStatusEndOfMonth(data);
  }

  function handleValidateEmployeesEndOfMonth() {
    const employeesByDivisionToValidate: ValidateListEmployeesEndOfMonthQuery[] =
      selectedEmployees
        .map((employee) => ({
          endOfMonthId: employee.endOfMonthId,
          employeesIds: selectedEmployees
            .filter(
              (_employee) => _employee.endOfMonthId === employee.endOfMonthId
            )
            .map((item) => item.employee),
        }))
        .filter(
          (
            value: ValidateListEmployeesEndOfMonthQuery,
            index: number,
            self: ValidateListEmployeesEndOfMonthQuery[]
          ) =>
            index ===
            self.findIndex((item) => item.endOfMonthId === value.endOfMonthId)
        );

    employeesByDivisionToValidate.forEach((employeeToValidate) =>
      validateEmployeesEndOfMonth({
        endOfMonthId: employeeToValidate.endOfMonthId,
        employeesIds: employeeToValidate.employeesIds,
      })
    );
  }

  function getUnitsToClose() {
    const _selectedChoices = options.filter?.[0]?.selectedChoices
      ?.map((elt) => elt.value)
      ?.filter((_elt) => !!_elt);

    const selectedDivisions = !!_selectedChoices?.length
      ? divisions.filter((div) => _selectedChoices.includes(div.name))
      : divisions;

    const selectedDivisionsIds = selectedDivisions.map((div) => div.id);

    return endOfMonthValidation.filter((elt) =>
      selectedDivisionsIds.includes(elt.divisionId)
    );
  }

  const exportMenuItems = useMemo(() => {
    const exports: { label: string; icon: ReactNode; onClick: () => void }[] = [
      {
        label: 'XLSX',
        icon: <IconFileSpreadsheet />,
        onClick: () => exportEndOfMonth(EndMonthExportType.XLSX),
      },
    ];
    if (isExportQuadraModuleActive) {
      exports.push({
        label: 'Quadra',
        icon: <IconFileExport />,
        onClick: () => exportEndOfMonth(EndMonthExportType.Quadra),
      });
    }
    if (isExportSilaeModuleActive) {
      exports.push({
        label: 'Silae',
        icon: <IconFileExport />,
        onClick: () => exportEndOfMonth(EndMonthExportType.Silae),
      });
    }
    if (isExportDiapaieModuleActive) {
      exports.push({
        label: 'DIAPAIE',
        icon: <IconFileExport />,
        onClick: () => exportEndOfMonth(EndMonthExportType.DIAPAIE),
      });
    }
    if (isExportIsapayeModuleActive) {
      exports.push({
        label: 'ISAPAYE',
        icon: <IconFileExport />,
        onClick: () => exportEndOfMonth(EndMonthExportType.ISAPAYE),
      });
    }
    if (isSynchroOpenPayeModuleActive) {
      exports.push({
        label: t('openpaye.sendVariables'),
        icon: <IconFileExport />,
        onClick: () => exportEndOfMonth(EndMonthExportType.SynchroOpenPaye),
      });
    }
    if (isExportCegidExpertModuleActive) {
      exports.push({
        label: 'Cegid Expext',
        icon: <IconFileExport />,
        onClick: () => exportEndOfMonth(EndMonthExportType.ExportCegidExpert),
      });
    }
    if (isSilaeConnectModuleActive) {
      exports.push({
        label: 'Envoyer les variables sur Silae',
        icon: <IconFileExport />,
        onClick: () => {},
      });
    }
    return exports;
  }, [
    isExportQuadraModuleActive,
    isExportSilaeModuleActive,
    isExportDiapaieModuleActive,
    isExportIsapayeModuleActive,
    isSynchroOpenPayeModuleActive,
    isExportCegidExpertModuleActive,
    isSilaeConnectModuleActive,
  ]);

  function openConfigureVariablePayroll() {
    editVariablePayrollOpenedHandlers.open();
  }

  function activateVariablePayrollFeature() {
    if (variablePayrollModule?.description?.url) {
      let payload: any;
      const _params = variablePayrollModule.configuration
        .params as VariablePayrollParams;
      if (!!_params?.variablesPayroll?.length) {
        payload = {
          ..._params,
          active: true,
        };
      } else {
        payload = {
          active: true,
          variablesPayroll: [],
        };
      }
      updateModuleParams({
        apiUrl: variablePayrollModule.description.url,
        payload,
      });
    }
  }

  const rightCustomActions = useMemo(() => {
    const result = [
      <CustomMenu
        key={'end-of-month-export'}
        buttonLabel={t('w.export')}
        buttonVariant={'filled'}
        leftSection={<IconTableExport />}
        menuLabel={t('w.entityVia', t('w.endOfMonth'))}
        menuItems={exportMenuItems}
        buttonLoading={isEndOfMonthExportLoading}
        buttonDisabled={!divisionIds.length}
      />,
    ];

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigureFeatures,
          scope: ScopeEnum.ALL,
        },
        user,
        company.id
      )
    ) {
      return [
        <Button
          key={'variable-payroll-module'}
          variant="light"
          onClick={
            isVariablePayrollModuleActive
              ? openConfigureVariablePayroll
              : activateVariablePayrollFeature
          }
        >
          {isVariablePayrollModuleActive
            ? t('w.configureVariablePayroll')
            : t('w.activateVariablePayrollFeature')}
        </Button>,
      ].concat(result);
    }

    return result;
  }, [
    isVariablePayrollModuleActive,
    exportMenuItems,
    isEndOfMonthExportLoading,
    divisionIds,
  ]);

  function getDivisionName(divisionId: string | undefined): string | undefined {
    const findDivision = divisions.find(
      (division) => division.id === divisionId
    );
    if (findDivision) {
      return findDivision.name;
    }
  }

  function getVariableValue(
    employee: string,
    variablePayrollId: string
  ): string {
    const findVariablePayroll = endOfMonthVariablesPayroll?.find(
      (item) =>
        item.employee === employee && item.variable.id === variablePayrollId
    );
    if (!findVariablePayroll) {
      return '';
    }

    const varPayrollConfig = variablesPayroll.find(
      (vp) => vp.id === findVariablePayroll.variable.id
    );

    if (varPayrollConfig?.type !== VariablePayrollType.TEXT) {
      const str = findVariablePayroll.variable.value;
      if (str !== null && str !== undefined && str.trim() !== '') {
        const normalizedStr = str.replace(',', '.');
        const parsedNumber = parseFloat(normalizedStr);
        if (!isNaN(parsedNumber)) {
          return parsedNumber.toFixed(2);
        }
      }
    }

    return findVariablePayroll.variable.value;
  }

  const columns: DataTableColumn<EndOfMonthDataResponseFront>[] =
    useMemo(() => {
      const defaultColumns: DataTableColumn<EndOfMonthDataResponseFront>[] = [
        {
          accessor: 'employeeObject.fullname',
          title: t('w.employee'),
          sortable: true,
          ellipsis: true,
          render: ({ employeeObject }) => (
            <GoToUserButton
              userId={employeeObject.id}
              userFullname={employeeObject.fullname!}
            />
          ),
        },
        {
          accessor: 'employee',
          title: t('w.serialNumber'),
          sortable: true,
          ellipsis: true,
          render: ({ employeeObject }) => (
            <IdentifiantsCompanyBadge
              identifiantsCompany={employeeObject.identifiantsCompany}
            />
          ),
        },
        {
          accessor: 'division.name',
          title: company?.divisionLabel ? company.divisionLabel : t('w.unit'),
          sortable: true,
          ellipsis: true,
          render: ({ division }) =>
            !!division?.name ? <UnitBadge unit={division.name} /> : '',
        },
        {
          accessor: 'leaveRequests',
          title: t('w.leaveRequests'),
          sortable: true,
          ellipsis: true,
          render: ({ leaves, employee, employeeObject }) => (
            <Badge
              onClick={() =>
                handleOpenEmployeeLeaveRequestsModal(
                  leaves,
                  employee,
                  employeeObject.fullname!,
                  false
                )
              }
              color={
                leaves.every(
                  (leave: LeaveResponse) => leave.status.state === 'VALID'
                )
                  ? '#CEE9E6'
                  : '#E70D4F1A'
              }
              styles={{
                label: {
                  textTransform: 'none',
                  color: leaves.every(
                    (leave: LeaveResponse) => leave.status.state === 'VALID'
                  )
                    ? '#0A9182'
                    : '#E70D4F',
                },
                root: { cursor: leaves.length === 0 ? 'default' : 'pointer' },
              }}
            >
              {leaves.length === 0
                ? 'Pas de congé'
                : leaves.length === 1
                ? leaves.length + ' demande'
                : leaves.length + ' demandes'}
            </Badge>
          ),
        },
        {
          accessor: globalValidation ? 'payslipStatus' : 'valid',
          title: t('w.status'),
          sortable: true,
          ellipsis: true,
          render: ({
            leaves,
            employee,
            employeeObject,
            valid,
            payslipStatus,
          }) =>
            !globalValidation ? (
              displayUserStatusEndOfMonthBadge(
                leaves,
                employee,
                employeeObject,
                valid
              )
            ) : (
              <Badge
                leftSection={
                  payslipStatus === 'TODO' ? (
                    <IconUserExclamation color={'#E70D4F'} size={15} />
                  ) : (
                    <IconChecks color={'#0A9182'} size={15} />
                  )
                }
                color={payslipStatus === 'TODO' ? '#FDE6ED' : '#CEE9E6'}
                styles={{
                  label: {
                    textTransform: 'none',
                    color: payslipStatus === 'TODO' ? '#E70D4F' : '#0A9182',
                  },
                }}
              >
                {upperFirst(t(`w.${payslipStatus}`))}
              </Badge>
            ),
        },
      ];
      if (isDisplayedExpenseReports) {
        defaultColumns.push({
          accessor: 'expenseReports',
          title: t('w.expenseReports'),
          sortable: true,
          ellipsis: true,
          render: ({ expenseReports, employee }) =>
            expenseReports && (
              <Badge
                p={'sm'}
                variant={expenseReports.length > 0 ? 'dot' : 'filled'}
                color={
                  expenseReports.length === 0
                    ? '#CEE9E6'
                    : 'var(--mantine-color-hifivework-6)'
                }
                styles={{
                  label: {
                    textTransform: 'none',
                    color:
                      expenseReports.length === 0
                        ? '#0A9182'
                        : 'var(--mantine-color-hifivework-6)',
                  },
                  root: {
                    cursor: expenseReports.length === 0 ? 'default' : 'pointer',
                  },
                }}
                onClick={() => {
                  const row = rows.find((item) => item.employee === employee);
                  if (!!row) {
                    modals.open({
                      modalId: 'expense-report',
                      title: `${t('w.expenseReports')} - ${
                        row.employeeObject.fullname
                      } - ${
                        company?.divisionLabel
                          ? company.divisionLabel
                          : t('w.unit')
                      } : ${getDivisionName(row.employeeObject.divisionId)}`,
                      children: (
                        <EndOfMonthValidationExpenseReports
                          expenseReports={
                            row.expenseReports as ExpenseReportObject[]
                          }
                          userFullname={row.employeeObject.fullname!}
                          company={company}
                        />
                      ),
                      fullScreen: true,
                    });
                  }
                }}
              >
                {expenseReports.length === 0
                  ? 'Pas de note de frais'
                  : `${expenseReports.length} ${
                      expenseReports.length === 1 ? 'demande' : 'demandes'
                    }`}
              </Badge>
            ),
        });
      }
      if (isDailyWorkModuleActive && !!counterTypeIdOvertime) {
        const overtimeCounterType = counterTypes?.find(
          (item) =>
            item.counterType.active &&
            item.counterType.id === counterTypeIdOvertime
        );
        if (!!overtimeCounterType) {
          defaultColumns.push({
            accessor: counterTypeIdOvertime,
            title: `${getTranslatedKey(
              t,
              overtimeCounterType.counterType.key
            )} (${t(
              `counterType.rule.${overtimeCounterType.counterType.rule}`
            )})`,
            sortable: false,
            ellipsis: true,
            render: ({ employee }) => (
              <Group>
                <CustomTooltip
                  label={t(
                    'w.counterOvertimeIncrementDesc',
                    `${getTranslatedKey(
                      t,
                      overtimeCounterType.counterType.key
                    )} (${t(
                      `counterType.rule.${overtimeCounterType.counterType.rule}`
                    )})`,
                    getVariableValue(employee, counterTypeIdOvertime) || 0
                  )}
                >
                  <IconInfoCircle
                    size={18}
                    color={'var(--mantine-color-hifivework-5)'}
                  />
                </CustomTooltip>
                <Text fz={'sm'} fw={'bold'}>
                  {getVariableValue(employee, counterTypeIdOvertime)}
                </Text>
              </Group>
            ),
          });
        }
      }
      variablesPayroll.forEach((variablePayroll) => {
        defaultColumns.push({
          accessor: variablePayroll?.id ? variablePayroll.id : '',
          title: `${variablePayroll.label} (${t(
            `addVariablePayroll.type.${variablePayroll.type}`
          )})`,
          sortable: true,
          ellipsis: true,
          render: ({ endOfMonthId, employee }) =>
            variablePayroll.editable ? (
              variablePayroll.type === 'FILE' ? (
                <EditVariablePayrollFileInput
                  key={`${
                    variablePayroll.id
                  }-${employee}-${selectedDate?.toString()}`}
                  variablePayrollValue={getVariableValue(
                    employee,
                    variablePayroll?.id!
                  )}
                  disabled={disableEditEndMonthVar}
                  endOfMonthId={endOfMonthId}
                  employeeId={employee}
                  companyId={company.id}
                  refetchAllData={refetchAllData}
                  variablePayrollId={variablePayroll?.id as string}
                />
              ) : (
                <EditVariablePayrollTextInput
                  key={`${
                    variablePayroll.id
                  }-${employee}-${selectedDate?.toString()}`}
                  variablePayrollValue={getVariableValue(
                    employee,
                    variablePayroll?.id!
                  )}
                  disabled={disableEditEndMonthVar}
                  endOfMonthId={endOfMonthId}
                  employeeId={employee}
                  companyId={company.id}
                  refetchAllData={refetchAllData}
                  variablePayrollId={variablePayroll?.id as string}
                />
              )
            ) : (
              <Group>
                {getVariableValue(employee, variablePayroll?.id!) !== '' &&
                  variablePayroll.type === 'HOURS' && (
                    <CustomTooltip label={t('w.decimalHours')}>
                      <IconInfoCircle
                        size={18}
                        color={'var(--mantine-color-hifivework-5)'}
                      />
                    </CustomTooltip>
                  )}
                <Text fz={'sm'} fw={'bold'}>
                  {getVariableValue(employee, variablePayroll?.id!)}
                </Text>
              </Group>
            ),
        });
      });
      defaultColumns.push({
        accessor: 'comment',
        title: t('w.comment'),
        sortable: true,
        ellipsis: true,
        render: ({ comment, endOfMonthId, employee }) => (
          <EndOfMonthCommentTextInput
            key={`${endOfMonthId}-${employee}`}
            companyId={company.id}
            comment={comment!}
            endOfMonthId={endOfMonthId}
            employeeId={employee}
            refetchAllData={refetchAllData}
            disabled={disableEditEndMonthVar}
          />
        ),
      });

      return defaultColumns;
    }, [
      expenseReportModule?.active,
      expenseReportModuleParams?.method,
      endOfMonthVariablesPayroll,
      variablesPayroll,
      globalValidation,
      dailyWorkModule,
      counterTypes,
      rows,
    ]);

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'employeeObject.fullname',
      label: t('w.employee'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'division.name',
          label: t('w.unit'),
          choices: [],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  const isValidEndOfMonth = useMemo(() => {
    if (!Boolean(endOfMonthValidation)) {
      return false;
    } else {
      const _selectedChoices = options.filter?.[0]?.selectedChoices
        ?.map((elt) => elt.value)
        ?.filter((_elt) => !!_elt);

      const selectedDivisions = !!_selectedChoices?.length
        ? divisions.filter((div) => _selectedChoices.includes(div.name))
        : divisions;

      const selectedDivisionsIds = selectedDivisions.map((div) => div.id);

      const filteredEndMonthValidation = endOfMonthValidation.filter((elt) =>
        selectedDivisionsIds.includes(elt.divisionId)
      );

      return filteredEndMonthValidation.every((item) => item.valid);
    }
  }, [endOfMonthValidation, selectedDate, divisionIds, options]);

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  useEffect(() => {
    if (divisions?.length) {
      const _unitNames: FilterOptionChoice[] = [];
      divisions.forEach((item) => {
        _unitNames.push({
          value: item.name,
          label: item.name === NO_WORK_UNIT ? t('NO_WORK_UNIT') : item.name,
        });
      });
      const _options: Options = { ...options };
      if (!!_options?.filter?.[0].choices) {
        _options.filter[0].choices = _unitNames;
        _options.filter[0].selectedChoices = _unitNames.filter((choice) =>
          _options?.filter?.[0]?.selectedChoices?.some(
            (selected) => selected.value === choice.value
          )
        );
      }
      setOptions(_options);
    }
  }, [divisions]);

  function onFilterByDivision(divisionName: string) {
    const _unitNames: FilterOptionChoice[] = [
      { value: divisionName, label: divisionName },
    ];

    const _options: Options = { ...options };
    if (!!_options?.filter?.[0].choices) {
      _options.filter[0].selectedChoices = _unitNames;
      setOptions(_options);
    }
  }

  function getEmployeesToValidate() {
    if (Boolean(rows)) {
      const _rows = cloneDeep(rows);
      const _selectedChoices = options.filter?.[0]?.selectedChoices
        ?.map((elt) => elt.value)
        ?.filter((_elt) => !!_elt);

      if (!!_selectedChoices?.length) {
        return _rows.filter(
          (item) =>
            _selectedChoices.includes(item.division?.name!) && !item.valid
        );
      } else {
        return _rows.filter((row) => !row.valid);
      }
    } else {
      return [];
    }
  }

  function handleOpenEmployeeLeaveRequestsModal(
    leaves: LeaveResponse[],
    employee: string,
    employeeFullname: string,
    isStatusEndOfMonth: boolean
  ): void {
    const waitingLeavesCount = leaves.filter(
      (leave: LeaveResponse) => leave.status.state === 'WAITING'
    ).length;

    if (!isStatusEndOfMonth) {
      if (leaves.length > 0) {
        modals.open({
          id: `employee-${employee}-leave-requests-modal`,
          title: (
            <Title size={'h3'} component="p">{`${t(
              'w.leavesAndAbsences'
            )} - ${employeeFullname}`}</Title>
          ),
          fullScreen: true,
          children: (
            <EndOfMonthValidationLeaveRequestsTable
              leaves={leaves}
              companyId={company.id}
              endOfMonthValidation={endOfMonthValidation}
              refetchEndOfMonthValidationData={refetchEndOfMonthValidationData}
            />
          ),
        });
      }
    } else {
      if (waitingLeavesCount > 0) {
        modals.open({
          id: `employee-${employee}-leave-requests-modal`,
          title: (
            <Title size={'h3'} component="p">{`${t(
              'w.leavesAndAbsences'
            )} - ${employeeFullname}`}</Title>
          ),
          fullScreen: true,
          children: (
            <EndOfMonthValidationLeaveRequestsTable
              leaves={leaves}
              companyId={company.id}
              endOfMonthValidation={endOfMonthValidation}
              refetchEndOfMonthValidationData={refetchEndOfMonthValidationData}
            />
          ),
        });
      }
    }
  }

  function displayUserStatusEndOfMonthBadge(
    leaves: LeaveResponse[],
    employee: string,
    employeeObject: UserResponse,
    valid: boolean
  ) {
    const waitingLeavesCount = leaves.filter(
      (leave: LeaveResponse) => leave.status.state === LeaveStatusState.WAITING
    ).length;

    return (
      <Badge
        variant={!valid && waitingLeavesCount !== 0 ? 'dot' : 'filled'}
        onClick={() =>
          handleOpenEmployeeLeaveRequestsModal(
            leaves,
            employee,
            employeeObject.fullname!,
            true
          )
        }
        color={
          valid
            ? '#CEE9E6'
            : waitingLeavesCount !== 0
            ? 'hifivework.2'
            : '#FCF0E0'
        }
        styles={{
          label: {
            textTransform: 'none',
            color: valid
              ? '#0A9182'
              : waitingLeavesCount !== 0
              ? 'var(--mantine-color-hifivework-6)'
              : '#EDB464',
          },
          root: {
            cursor: valid || waitingLeavesCount === 0 ? 'default' : 'pointer',
          },
        }}
      >
        {valid
          ? t('w.endOfMonthValidated')
          : waitingLeavesCount > 0
          ? `${waitingLeavesCount} ${
              waitingLeavesCount === 1
                ? `${t('w.leave').slice(0, -1).toLowerCase()} ${t(
                    'w.toValidate'
                  )}`
                : `${t('w.leave').toLowerCase()} ${t('w.toValidate')}`
            }`
          : t('w.endOfMonthToValidate')}
      </Badge>
    );
  }

  const disableEditEndMonthVar = isAccountantEndOfMonth
    ? false
    : !Boolean(accountantsListData?.length)
    ? false
    : isValidEndOfMonth;

  function renderLeftCustomActions() {
    const actions: ReactNode[] = [];

    if (
      !hasPermission(
        {
          permission: BuiltInPermissions.ValidateEndOfMonth,
          scope: ScopeEnum.ALL,
        },
        user,
        company.id
      )
    ) {
      return actions;
    }

    const employeesToValidate = getEmployeesToValidate();
    let allPayslips: EndOfMonthDataResponseFront[] = [];
    let payslipsTodo: EndOfMonthDataResponseFront[] = [];
    if (Boolean(rows)) {
      const clonedRows = cloneDeep(rows);
      allPayslips = clonedRows;
      payslipsTodo = clonedRows.filter(
        (row) => row.payslipStatus === Payroll.TODO
      );
    }

    if (globalValidation) {
      actions.push(
        <SelectedEmployeesHoverCard selectedEmployees={selectedEmployees}>
          <Button
            leftSection={<IconChecks />}
            disabled={selectedEmployees.length === 0}
            onClick={() => handleUpdateUsersPayslipStatus(Payroll.DONE)}
            loading={updatePayslipStatusLoading}
          >
            {t('w.markAsDone')}
          </Button>
        </SelectedEmployeesHoverCard>
      );
      actions.push(
        <SelectedEmployeesHoverCard selectedEmployees={selectedEmployees}>
          <Button
            leftSection={<IconUserExclamation />}
            disabled={selectedEmployees.length === 0}
            onClick={() => handleUpdateUsersPayslipStatus(Payroll.TODO)}
            loading={updatePayslipStatusLoading}
          >
            {t('w.markAsTodo')}
          </Button>
        </SelectedEmployeesHoverCard>
      );
      addPayslipsStatusHoverCard(actions, payslipsTodo, allPayslips);
    } else {
      actions.push(
        <SelectedEmployeesHoverCard selectedEmployees={selectedEmployees}>
          <Button
            key={'validate-employees'}
            leftSection={<IconChecks />}
            disabled={
              selectedEmployees.length === 0 || employeesToValidate.length === 0
            }
            loading={validateEmployeesEndOfMonthLoading}
            onClick={handleValidateEmployeesEndOfMonth}
          >
            {`${t('w.validate')} ${t('w.employees').toLowerCase()}`}
          </Button>
        </SelectedEmployeesHoverCard>
      );
    }

    if (endOfMonthValidationData?.length) {
      addValidationStatusHoverCard(actions, payslipsTodo, allPayslips);
    }

    if (isValidEndOfMonth) {
      if (divisionIds.length && endOfMonthValidationData?.length) {
        actions.push(
          <Badge
            key={'monthClosed'}
            leftSection={<IconCheck color={'#0A9182'} />}
            size="xl"
            color={'#CEE9E6'}
            styles={{
              label: { textTransform: 'none', color: '#0A9182' },
            }}
          >
            {t('w.monthClosed')}
          </Badge>
        );
      }
    } else {
      if (employeesToValidate.length === 0) {
        addCloseMonthButton(actions);
      } else {
        addEmployeesToValidateBadge(actions, employeesToValidate);
      }
    }

    return actions;
  }

  function addEmployeesToValidateBadge(
    actions: ReactNode[],
    employeesToValidate: EndOfMonthDataResponseFront[]
  ) {
    if (employeesToValidate.length) {
      actions.push(
        <SelectedEmployeesHoverCard selectedEmployees={employeesToValidate}>
          <Badge
            key={'thereAreStillEmployeesToBeValidated'}
            leftSection={<IconInfoCircle color={'#E70D4F'} />}
            size="xl"
            color={'#E70D4F1A'}
            styles={{
              label: { textTransform: 'none', color: '#E70D4F' },
            }}
          >
            {t('w.thereAreStillEmployeesToBeValidated')}
          </Badge>
        </SelectedEmployeesHoverCard>
      );
    }
  }

  function addCloseMonthButton(actions: ReactNode[]) {
    actions.push(
      <UnitsToCloseHoverCard
        divisions={divisions}
        endOfMonthValidations={getUnitsToClose()}
      >
        <Button
          key={'closeMonth'}
          leftSection={<IconCalendarCheck />}
          onClick={() =>
            getUnitsToClose().forEach((endOfMonth) =>
              validateDivisionEndOfMonth(endOfMonth.id)
            )
          }
          loading={isValidateDivisionEndOfMonthLoading}
        >
          {t('w.closeMonth')}
        </Button>
      </UnitsToCloseHoverCard>
    );
  }

  function addValidationStatusHoverCard(
    actions: ReactNode[],
    payslipsTodo: EndOfMonthDataResponseFront[],
    allPayslips: EndOfMonthDataResponseFront[]
  ) {
    const result = endOfMonthValidationData
      .filter((item) => item.data.length)
      .map((item) => {
        const division = divisions?.find(
          (div) =>
            div.name !== NO_WORK_UNIT &&
            div.nbUsers &&
            div.id === item.divisionId
        );
        const validationString = item.validationDate
          ? t(
              'w.monthClosedUnitOn',
              division?.name,
              dayjs(item.validationDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
            )
          : t('w.monthNotClosedUnit', division?.name);

        const _payslipsTodo = payslipsTodo.filter(
          (payslip) => payslip.division?.id === division?.id
        );

        const _allPayslips = allPayslips.filter(
          (payslip) => payslip.division?.id === division?.id
        );

        const payslipString = t(
          'w.payslipsByDivision',
          _payslipsTodo.length,
          _allPayslips.length
        );

        return {
          divisionId: item.divisionId,
          divisionName: division?.name!,
          validationString,
          payslipString,
          isValid: !!item.validationDate,
        };
      });

    const validatedData = result.filter((elt) => elt.isValid);

    actions.push(
      <HoverCard shadow="md" withArrow>
        <HoverCard.Target>
          <Badge
            size="xl"
            styles={{
              label: {
                textTransform: 'none',
                color:
                  validatedData.length === result.length
                    ? '#0A9182'
                    : '#E70D4F',
              },
            }}
            color={
              validatedData.length === result.length ? '#CEE9E6' : '#FDE6ED'
            }
          >
            {t('w.monthClosedUnitsCount', validatedData.length, result.length)}
          </Badge>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <ScrollArea h={300}>
            <Stack gap="sm">
              {result.map((elt) => (
                <Tooltip label={t('w.workOnDivision')}>
                  <Badge
                    onClick={() => onFilterByDivision(elt.divisionName)}
                    variant="outline"
                    key={elt.divisionId}
                    styles={{
                      root: { cursor: 'pointer' },
                      label: {
                        textTransform: 'none',
                        color: elt.isValid ? '#0A9182' : '#E70D4F',
                      },
                    }}
                    color={elt.isValid ? '#CEE9E6' : '#FDE6ED'}
                  >
                    {elt.validationString} - {elt.payslipString}
                  </Badge>
                </Tooltip>
              ))}
            </Stack>
          </ScrollArea>
        </HoverCard.Dropdown>
      </HoverCard>
    );
  }

  function addPayslipsStatusHoverCard(
    actions: ReactNode[],
    payslipsTodo: EndOfMonthDataResponseFront[],
    allPayslips: EndOfMonthDataResponseFront[]
  ) {
    actions.push(
      <HoverCard shadow="md" withArrow>
        <HoverCard.Target>
          <Badge
            size="xl"
            styles={{
              label: {
                textTransform: 'none',
                color: !payslipsTodo.length ? '#0A9182' : '#E70D4F',
              },
            }}
            color={!payslipsTodo.length ? '#CEE9E6' : '#FDE6ED'}
          >
            {t('w.payslipsTodoCount', payslipsTodo.length, allPayslips.length)}
          </Badge>
        </HoverCard.Target>
        {!!payslipsTodo.length && (
          <HoverCard.Dropdown>
            <ScrollArea h={300}>
              <Stack gap="sm">
                {payslipsTodo.map((elt) => (
                  <Tooltip label={t('w.markAsDone')}>
                    <Badge
                      variant="outline"
                      key={elt.employee}
                      styles={{
                        root: { cursor: 'pointer' },
                        label: {
                          textTransform: 'none',
                          color:
                            elt.payslipStatus === Payroll.DONE
                              ? '#0A9182'
                              : '#E70D4F',
                        },
                      }}
                      onClick={() => markPayslipAsDone(elt)}
                      color={
                        elt.payslipStatus === Payroll.DONE
                          ? '#CEE9E6'
                          : '#FDE6ED'
                      }
                    >
                      {elt.employeeObject.fullname}
                    </Badge>
                  </Tooltip>
                ))}
              </Stack>
            </ScrollArea>
          </HoverCard.Dropdown>
        )}
      </HoverCard>
    );
  }

  return (
    <>
      <LoadingBadge
        loading={
          isEndOfMonthValidationDataFetching || isVariablesPayrollDataFetching
        }
      />
      <Table
        pinFirstColumn
        rows={rows}
        options={options}
        lang={lang}
        withTableBorder={false}
        columns={columns}
        fetching={
          isEndOfMonthValidationDataLoading ||
          isVariablesPayrollDataLoading ||
          triggeredBySelectedDate
        }
        onSelect={(employee) =>
          setSelectedEmployees(employee as EndOfMonthDataResponseFront[])
        }
        leftCustomActions={renderLeftCustomActions()}
        rightCustomActions={rightCustomActions}
        idAccessor={'employee'}
        height={'calc(100vh - 210px)'}
      />

      {variablePayrollModule && (
        <ModuleToEditModal
          opened={editVariablePayrollOpened}
          onClose={() => {
            refetchVariablesPayrollData();
            editVariablePayrollOpenedHandlers.close();
          }}
          moduleToEdit={variablePayrollModule}
          updateModuleParams={updateModuleParams}
          targetCompanyId={company.id}
          accountantCompanyId={
            accountantCompanyId ? accountantCompanyId : company.id
          }
        />
      )}
    </>
  );
}
