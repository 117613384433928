import { Box, Group, Menu, Text, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconAdjustments,
  IconArrowsLeftRight,
  IconChevronRight,
  IconFileInvoice,
  IconLogout,
  IconUserCircle,
} from '@tabler/icons-react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import CustomUserAvatar from '../../../components/CustomAvatar/CustomUserAvatar';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import ProfileChoiceModal from '../../../features/login/components/ProfileChoiceModal';
import usePaymentAccounts from '../../../hooks/usePaymentAccounts';
import { isWhiteLabel } from '../../../utils/isWhiteLabel';
import s from './UserMenu.module.sass';

export default function UserMenu() {
  const { user, logout, loginResponse } = useAuth();
  const { t } = useI18n();
  const [opened, handlers] = useDisclosure(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = user;
  const { paymentAccounts } = usePaymentAccounts();

  return (
    <>
      <Menu
        withArrow
        transitionProps={{
          transition: 'fade',
          duration: 150,
          timingFunction: 'ease',
        }}
        disabled={pathname.includes('accept/link')}
      >
        <Menu.Target>
          <Box className={s.box}>
            <UnstyledButton className={s.user}>
              <Group style={{ flexFlow: 'nowrap' }}>
                <CustomUserAvatar userId={id} />
                <div style={{ flex: 1, width: '100%', overflow: 'hidden' }}>
                  <Text fz="sm" fw={'bold'} className={s.text}>
                    {user.fullname}
                  </Text>
                  <Text c={'hifivework.3'} fz="xs" fw={'bold'}>
                    {user.company?.name}
                  </Text>
                </div>
                <IconChevronRight size={20} />
              </Group>
            </UnstyledButton>
          </Box>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            className={s.item}
            onClick={() => {
              navigate(`/profile/user/${id}`);
            }}
            leftSection={<IconAdjustments size={20} />}
          >
            {t('w.profileSettings')}
          </Menu.Item>
          <Menu.Item
            className={s.item}
            onClick={() => {
              navigate(`/account/user/${id}`);
            }}
            leftSection={<IconUserCircle size={20} />}
          >
            {t('w.accountSettings')}
          </Menu.Item>
          {Boolean(paymentAccounts?.length) && !isWhiteLabel() && (
            <Menu.Item
              className={s.item}
              onClick={() => navigate('/manage-payment-account')}
              leftSection={<IconFileInvoice size={20} />}
            >
              {t('w.billing')}
            </Menu.Item>
          )}
          <Menu.Item
            className={s.item}
            onClick={logout}
            leftSection={<IconLogout size={20} />}
          >
            {t('w.logout')}
          </Menu.Item>
          {loginResponse?.users?.length > 1 && (
            <>
              <Menu.Divider />
              <Menu.Item
                className={s.item}
                onClick={handlers.open}
                leftSection={<IconArrowsLeftRight size={20} />}
              >
                {t('w.changeAccount')}
              </Menu.Item>
            </>
          )}
        </Menu.Dropdown>
      </Menu>
      <ProfileChoiceModal
        open={opened}
        onClose={handlers.close}
        loginResponse={loginResponse}
      />
    </>
  );
}
