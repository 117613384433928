import { Stepper } from '@mantine/core';
import {
  type DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { IconFileImport, IconListCheck } from '@tabler/icons-react';
import { useState } from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import useResponsive from '../../../../hooks/useResponsive';
import type { CompanyResponse } from '../../../../types/api/response/company';
import type { DivisionResponse } from '../../../../types/api/response/division';
import {
  getPersistedObject,
  persistData,
} from '../../../../utils/localStorage';
import EndOfMonthPayslips from '../../../end-of-month/payslips/EndOfMonthPayslips';
import EndOfMonthValidationTable from '../../../end-of-month/validations/components/EndOfMonthValidationTable';

type Props = {
  selectedDate: DateValue | DatesRangeValue | Date[];
  divisions: DivisionResponse[];
  customerCompany: CompanyResponse;
  globalValidation: boolean;
  accountantCompanyId: string;
};

export default function AccountantCustomersEndOfMonthStepper({
  selectedDate,
  divisions,
  customerCompany,
  globalValidation,
  accountantCompanyId,
}: Props) {
  const { t } = useI18n();
  const { isDesktop } = useResponsive();
  const [active, setActive] = useState<number>(
    parseInt(getPersistedObject('customerEndOfMonthStepper'))
  );

  function handleStepClick(stepIndex: number): void {
    persistData('customerEndOfMonthStepper', stepIndex.toString());
    setActive(stepIndex);
  }

  return (
    <Stepper
      active={active}
      onStepClick={handleStepClick}
      // allowNextStepsSelect={globalValidation}
    >
      <Stepper.Step
        label={
          isDesktop
            ? globalValidation
              ? t('w.preparePayslips')
              : t('w.endOfMonthValidation')
            : undefined
        }
        icon={<IconListCheck />}
      >
        <EndOfMonthValidationTable
          selectedDate={selectedDate}
          divisions={divisions}
          company={customerCompany}
          globalValidation={globalValidation}
          accountantCompanyId={accountantCompanyId}
        />
      </Stepper.Step>
      <Stepper.Step
        label={
          isDesktop
            ? `${t('w.import')} ${t('w.payslips').toLowerCase()}`
            : undefined
        }
        description={
          isDesktop
            ? globalValidation
              ? ''
              : t('w.endOfMonthToValidate')
            : undefined
        }
        icon={<IconFileImport />}
      >
        <EndOfMonthPayslips
          accountantCompanyId={accountantCompanyId}
          customerCompanyId={customerCompany?.id}
          customerSelectedDate={selectedDate}
        />
      </Stepper.Step>
    </Stepper>
  );
}
