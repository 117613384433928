import { Group, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconAlertTriangle, IconX } from '@tabler/icons-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';

import InvitationsHistoryPayrollOfficerTable from '../../../components/InvitationsHistoryPayrollOfficerTable';
import Page, { PageLink } from '../../../components/Page/Page';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import useCompany from '../../../hooks/useCompany';
import CharteredAccountantService from '../../../services/CharteredAccountantService';
import CkModuleManagerServiceMS from '../../../services/CkModuleManagerServiceMS';
import PaymentAccountService from '../../../services/PaymentAccountService';
import { ViewModule } from '../../../types/api/payload/module';
import { ModuleName } from '../../../types/api/response/module';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../types/api/response/role';
import { hasPermission } from '../../../utils/authorization';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import FeatureSynchroOpenpayeParams from '../../company/features/components/SynchroOpenpaye/FeatureSynchroOpenpayeParams';
import AccountantCustomersTable from './component/AccountantCustomersTable';
import AddCustomerCompanyForm from './component/AddCustomerCompanyForm';
import ImportClientsForm from './component/ImportClientsForm';

export default function AccountantCustomerManagement() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const { data: modules } = useQuery({
    enabled: !!companyId,
    queryKey: ['CkModuleManagerServiceMS.list', companyId],
    queryFn: () =>
      CkModuleManagerServiceMS.list(companyId, ViewModule.companyFeature),
  });

  const openpayeModule = modules?.find(
    (module) => module.name === ModuleName.SynchroOpenPaye
  );
  const isOpenpayeModuleActive = openpayeModule?.active;
  const queryClient = useQueryClient();
  const {
    data: invitationAccountantHistoryData,
    isLoading: isInvitationAccountantHistoryLoading,
    refetch: refetchInvitationAccountantHistoryData,
    error,
  } = useQuery({
    queryKey: [
      'CharteredAccountantService.invitationAccountantHistory',
      companyId,
    ],
    queryFn: () =>
      CharteredAccountantService.invitationAccountantHistory(companyId),
    onError: (err: any) => {
      if (err.response.status !== 403) {
        showNotification({
          id: 'invitation-accountant-history-error',
          title: t('w.error'),
          message: handleErrorMessage(err, t),
          color: 'red',
          icon: <IconX />,
        });
      }
    },
  });

  const { data: companyPaymentAccount } = useQuery({
    queryKey: ['PaymentAccountService.getCompanyPaymentAccount', companyId],
    queryFn: () => PaymentAccountService.getCompanyPaymentAccount(companyId),
  });

  const isPaymentAccountActive =
    Boolean(companyPaymentAccount?.active) &&
    Boolean(
      companyPaymentAccount?.companies?.find(
        (paymentCompany) =>
          paymentCompany.id === companyId && paymentCompany.active
      )
    );
  const [activeLink, setActiveLink] = useState<string>(t('w.customers'));

  function getLinks() {
    const links: PageLink[] = [
      {
        label: t('w.customers'),
        onClick: () => {
          setActiveLink(t('w.customers'));
        },
        active: activeLink === t('w.customers'),
      },
    ];

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigurePayment,
          scope: ScopeEnum.ALL,
        },
        user,
        companyId
      )
    ) {
      links.push({
        active: false,
        label: t('w.bulkImportClients'),
        onClick: () =>
          isPaymentAccountActive
            ? modals.open({
                transitionProps: { transition: 'slide-down' },
                modalId: 'accountant-import-clients-modal',
                title: (
                  <Title size={'h3'} component="p">
                    {t('w.bulkImportClients')}
                  </Title>
                ),
                size: '90%',
                children: (
                  <ImportClientsForm
                    accountantCompanyId={companyId}
                    refresh={() =>
                      queryClient.invalidateQueries({
                        queryKey: [
                          'CharteredAccountantService.accountantCustomersList',
                          companyId,
                        ],
                      })
                    }
                    onClose={() =>
                      modals.close('accountant-import-clients-modal')
                    }
                  />
                ),
              })
            : showNotification({
                id: 'payment-account-not-active',
                title: t('w.warning'),
                message: t('w.yourCompanyBillingAccountIsNotActivated'),
                icon: <IconAlertTriangle />,
                color: 'orange',
              }),
        disabled: activeLink !== t('w.customers'),
      });
    }

    if (error?.response?.status !== 403) {
      links.push({
        label: t('w.history'),
        onClick: () => {
          setActiveLink(t('w.history'));
        },
        active: activeLink === t('w.history'),
      });
    }

    if (
      hasPermission(
        {
          permission: BuiltInPermissions.ConfigurePayment,
          scope: ScopeEnum.ALL,
        },
        user,
        companyId
      ) &&
      isOpenpayeModuleActive
    ) {
      links.push({
        label: t('openpaye.synchroDossiers'),
        active: false,
        onClick: () =>
          isPaymentAccountActive
            ? modals.open({
                transitionProps: { transition: 'slide-down' },
                modalId: 'add-customer-company',
                fullScreen: true,
                title: (
                  <Title size={'h3'} component="p">
                    {t('openpaye.synchroDossiers')}
                  </Title>
                ),
                children: (
                  <FeatureSynchroOpenpayeParams
                    module={openpayeModule}
                    companyId={companyId}
                    isAccountant
                  />
                ),
              })
            : showNotification({
                id: 'payment-account-not-active',
                title: t('w.warning'),
                message: t('w.yourCompanyBillingAccountIsNotActivated'),
                icon: <IconAlertTriangle />,
                color: 'orange',
              }),
      });
    }

    return links;
  }

  function renderContent() {
    switch (activeLink) {
      case t('w.customers'):
        return (
          <AccountantCustomersTable
            isPaymentAccountActive={isPaymentAccountActive}
          />
        );
      case t('w.history'):
        return (
          <InvitationsHistoryPayrollOfficerTable
            invitationAccountantHistoryData={invitationAccountantHistoryData}
            isInvitationAccountantHistoryLoading={
              isInvitationAccountantHistoryLoading
            }
            refetchInvitationAccountantHistoryData={
              refetchInvitationAccountantHistoryData
            }
          />
        );
      default:
        return null;
    }
  }

  return (
    <Page
      title={t('w.firmClients')}
      parent={{ label: activeLink }}
      links={getLinks()}
      add={
        hasPermission(
          {
            permission: BuiltInPermissions.ConfigurePayment,
            scope: ScopeEnum.ALL,
          },
          user,
          companyId
        )
          ? {
              label: `${t('w.add')} ${t('w.customers')
                .slice(0, -1)
                .toLowerCase()}`,
              onClick: () =>
                isPaymentAccountActive
                  ? modals.open({
                      modalId: 'add-customer-company',
                      fullScreen: true,
                      children: (
                        <AddCustomerCompanyForm linkAsAccountant={true} />
                      ),
                    })
                  : showNotification({
                      id: 'payment-account-not-active',
                      title: t('w.warning'),
                      message: t('w.yourCompanyBillingAccountIsNotActivated'),
                      icon: <IconAlertTriangle />,
                      color: 'orange',
                    }),
            }
          : undefined
      }
    >
      <Group grow preventGrowOverflow={false} wrap="nowrap">
        {renderContent()}
      </Group>
    </Page>
  );
}
