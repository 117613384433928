import React from 'react';

import VariablesPayrollTable from './components/variables-payroll-table/VariablesPayrollTable';

type Props = {
  targetCompanyId: string;
  accountantCompanyId: string;
};

export default function VariablesPayrollParams({
  targetCompanyId,
  accountantCompanyId,
}: Props) {
  return (
    <VariablesPayrollTable
      targetCompanyId={targetCompanyId}
      accountantCompanyId={accountantCompanyId}
    />
  );
}
