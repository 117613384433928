import { Accordion, Button, Group, Loader, Stack, Text } from '@mantine/core';
import type {
  DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { modals } from '@mantine/modals';
import {
  IconAlertTriangle,
  IconCheck,
  IconInfoCircle,
} from '@tabler/icons-react';
import React from 'react';

import { useI18n } from '../../../../../contexts/I18nProvider';
import type { PayslipsMonthTreatment } from '../../../../../types/types';
import { milliToFullDateString } from '../../../../../utils/format';

export default function PayslipsTreatmentAccordionItem({
  treatment,
  setSelectedDate,
}: {
  treatment: PayslipsMonthTreatment;
  setSelectedDate: React.Dispatch<
    React.SetStateAction<DateValue | DatesRangeValue | Date[]>
  >;
}) {
  const { t } = useI18n();

  function goToMonthClick() {
    let _date = new Date();
    _date.setFullYear(treatment.year, treatment.month - 1, 15);
    setSelectedDate(_date);
    modals.close(`${treatment.state.toLowerCase()}-treatments-payslips-modal`);
  }

  function displayInfoDetail(primaryText: string, secondaryText: string) {
    return (
      <Group gap={'xs'}>
        <Text fz={'sm'} fw={'bold'} c={'hifivework.3'}>
          {primaryText} :
        </Text>
        <Text fz={'sm'} fw={'bold'}>
          {secondaryText}
        </Text>
      </Group>
    );
  }

  return (
    <Accordion.Item value={treatment.id}>
      <Accordion.Control
        disabled={treatment.state === 'PROCESSING'}
        icon={
          !treatment.error?.errors?.length && treatment.state === 'SUCCESS' ? (
            <IconCheck color={'green'} />
          ) : treatment.state === 'ERROR' ? (
            <IconInfoCircle color={'red'} />
          ) : treatment.state === 'PROCESSING' ? (
            <Loader color="blue" size="sm" />
          ) : (
            <IconAlertTriangle color={'orange'} />
          )
        }
      >
        <Group grow>
          {treatment.state !== 'PROCESSING' ? (
            <Stack gap={'xs'}>
              {displayInfoDetail(
                t('w.importDate'),
                milliToFullDateString(treatment.updated)
              )}
              {displayInfoDetail(t('w.importedFile'), treatment.filename)}
              {treatment.state === 'SUCCESS' &&
                displayInfoDetail(
                  t('w.monthConcerned'),
                  `${treatment.month} / ${treatment.year}`
                )}
            </Stack>
          ) : (
            <Text fz={'sm'} fw={'bold'}>
              {`${t('w.treatments').slice(0, t('w.treatments').length - 1)} ${t(
                'w.inProgress'
              ).toLowerCase()}`}
            </Text>
          )}
          <Button variant="default" onClick={goToMonthClick}>
            {t('payslips.import.goToMonth')}
          </Button>
        </Group>
      </Accordion.Control>
      <Accordion.Panel>
        <Stack gap={'xs'}>
          {treatment.error?.errors?.map((error, index) => (
            <Text
              fz={'xs'}
              fw={'bold'}
              c={'dimmed'}
              key={`${error.key}-${index}`}
              styles={{
                root: {
                  cursor: treatment.state === 'SUCCESS' ? 'pointer' : 'default',
                },
              }}
              onClick={(event) => {
                goToMonthClick();
                event.stopPropagation();
              }}
            >
              Attention: {error.msg}
            </Text>
          ))}
        </Stack>
      </Accordion.Panel>
    </Accordion.Item>
  );
}
