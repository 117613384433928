import { Accordion } from '@mantine/core';
import type {
  DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import React from 'react';

import type {
  PayslipsMonthTreatment,
  PayslipsMonthTreatmentState,
} from '../../../../../types/types';
import PayslipsTreatmentAccordionItem from './PayslipsTreatmentAccordionItem';

type Props = {
  state: PayslipsMonthTreatmentState;
  pendingTreatment: PayslipsMonthTreatment[];
  successTreatment: PayslipsMonthTreatment[];
  errorTreatment: PayslipsMonthTreatment[];
  setSelectedDate: React.Dispatch<
    React.SetStateAction<DateValue | DatesRangeValue | Date[]>
  >;
};
export default function PayslipsTreatmentAccordion({
  state,
  pendingTreatment,
  successTreatment,
  errorTreatment,
  setSelectedDate,
}: Props) {
  return (
    <Accordion>
      {state === 'SUCCESS' &&
        successTreatment.map((treatment) => (
          <PayslipsTreatmentAccordionItem
            key={treatment.id}
            treatment={treatment}
            setSelectedDate={setSelectedDate}
          />
        ))}
      {state === 'ERROR' &&
        errorTreatment.map((treatment) => (
          <PayslipsTreatmentAccordionItem
            key={treatment.id}
            treatment={treatment}
            setSelectedDate={setSelectedDate}
          />
        ))}
      {state === 'PROCESSING' &&
        pendingTreatment.map((treatment) => (
          <PayslipsTreatmentAccordionItem
            key={treatment.id}
            treatment={treatment}
            setSelectedDate={setSelectedDate}
          />
        ))}
    </Accordion>
  );
}
