import {
  ActionIcon,
  Box,
  Card,
  Fieldset,
  Grid,
  Group,
  NumberInput,
  Select,
  SimpleGrid,
  Stepper,
  Text,
  Textarea,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { modals } from '@mantine/modals';
import {
  IconArrowsMaximize,
  IconBuildingBank,
  IconCalendarTime,
  IconCheck,
  IconClockHour5,
  IconDownload,
  IconFile,
  IconUser,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React, { useEffect, useState } from 'react';

import ConfidentialOverlay from '../../../../components/ConfidentialOverlay';
import CustomDropzone from '../../../../components/CustomDropzone/CustomDropzone';
import DailyworkTypeMode from '../../../../components/DailyworkTypeMode';
import FileViewer from '../../../../components/FileViewer/FileViewer';
import LabelText from '../../../../components/LabelText/LabelText';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import useResponsive from '../../../../hooks/useResponsive';
import useUserCompanyModules from '../../../../hooks/useUserCompanyModules';
import ProfileService from '../../../../services/ProfileService';
import type { CompanyGroup } from '../../../../types/api/payload/company';
import {
  CompanyResponse,
  CountryAreaEnum,
  CountryEnum,
} from '../../../../types/api/response/company';
import {
  type DailyWorkParams,
  ModuleName,
} from '../../../../types/api/response/module';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../../types/api/response/role';
import type {
  IdentifiantCompany,
  UserResponse,
} from '../../../../types/api/response/user';
import { hasPermission } from '../../../../utils/authorization';
import { typesContract } from '../../../../utils/contracts';
import { typesRegime } from '../../../../utils/regimes';
import CountriesSelect from '../../../sign-up/components/CountriesSelect';
import UserIdentifiantsCompany from '../../../user-profile/components/UserIdentifiantsCompany';
import UserIdentifiantsCompanyButton from '../../../user-profile/components/UserIdentifiantsCompanyButton';
import type { ContractInfo } from '../../../user-profile/settings/UserProfileSettings';
import { FranceAreaSelect } from '../../public-holidays-and-leaves/components/FranceAreaSelect';
import s from './AddEmployeeSteppers/AddEmployeeSteppers.module.sass';
import { AddEmployeeWorkContractFormValues } from './AddEmployeeWorkContractForm';
dayjs.extend(duration);

type Props = {
  form: ContractInfo | AddEmployeeWorkContractFormValues;
  userProfile: UserResponse;
  setSelectedCompanyGroup?: (data: string | null) => void;
  companyGroupSelect?: any;
  companyGroupList?: CompanyGroup[];
  company?: CompanyResponse;
};

function isEditMode(
  form: ContractInfo | AddEmployeeWorkContractFormValues
): form is AddEmployeeWorkContractFormValues {
  return Boolean((form as AddEmployeeWorkContractFormValues)?.values);
}

export default function ContractualStepper({
  form,
  userProfile,
  setSelectedCompanyGroup,
  companyGroupSelect,
  companyGroupList,
  company,
}: Props) {
  const { t } = useI18n();
  const { isDesktop } = useResponsive();
  const [activeStepper, setActiveStepper] = useState(0);
  const { getModule } = useUserCompanyModules(userProfile.id!);
  const { access_token, user } = useAuth();
  const [filePath, setFilePath] = useState<string | null>(null);
  const isModuleDailyWorkActiveForCompany = getModule(
    ModuleName.DailyWork
  )?.active;
  const isModuleDailyWorkActiveForAll = (
    getModule(ModuleName.DailyWork)?.configuration?.params as DailyWorkParams
  )?.activeForAll;
  const isActiveForUser = Boolean(
    userProfile.paidFeatures?.find((item) => item.active)
  );
  const fileName = t('w.workContract');
  const isOnboardingLoggedUserValidatedByUser =
    Boolean(userProfile?.onBoardingId) &&
    user.id === userProfile?.id &&
    userProfile?.onBoarding?.onBoardingEmployee?.validationDate !== null;
  const isNotMe = user.id !== userProfile?.id;
  const canReadContractualInfo = hasPermission(
    {
      permission: BuiltInPermissions.UserContractualInfoShow,
      scope: ScopeEnum.ALL,
    },
    user,
    userProfile?.companyId
  );
  const canEditContractualInfo = hasPermission(
    {
      permission: BuiltInPermissions.UserContractualInfoEdit,
      scope: ScopeEnum.ALL,
    },
    user,
    userProfile?.companyId
  );

  const showContractualInfoConfidential =
    !isOnboardingLoggedUserValidatedByUser &&
    isNotMe &&
    !canReadContractualInfo &&
    !canEditContractualInfo;

  const disabled =
    isOnboardingLoggedUserValidatedByUser || !canEditContractualInfo;

  useEffect(() => {
    openImage();
  }, [form]);

  function openImage() {
    const userId = isEditMode(form) ? form.values.userId : userProfile.id;
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';

    xhr.onload = function () {
      if (xhr.status === 200) {
        const newBlob = new Blob([this.response], {
          type: 'application/pdf',
        });
        const _filePath = window.URL.createObjectURL(newBlob);

        let link = document.createElement('a');
        link.href = _filePath;
        link.download = fileName;
        link.target = '_blank';
        setFilePath(_filePath);
      }
    };
    xhr.open('GET', ProfileService.getUserContract(userId), true);
    xhr.setRequestHeader('Authorization', 'Bearer ' + access_token);
    xhr.send();
  }

  function onMaximizeClick() {
    if (!filePath) {
      return;
    }

    let link = document.createElement('a');
    link.href = filePath;
    link.download = `${fileName}.pdf`;
    link.target = '_blank';

    modals.openConfirmModal({
      modalId: `document-${fileName}-attachment-modal`,
      fullScreen: true,
      size: 'xl',
      children: (
        <FileViewer
          id={`document-${fileName}`}
          srcUrl={filePath}
          title={`${fileName}.pdf`}
        />
      ),
      labels: { cancel: t('w.cancel'), confirm: t('w.download') },
      onConfirm: () => link.click(),
    });
  }

  function onDownloadClick() {
    if (!filePath) {
      return;
    }
    let link = document.createElement('a');
    link.href = filePath;
    link.download = `${fileName}.pdf`;
    link.target = '_blank';
    link.click();
  }

  function renderComponentRequest(_form: AddEmployeeWorkContractFormValues) {
    switch (activeStepper) {
      case 0:
        return (
          <SimpleGrid cols={{ base: 1, sm: 2 }}>
            {companyGroupList &&
              setSelectedCompanyGroup &&
              companyGroupList.filter(
                (item: CompanyGroup) => item.siret !== company?.siret
              ).length > 0 && (
                <Select
                  data={companyGroupSelect}
                  label={<LabelText text={t('w.companyGroup')} />}
                  placeholder={t('w.select')}
                  onChange={(_value) => setSelectedCompanyGroup(_value)}
                  disabled={disabled}
                />
              )}
            <TextInput
              label={<LabelText text={t('w.jobType')} />}
              placeholder={t('w.noValue')}
              {..._form.getInputProps('employment')}
              disabled={disabled}
            />
            <Select
              label={<LabelText text={t('w.typeOfContract')} />}
              data={typesContract(t)}
              placeholder={t('w.noValue')}
              {..._form.getInputProps('contractType')}
              disabled={disabled}
            />
            <TextInput
              label={<LabelText text={t('w.otherContractType')} />}
              placeholder={t('w.other')}
              {..._form.getInputProps('otherContractType')}
              disabled={_form.values.contractType !== 'OTHER' || disabled}
            />
            <Select
              label={<LabelText text={t('w.regimeType')} />}
              data={typesRegime(t)}
              placeholder={t('w.noValue')}
              {..._form.getInputProps('regime')}
              disabled={disabled}
            />
            <TextInput
              label={<LabelText text={t('w.regimeOther')} />}
              placeholder={t('w.other')}
              {..._form.getInputProps('otherRegime')}
              disabled={_form.values.regime !== 'OTHER' || disabled}
            />
            <TextInput
              label={<LabelText text={t('w.regimeLevel')} />}
              placeholder={t('w.regimeLevel')}
              {..._form.getInputProps('regimeLevel')}
              disabled={disabled}
            />
            <TextInput
              label={<LabelText text={t('w.professionalQualification')} />}
              placeholder={t('w.noValue')}
              {..._form.getInputProps('professionalQualifications')}
              disabled={disabled}
            />
            <Group grow>
              <CountriesSelect
                value={_form.values.countryCode!}
                onChange={(value) =>
                  _form.setFieldValue('countryCode', value as CountryEnum)
                }
                label={t('w.countryOfContract')}
                disabled={disabled}
              />
              {_form.values.countryCode === CountryEnum.FR && (
                <FranceAreaSelect
                  onChange={(value) =>
                    _form.setFieldValue('countryArea', value as CountryAreaEnum)
                  }
                  value={_form.values.countryArea}
                  disabled={disabled}
                />
              )}
            </Group>
            <Textarea
              resize={'vertical'}
              placeholder={t('w.noValue')}
              label={<LabelText text={t('w.comment')} />}
              {..._form.getInputProps('comment')}
              disabled={disabled}
            />
          </SimpleGrid>
        );
      case 1:
        return (
          <SimpleGrid cols={{ base: 1, sm: 2 }}>
            <DateInput
              label={<LabelText text={t('w.dateOfAuthorizationToHire')} />}
              valueFormat={'D MMMM YYYY'}
              placeholder={t('w.noValue')}
              clearable
              {..._form.getInputProps('employmentAuthorizationDate')}
              disabled={disabled}
            />
            <DateInput
              label={<LabelText text={t('w.dateOfEntry')} />}
              valueFormat={'D MMMM YYYY'}
              placeholder={t('w.noValue')}
              clearable
              {..._form.getInputProps('entryDate')}
              disabled={disabled}
            />
            <DateInput
              label={<LabelText text={t('w.dateAuthorizationForDismissal')} />}
              valueFormat={'D MMMM YYYY'}
              placeholder={t('w.noValue')}
              clearable
              {..._form.getInputProps('layoffAuthorizationDate')}
              disabled={_form.values.exitDate != null || disabled}
            />
            <DateInput
              label={<LabelText text={t('w.dateOfExit')} />}
              valueFormat={'D MMMM YYYY'}
              placeholder={t('w.noValue')}
              clearable
              {..._form.getInputProps('exitDate')}
              disabled={disabled}
            />
            <DateInput
              label={
                <LabelText
                  text={t('contractualInfo.trialPeriodEndDate.label')}
                />
              }
              valueFormat={'D MMMM YYYY'}
              placeholder={t('w.noValue')}
              clearable
              {..._form.getInputProps('trialPeriodEndDate')}
              disabled={disabled || !_form.values.entryDate}
              minDate={dayjs(_form.values.entryDate).toDate()}
            />
            {_form.values.entryDate && _form.values.trialPeriodEndDate && (
              <Fieldset
                p={0}
                variant="unstyled"
                legend={t('contractualInfo.trialPeriodEndDate.calculatedDate')}
              >
                <Text size="sm">
                  {t(
                    'contractualInfo.trialPeriodEndDate.nbMonths',
                    dayjs(_form.values.trialPeriodEndDate).diff(
                      dayjs(_form.values.entryDate),
                      'month'
                    )
                  )}
                </Text>
              </Fieldset>
            )}
          </SimpleGrid>
        );
      case 2:
        return (
          <NumberInput
            min={0}
            label={<LabelText text={t('w.grossMonthlyRemuneration')} />}
            {..._form.getInputProps('grossPay')}
            disabled={disabled}
          />
        );
      case 3:
        return (
          <SimpleGrid cols={{ base: 1, sm: 2 }}>
            <NumberInput
              min={0}
              label={<LabelText text={t('w.weeklyHours')} />}
              placeholder={
                !_form.values.weeklyHours
                  ? _form.values.monthlyHours
                    ? ((_form.values.monthlyHours * 12) / 52).toFixed(2)
                    : _form.values.yearlyHours
                    ? (_form.values.yearlyHours / 45.4).toFixed(2)
                    : undefined
                  : undefined
              }
              {..._form.getInputProps('weeklyHours')}
              disabled={
                !!_form.values.monthlyHours ||
                !!_form.values.yearlyHours ||
                disabled
              }
            />
            <NumberInput
              min={0}
              label={<LabelText text={t('w.monthlyHours')} />}
              {..._form.getInputProps('monthlyHours')}
              placeholder={
                !_form.values.monthlyHours
                  ? _form.values.weeklyHours
                    ? ((_form.values.weeklyHours * 52) / 12).toFixed(2)
                    : _form.values.yearlyHours
                    ? (((_form.values.yearlyHours / 45.4) * 52) / 12).toFixed(2)
                    : undefined
                  : undefined
              }
              disabled={
                !!_form.values.weeklyHours ||
                !!_form.values.yearlyHours ||
                disabled
              }
            />
            <NumberInput
              min={0}
              label={<LabelText text={t('w.yearlyHours')} />}
              {..._form.getInputProps('yearlyHours')}
              placeholder={
                !_form.values.yearlyHours
                  ? _form.values.weeklyHours
                    ? (_form.values.weeklyHours * 45.4).toFixed(2)
                    : _form.values.monthlyHours
                    ? (((_form.values.monthlyHours * 12) / 52) * 45.4).toFixed(
                        2
                      )
                    : undefined
                  : undefined
              }
              disabled={
                !!_form.values.monthlyHours ||
                !!_form.values.weeklyHours ||
                disabled
              }
            />
          </SimpleGrid>
        );
      case 4:
        return (
          <Fieldset legend={t('w.workContract')}>
            <Group grow preventGrowOverflow={false} wrap="nowrap">
              <Box>
                <Card w="fit-content">
                  {!!filePath && (
                    <FileViewer
                      id={`document-${fileName}`}
                      srcUrl={filePath}
                      title={`${fileName}.pdf`}
                      size="md"
                    />
                  )}
                  <Group mt="xs" align="center" justify="center" gap="xs">
                    <Tooltip label={t('w.download')}>
                      <ActionIcon
                        variant="light"
                        size="sm"
                        onClick={onDownloadClick}
                      >
                        <IconDownload
                          color="var(--mantine-color-hifivework-5)"
                          stroke={1.5}
                        />
                      </ActionIcon>
                    </Tooltip>
                    <Tooltip label={t('w.maximize')} onClick={onMaximizeClick}>
                      <ActionIcon variant="light" size="sm">
                        <IconArrowsMaximize
                          color="var(--mantine-color-hifivework-5)"
                          stroke={1.5}
                        />
                      </ActionIcon>
                    </Tooltip>
                  </Group>
                </Card>
              </Box>
              <CustomDropzone
                onDrop={(files) => _form.setFieldValue('file', files)}
                files={_form.values.file}
                mimeType={'pdf'}
                resetFile={() => _form.setFieldValue('file', [])}
                disabled={disabled}
              />
            </Group>
          </Fieldset>
        );
      case 5:
        return (
          <Grid grow>
            <Grid.Col span={12}>
              <TextInput
                label={<LabelText text={t('w.nameOnPayslip')} />}
                placeholder={t('w.noValue')}
                {..._form.getInputProps('payslipName')}
                disabled={disabled}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              {_form?.values?.identifiantsCompany &&
                _form.values.identifiantsCompany.length > 0 &&
                _form.values.identifiantsCompany.map(
                  (item: IdentifiantCompany, index: number) => (
                    <UserIdentifiantsCompany
                      key={index}
                      index={index}
                      form={_form}
                    />
                  )
                )}
            </Grid.Col>
            <Grid.Col>
              <UserIdentifiantsCompanyButton
                onClick={() =>
                  _form.insertListItem('identifiantsCompany', {
                    matricule: '',
                    codeCompany: '',
                  })
                }
              />
            </Grid.Col>
          </Grid>
        );
      case 6:
        return (
          <Fieldset legend={t('w.timeManagement')}>
            {!disabled && (
              <DailyworkTypeMode
                t={t}
                id={userProfile.id}
                companyId={userProfile.companyId!}
                fixedPriceManager={userProfile.fixedPriceManager!}
                paidFeatures={userProfile.paidFeatures!}
              />
            )}
          </Fieldset>
        );
      default:
        return null;
    }
  }

  return (
    <Group grow preventGrowOverflow={false} wrap="nowrap" align="start">
      <Stepper
        size="sm"
        className={isDesktop ? s.stepper : undefined}
        active={activeStepper}
        orientation={'vertical'}
        onStepClick={setActiveStepper}
      >
        <Stepper.Step
          icon={<IconUser size="1.1rem" />}
          label={isDesktop ? 'Informations' : ''}
          description={isDesktop ? 'Principales' : ''}
          completedIcon={
            isEditMode(form) ? (
              <IconCheck size={'1.1rem'} />
            ) : (
              <IconUser size="1.1rem" />
            )
          }
        />
        <Stepper.Step
          icon={<IconCalendarTime size="1.1rem" />}
          label={isDesktop ? 'Dates' : ''}
          description={isDesktop ? 'Embauche et sortie' : ''}
          completedIcon={
            isEditMode(form) ? (
              <IconCheck size={'1.1rem'} />
            ) : (
              <IconCalendarTime size="1.1rem" />
            )
          }
        />
        <Stepper.Step
          icon={<IconBuildingBank size={'1.1rem'} />}
          label={isDesktop ? t('w.remuneration') : ''}
          description={isDesktop ? 'Salaire' : ''}
          completedIcon={
            isEditMode(form) ? (
              <IconCheck size={'1.1rem'} />
            ) : (
              <IconBuildingBank size="1.1rem" />
            )
          }
        />
        <Stepper.Step
          icon={<IconClockHour5 size={'1.1rem'} />}
          label={isDesktop ? 'Horaires' : ''}
          description={isDesktop ? 'Temps de travail' : ''}
          completedIcon={
            isEditMode(form) ? (
              <IconCheck size={'1.1rem'} />
            ) : (
              <IconClockHour5 size="1.1rem" />
            )
          }
        />
        <Stepper.Step
          icon={<IconFile size="1.1rem" />}
          label={isDesktop ? 'Contrat de travail' : ''}
          description={isDesktop ? 'Pièce jointe' : ''}
          completedIcon={
            isEditMode(form) ? (
              <IconCheck size={'1.1rem'} />
            ) : (
              <IconFile size="1.1rem" />
            )
          }
        />
        <Stepper.Step
          icon={<IconFile size="1.1rem" />}
          label={isDesktop ? 'Informations' : ''}
          description={isDesktop ? 'Paie' : ''}
          completedIcon={
            isEditMode(form) ? (
              <IconCheck size={'1.1rem'} />
            ) : (
              <IconFile size="1.1rem" />
            )
          }
        />
        {isModuleDailyWorkActiveForCompany &&
          hasPermission(
            [
              {
                permission: BuiltInPermissions.ValidateLeavesAbsences,
                scope: ScopeEnum.ALL,
              },
              {
                permission: BuiltInPermissions.AuthorizedUpsertDailyWork,
                scope: ScopeEnum.ALL,
              },
              {
                permission: BuiltInPermissions.AuthorizedReadDailyWork,
                scope: ScopeEnum.ALL,
              },
            ],
            user,
            userProfile.companyId
          ) &&
          (isModuleDailyWorkActiveForAll || isActiveForUser) && (
            <Stepper.Step
              icon={<IconClockHour5 size={'1.1rem'} />}
              label={isDesktop ? 'Gestion' : ''}
              description={isDesktop ? 'Des temps' : ''}
              completedIcon={
                isEditMode(form) ? (
                  <IconCheck size={'1.1rem'} />
                ) : (
                  <IconClockHour5 size="1.1rem" />
                )
              }
            />
          )}
      </Stepper>

      <Box pos="relative">
        {showContractualInfoConfidential && <ConfidentialOverlay />}
        {renderComponentRequest(form as AddEmployeeWorkContractFormValues)}
      </Box>
    </Group>
  );
}
