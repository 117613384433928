import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import {
  ActionIcon,
  Button,
  Flex,
  Group,
  Text,
  Title,
  Tooltip,
  UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconEdit,
  IconFolders,
  IconHistory,
  IconRefresh,
  IconTableDown,
  IconUserHexagon,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ActiveInactiveBadge from '../../../../components/badges/ActiveInactiveBadge';
import CustomModal from '../../../../components/CustomModal';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import { useModule } from '../../../../contexts/ModuleProvider';
import useCompany from '../../../../hooks/useCompany';
import CharteredAccountantService from '../../../../services/CharteredAccountantService';
import CompanyAccountantService from '../../../../services/CompanyAccountantService';
import CompanyService from '../../../../services/CompanyService';
import type {
  CharteredAccountantView,
  CompanyAccountantInfoViewClient,
} from '../../../../types/api/response/accountant';
import type { CompanyResponse } from '../../../../types/api/response/company';
import {
  ModuleName,
  SynchroOpenpayeParams,
} from '../../../../types/api/response/module';
import { DocumentPropertySpace } from '../../../../types/types';
import { milliToFullDateString } from '../../../../utils/format';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../utils/optionsPreferences';
import saveDownloadedfile from '../../../../utils/saveDownloadedFile';
import LastSynchroOpenpaye from '../../../company/features/components/SynchroOpenpaye/components/LastSynchroOpenpaye';
import DocumentsTable from '../../../documents/components/DocumentsTable';
import CompanyInfoWrapper from '../../components/CompanyInfoWrapper';
import AccountantConfigModal from './AccountantConfigModal';
import AssignGeneralManagersForm from './AssignGeneralManagersForm';
import ChooseDossierToSyncToExistingClient from './ChooseDossierToSyncToExistingClient';
import CustomerEditForm from './CustomerEditForm';

const componentName = 'AccountantCustomersTable';

type Props = {
  isPaymentAccountActive: boolean;
};

export default function AccountantCustomersTable({
  isPaymentAccountActive,
}: Props) {
  const { t, lang } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const navigate = useNavigate();
  const { getModule } = useModule();
  const openpayeModule = getModule(ModuleName.SynchroOpenPaye);
  const openpayeModuleParams = openpayeModule?.configuration
    .params as SynchroOpenpayeParams;
  const { data: accountantEmployeesData } = useQuery({
    queryKey: ['CompanyService.listEmployees', companyId],
    queryFn: () =>
      CompanyService.listEmployees(companyId, ['fullname', 'mail', 'valid']),
  });

  const [
    accountantConfigModalOpened,
    {
      open: handleAccountantConfigModalOpen,
      close: handleAccountantConfigClose,
    },
  ] = useDisclosure(false);
  const [accountantsToConfigure, setAccountantsToConfigure] =
    useState<CompanyAccountantInfoViewClient | null>(null);
  const [customerCompany, setCustomerCompany] =
    useState<CompanyResponse | null>(null);
  const { mutate: exportClients, isLoading: isExportLoading } = useMutation({
    mutationFn: (payload: { selectValid: boolean; selectInvalid: boolean }) =>
      CompanyAccountantService.exportClients(
        companyId,
        payload.selectValid,
        payload.selectInvalid
      ),
    onSuccess: (data) => {
      saveDownloadedfile(data);
    },
    onError: (error) =>
      showNotification({
        id: 'export-employees-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconCheck />,
        color: 'red',
      }),
  });

  function onExportClick() {
    let selectValid = !!options?.filter
      ?.find((item) => item.accessor === 'active')
      ?.selectedChoices?.find((item) => item.label === t('w.active'));

    let selectInvalid = !!options?.filter
      ?.find((item) => item.accessor === 'active')
      ?.selectedChoices?.find((item) => item.label === t('w.inactive'));

    if (!selectValid && !selectInvalid) {
      selectValid = true;
      selectInvalid = true;
    }

    exportClients({ selectValid, selectInvalid });
  }

  const {
    data: accountantCustomersData,
    isLoading: isAccountantCustomersDataLoading,
    refetch: refetchAccountantCustomersData,
  } = useQuery({
    queryKey: ['CharteredAccountantService.accountantCustomersList', companyId],
    queryFn: () =>
      CharteredAccountantService.accountantCustomersList(companyId),
  });

  const accountantEmployees = useMemo(() => {
    if (!accountantEmployeesData) {
      return [];
    }
    return accountantEmployeesData
      .filter((item) => item.valid)
      .map((employee) => {
        return {
          value: employee.id,
          label: employee.fullname as string,
        };
      });
  }, [accountantEmployeesData]);

  const rows: CompanyAccountantInfoViewClient[] = useMemo(() => {
    if (!accountantCustomersData) {
      return [];
    }

    if (accountantsToConfigure?.clientCompanyId) {
      const _updatedAccountantsToConfigure = accountantCustomersData.find(
        (item) =>
          item.clientCompanyId === accountantsToConfigure.clientCompanyId
      );

      if (_updatedAccountantsToConfigure) {
        setAccountantsToConfigure(_updatedAccountantsToConfigure);
      }
    }

    return accountantCustomersData;
  }, [accountantCustomersData]);

  const { mutate: updateCustomerCompany } = useMutation({
    mutationFn: (variables: {
      accountant: CharteredAccountantView;
      active: boolean;
      clientCompanyId: string;
    }) =>
      CharteredAccountantService.updateCustomerCompanyInformation(
        companyId,
        variables
      ),
    onSuccess: () => {
      refetchAccountantCustomersData();
      showNotification({
        id: 'update-customer-company-status-successful',
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });
      modals.closeAll();
    },
    onError: (error) =>
      showNotification({
        id: 'update-customer-company-status-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function onOpenOpenpayeDossierToSyncClick(customerCompany: CompanyResponse) {
    modals.open({
      transitionProps: { transition: 'slide-down' },
      modalId: `customer-${customerCompany.id}-edit-modal`,
      title: (
        <Title size={'h3'} component="p">
          {t('openpaye.chooseDossierToSync', customerCompany.name)}
        </Title>
      ),
      size: '90%',
      children: (
        <ChooseDossierToSyncToExistingClient
          customerCompany={customerCompany}
          accountantCompanyId={companyId}
          openpayeModuleParams={openpayeModuleParams}
        />
      ),
    });
  }

  const columns: DataTableColumn<CompanyAccountantInfoViewClient>[] = [
    {
      accessor: 'customerCompany.name',
      title: t('w.client'),
      sortable: true,
      ellipsis: true,
      render: ({ customerCompany, clientCompanyId }) => (
        <Flex direction={'column'}>
          <Tooltip
            label={`${t('w.configure')} ${t(
              'w.customerCompany'
            ).toLowerCase()}`}
          >
            <UnstyledButton
              onClick={() => {
                navigate(`/company/${clientCompanyId}/home`);
              }}
            >
              <CompanyInfoWrapper
                companyId={customerCompany.id}
                companyName={customerCompany.name}
              />
            </UnstyledButton>
          </Tooltip>
          <Text c="dimmed" fz="xs">
            {customerCompany?.address?.address1}{' '}
            {customerCompany?.address?.address2 &&
              ` - ${customerCompany.address.address2}`}{' '}
            {customerCompany?.address?.zipCode} {customerCompany?.address?.city}
          </Text>
          <Text c="dimmed" fz="xs">
            {customerCompany.mail}
          </Text>
          {customerCompany.phone && (
            <Text c="dimmed" fz="xs">
              {customerCompany.phone}
            </Text>
          )}
        </Flex>
      ),
    },
    {
      accessor: 'active',
      title: t('w.status'),
      sortable: true,
      ellipsis: true,
      render: ({ active }) => <ActiveInactiveBadge value={active} />,
    },
    {
      accessor: 'payrollManagersWhoManageClientCompany',
      title: t('w.payrollManagersWhoManageClientCompany'),
      sortable: true,
      ellipsis: true,
      render: (row) => (
        <AssignGeneralManagersForm
          accountantsToConfigure={row}
          accountantEmployees={accountantEmployees}
          refetchAccountantCustomersData={refetchAccountantCustomersData}
          withTitle={false}
        />
      ),
    },
    {
      accessor: 'accountant',
      title: t('w.infoAccountantShowToClient'),
      sortable: false,
      ellipsis: true,
      render: ({ accountant }) => (
        <Flex direction={'column'}>
          <Text fz="xs" fw="bold">
            {accountant.name} - {accountant.address}
          </Text>
          <Text c="dimmed" fz="xs">
            {accountant.mail}
          </Text>
          {accountant.phone && (
            <Text c="dimmed" fz="xs">
              {accountant.phone}
            </Text>
          )}
        </Flex>
      ),
    },
    {
      accessor: 'customerCompany.created',
      title: t('w.created'),
      sortable: true,
      ellipsis: true,
      render: ({ customerCompany }) =>
        customerCompany.created
          ? milliToFullDateString(customerCompany.created)
          : '',
    },
    {
      accessor: 'customerCompany.updated',
      title: t('w.updated'),
      sortable: true,
      ellipsis: true,
      render: ({ customerCompany }) =>
        customerCompany.updated
          ? milliToFullDateString(customerCompany.updated)
          : '',
    },
    {
      accessor: 'actions',
      title: t('w.actions'),
      textAlign: 'right',
      width: '0%',
      sortable: false,
      render: (row) => (
        <Group gap={6} justify="end" wrap={'nowrap'}>
          {openpayeModule?.active &&
            !!openpayeModuleParams?.dossiers?.length && (
              <>
                <Tooltip label={`${t('w.synchronize')} OpenPaye`}>
                  <ActionIcon
                    color="blue"
                    variant="subtle"
                    onClick={() =>
                      onOpenOpenpayeDossierToSyncClick(row.customerCompany)
                    }
                  >
                    <IconRefresh />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label={`${t('module.lastSynchro')} OpenPaye`}>
                  <ActionIcon
                    color="blue"
                    onClick={() => {
                      modals.open({
                        transitionProps: { transition: 'slide-down' },
                        modalId: 'last-syncrho',
                        title: (
                          <Title size={'h3'} component="p">
                            {row.customerCompany.name}
                          </Title>
                        ),
                        fullScreen: true,
                        children: (
                          <LastSynchroOpenpaye
                            companyId={row.clientCompanyId}
                          />
                        ),
                      });
                    }}
                    variant="subtle"
                  >
                    <IconHistory />
                  </ActionIcon>
                </Tooltip>
              </>
            )}
          <Tooltip
            label={`${t('w.configure')} ${t(
              'w.payrollOfficers'
            ).toLowerCase()}`}
          >
            <ActionIcon
              color="blue"
              onClick={() => {
                handleAccountantConfigModalOpen();
                setAccountantsToConfigure(row);
              }}
              variant="subtle"
            >
              <IconUserHexagon />
            </ActionIcon>
          </Tooltip>
          <Tooltip label={t('w.sharedDocuments')}>
            <ActionIcon
              color="blue"
              variant="subtle"
              onClick={() => setCustomerCompany(row.customerCompany)}
            >
              <IconFolders />
            </ActionIcon>
          </Tooltip>
          <Tooltip label={t('w.edit')}>
            <ActionIcon
              variant="subtle"
              color="blue"
              onClick={() => openEditModal(row)}
            >
              <IconEdit />
            </ActionIcon>
          </Tooltip>
        </Group>
      ),
    },
  ];

  function openEditModal(accountants: CompanyAccountantInfoViewClient) {
    modals.open({
      transitionProps: { transition: 'slide-down' },
      modalId: `customer-${accountants.clientCompanyId}-edit-modal`,
      title: (
        <Title size={'h3'} component="p">
          {t('w.edit')}
        </Title>
      ),
      size: '90%',
      children: (
        <CustomerEditForm
          accountants={accountants}
          updateCustomerCompanyStatus={updateCustomerCompany}
        />
      ),
    });
  }

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'customerCompany.name',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({ componentName }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [
        {
          accessor: 'active',
          label: t('w.status'),
          choices: [
            { value: 'true', label: t('w.active') },
            { value: 'false', label: t('w.inactive') },
          ],
          selectedChoices: [],
        },
      ],
    }),
    updateFilter,
  });

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  return (
    <>
      <Table
        pinFirstColumn
        pinLastColumn
        idAccessor={'customerCompany.id'}
        options={options}
        columns={columns}
        rows={rows}
        lang={lang}
        fetching={isAccountantCustomersDataLoading}
        height={'calc(100vh - 220px)'}
        rightCustomActions={[
          <Tooltip
            key={'import-export-customer-company'}
            label={t('w.yourCompanyBillingAccountIsNotActivated')}
            position={'left'}
            disabled={isPaymentAccountActive}
          >
            <Button
              key={'export-button'}
              leftSection={<IconTableDown />}
              variant={'filled'}
              disabled={!isPaymentAccountActive}
              onClick={onExportClick}
              loading={isExportLoading}
            >
              {t('w.export')}
            </Button>
          </Tooltip>,
        ]}
        withTableBorder={false}
        width="0vw" // autoscale width
      />
      {accountantEmployeesData && (
        <AccountantConfigModal
          opened={accountantConfigModalOpened}
          onClose={handleAccountantConfigClose}
          accountantsToConfigure={accountantsToConfigure}
          refetchAccountantCustomersData={refetchAccountantCustomersData}
          accountantEmployeesData={accountantEmployeesData}
        />
      )}
      <CustomModal
        opened={customerCompany !== null}
        onClose={() => setCustomerCompany(null)}
        title={`${t('w.sharedDocuments')} - ${customerCompany?.name}`}
        fullScreen
      >
        {customerCompany !== null && (
          <DocumentsTable
            hasPermissionToEditSpace={true}
            space={DocumentPropertySpace.ACCOUNTANT}
            userId={user.id}
            companyId={customerCompany.id}
            accountantCompanyId={companyId}
          />
        )}
      </CustomModal>
    </>
  );
}
