import {
  Accordion,
  ActionIcon,
  Fieldset,
  Group,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconPlus, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as React from 'react';

import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import DailyWorkService from '../../../services/DailyWorkService';
import type {
  DailyWorkDailyTemplateResponse,
  DailyWorkWeeklyTemplateResponse,
} from '../../../types/api/response/dailyWork';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import { weeklyPeriods } from '../PlanningView';
import DailyTemplateForm from '../work-templates/components/daily/DailyTemplateForm';
import WeeklyTemplateForm from '../work-templates/components/weekly/WeeklyTemplateForm';
import WorkTemplate from '../work-templates/components/WorkTemplate';

type Props = {
  selectedTemplate:
    | DailyWorkDailyTemplateResponse
    | DailyWorkWeeklyTemplateResponse
    | undefined;
  setSelectedTemplate: React.Dispatch<
    React.SetStateAction<
      | DailyWorkDailyTemplateResponse
      | DailyWorkWeeklyTemplateResponse
      | undefined
    >
  >;
};

enum Tab {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
}

export function ActivityTemplatesCrud({
  selectedTemplate,
  setSelectedTemplate,
}: Props) {
  const { user } = useAuth();
  const { t } = useI18n();

  const { data: dailyTemplates, refetch: refetchDailyTemplatesData } = useQuery(
    {
      queryKey: ['DailyWorkService.getDailyTemplates', user.id],
      queryFn: () => DailyWorkService.getDailyTemplates(user.id),
    }
  );

  const { data: weeklyTemplates, refetch: refetchWeeklyTemplatesData } =
    useQuery({
      queryKey: ['DailyWorkService.getWeeklyTemplates', user.id],
      queryFn: () => DailyWorkService.getWeeklyTemplates(user.id),
    });

  const { mutate: deleteDailyTemplate } = useMutation({
    mutationFn: (variables: string) =>
      DailyWorkService.deleteDailyTemplate(variables),
    onSuccess: () => {
      modals.closeAll();
      refetchDailyTemplatesData();
      showNotification({
        id: `delete-daily-template-successful`,
        title: t('w.success'),
        message: `${t('w.template')} ${t('w.deleted').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: `delete-daily-template-successful-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: deleteWeeklyTemplate } = useMutation({
    mutationFn: (variables: string) =>
      DailyWorkService.deleteWeeklyTemplate(variables),
    onSuccess: () => {
      modals.closeAll();
      refetchWeeklyTemplatesData();
      showNotification({
        id: `delete-weekly-template-successful`,
        title: t('w.success'),
        message: `${t('w.template')} ${t('w.deleted').toLowerCase()}`,
        color: 'green',
        icon: <IconCheck />,
      });
    },
    onError: (error) =>
      showNotification({
        id: `delete-weekly-template-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function onAddDailyTemplateClick() {
    modals.open({
      id: 'add-daily-template-modal',
      title: (
        <Title size={'h3'} component="p">
          {t('w.daily')}
        </Title>
      ),
      children: (
        <DailyTemplateForm
          template={{
            id: '',
            name: '',
            period: [],
            shared: false,
            creatorId: user.id,
          }}
          refetchDailyTemplatesData={refetchDailyTemplatesData}
          onClose={() => modals.closeAll()}
          isEditing={false}
        />
      ),
    });
  }

  function onAddWeeklyTemplateClick() {
    modals.open({
      id: 'add-weekly-template-modal',
      title: (
        <Title size={'h3'} component="p">
          {t('w.weekly')}
        </Title>
      ),
      children: (
        <WeeklyTemplateForm
          template={{
            id: '',
            name: '',
            weeklyPeriods: weeklyPeriods,
            shared: false,
            creatorId: user.id,
          }}
          refetchWeeklyTemplatesData={refetchWeeklyTemplatesData}
          onClose={modals.closeAll}
          isEditing={false}
        />
      ),
      size: '80%',
    });
  }

  function handleOpenUpdateDailyTemplateModal(
    template: DailyWorkDailyTemplateResponse
  ): void {
    modals.open({
      id: `daily-template-${template.id}-modal`,
      title: (
        <Title size={'h3'} component="p">
          {template.name}
        </Title>
      ),
      children: (
        <DailyTemplateForm
          template={template}
          refetchDailyTemplatesData={refetchDailyTemplatesData}
          isEditing
          onClose={() => modals.closeAll()}
        />
      ),
    });
  }

  function handleOpenDeleteDailyTemplateModal(id: string, name: string) {
    modals.openConfirmModal({
      id: `delete-daily-${id}-template-modal`,
      title: (
        <Title size={'h3'} component="p">{`${t(
          'w.confirmDeletion'
        )} ${name}`}</Title>
      ),
      size: 'lg',
      labels: { confirm: t('w.delete'), cancel: t('w.cancel') },
      onConfirm: () => deleteDailyTemplate(id),
    });
  }

  function handleOpenUpdateWeeklyTemplateModal(
    template: DailyWorkWeeklyTemplateResponse
  ) {
    modals.open({
      id: `weekly-template-${template.id}-modal`,
      title: (
        <Title size={'h3'} component="p">
          {template.name}
        </Title>
      ),
      children: (
        <WeeklyTemplateForm
          template={template}
          refetchWeeklyTemplatesData={refetchWeeklyTemplatesData}
          onClose={modals.closeAll}
          isEditing
        />
      ),
      size: '80%',
    });
  }

  function handleOpenDeleteWeeklyTemplateModal(id: string, name: string) {
    modals.openConfirmModal({
      id: `delete-weekly-${id}-template-modal`,
      title: (
        <Title size={'h3'} component="p">{`${t(
          'w.confirmDeletion'
        )} ${name}`}</Title>
      ),
      size: 'lg',
      labels: { confirm: t('w.delete'), cancel: t('w.cancel') },
      onConfirm: () => deleteWeeklyTemplate(id),
    });
  }

  return (
    <Accordion variant="filled">
      <Accordion.Item value={Tab.DAILY}>
        <Accordion.Control>
          <Text size="sm">{t('w.dailyTemplates')}</Text>
        </Accordion.Control>
        <Accordion.Panel>
          <Fieldset
            variant="unstyled"
            legend={
              <Group>
                <Text size="sm" c="dimmed">
                  {t('w.myTemplates')}
                </Text>
                <Tooltip label={t('w.addTemplate')}>
                  <ActionIcon
                    size="xs"
                    variant="light"
                    radius="xl"
                    aria-label="add-template"
                    onClick={onAddDailyTemplateClick}
                  >
                    <IconPlus
                      style={{ width: '70%', height: '70%' }}
                      stroke={1.5}
                    />
                  </ActionIcon>
                </Tooltip>
              </Group>
            }
          >
            <Group gap={'xs'}>
              {dailyTemplates
                ?.filter((elt) => elt.creatorId === user.id)
                ?.map((item) => (
                  <WorkTemplate
                    key={item.id}
                    template={item}
                    handleOpenTemplateClick={(event) => {
                      event.stopPropagation();
                      handleOpenUpdateDailyTemplateModal(item);
                    }}
                    handleDeleteTemplateClick={(event) => {
                      event.stopPropagation();
                      handleOpenDeleteDailyTemplateModal(item.id, item.name);
                    }}
                    setSelectedTemplate={
                      selectedTemplate
                        ? () => setSelectedTemplate(undefined)
                        : () => setSelectedTemplate(item)
                    }
                    selectedTemplate={selectedTemplate}
                  />
                ))}
            </Group>
          </Fieldset>
          <Fieldset
            mt="sm"
            variant="unstyled"
            legend={
              <Text size="sm" c="dimmed">
                {t('w.sharedTemplates')}
              </Text>
            }
          >
            <Group gap={'xs'}>
              {dailyTemplates
                ?.filter((elt) => elt.creatorId !== user.id)
                ?.map((item) => (
                  <WorkTemplate
                    key={item.id}
                    template={item}
                    handleOpenTemplateClick={(event) => {
                      event.stopPropagation();
                      handleOpenUpdateDailyTemplateModal(item);
                    }}
                    handleDeleteTemplateClick={(event) => {
                      event.stopPropagation();
                      handleOpenDeleteDailyTemplateModal(item.id, item.name);
                    }}
                    setSelectedTemplate={
                      selectedTemplate
                        ? () => setSelectedTemplate(undefined)
                        : () => setSelectedTemplate(item)
                    }
                    selectedTemplate={selectedTemplate}
                  />
                ))}
            </Group>
          </Fieldset>
        </Accordion.Panel>
      </Accordion.Item>

      <Accordion.Item value={Tab.WEEKLY}>
        <Accordion.Control>
          <Text size="sm">{t('w.weeklyTemplates')}</Text>
        </Accordion.Control>
        <Accordion.Panel>
          <Fieldset
            variant="unstyled"
            legend={
              <Group>
                <Text size="sm" c="dimmed">
                  {t('w.myTemplates')}
                </Text>
                <Tooltip label={t('w.addTemplate')}>
                  <ActionIcon
                    size="xs"
                    variant="light"
                    radius="xl"
                    aria-label="add-template"
                    onClick={onAddWeeklyTemplateClick}
                  >
                    <IconPlus
                      style={{ width: '70%', height: '70%' }}
                      stroke={1.5}
                    />
                  </ActionIcon>
                </Tooltip>
              </Group>
            }
          >
            <Group gap={'xs'}>
              {weeklyTemplates
                ?.filter((elt) => elt.creatorId === user.id)
                ?.map((item) => (
                  <WorkTemplate
                    key={item.id}
                    template={item}
                    handleOpenTemplateClick={(event) => {
                      event.stopPropagation();
                      handleOpenUpdateWeeklyTemplateModal(item);
                    }}
                    handleDeleteTemplateClick={(event) => {
                      event.stopPropagation();
                      handleOpenDeleteWeeklyTemplateModal(item.id, item.name);
                    }}
                    setSelectedTemplate={
                      selectedTemplate
                        ? () => setSelectedTemplate(undefined)
                        : () => setSelectedTemplate(item)
                    }
                    selectedTemplate={selectedTemplate}
                  />
                ))}
            </Group>
          </Fieldset>

          <Fieldset
            mt="sm"
            variant="unstyled"
            legend={
              <Text size="sm" c="dimmed">
                {t('w.sharedTemplates')}
              </Text>
            }
          >
            <Group gap={'xs'}>
              {weeklyTemplates
                ?.filter((elt) => elt.creatorId !== user.id)
                ?.map((item) => (
                  <WorkTemplate
                    key={item.id}
                    template={item}
                    handleOpenTemplateClick={(event) => {
                      event.stopPropagation();
                      handleOpenUpdateWeeklyTemplateModal(item);
                    }}
                    handleDeleteTemplateClick={(event) => {
                      event.stopPropagation();
                      handleOpenDeleteWeeklyTemplateModal(item.id, item.name);
                    }}
                    setSelectedTemplate={
                      selectedTemplate
                        ? () => setSelectedTemplate(undefined)
                        : () => setSelectedTemplate(item)
                    }
                    selectedTemplate={selectedTemplate}
                  />
                ))}
            </Group>
          </Fieldset>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}
