import { Button, Flex, Group, Image, Indicator, rem } from '@mantine/core';
import { IconCircleCheckFilled } from '@tabler/icons-react';
import React from 'react';

import CustomTooltip from '../../../../components/CustomTooltip';
import { useI18n } from '../../../../contexts/I18nProvider';
import useResponsive from '../../../../hooks/useResponsive';

type Props = {
  mail: string | undefined;
  connected: boolean;
  logoUrl: string;
  onClick: () => void;
};

export default function OauthProviderItem({
  mail,
  connected,
  logoUrl,
  onClick,
}: Props) {
  const { t } = useI18n();
  const { isMobile } = useResponsive();

  return (
    <CustomTooltip
      label={mail}
      position={isMobile ? 'top' : 'right'}
      disabled={!connected}
    >
      <Indicator
        color={'transparent'}
        offset={13}
        label={
          <IconCircleCheckFilled
            style={{ color: 'var(--mantine-color-green-9)' }}
          />
        }
        disabled={!connected}
      >
        <Group
          align={'center'}
          justify={'space-between'}
          p={'sm'}
          styles={{
            root: {
              border: '0.5px solid var(--mantine-color-gray-4)',
              borderRadius: 10,
              backgroundColor: connected
                ? 'var(--mantine-color-green-1)'
                : undefined,
            },
          }}
        >
          <Flex justify={'flex-start'}>
            <Image src={logoUrl} h={rem(40)} />
          </Flex>
          <Flex direction={'column'}>
            <CustomTooltip label={t('w.availableSoon')} disabled={connected}>
              <Button
                disabled={!connected}
                variant={'subtle'}
                size={'xs'}
                onClick={onClick}
              >
                {connected ? t('w.logout') : t('w.login')}
              </Button>
            </CustomTooltip>
          </Flex>
        </Group>
      </Indicator>
    </CustomTooltip>
  );
}
