import { BuiltInRoles } from '../types/api/response/role';
import type { UserResponse } from '../types/api/response/user';
import type { PermissionScoped } from '../types/front/role';

export function isAccountant(user: UserResponse, companyId: string): boolean {
  return !!user.myCompanies?.includes(companyId);
}

export function hasRole(
  roleName: BuiltInRoles | BuiltInRoles[],
  user?: UserResponse,
  companyId?: string
): boolean {
  if (!user?.role) {
    // no user or no role
    return false;
  }

  if (user.role.name === BuiltInRoles.CODERKAINE) {
    return true;
  }

  if (!user.company?.id) {
    // not coderkaine and no company
    return false;
  }
  if (
    !!companyId &&
    user.companyId !== companyId &&
    isAccountant(user, companyId)
  ) {
    return true;
  }

  const userRole = user.role.name;

  if (typeof roleName === 'string') {
    // have company and have role, return equality test
    return userRole === roleName;
  }

  if (Array.isArray(roleName)) {
    return roleName.includes(userRole);
  }

  return false;
}

export function hasPermission(
  permission: PermissionScoped | PermissionScoped[],
  user?: UserResponse,
  companyId?: string
): boolean {
  if (!user?.role?.permissions) {
    return false;
  }

  if (
    !!companyId &&
    user.companyId !== companyId &&
    isAccountant(user, companyId)
  ) {
    return true;
  }

  const userPermissions = user.role.permissions;

  const checkPermission = (perm: PermissionScoped) =>
    userPermissions.some(
      (elt) =>
        elt.permission.key === perm.permission && elt.scope === perm.scope
    );

  if (Array.isArray(permission)) {
    return permission.some(checkPermission);
  }

  return checkPermission(permission);
}
