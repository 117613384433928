import { Flex, Skeleton, Title } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';

import CompanyService from '../../services/CompanyService';
import { ACCOUNTANT_MODE } from '../../types/types';
import { getPersistedObject } from '../../utils/localStorage';
import CustomCompanyAvatar from './../CustomAvatar/CustomCompanyAvatar';
import s from './CompanyBadge.module.sass';

type Props = {
  companyId: string;
};

export default function CompanyBadge({ companyId }: Props) {
  const { data: company } = useQuery({
    queryKey: ['CompanyService.getCompany', companyId],
    queryFn: () => CompanyService.getCompany(companyId),
  });
  const isAccountantMode = getPersistedObject('mode') === ACCOUNTANT_MODE;

  if (!company) return <Skeleton h={60} />;

  return (
    <Flex
      component="span"
      justify="center"
      align="center"
      gap="xs"
      className={isAccountantMode ? s.rootAccountant : s.root}
    >
      <CustomCompanyAvatar size="lg" companyId={company.id} />
      <Title component="span" order={4}>
        {company?.name}
      </Title>
    </Flex>
  );
}
