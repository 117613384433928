import {
  ActionIcon,
  Anchor,
  Flex,
  Group,
  NavLink,
  Stack,
  Text,
  Title,
  Tooltip,
  Transition,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconChevronLeft,
  IconMenuDeep,
  IconPlus,
  IconX,
} from '@tabler/icons-react';
import * as React from 'react';
import { PropsWithChildren, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppBar } from '../../contexts/AppBarProvider';
import { useI18n } from '../../contexts/I18nProvider';
import {
  getPersistedObject,
  persistData,
  removePersistedObject,
} from '../../utils/localStorage';
import UnitFilter from './components/UnitFilter/UnitFilter';
import s from './Page.module.sass';

export type PageLink = {
  label: string;
  active: boolean;
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  onClick?: () => void;
  links?: PageLink[];
  children?: ReactNode;
  disabled?: boolean;
};

type Props = {
  title: string;
  /**
   * support 1 nested level
   */
  links?: PageLink[];
  headerRightSection?: ReactNode;
  parent: {
    action?: () => void;
    label: string;
  };
  add?: {
    label?: string;
    onClick: () => void;
  };
};

const PageMenuOpenedKey = 'hfw-p-m-o';

export default function Page({
  title,
  links,
  parent,
  children,
  headerRightSection,
  add,
}: Props & PropsWithChildren) {
  const { t } = useI18n();
  const navigate = useNavigate();
  const {
    isAppBarUsed,
    appBarSelectedDivisions,
    setAppBarSelectedDivisions,
    divisionsToSelect,
  } = useAppBar();
  const hasMenu = Boolean(!!links || isAppBarUsed);
  const [opened, { toggle }] = useDisclosure(
    Boolean(getPersistedObject(PageMenuOpenedKey)) && hasMenu
  );

  function goBack() {
    navigate(-1);
  }

  return (
    <Group align="start" grow preventGrowOverflow={false} wrap="nowrap">
      <Flex direction="column" className={opened ? s.left : undefined}>
        <Group justify="space-between" mb="sm" className={s.header}>
          <Group mb="sm">
            <Tooltip label={t('w.return')}>
              <ActionIcon
                size="lg"
                variant="default"
                radius="xl"
                onClick={goBack}
              >
                <IconChevronLeft
                  style={{ width: '70%', height: '70%' }}
                  stroke={1.5}
                />
              </ActionIcon>
            </Tooltip>
            <Stack gap={0}>
              {parent.action ? (
                <Anchor
                  ta="left"
                  fw="bold"
                  underline="hover"
                  c="dimmed"
                  component="button"
                  onClick={parent.action}
                >
                  {parent.label}
                </Anchor>
              ) : (
                <Text c="dimmed" fw="bold">
                  {parent.label}
                </Text>
              )}
              <Title order={2}>{title}</Title>
            </Stack>
          </Group>
          {(hasMenu || !!headerRightSection || !!add?.onClick) && (
            <Group gap="sm">
              {headerRightSection && headerRightSection}
              {isAppBarUsed && (
                <UnitFilter
                  value={appBarSelectedDivisions}
                  divisions={divisionsToSelect}
                  onChange={setAppBarSelectedDivisions}
                />
              )}
              {!!add?.onClick && (
                <Tooltip label={add.label || t('w.add')}>
                  <ActionIcon size="xl" radius="xl" onClick={add.onClick}>
                    <IconPlus
                      style={{ width: '70%', height: '70%' }}
                      stroke={1.5}
                    />
                  </ActionIcon>
                </Tooltip>
              )}
              {!!links && (
                <Tooltip label={opened ? t('w.closeMenu') : t('w.menu')}>
                  <ActionIcon
                    variant="light"
                    size="xl"
                    radius="xl"
                    onClick={() => {
                      opened
                        ? removePersistedObject(PageMenuOpenedKey)
                        : persistData(PageMenuOpenedKey, 'true');
                      toggle();
                    }}
                  >
                    {opened ? (
                      <IconX
                        style={{ width: '70%', height: '70%' }}
                        stroke={1.5}
                      />
                    ) : (
                      <IconMenuDeep
                        style={{ width: '70%', height: '70%' }}
                        stroke={1.5}
                      />
                    )}
                  </ActionIcon>
                </Tooltip>
              )}
            </Group>
          )}
        </Group>
        {children}
      </Flex>
      {hasMenu && (
        <Transition mounted={opened} transition="slide-left">
          {(styles) => (
            <div style={styles} className={s.menuSticky}>
              <Stack gap={0}>
                <Title pl="sm" mb="sm" order={3}>
                  {t('w.menu')}
                </Title>
                {links?.map((link) => (
                  <NavLink
                    classNames={{
                      root: s.root,
                      label: s.label,
                      collapse: s.collapse,
                    }}
                    key={link.label}
                    autoContrast
                    label={link.label}
                    onClick={link.onClick}
                    active={link.active}
                    leftSection={link.leftSection}
                    rightSection={undefined}
                    disabled={link.disabled}
                  >
                    {(!!link.links?.length || !!link.children) && (
                      <Stack gap={0} mt="xs">
                        {link.links?.map((subLink) => (
                          <NavLink
                            classNames={{
                              root: s.root,
                              label: s.label,
                              collapse: s.collapse,
                            }}
                            key={`${link.label}-${subLink.label}`}
                            label={subLink.label}
                            autoContrast
                            onClick={subLink.onClick}
                            active={subLink.active}
                            leftSection={link.leftSection}
                            rightSection={link.rightSection}
                            disabled={link.disabled}
                          />
                        ))}
                        {!!link.children ? link.children : null}
                      </Stack>
                    )}
                  </NavLink>
                ))}
              </Stack>
            </div>
          )}
        </Transition>
      )}
    </Group>
  );
}
