import { Stack } from '@mantine/core';
import type {
  DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';

import { useI18n } from '../../../../../contexts/I18nProvider';
import { PayslipMonthResponseFront } from '../../../../../types/types';
import ImportPayslipsTitle from '../ImportPayslipsTitle';
import PayslipsNotMatchedTable from './PayslipsNotMatchedTable';

type Props = {
  payslipsMonthInfo: PayslipMonthResponseFront | undefined;
  selectedDate: DateValue | DatesRangeValue | Date[];
  companyId: string;
};

export default function PayslipsNotMatched({
  payslipsMonthInfo,
  selectedDate,
  companyId,
}: Props) {
  const { t } = useI18n();
  return (
    <Stack gap={'xl'}>
      <ImportPayslipsTitle title={t('w.listOfPayslipsWithoutEmployees')} />
      <PayslipsNotMatchedTable
        payslipsMonthInfo={payslipsMonthInfo}
        selectedDate={selectedDate}
        companyId={companyId}
      />
    </Stack>
  );
}
