import { Button, Flex, Group, Space, Switch, Title } from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconCircleArrowLeft,
  IconCirclePlus,
  IconCopyCheck,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import React from 'react';

import ButtonsDpae from '../../../../components/ButtonsDpae';
import LabelText from '../../../../components/LabelText/LabelText';
import { useAuth } from '../../../../contexts/AuthProvider';
import { useI18n } from '../../../../contexts/I18nProvider';
import ProfileService from '../../../../services/ProfileService';
import type {
  UserContractualInfoType,
  UserPersonalInfo,
  UserPersonalSensitive,
  UserResponse,
} from '../../../../types/api/response/user';
import handleErrorMessage from '../../../../utils/handleErrorMessage';

type Props = {
  onClose?: () => void;
  employee: UserResponse | null;
  validateEmployeeOnBoarding: (variables: {
    userId?: string;
    employee: boolean;
    accountant: boolean;
  }) => void;
  refetch: () => void;
  setActive: (number: number) => void;
  active: number;
  selectedCompanyGroup: string | null;
  userPersonalInfo: UserPersonalInfo | null;
  userSensitiveInfo?: UserPersonalSensitive | null;
  userContractualInfo: UserContractualInfoType | null;
};

interface FormValues {
  registerInPayroll?: boolean;
}

export default function OnBoardingAccountantStep({
  onClose,
  employee,
  validateEmployeeOnBoarding,
  refetch,
  active,
  setActive,
  selectedCompanyGroup,
  userPersonalInfo,
  userSensitiveInfo,
  userContractualInfo,
}: Props) {
  const { t } = useI18n();
  const { user } = useAuth();

  const form = useForm<FormValues>({
    initialValues: {
      registerInPayroll: false,
    },
  });

  const { data: dpae, refetch: refetchDpae } = useQuery({
    enabled: !!employee?.id,
    queryKey: ['ProfileService.getDpae', employee?.id],
    queryFn: () => ProfileService.getDpae(employee?.id!),
  });

  function onRegisterInPayrollChange({
    userId,
    registerInPayroll,
  }: {
    userId: string;
    registerInPayroll: boolean;
  }) {
    updateOBByAccountant({ userId, registerInPayroll });
  }

  const { mutate: updateOBByAccountant } = useMutation({
    mutationFn: (variables: { userId: string; registerInPayroll: boolean }) =>
      ProfileService.updateOnBoardingInfosByAccountant(
        variables.userId,
        variables.registerInPayroll
      ),
    onSuccess: (data) => {
      if (data) {
        refetch();
        showNotification({
          id: `update-user-onboarding-accountant-successful`,
          title: t('w.success'),
          message: t('success.onboardingUpdated'),
          color: 'green',
          icon: <IconCheck />,
        });
      }
    },
    onError: (error) =>
      showNotification({
        id: 'update-user-fixedPricedManager-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { mutate: createDpae } = useMutation({
    mutationFn: (variables: { selectedCompanyGroup: string | null }) =>
      ProfileService.createDpae(employee?.id, variables.selectedCompanyGroup),
    onSuccess: (data) => {
      if (data) {
        refetch();
        refetchDpae();
        showNotification({
          id: `create-dpae-successful`,
          title: t('w.success'),
          message: t('success.dpaeCreated'),
          color: 'green',
          icon: <IconCheck />,
        });
      }
    },
    onError: (error) =>
      showNotification({
        id: 'create-dpae-error',
        title: t('w.error'),
        // @ts-ignore
        message: error.response?.data,
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleValidateOBAccountant() {
    modals.openConfirmModal({
      modalId: 'confirm-valid-on-boarding',
      title: (
        <Title size={'h3'} component="p">
          {t('w.confirmOnBoardingInfosAccountant')}
        </Title>
      ),
      size: 'xl',
      labels: { cancel: t('w.cancel'), confirm: t('w.confirm') },
      onConfirm: () =>
        validateEmployeeOnBoarding({
          userId: employee?.id,
          employee: false,
          accountant: true,
        }),
    });
  }

  function handleCreateDPAE() {
    if (
      userContractualInfo === null ||
      userPersonalInfo === null ||
      userSensitiveInfo === null
    ) {
      showNotification({
        id: 'create-dpae-error',
        title: t('w.error'),
        message: t('error.informationMissingDpae'),
        color: 'red',
        icon: <IconX />,
      });
    } else {
      if (selectedCompanyGroup === null || selectedCompanyGroup === '') {
        createDpae({ selectedCompanyGroup: null });
      } else {
        createDpae({ selectedCompanyGroup: selectedCompanyGroup });
      }
    }
  }

  return (
    <form onSubmit={form.onSubmit(handleValidateOBAccountant)}>
      <Title order={3}>{'Pré-embauche gestionnaire'}</Title>
      <Space h={'xl'} />
      {employee &&
        Boolean(employee?.onBoardingId) &&
        employee.id !== user.id && (
          <Switch
            label={<LabelText text={t('w.registerInPayroll')} />}
            labelPosition={'left'}
            checked={
              employee?.onBoarding?.onBoardingAccountant?.registerInPayroll
            }
            disabled={
              !Boolean(employee?.onBoardingId) && user.id === employee?.id
            }
            onChange={(value) =>
              onRegisterInPayrollChange({
                userId: employee?.id,
                registerInPayroll: value.currentTarget.checked,
              })
            }
          />
        )}
      <Space h={'xl'} />
      <Flex justify={'flex-start'} gap={'15px'} align={'flex-end'}>
        <Button
          onClick={() => handleCreateDPAE()}
          disabled={Boolean(dpae) && employee?.id !== user.id}
          leftSection={<IconCirclePlus size={'1.2rem'} />}
        >
          Effectuer la DPAE
        </Button>
        {dpae && <ButtonsDpae dpae={dpae} employee={employee} edit={true} />}
      </Flex>
      <Group justify={'flex-end'} mt={'51px'} gap={'30px'}>
        {employee && Boolean(employee?.onBoardingId) && (
          <Button
            type={'submit'}
            color={'#0A9182'}
            leftSection={<IconCopyCheck />}
            disabled={
              employee?.id === user.id ||
              employee?.onBoarding?.onBoardingEmployee?.validationDate ===
                null ||
              employee?.onBoarding?.onBoardingCompany?.validationDate ===
                null ||
              employee?.onBoarding?.onBoardingCompany?.validationDate
                ?.dateOfUpdate === null
            }
          >
            {t('w.validateInfos')}
          </Button>
        )}
        <Button
          onClick={() => setActive(active - 1)}
          leftSection={<IconCircleArrowLeft />}
          variant={'outline'}
        >
          {t('w.back')}
        </Button>

        {onClose && (
          <Button
            onClick={onClose}
            variant={'outline'}
            disabled={
              Boolean(employee?.onBoardingId) && employee?.id === user.id
            }
          >
            {t('w.close')}
          </Button>
        )}
      </Group>
    </form>
  );
}
