import { useQueries, useQuery } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';

import { useAppBar } from '../../../../contexts/AppBarProvider';
import { useAuth } from '../../../../contexts/AuthProvider';
import useFetchDivisions from '../../../../hooks/useFetchDivisions';
import CompanyService from '../../../../services/CompanyService';
import CounterTypeService from '../../../../services/CounterTypeService';
import CounterUserService from '../../../../services/CounterUserService';
import { BuiltInRoles } from '../../../../types/api/response/role';
import { hasRole } from '../../../../utils/authorization';
import { NO_WORK_UNIT } from '../../../../variables/GlobalVariables';
import UsersCounters, { UsersCountersData } from './UsersCounters';

type Props = {
  companyId: string;
  withUnitFilter?: boolean;
  selectedDivisionIds?: string[];
};

export default function HolidayCounters({
  companyId,
  withUnitFilter = true,
  selectedDivisionIds,
}: Props) {
  const { divisions } = useFetchDivisions({ companyId });
  const {
    setIsAppBarUsed,
    setDivisionsToSelect,
    setAppBarSelectedDivisions,
    appBarSelectedDivisions,
  } = useAppBar();
  const { user } = useAuth();
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  useEffect(() => {
    if (!withUnitFilter) return;

    setIsAppBarUsed(true);
    return function cleanup() {
      setIsAppBarUsed(false);
    };
  }, [withUnitFilter]);

  useEffect(() => {
    if (!withUnitFilter) return;
    buildAppbarDivisions();
  }, [hasRole(BuiltInRoles.ADMINISTRATOR, user, companyId), divisions]);

  function buildAppbarDivisions() {
    let _divisions = divisions.filter(
      (division) => division.name !== NO_WORK_UNIT
    );
    if (!hasRole(BuiltInRoles.ADMINISTRATOR, user, companyId)) {
      _divisions = _divisions.filter(
        (division) =>
          division.n1 === user.id ||
          division.n2 === user.id ||
          division.assistant === user.id
      );
    }
    const divisionsNames = _divisions.map((division) => division.name);
    setDivisionsToSelect(divisionsNames);
    setAppBarSelectedDivisions(divisionsNames);
  }
  const divisionIds: string[] = useMemo(() => {
    if (selectedDivisionIds) return selectedDivisionIds;
    if (!divisions) return [];

    if (!appBarSelectedDivisions?.length) {
      return divisions.map((item) => item.id);
    }

    const result: string[] = [];
    appBarSelectedDivisions.forEach((selectedDivision: string) => {
      const findDivision = divisions.find(
        (division) => division.name === selectedDivision
      );
      if (findDivision) {
        result.push(findDivision.id);
      }
    });
    return result;
  }, [divisions, appBarSelectedDivisions, selectedDivisionIds]);

  const { data: users } = useQuery({
    queryKey: ['CompanyService.listEmployees', companyId],
    queryFn: () =>
      CompanyService.listEmployees(companyId, [
        'fullname',
        'divisionId',
        'valid',
      ]),
  });
  const usersCountersQueries = useQueries({
    queries: users
      ? users
          .filter((item) => item.valid)
          .map((user) => {
            return {
              queryKey: ['CounterUserService.getByUser', user.id],
              queryFn: () => CounterUserService.getByUser(user.id),
            };
          })
      : [],
  });

  const data: UsersCountersData[] | undefined = useMemo(() => {
    if (!users) return undefined;

    if (refreshTrigger) {
      setRefreshTrigger(false);
      return undefined;
    }

    const dataArray = usersCountersQueries.map((item) => item.data);

    if (
      !dataArray?.length ||
      !dataArray.every((currentValue) => !!currentValue)
    )
      return undefined;

    const result: UsersCountersData[] = [];

    users
      .filter((item) => item.valid && divisionIds.includes(item.divisionId!))
      .forEach((user) => {
        const countersUsers = dataArray.find(
          (arr) => arr?.[0].userId === user.id
        );
        const division = divisions.find((item) => item.id === user.divisionId);

        result.push({
          userId: user.id,
          userFullname: user.fullname!,
          division,
          countersUsers,
        });
      });

    return result.sort((a, b) => a.userFullname.localeCompare(b.userFullname));
  }, [usersCountersQueries, users, divisionIds, refreshTrigger]);

  const { data: counterTypeResponses } = useQuery({
    queryKey: ['CounterTypeService.list', companyId],
    queryFn: () => CounterTypeService.list(companyId),
  });

  function refresh() {
    setRefreshTrigger(true);
  }

  return (
    <UsersCounters
      data={data}
      counterTypeResponses={counterTypeResponses}
      divisions={divisions}
      companyId={companyId}
      refresh={refresh}
    />
  );
}
