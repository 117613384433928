import { FilterOption, Options, Table } from '@ckprivate/ckf-ui';
import { ActionIcon, Group, Title } from '@mantine/core';
import type {
  DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCheck,
  IconUserEdit,
  IconUserUp,
  IconUserX,
  IconX,
} from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DataTableColumn } from 'mantine-datatable';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CustomTooltip from '../../../../../components/CustomTooltip';
import GoToUserButton from '../../../../../components/GoToUserButton/GoToUserButton';
import { useI18n } from '../../../../../contexts/I18nProvider';
import ProfileService from '../../../../../services/ProfileService';
import {
  ACCOUNTANT_MODE,
  PayslipMonthResponseFront,
} from '../../../../../types/types';
import handleErrorMessage from '../../../../../utils/handleErrorMessage';
import { getPersistedObject } from '../../../../../utils/localStorage';
import {
  getFilter,
  getHiddenColumns,
  saveFilter,
  saveHiddenColumns,
} from '../../../../../utils/optionsPreferences';
import AssignEmployeeWithoutPayslip from './AssignEmployeeWithoutPayslip';

const componentName = 'employeeWithoutPayslip';

type Props = {
  payslipsMonthInfo: PayslipMonthResponseFront | undefined;
  selectDate: DateValue | DatesRangeValue | Date[];
  companyId: string;
};

export default function EmployeeWithoutPayslipTable({
  payslipsMonthInfo,
  selectDate,
  companyId,
}: Props) {
  const { t, lang } = useI18n();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [options, setOptions] = useState<Options>({
    sort: { columnAccessor: '', direction: 'asc' },
    search: {
      accessor: 'fullname',
      label: t('w.name'),
    },
    hiddenColumns: getHiddenColumns({
      componentName,
      hiddenColumns: [''],
    }),
    updateHiddenColumns,
    filter: getFilter({
      componentName,
      filter: [],
    }),
    updateFilter,
  });

  const columns: DataTableColumn<any>[] = useMemo(
    () => [
      {
        accessor: 'fullname',
        title: t('w.name'),
        sortable: true,
        ellipsis: true,
        render: ({ userId, fullname }) => (
          <GoToUserButton userId={userId} userFullname={fullname} />
        ),
      },
      {
        accessor: 'actions',
        title: 'Actions',
        sortable: true,
        ellipsis: true,
        render: ({ userId, fullname }) => (
          <Group gap={4} justify="center" wrap={'nowrap'}>
            <CustomTooltip label={t('w.assign')}>
              <ActionIcon
                size="sm"
                color="blue"
                variant={'subtle'}
                onClick={() =>
                  handleOpenAssignPaylipToUserModal(userId, fullname)
                }
              >
                <IconUserUp size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.edit')}>
              <ActionIcon
                size="sm"
                color="green"
                variant={'subtle'}
                onClick={() => handleNavigateToUserProfile(userId)}
              >
                <IconUserEdit size={16} />
              </ActionIcon>
            </CustomTooltip>
            <CustomTooltip label={t('w.deactivate')}>
              <ActionIcon
                size="sm"
                color="red"
                variant={'subtle'}
                onClick={() => handleOpenInvalidateUserModal(userId, fullname)}
              >
                <IconUserX size={16} />
              </ActionIcon>
            </CustomTooltip>
          </Group>
        ),
      },
    ],
    []
  );

  function updateFilter(newFilter: FilterOption[]) {
    const _options = { ...options };
    _options.filter = newFilter;
    saveFilter({ componentName, filter: newFilter });
    setOptions(_options);
  }

  function updateHiddenColumns(newHiddenColumns: string[]) {
    const _options = { ...options };
    _options.hiddenColumns = newHiddenColumns;
    saveHiddenColumns({ componentName, hiddenColumns: newHiddenColumns });
    setOptions(_options);
  }

  function handleOpenAssignPaylipToUserModal(
    userId: string,
    fullname: string
  ): void {
    modals.open({
      modalId: `assign-payslip-to-user-${userId}`,
      title: (
        <Title size={'h3'} component="p">
          {t('w.chooseAPayslipThatDoesNotCorrespondToAnyEmployeeTo', fullname)}
        </Title>
      ),
      size: '60%',
      children: (
        <AssignEmployeeWithoutPayslip
          payslipsMonthInfo={payslipsMonthInfo}
          selectDate={selectDate}
          selectedUserId={userId}
          companyId={companyId}
        />
      ),
    });
  }

  function handleNavigateToUserProfile(userId: string): void {
    if (getPersistedObject('mode') === ACCOUNTANT_MODE) {
      modals.close(`customer-company-id-${companyId}-end-of-month-modal`);
      navigate(`/company/${companyId}/profile/user/${userId}`);
    } else {
      navigate(`/profile/user/${userId}`);
    }
  }

  const { mutate: invalidateUser } = useMutation({
    mutationFn: (variables: string) => ProfileService.invalidateUser(variables),
    onSuccess: () => {
      showNotification({
        id: 'invalidate-user-successful',
        title: t('w.success'),
        message: t('user.deactivated'),
        color: 'green',
        icon: <IconCheck />,
      });
      queryClient.invalidateQueries({
        queryKey: [
          'PayslipService.getPayslipsTreatmentForCompanyId',
          companyId,
        ],
      });
    },
    onError: (error) =>
      showNotification({
        id: 'invalidate-user-payslip-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function handleOpenInvalidateUserModal(userId: string, fullname: string) {
    modals.openConfirmModal({
      modalId: `invalidate-user-${userId}-modal`,
      title: (
        <Title size={'h3'} component="p">
          {t('w.deactivate')} {t('w.user').toLowerCase()} {fullname}
        </Title>
      ),
      size: 'lg',
      labels: { confirm: t('w.deactivate'), cancel: t('w.cancel') },
      onConfirm: () => invalidateUser(userId),
    });
  }

  return (
    <Table
      options={options}
      rows={payslipsMonthInfo?.notFound}
      columns={columns}
      idAccessor={'userId'}
      lang={lang}
      height={'calc(100vh - 360px)'}
    />
  );
}
