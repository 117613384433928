import {
  DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';

import { useAppBar } from '../../../../contexts/AppBarProvider';
import { useAuth } from '../../../../contexts/AuthProvider';
import useFetchDivisions from '../../../../hooks/useFetchDivisions';
import CompanyService from '../../../../services/CompanyService';
import ExpenseReportService from '../../../../services/ExpenseReportService';
import { CompanyResponse } from '../../../../types/api/response/company';
import { BuiltInRoles } from '../../../../types/api/response/role';
import {
  ExpenseStatusState,
  type UserExpenseReportSummaryDetail,
} from '../../../../types/types';
import { hasRole } from '../../../../utils/authorization';
import { NO_WORK_UNIT } from '../../../../variables/GlobalVariables';
import EndOfMonthExpenseReportsTable from './EndOfMonthExpenseReportsTable';

export type EndOfMonthExpenseReportRow = UserExpenseReportSummaryDetail & {
  userId: string;
  userFullname: string;
  statusState: ExpenseStatusState;
  userDivisionName?: string;
};

type Props = {
  selectedDate: DateValue | DatesRangeValue | Date[];
  company: CompanyResponse;
};

export default function ByMonth({ selectedDate, company }: Props) {
  const { user } = useAuth();
  const { divisions } = useFetchDivisions({ companyId: company.id });
  const {
    setIsAppBarUsed,
    setDivisionsToSelect,
    setAppBarSelectedDivisions,
    appBarSelectedDivisions,
  } = useAppBar();

  const divisionsId: string[] = useMemo(() => {
    if (!divisions) {
      return [];
    }

    if (!appBarSelectedDivisions?.length) {
      return divisions.map((item) => item.id);
    }

    const result: string[] = [];
    appBarSelectedDivisions.forEach((selectedDivision: string) => {
      const findDivision = divisions.find(
        (division) => division.name === selectedDivision
      );
      if (findDivision) {
        result.push(findDivision.id);
      }
    });

    return result;
  }, [divisions, appBarSelectedDivisions]);

  const {
    data: expenseReports,
    refetch: refetchExpenseReports,
    isFetching: isExpenseReportsLoading,
  } = useQuery({
    enabled: !!divisionsId.length,
    queryKey: [
      'ExpenseReportService.getExpenseReportsByDivisionsAndMonth',
      company.id,
    ],
    queryFn: () =>
      ExpenseReportService.getExpenseReportsByDivisionsAndMonth(company.id, {
        // @ts-ignore
        monthConcerned: dayjs(selectedDate)
          .set('date', 15)
          .format('DD/MM/YYYY'),
        divisionIds: divisionsId,
      }),
  });

  useEffect(() => {
    refetchExpenseReports();
  }, [selectedDate, divisionsId]);

  const { data: employees } = useQuery({
    queryKey: ['CompanyService.listEmployees', company.id],
    queryFn: () =>
      CompanyService.listEmployees(company.id, ['fullname', 'divisionId']),
  });

  useEffect(() => {
    setIsAppBarUsed && setIsAppBarUsed(true);
    return function cleanup() {
      setIsAppBarUsed && setIsAppBarUsed(false);
    };
  }, []);

  useEffect(() => {
    buildAppbarDivisions();
  }, [hasRole(BuiltInRoles.ADMINISTRATOR, user, company.id), divisions]);

  const rows: EndOfMonthExpenseReportRow[] = useMemo(() => {
    if (!employees?.length || !expenseReports?.length) return [];

    const result: EndOfMonthExpenseReportRow[] = [];
    expenseReports.forEach((item) => {
      const creator = item.values?.[0]?.creator;
      if (creator) {
        const employee = employees.find((item) => item.id === creator);
        if (employee) {
          const division = divisions.find(
            (item) => item.id === employee.divisionId
          );

          let _statusState = ExpenseStatusState.REJECTED;

          if (item.waiting > 0) {
            _statusState = ExpenseStatusState.WAITING;
          } else if (item.processed > 0) {
            _statusState = ExpenseStatusState.PROCESSED;
          } else if (item.refundProcessing > 0) {
            _statusState = ExpenseStatusState.VALID;
          } else if (item.refunded > 0) {
            _statusState = ExpenseStatusState.REFUNDED;
          }

          result.push({
            ...item,
            userId: employee.id,
            userFullname: employee.fullname!,
            userDivisionName: division?.name,
            statusState: _statusState,
          });
        }
      }
    });

    return result;
  }, [expenseReports, employees, divisions]);

  function buildAppbarDivisions() {
    let _divisions = divisions.filter(
      (division) => division.name !== NO_WORK_UNIT
    );
    if (!hasRole(BuiltInRoles.ADMINISTRATOR, user, company.id)) {
      _divisions = _divisions.filter(
        (division) =>
          division.n1 === user.id ||
          division.n2 === user.id ||
          division.assistant === user.id
      );
    }
    const divisionsNames = _divisions.map((division) => division.name);
    setDivisionsToSelect && setDivisionsToSelect(divisionsNames);
    setAppBarSelectedDivisions && setAppBarSelectedDivisions(divisionsNames);
  }

  return (
    <EndOfMonthExpenseReportsTable
      rows={rows}
      isExpenseReportsLoading={isExpenseReportsLoading}
      selectedDate={selectedDate}
      company={company}
    />
  );
}
