import { Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconAlertTriangle, IconCheck } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Page, { PageLink } from '../../components/Page/Page';
import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import { useModule } from '../../contexts/ModuleProvider';
import ProfileService from '../../services/ProfileService';
import { ModuleName } from '../../types/api/response/module';
import { UserResponse } from '../../types/api/response/user';
import { toDateOfDayString } from '../../utils/format';
import ExpenseReportRequestForm from '../expense-reports/components/ExpenseReportRequestForm';
import ShuttleSheetForm from '../shuttle-sheets/components/create/ShuttleSheetForm';
import UserProfileLeaves from '../user-profile/components/UserProfileLeaves';
import LeavesRequestFormModal from './components/leaves/LeavesRequestFormModal';
import PrivacyPolicyModal from './components/PrivacyPolicyModal';
import WarningPrivacyPolicyModal from './components/WarningPrivacyPolicyModal';

export default function Home() {
  const { user, access_token, refresh_token, updateUser } = useAuth();
  const { t } = useI18n();
  const [opened, { close, open }] = useDisclosure(false);
  const [warningPrivacyPolicyModalOpened, setWarningPrivacyPolicyModalOpened] =
    useState<boolean>(false);
  const { getModule } = useModule();
  const isExpenseReportModuleActive = Boolean(
    getModule(ModuleName.ExpenseReport)?.active
  );
  const isShuttleSheetModuleActive = Boolean(
    getModule(ModuleName.ShuttleSheet)?.active
  );
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!user.termsAcceptance) {
      open();
    } else {
      if (Boolean(user.onBoardingId)) {
        navigate('/employee/on-boarding');
      }
    }
  }, [user]);

  const { mutate: updateUserTermsAcceptance } = useMutation({
    mutationFn: (payload: { access_token: string; user: any }) =>
      ProfileService.updateUserTermsAcceptance(
        payload.access_token,
        payload.user.id
      ),
    onSuccess: (_data, payload) => {
      updateUser(_data, payload.access_token, refresh_token, null);
      close();
      setWarningPrivacyPolicyModalOpened(false);
      if (_data.onBoardingId !== null) {
        signupOnBoarding();
      }
      showNotification({
        id: 'userTermsAcceptance',
        title: t('w.success'),
        message: `${t('w.privacyPolicy')[0].toUpperCase()}${t(
          'w.privacyPolicy'
        ).slice(1)} ${t('w.accepted')}`,
        color: 'green',
        icon: <IconCheck />,
      });
    },
  });

  const { mutate: signupOnBoarding } = useMutation({
    mutationFn: () => ProfileService.employeeOnBoardingSignUp(user.id),
  });

  function handleAgreeTermsClick() {
    updateUserTermsAcceptance({ access_token: access_token, user: user });
  }

  function handleModalClose() {
    showNotification({
      id: 'close-privacy-policy-modal',
      title: t('w.warning'),
      message: t('w.privacyPolicyTextPart4'),
      color: 'orange',
      icon: <IconAlertTriangle />,
    });
    setWarningPrivacyPolicyModalOpened(true);
  }

  function openLeaveRequestForm() {
    modals.open({
      transitionProps: { transition: 'slide-down' },
      modalId: 'leave-request-form',
      title: (
        <Title size={'h3'} component="p">
          {t('w.requestForLeaveOrAbsence', user.fullname)}
        </Title>
      ),
      size: '90%',
      children: (
        <LeavesRequestFormModal
          onClose={() => modals.close('leave-request-form')}
          user={user}
        />
      ),
      onClose: () => modals.close('leave-request-form'),
    });
  }

  function getLinks(_userProfile: UserResponse): PageLink[] | undefined {
    const links: PageLink[] = [];

    if (isExpenseReportModuleActive) {
      links.push({
        label: t('w.addExpenseReport'),
        onClick: () => {
          modals.open({
            transitionProps: { transition: 'slide-down' },
            modalId: `add-new-expense-report-modal`,
            title: (
              <Title size={'h3'} component="p">
                {t('w.addExpenseReport')}
              </Title>
            ),
            size: '90%',
            children: (
              <ExpenseReportRequestForm
                closeModal={() => modals.close('add-new-expense-report-modal')}
                userProfile={user}
              />
            ),
          });
        },
        active: false,
      });
    }

    if (isShuttleSheetModuleActive) {
      links.push({
        label: t('w.createNewShuttleSheet'),
        onClick: () => {
          modals.open({
            transitionProps: { transition: 'slide-down' },
            modalId: 'create-shuttle-sheet-modal',
            title: (
              <Title size={'h3'} component="p">
                {t('w.createNewShuttleSheet')}
              </Title>
            ),
            size: '90%',
            children: (
              <ShuttleSheetForm
                companyId={user.companyId!}
                modalId={'create-shuttle-sheet-modal'}
                refetch={() =>
                  queryClient.invalidateQueries({
                    queryKey: [
                      'ShuttleSheetService.userShuttleSheetsList',
                      user.companyId!,
                      user.id,
                    ],
                  })
                }
              />
            ),
          });
        },
        active: false,
      });
    }

    return !!links.length ? links : undefined;
  }

  return (
    <>
      {!!user?.company?.id && (
        <Page
          parent={{ label: toDateOfDayString() }}
          title={t('w.hello', user.firstname)}
          links={getLinks(user)}
          add={{
            label: t('w.addLeaveOrAbsence'),
            onClick: openLeaveRequestForm,
          }}
        >
          <UserProfileLeaves userProfile={user} />
        </Page>
      )}
      <PrivacyPolicyModal
        opened={opened}
        onClose={handleModalClose}
        onClick={handleAgreeTermsClick}
      />
      <WarningPrivacyPolicyModal
        opened={warningPrivacyPolicyModalOpened}
        onClose={() => setWarningPrivacyPolicyModalOpened(false)}
        onClick={handleAgreeTermsClick}
      />
    </>
  );
}
