import { Fieldset, Group, SimpleGrid, Stack, TextInput } from '@mantine/core';
import React from 'react';

import CustomDropzone from '../../../../../components/CustomDropzone/CustomDropzone';
import LabelText from '../../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../../contexts/I18nProvider';
import { UserPersonalSensitiveDocument } from '../../../../../types/api/payload/user';
import type { UserResponse } from '../../../../../types/api/response/user';
import UserPersonalSensitiveDocumentViewer from '../UserPersonalSensitiveDocumentViewer/UserPersonalSensitiveDocumentViewer';

type Props = {
  form: any;
  disabled: boolean;
  employee: UserResponse;
};

export default function BankForm({ form, disabled, employee }: Props) {
  const { t } = useI18n();

  return (
    <Stack>
      <SimpleGrid cols={{ base: 1, sm: 2 }}>
        <TextInput
          label={<LabelText text={t('w.iban')} />}
          placeholder={t('w.noValue')}
          {...form.getInputProps('iban')}
          disabled={disabled}
        />
        <TextInput
          label={<LabelText text={'BIC'} />}
          placeholder={t('w.noValue')}
          {...form.getInputProps('bic')}
          disabled={disabled}
        />
      </SimpleGrid>

      <Fieldset legend={`${t('w.file')} ${t('w.iban')}`}>
        <Group grow preventGrowOverflow={false} wrap="nowrap">
          <UserPersonalSensitiveDocumentViewer
            userId={employee.id}
            type={UserPersonalSensitiveDocument.iban}
            fileName={`${t('w.file')} ${t('w.iban')}`}
          />
          <CustomDropzone
            onDrop={(files) => form.setFieldValue('ibanFiles', files)}
            files={form.values.ibanFiles}
            mimeType={'pdf'}
            resetFile={() => form.setFieldValue('ibanFiles', [])}
            disabled={disabled}
          />
        </Group>
      </Fieldset>
    </Stack>
  );
}
