import { Box, Flex, Loader, Pill, Text } from '@mantine/core';
import * as React from 'react';

import { useI18n } from '../contexts/I18nProvider';

type Props = {
  loading: boolean;
};

export default function LoadingBadge({ loading }: Props) {
  const { t } = useI18n();
  return (
    <>
      {loading ? (
        <Pill
          size="xl"
          display="flex"
          w="fit-content"
          m="0 auto"
          h="var(--mantine-spacing-xl)"
        >
          <Flex h="100%" justify="center" align="center" gap="sm">
            <Loader size={25} />
            <Text>{t('w.loading')}</Text>
          </Flex>
        </Pill>
      ) : (
        <Box w="fit-content" m="0 auto" />
      )}
    </>
  );
}
