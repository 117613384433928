import { Flex, LoadingOverlay, TextInput } from '@mantine/core';
import { isEmail, useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';

import ButtonSendInvitation from '../../../../components/ButtonSendInvitation';
import LabelText from '../../../../components/LabelText/LabelText';
import { useI18n } from '../../../../contexts/I18nProvider';
import CharteredAccountantService from '../../../../services/CharteredAccountantService';
import handleErrorMessage from '../../../../utils/handleErrorMessage';

type Props = {
  focusOnInvitationsHistory: () => void;
  onClose: () => void;
  companyId: string;
};

export default function AddPayrollOfficerForm({
  focusOnInvitationsHistory,
  onClose,
  companyId,
}: Props) {
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const form = useForm({
    initialValues: {
      mail: '',
    },
    validate: {
      mail: isEmail(t('w.invalid.email')),
    },
  });

  const {
    mutate: inviteAccountantToAccess,
    isLoading: isInviteAccountantToAccessLoading,
  } = useMutation({
    mutationFn: (variables: { mail: string }) =>
      CharteredAccountantService.inviteAccountantToAccess(
        companyId,
        variables.mail
      ),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [
          'CharteredAccountantService.invitationAccountantHistory',
          companyId,
        ],
      });
      if (data) {
        const { toMail } = data;
        showNotification({
          id: `send-email-${toMail}-invitation-to-accountant-successful`,
          title: t('w.success'),
          message: t('w.mailSent', toMail),
          color: 'green',
          icon: <IconCheck />,
        });
        onClose();
        focusOnInvitationsHistory();
      }
    },
    onError: (error) =>
      showNotification({
        id: `send-email-invitation-to-accountant-error`,
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        inviteAccountantToAccess({ mail: values.mail })
      )}
    >
      <LoadingOverlay visible={isInviteAccountantToAccessLoading} />
      <TextInput
        required
        label={<LabelText text={t('w.emailAddress')} />}
        {...form.getInputProps('mail')}
      />
      <Flex mt={'xl'} justify={'flex-end'}>
        <ButtonSendInvitation />
      </Flex>
    </form>
  );
}
