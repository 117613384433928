import { CompanyLeave } from '../types/types';
import { MS_PLANNING_ENDPOINT } from '../variables/GlobalVariables';
import request from './AxiosClient';

export interface CompanyLeaveData {
  companyId: string;
  name: string;
  periodStart: string;
  periodEnd: string;
  description: string;
  excludeDivisions: string[];
  leaveTypeId: string;
  startHalf: boolean;
  endHalf: boolean;
}

function getCompanyLeaves(companyId: string): Promise<CompanyLeave[]> {
  return request
    .get(`${MS_PLANNING_ENDPOINT}/v2/planning/${companyId}/mandatory-leaves`)
    .then((res) => res?.data);
}

function createCompanyLeave(companyId: string, data: CompanyLeaveData) {
  return request
    .post(
      `${MS_PLANNING_ENDPOINT}/v2/planning/${companyId}/mandatory-leaves`,
      data
    )
    .then((res) => res?.data);
}

function deleteCompanyLeave(companyId: string, leaveId: string) {
  return request
    .delete(
      `${MS_PLANNING_ENDPOINT}/v2/planning/${companyId}/mandatory-leaves/${leaveId}`
    )
    .then((res) => res?.data);
}

const MandatoryLeaveService = {
  getCompanyLeaves,
  createCompanyLeave,
  deleteCompanyLeave,
};

export default MandatoryLeaveService;
