import { SegmentedControl } from '@mantine/core';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import { useModule } from '../../../contexts/ModuleProvider';
import { ModuleName } from '../../../types/api/response/module';
import { ACCOUNTANT_MODE, COMPANY_MODE } from '../../../types/types';
import { getPersistedObject, persistData } from '../../../utils/localStorage';

export default function AccountantModeSwitcher() {
  const { getModule } = useModule();
  const { user } = useAuth();
  const { t } = useI18n();
  const [value, setValue] = useState('');
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isAccountantActiveModule = getModule(
    ModuleName.CharteredAccountant
  )?.active;

  useEffect(() => {
    if (getPersistedObject('mode')) {
      setValue(getPersistedObject('mode'));
    }
  }, [getPersistedObject('mode')]);

  useEffect(() => {
    if (
      pathname.includes('accept/link') ||
      pathname.includes('chartered-accountant/accept/customer/invitation')
    ) {
      setValue(ACCOUNTANT_MODE);
      persistData('mode', ACCOUNTANT_MODE);
    }
  }, [pathname]);

  function onModeChange(
    newMode: typeof COMPANY_MODE | typeof ACCOUNTANT_MODE | string
  ) {
    setValue(newMode);
    persistData('mode', newMode);
    if (newMode === ACCOUNTANT_MODE) {
      navigate('/chartered-accountant/market-place');
    } else {
      navigate('/home');
    }
  }

  if (!isAccountantActiveModule || Boolean(user.onBoardingId)) return null;

  return (
    <SegmentedControl
      color={
        value === COMPANY_MODE
          ? 'var(--mantine-color-hifivework-5)'
          : 'var(--mantine-color-orange-3)'
      }
      bg={'#ECEBF8'}
      value={value}
      onChange={onModeChange}
      data={[
        { label: t('w.myCompany'), value: COMPANY_MODE },
        { label: t('w.accountantMode'), value: ACCOUNTANT_MODE },
      ]}
    />
  );
}
