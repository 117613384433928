import { Box, Group, Stepper } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  IconCalendarTime,
  IconCheck,
  IconFile,
  IconUser,
} from '@tabler/icons-react';
import React, { useState } from 'react';

import ConfidentialOverlay from '../../../../../components/ConfidentialOverlay';
import { useAuth } from '../../../../../contexts/AuthProvider';
import { useI18n } from '../../../../../contexts/I18nProvider';
import useResponsive from '../../../../../hooks/useResponsive';
import {
  BuiltInPermissions,
  ScopeEnum,
} from '../../../../../types/api/response/role';
import type { UserResponse } from '../../../../../types/api/response/user';
import { hasPermission } from '../../../../../utils/authorization';
import { isHiFiveWorkProject } from '../../../../../utils/projects';
import s from '../AddEmployeeSteppers/AddEmployeeSteppers.module.sass';
import { AddEmployeeSensitiveFormValues } from './AddEmployeeSensitiveForm';
import BankForm from './BankForm';
import ForeignWorkerForm from './ForeignWorkerForm';
import IdentityForm from './IdentityForm';
import SocialSecurityForm from './SocialSecurityForm';

type Props = {
  form: UseFormReturnType<
    AddEmployeeSensitiveFormValues,
    (values: AddEmployeeSensitiveFormValues) => AddEmployeeSensitiveFormValues
  >;
  employee: UserResponse;
};

export default function SensitiveInfoStepper({ form, employee }: Props) {
  const { t } = useI18n();
  const { isDesktop } = useResponsive();
  const [activeStepper, setActiveStepper] = useState(0);
  const { user } = useAuth();

  const isOnboardingLoggedUserValidatedByUser =
    Boolean(employee?.onBoardingId) &&
    user.id === employee?.id &&
    employee?.onBoarding?.onBoardingEmployee?.validationDate !== null;
  const isNotMe = user.id !== employee?.id;
  const canReadPersonalInfo = hasPermission(
    {
      permission: BuiltInPermissions.UserPersonalInfoShow,
      scope: ScopeEnum.ALL,
    },
    user,
    employee?.companyId
  );
  const canEditPersonalInfo = hasPermission(
    {
      permission: BuiltInPermissions.UserPersonalInfoEdit,
      scope: ScopeEnum.ALL,
    },
    user,
    employee?.companyId
  );

  const showPersonalInfoConfidential =
    !isOnboardingLoggedUserValidatedByUser &&
    isNotMe &&
    !canReadPersonalInfo &&
    !canEditPersonalInfo;

  function renderComponentRequest() {
    const disabled =
      isOnboardingLoggedUserValidatedByUser ||
      (!canEditPersonalInfo && isNotMe);

    switch (activeStepper) {
      case 0:
        return (
          <IdentityForm employee={employee} form={form} disabled={disabled} />
        );
      case 1:
        return (
          <SocialSecurityForm
            employee={employee}
            form={form}
            disabled={disabled}
          />
        );
      case 2:
        return <BankForm employee={employee} form={form} disabled={disabled} />;
      case 3:
        return (
          <ForeignWorkerForm
            form={form}
            employee={employee}
            disabled={disabled}
          />
        );
    }
  }

  return (
    <Group grow preventGrowOverflow={false} wrap="nowrap" align="start">
      <Stepper
        size="sm"
        className={isDesktop ? s.stepper : undefined}
        active={activeStepper}
        orientation={'vertical'}
        onStepClick={setActiveStepper}
      >
        <Stepper.Step
          icon={<IconUser />}
          label={isDesktop ? t('w.identityDocument') : ''}
          description={isDesktop ? t('w.documents') : ''}
          completedIcon={<IconCheck />}
        />
        <Stepper.Step
          style={isHiFiveWorkProject() ? undefined : { display: 'none' }}
          icon={<IconCalendarTime />}
          label={isDesktop ? t('w.socialSecurity') : ''}
          description={isDesktop ? t('w.documents') : ''}
          completedIcon={<IconCheck />}
        />
        <Stepper.Step
          icon={<IconFile />}
          label={isDesktop ? t('w.informations') : ''}
          description={isDesktop ? t('w.banking') : ''}
          completedIcon={<IconCheck />}
        />
        <Stepper.Step
          icon={<IconFile />}
          label={isDesktop ? t('w.aWorker') : ''}
          description={isDesktop ? t('w.foreign') : ''}
          completedIcon={<IconCheck />}
        />
      </Stepper>
      <Box pos="relative">
        {showPersonalInfoConfidential && <ConfidentialOverlay />}
        {renderComponentRequest()}
      </Box>
    </Group>
  );
}
