import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import CompanyService from '../services/CompanyService';
import { UserResponse } from '../types/api/response/user';

export default function useCompany(
  user: UserResponse,
  clientCompanyId?: string
) {
  const { companyId } = useParams();
  const id: string = useMemo(
    () =>
      !!clientCompanyId
        ? clientCompanyId
        : !!companyId
        ? companyId
        : !!user?.company?.id
        ? user.company.id
        : user?.companyId!,
    [companyId, user]
  );

  const fiveMinutes = 300000;

  const { data, refetch } = useQuery({
    enabled: !!id,
    queryKey: ['CompanyService.getCompany', id],
    queryFn: () => CompanyService.getCompany(id),
    staleTime: fiveMinutes,
  });

  return { id, company: data, refetch };
}
