import { CommonEntity } from '../common';

export type Template = CommonEntity & {
  ownerId: string;
  name: string;
  file?: string;
  keys: string[];
};

export type TemplateMapping = CommonEntity & {
  templateId: string;
  templateKey: string;
  entityClass?: string;
  entityField?: string;
  entityKey?: string;
};

export enum TemplateKeyEnum {
  NOT_MAPPED_KEY = 'NOT_MAPPED_KEY',

  USER_MAIL = 'USER_MAIL',
  USER_FIRSTNAME = 'USER_FIRSTNAME',
  USER_LASTNAME = 'USER_LASTNAME',
  USER_PHONE = 'USER_PHONE',
  USER_EMPLOYMENT = 'USER_EMPLOYMENT',

  PERSONALINFO_NATIONALITY = 'PERSONALINFO_NATIONALITY',
  PERSONALINFO_GENDER = 'PERSONALINFO_GENDER',
  PERSONALINFO_PHONENUMBER = 'PERSONALINFO_PHONENUMBER',
  PERSONALINFO_BIRTHDAY = 'PERSONALINFO_BIRTHDAY',
  PERSONALINFO_BIRTHPLACE = 'PERSONALINFO_BIRTHPLACE',
  PERSONALINFO_BIRTHDEPARTMENT = 'PERSONALINFO_BIRTHDEPARTMENT',
  PERSONALINFO_ADDRESS = 'PERSONALINFO_ADDRESS',
  PERSONALINFO_ZIPCODE = 'PERSONALINFO_ZIPCODE',
  PERSONALINFO_CITY = 'PERSONALINFO_CITY',
  PERSONALINFO_BIRTHCOUNTRY = 'PERSONALINFO_BIRTHCOUNTRY',
  PERSONALINFO_BIRTHNAME = 'PERSONALINFO_BIRTHNAME',
  PERSONALINFO_COMMONNAME = 'PERSONALINFO_COMMONNAME',

  CONTRACTUALINFO_REGIME = 'CONTRACTUALINFO_REGIME',
  CONTRACTUALINFO_REGIMELEVEL = 'CONTRACTUALINFO_REGIMELEVEL',
  CONTRACTUALINFO_OTHERREGIME = 'CONTRACTUALINFO_OTHERREGIME',
  CONTRACTUALINFO_PROFESSIONALQUALIFICATIONS = 'CONTRACTUALINFO_PROFESSIONALQUALIFICATIONS',
  CONTRACTUALINFO_CONTRACTTYPE = 'CONTRACTUALINFO_CONTRACTTYPE',
  CONTRACTUALINFO_OTHERCONTRACTTYPE = 'CONTRACTUALINFO_OTHERCONTRACTTYPE',
  CONTRACTUALINFO_WEEKLYHOURS = 'CONTRACTUALINFO_WEEKLYHOURS',
  CONTRACTUALINFO_MONTHLYHOURS = 'CONTRACTUALINFO_MONTHLYHOURS',
  CONTRACTUALINFO_YEARLYHOURS = 'CONTRACTUALINFO_YEARLYHOURS',
  CONTRACTUALINFO_GROSSPAY = 'CONTRACTUALINFO_GROSSPAY',
  CONTRACTUALINFO_ENTRYDATE = 'CONTRACTUALINFO_ENTRYDATE',
  CONTRACTUALINFO_EXITDATE = 'CONTRACTUALINFO_EXITDATE',
  CONTRACTUALINFO_EMPLOYMENTAUTHORIZATIONDATE = 'CONTRACTUALINFO_EMPLOYMENTAUTHORIZATIONDATE',
  CONTRACTUALINFO_LAYOFFAUTHORIZATIONDATE = 'CONTRACTUALINFO_LAYOFFAUTHORIZATIONDATE',
  CONTRACTUALINFO_TRIALPERIODENDDATE = 'CONTRACTUALINFO_TRIALPERIODENDDATE',

  PERSONALSENSITIVE_IDENTITYCARDNUMBER = 'PERSONALSENSITIVE_IDENTITYCARDNUMBER',
  PERSONALSENSITIVE_SSNUMBER = 'PERSONALSENSITIVE_SSNUMBER',
  PERSONALSENSITIVE_HANDICAPPEDWORKER = 'PERSONALSENSITIVE_HANDICAPPEDWORKER',
  PERSONALSENSITIVE_IBAN = 'PERSONALSENSITIVE_IBAN',
  PERSONALSENSITIVE_BIC = 'PERSONALSENSITIVE_BIC',
  PERSONALSENSITIVE_RESIDENCEPERMITNUMBER = 'PERSONALSENSITIVE_RESIDENCEPERMITNUMBER',
  PERSONALSENSITIVE_RESIDENCEPERMITEXPIRATIONDATE = 'PERSONALSENSITIVE_RESIDENCEPERMITEXPIRATIONDATE',
  PERSONALSENSITIVE_MEDICALCHECKUPDATE = 'PERSONALSENSITIVE_MEDICALCHECKUPDATE',

  PERSONALFAMILY_FAMILYSTATE = 'PERSONALFAMILY_FAMILYSTATE',
  PERSONALFAMILY_NUMBERDEPENDENTS = 'PERSONALFAMILY_NUMBERDEPENDENTS',
  PERSONALFAMILY_NUMBERDEPENDENTSCHILDREN = 'PERSONALFAMILY_NUMBERDEPENDENTSCHILDREN',

  COMPANY_NAME = 'COMPANY_NAME',
  COMPANY_ADDRESS = 'COMPANY_ADDRESS',
  COMPANY_ZIPCODE = 'COMPANY_ZIPCODE',
  COMPANY_CITY = 'COMPANY_CITY',
  COMPANY_PHONE = 'COMPANY_PHONE',
  COMPANY_FAX = 'COMPANY_FAX',
  COMPANY_MAIL = 'COMPANY_MAIL',
  COMPANY_SIRET = 'COMPANY_SIRET',
  COMPANY_HEALTHSERVICE = 'COMPANY_HEALTHSERVICE',
  COMPANY_CODEAPE = 'COMPANY_CODEAPE',

  DIVISION_NAME = 'DIVISION_NAME',
}
