import { Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import Page, { PageLink } from '../../components/Page/Page';
import { useAppBar } from '../../contexts/AppBarProvider';
import { useAuth } from '../../contexts/AuthProvider';
import { useI18n } from '../../contexts/I18nProvider';
import { useModule } from '../../contexts/ModuleProvider';
import useDisplayTeamActivities from '../../hooks/accessChecking/activities/useDisplayTeamActivities';
import useCompany from '../../hooks/useCompany';
import useFetchDivisions from '../../hooks/useFetchDivisions';
import DailyWorkService from '../../services/DailyWorkService';
import LeaveTypeService from '../../services/LeaveTypeService';
import { DayOfWeek } from '../../types/api/common';
import type {
  DailyWorkDailyTemplateResponse,
  DailyWorkWeeklyTemplateResponse,
  WeeklyPeriod,
} from '../../types/api/response/dailyWork';
import { ModuleName } from '../../types/api/response/module';
import { BuiltInPermissions, ScopeEnum } from '../../types/api/response/role';
import { hasPermission } from '../../utils/authorization';
import { toDateOfDayString } from '../../utils/format';
import handleErrorMessage from '../../utils/handleErrorMessage';
import { htmlToPdf } from '../../utils/htmlToPdf';
import saveDownloadedfile from '../../utils/saveDownloadedFile';
import ImportActivitiesForm from '../activities/my-activities/components/ImportActivitiesForm';
import BlockingPeriodsStepper from '../company/periods/components/blocking-periods/BlockingPeriodsStepper';
import CompanyMandatoryLeavesStepper from '../company/periods/components/company-mandatory-leaves/CompanyMandatoryLeavesStepper';
import { ActivityTemplatesCrud } from './components/ActivityTemplatesCrud';
import LeaveTypeFilter from './components/LeaveTypeFilter/LeaveTypeFilter';
import PlanningExportExcelMonthPicker from './components/PlanningExportExcelMonthPicker';
import PlanningScheduler, {
  PlanningSchedulerRef,
} from './components/PlanningScheduler';

export const weeklyPeriods: WeeklyPeriod[] = [
  { day: DayOfWeek.MONDAY, period: [] },
  { day: DayOfWeek.TUESDAY, period: [] },
  { day: DayOfWeek.WEDNESDAY, period: [] },
  { day: DayOfWeek.THURSDAY, period: [] },
  { day: DayOfWeek.FRIDAY, period: [] },
  { day: DayOfWeek.SATURDAY, period: [] },
  { day: DayOfWeek.SUNDAY, period: [] },
];

export default function PlanningView() {
  const planningId = 'planning-id-to-print';
  const [key, setKey] = useState(0);
  const planningSchedulerRef = useRef<PlanningSchedulerRef>(null);
  const exportDate = planningSchedulerRef.current?.visibleTimes?.start;
  const visibleTimes = planningSchedulerRef.current?.visibleTimes;
  const leaveTypeFilterOptions =
    planningSchedulerRef.current?.leaveTypeFilterOptions;
  const onLeaveTypeFilterClick =
    planningSchedulerRef.current?.onLeaveTypeFilterClick;
  const { user } = useAuth();
  const { t } = useI18n();
  const { id: companyId, company } = useCompany(user);
  const { getModule } = useModule();
  const canImportActivities = useDisplayTeamActivities();
  const {
    appBarSelectedDivisions,
    setIsAppBarUsed,
    setDivisionsToSelect,
    setAppBarSelectedDivisions,
  } = useAppBar();
  const { divisions } = useFetchDivisions({ companyId });
  const [openedTemplates, { toggle }] = useDisclosure(false);
  const [selectedTemplate, setSelectedTemplate] = useState<
    DailyWorkDailyTemplateResponse | DailyWorkWeeklyTemplateResponse | undefined
  >(undefined);
  const { data: leaveTypes } = useQuery({
    queryKey: ['LeaveTypeService.getLeaveTypes', companyId],
    queryFn: () => LeaveTypeService.getLeaveTypes(companyId),
    onError: (err) =>
      showNotification({
        id: 'get-all-leaves-types-list-error',
        title: t('w.error'),
        message: handleErrorMessage(err, t),
        color: 'red',
        icon: <IconX />,
      }),
  });
  const { mutate: exportActivitiesDivisions } = useMutation({
    mutationFn: (payload: { month: number; year: number }) =>
      DailyWorkService.exportUserDailyWorkByDivisions(
        selectedDivisions,
        payload.month,
        payload.year
      ),
    onSuccess: (data) => {
      saveDownloadedfile(data);
    },
    onError: (error) =>
      showNotification({
        id: 'export-dailywork-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconX />,
        color: 'red',
      }),
  });

  const selectedDivisions: string[] = useMemo(() => {
    if (!divisions || !appBarSelectedDivisions?.length) return [];

    const result: string[] = [];
    appBarSelectedDivisions.forEach((selectedDivision: string) => {
      const findDivision = divisions.find(
        (division) => division.name === selectedDivision
      );
      if (findDivision) {
        result.push(findDivision.id);
      }
    });
    return result;
  }, [divisions, appBarSelectedDivisions]);

  const dailyWorkModule = getModule(ModuleName.DailyWork);

  useEffect(() => {
    setIsAppBarUsed(true);
    return function cleanup() {
      setIsAppBarUsed(false);
    };
  }, []);

  useEffect(() => {
    buildAppbarDivisions();
  }, [divisions]);

  function buildAppbarDivisions() {
    const _divisions = filterDivisionsByAccessRight();
    const divisionsNames = _divisions.map((division) => division.name);
    setDivisionsToSelect(divisionsNames);
    setAppBarSelectedDivisions(divisionsNames);
  }
  const handleRefetch = () => {
    if (planningSchedulerRef.current) {
      planningSchedulerRef.current.refetchPlanning();
    }
  };

  function filterDivisionsByAccessRight() {
    let _divisions = cloneDeep(divisions);

    // managers only see divisions where they are n1, n2 or assistant
    if (
      !hasPermission(
        [
          {
            permission: BuiltInPermissions.AuthorizedReadPlanning,
            scope: ScopeEnum.ALL,
          },
          {
            permission: BuiltInPermissions.AuthorizedReadAllUsersInfo,
            scope: ScopeEnum.ALL,
          },
        ],
        user,
        companyId
      )
    ) {
      _divisions = _divisions.filter(
        (_div) =>
          _div.n1 === user.id ||
          _div.n2 === user.id ||
          _div.assistant === user.id ||
          _div.id === user.division?.id
      );
    }
    return _divisions;
  }

  function getLinks() {
    const links: PageLink[] = [];
    if (!!divisions.length) {
      if (
        hasPermission(
          {
            permission: BuiltInPermissions.ConfigureBlockingPeriods,
            scope: ScopeEnum.ALL,
          },
          user,
          companyId
        )
      ) {
        links.push({
          label: t('w.addBlockingPeriod'),
          onClick: () => {
            modals.open({
              modalId: 'addBlockingPeriod-modal',
              title: (
                <Title size={'h3'} component="p">
                  {t('w.addBlockingPeriod')}
                </Title>
              ),
              size: '80%',
              children: (
                <BlockingPeriodsStepper
                  divisions={filterDivisionsByAccessRight()}
                  onClose={() => modals.close('addBlockingPeriod-modal')}
                  refresh={handleRefetch}
                />
              ),
            });
          },
          active: false,
        });
      }

      if (
        !!leaveTypes?.length &&
        hasPermission(
          {
            permission: BuiltInPermissions.ConfigurePublicHolidaysCompanyLeaves,
            scope: ScopeEnum.ALL,
          },
          user,
          companyId
        )
      ) {
        links.push({
          label: t('w.addCompanyLeave'),
          onClick: () => {
            modals.open({
              modalId: 'addCompanyLeave-modal',
              title: (
                <Title size={'h3'} component="p">
                  {t('w.addCompanyLeave')}
                </Title>
              ),
              size: '80%',
              children: (
                <CompanyMandatoryLeavesStepper
                  divisions={filterDivisionsByAccessRight()}
                  allCompanyLeaveTypesListData={leaveTypes}
                  refresh={handleRefetch}
                  onClose={() => modals.close('addCompanyLeave-modal')}
                />
              ),
            });
          },
          active: false,
        });
      }
    }

    if (exportDate) {
      const isPresenceEntryActive = Boolean(
        selectedDivisions.length > 0 &&
          dailyWorkModule?.active &&
          !!planningSchedulerRef.current
      );

      if (dailyWorkModule?.active) {
        links.push({
          label: t('w.presenceEntry'),
          onClick: () => {
            toggle();
            setSelectedTemplate(undefined);
          },
          active: isPresenceEntryActive && openedTemplates,
          disabled: !selectedDivisions.length && dailyWorkModule?.active,
          children: (
            <>
              {isPresenceEntryActive && (
                <ActivityTemplatesCrud
                  selectedTemplate={selectedTemplate}
                  setSelectedTemplate={setSelectedTemplate}
                />
              )}
            </>
          ),
        });
      }

      if (canImportActivities) {
        links.push({
          active: false,
          label: `${t('w.export')} ${t('w.activities').toLowerCase()}`,
          onClick: () => {
            setTimeout(
              () =>
                exportActivitiesDivisions({
                  month: exportDate.getMonth() + 1,
                  year: exportDate.getFullYear(),
                }),
              500
            );
          },
        });
        if (
          hasPermission(
            {
              permission: BuiltInPermissions.AuthorizedUpsertDailyWork,
              scope: ScopeEnum.ALL,
            },
            user,
            companyId
          )
        ) {
          links.push({
            active: false,
            label: `${t('w.import')} ${t('w.activities').toLowerCase()}`,
            onClick: () =>
              openImportActivities({
                month: exportDate.getMonth() + 1,
                year: exportDate.getFullYear(),
              }),
          });
        }
      }

      links.push({
        active: false,
        label: t('planning.export.pdf'),
        disabled: selectedDivisions.length === 0,
        onClick: exportPdf,
      });

      links.push({
        active: false,
        label: t('planning.export.xlsx'),
        disabled: selectedDivisions.length === 0,
        onClick: openExportXlsxModal,
      });
    }

    return !!links.length ? links : undefined;
  }

  function openImportActivities(options: { month: number; year: number }) {
    modals.open({
      modalId: `import-user-activities`,
      title: (
        <Title size={'h3'} component="p">
          {t('w.import')}
        </Title>
      ),
      size: 'xl',
      children: (
        <ImportActivitiesForm
          exportClick={() => exportActivitiesDivisions({ ...options })}
          refresh={handleRefetch}
        />
      ),
    });
  }

  function exportPdf() {
    if (!visibleTimes) return;

    let periodString = '';
    if (visibleTimes.unit === 'week') {
      periodString = `Semaine ${dayjs(visibleTimes.start).isoWeek()}`;
    } else {
      periodString = dayjs(visibleTimes.start).format('MMMM YYYY');
    }
    htmlToPdf(planningId, t, `Planning-${company?.name}-${periodString}`);
  }

  function openExportXlsxModal() {
    if (!companyId) return;

    modals.open({
      id: 'choose-month-export-planning-excel',
      title: (
        <Title size={'h3'} component="p">
          {t('planning.export.xlsx')}
        </Title>
      ),
      children: (
        <PlanningExportExcelMonthPicker
          selectedDivisions={selectedDivisions}
          closeModal={() => modals.close('choose-month-export-planning-excel')}
          companyId={companyId}
        />
      ),
    });
  }

  const refreshParentView = useCallback(() => {
    setKey((prevKey) => prevKey + 1);
  }, []);

  return (
    <Page
      parent={{ label: toDateOfDayString() }}
      title={t('w.planning')}
      links={getLinks()}
      headerRightSection={
        <div key={key}>
          {!!leaveTypeFilterOptions?.length && !!onLeaveTypeFilterClick ? (
            <LeaveTypeFilter
              options={leaveTypeFilterOptions}
              onChange={onLeaveTypeFilterClick}
            />
          ) : undefined}
        </div>
      }
    >
      {company && (
        <PlanningScheduler
          planningId={planningId}
          selectedDivisions={selectedDivisions}
          selectedTemplate={selectedTemplate}
          divisions={divisions}
          company={company}
          ref={planningSchedulerRef}
          refreshParentView={refreshParentView}
        />
      )}
    </Page>
  );
}
