import {
  ActionIcon,
  Badge,
  Group,
  Skeleton,
  Title,
  Tooltip,
} from '@mantine/core';
import {
  type DatesRangeValue,
  DateValue,
} from '@mantine/dates/lib/types/DatePickerValue';
import { modals } from '@mantine/modals';
import {
  IconInfoCircle,
  IconProgress,
  IconUpload,
  IconWindowMaximize,
} from '@tabler/icons-react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React from 'react';

import CustomCompanyAvatar from '../../../../components/CustomAvatar/CustomCompanyAvatar';
import { useI18n } from '../../../../contexts/I18nProvider';
import useFetchDivisions from '../../../../hooks/useFetchDivisions';
import EndOfMonthService from '../../../../services/EndOfMonthService';
import type { MonthStatus } from '../../../../types/api/response/accountant';
import type { CompanyResponse } from '../../../../types/api/response/company';
import { Payroll } from '../../../../types/api/response/endMonth';
import type { EndOfMonthDataResponseFront } from '../../../../types/front/endMonth';
import { persistData } from '../../../../utils/localStorage';
import AccountantCustomersEndOfMonthStepper from './AccountantCustomersEndOfMonthStepper';

type Props = {
  status: MonthStatus;
  clientCompanyId: string;
  accountantCompanyId: string;
  customerCompany: CompanyResponse;
  selectedDate: DateValue | DatesRangeValue | Date[];
  managedDivisionsIds: string[] | null;
};

export default function AccountantCustomersEndOfMonthStatusBadge({
  status,
  clientCompanyId,
  accountantCompanyId,
  customerCompany,
  selectedDate,
  managedDivisionsIds,
}: Props) {
  const { t } = useI18n();
  const queryClient = useQueryClient();
  const { divisions } = useFetchDivisions({ companyId: clientCompanyId });
  const { data, isLoading } = useQuery({
    enabled: !!divisions.length,
    queryKey: [
      'EndOfMonthService.getEndOfMonthValidation',
      clientCompanyId,
      // @ts-ignore
      dayjs(selectedDate).set('date', 15).format('DD/MM/YYYY'),
    ],
    queryFn: () =>
      EndOfMonthService.getEndOfMonthValidation(clientCompanyId, {
        // @ts-ignore
        monthConcerned: dayjs(selectedDate)
          .set('date', 15)
          .format('DD/MM/YYYY'),
        divisionIds: divisions?.map((item) => item.id),
      }),
  });

  function getPayslipsTodoNumber(): number | null {
    const result: EndOfMonthDataResponseFront[] = [];
    if (!data) return null;

    data.forEach((item) => {
      item.data.forEach((data) => {
        result.push({
          ...data,
          expenseReports: [],
          variablesPayrollArray: [],
          endOfMonthId: item.id,
        });
      });
    });

    return result.filter((item) => item.payslipStatus === Payroll.TODO).length;
  }

  function getStatus() {
    let badgeColor: string = '#FCF0E0';
    let badgeLabelColor: string = '#EDB464';
    let badgelabel: string = t('w.waitingClientInfo');
    let badgeIcon = <IconInfoCircle color="var(--mantine-color-orange-5)" />;

    if (status.globalValidation) {
      badgeColor = 'var(--mantine-color-hifivework-2)';
      badgeLabelColor = 'white';
      badgelabel = t('w.preparePayslips');
      badgeIcon = <IconProgress />;
    }

    if (getPayslipsTodoNumber() != null && getPayslipsTodoNumber() === 0) {
      badgeColor = '#CEE9E6';
      badgeLabelColor = '#0A9182';
      badgelabel = t('w.importPayslips');
      badgeIcon = <IconUpload color={'#0A9182'} />;
    }

    return {
      badgeColor: badgeColor,
      badgeLabelColor: badgeLabelColor,
      badgelabel: badgelabel,
      badgeIcon: badgeIcon,
    };
  }

  function getDivisions() {
    if (!managedDivisionsIds) return divisions;

    return divisions?.filter((item) => managedDivisionsIds.includes(item.id));
  }

  function handleOpenCustomerEndOfMonthClick() {
    persistData('customerEndOfMonthStepper', '0');
    modals.open({
      transitionProps: { transition: 'slide-down' },
      modalId: `customer-company-id-${clientCompanyId}-end-of-month-modal`,
      title: (
        <Group>
          <CustomCompanyAvatar companyId={clientCompanyId} size={'lg'} />
          <Title size={'h3'} component="p">{`${customerCompany.name} / ${
            getStatus().badgelabel === t('w.waitingClientInfo')
              ? t('w.endOfMonthValidation')
              : getStatus().badgelabel
          } [${status.monthConcerned}]`}</Title>
        </Group>
      ),
      fullScreen: true,
      children: (
        <AccountantCustomersEndOfMonthStepper
          selectedDate={selectedDate}
          divisions={getDivisions()}
          customerCompany={customerCompany}
          accountantCompanyId={accountantCompanyId}
          globalValidation={status.globalValidation}
        />
      ),
      onClose: () => {
        queryClient.invalidateQueries({
          queryKey: ['CharteredAccountantService.accountantCustomersList'],
        });
      },
    });
  }

  return (
    <Group>
      <Tooltip label={t('w.open')}>
        <ActionIcon
          variant="outline"
          radius="xl"
          onClick={handleOpenCustomerEndOfMonthClick}
        >
          <IconWindowMaximize
            style={{ width: '70%', height: '70%' }}
            stroke={1.5}
          />
        </ActionIcon>
      </Tooltip>
      {isLoading ? (
        <Skeleton h="md" w="xl" />
      ) : (
        <Badge
          size="lg"
          leftSection={getStatus().badgeIcon}
          color={getStatus().badgeColor}
          styles={{
            root: { textTransform: 'none', cursor: 'pointer' },
            label: { color: getStatus().badgeLabelColor },
          }}
          onClick={handleOpenCustomerEndOfMonthClick}
        >
          {getStatus().badgelabel}
        </Badge>
      )}
    </Group>
  );
}
