import {
  ActionIcon,
  Flex,
  Group,
  Image,
  Paper,
  Text,
  Tooltip,
} from '@mantine/core';
import {
  Dropzone,
  FileWithPath,
  IMAGE_MIME_TYPE,
  MIME_TYPES,
  PDF_MIME_TYPE,
} from '@mantine/dropzone';
import {
  IconCheck,
  IconCircleCheck,
  IconCloudUpload,
  IconX,
} from '@tabler/icons-react';
import React, { PropsWithChildren } from 'react';

import { useI18n } from '../../contexts/I18nProvider';
import FileViewer from '../FileViewer/FileViewer';
import s from './CustomDropzone.module.sass';

type Props = {
  onDrop: (files: FileWithPath[]) => void;
  files: FileWithPath[];
  mimeType:
    | 'img'
    | 'pdf'
    | 'excel'
    | 'expense-report'
    | 'docx'
    | 'docxPdf'
    | 'several';
  type?: 'default' | 'multiple-files';
  resetFile?: () => void;
  disabled?: boolean;
};

export default function CustomDropzone({
  onDrop,
  files,
  mimeType,
  type = 'default',
  resetFile,
  disabled,
  children,
}: Props & PropsWithChildren) {
  const { t } = useI18n();

  function getAcceptedTypeFile() {
    switch (mimeType) {
      case 'pdf':
        return PDF_MIME_TYPE;
      case 'excel':
        return [MIME_TYPES.xls, MIME_TYPES.xlsx, MIME_TYPES.csv];
      case 'expense-report':
        return [MIME_TYPES.png, MIME_TYPES.jpeg, MIME_TYPES.pdf];
      case 'docx':
        return [MIME_TYPES.docx];
      case 'docxPdf':
        return [MIME_TYPES.docx, MIME_TYPES.pdf];
      case 'several':
        return [
          MIME_TYPES.png,
          MIME_TYPES.gif,
          MIME_TYPES.jpeg,
          MIME_TYPES.svg,
          MIME_TYPES.webp,
          MIME_TYPES.avif,
          MIME_TYPES.mp4,
          MIME_TYPES.zip,
          MIME_TYPES.csv,
          MIME_TYPES.pdf,
          MIME_TYPES.doc,
          MIME_TYPES.docx,
          MIME_TYPES.xls,
          MIME_TYPES.xlsx,
          MIME_TYPES.ppt,
          MIME_TYPES.pptx,
          MIME_TYPES.exe,
        ];
      default:
        return IMAGE_MIME_TYPE.filter(
          (item) => item === 'image/png' || item === 'image/jpeg'
        );
    }
  }

  function getDocumentUrl() {
    if (files.length > 0) {
      return URL.createObjectURL(files[0]);
    }

    return '';
  }

  function renderDropzoneType() {
    switch (type) {
      case 'multiple-files':
        return (
          <Flex direction={'column'} gap={'sm'}>
            <Dropzone
              onDrop={onDrop}
              accept={getAcceptedTypeFile()}
              h={'112px'}
              bg={'#f8f9fa'}
              disabled={disabled}
            >
              <Group justify={'center'}>
                <Dropzone.Accept>
                  <IconCheck size={60} className={s.iconSucces} />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX size={60} stroke={1.5} className={s.iconError} />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <IconCloudUpload size={60} className={s.icon} />
                </Dropzone.Idle>
                <Flex
                  direction={'column'}
                  align={'center'}
                  justify={'center'}
                  gap={'xs'}
                >
                  <Text fz={'md'} fw={'bold'} c={'hifivework.6'}>
                    {t('w.importFileGuide')}
                  </Text>
                  <Text fz={'xs'} c={'dimmed'}>
                    {t('w.orClickToBrowseYourFiles')}
                  </Text>
                </Flex>
              </Group>
            </Dropzone>
            {files.length > 0 &&
              files.map((file, index) => (
                <Paper
                  shadow="md"
                  p="xs"
                  withBorder
                  radius={'md'}
                  key={`${file.name}-${index}`}
                >
                  <Group justify={'space-between'}>
                    <Group gap={'5px'}>
                      <IconCircleCheck size={20} className={s.iconCheck} />
                      <Text mb={-2} fz={'xs'}>
                        {file.path}
                      </Text>
                    </Group>
                    <ActionIcon onClick={resetFile} size={'sm'}>
                      <IconX />
                    </ActionIcon>
                  </Group>
                </Paper>
              ))}
          </Flex>
        );
      default:
        return (
          <Flex direction={'column'} gap={'sm'}>
            <Dropzone
              onDrop={onDrop}
              accept={getAcceptedTypeFile()}
              bg="var(--mantine-color-gray-1)"
              disabled={disabled}
            >
              <Group justify={'center'}>
                {children ? (
                  children
                ) : (
                  <>
                    <Dropzone.Accept>
                      <IconCheck size={60} className={s.iconSucces} />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                      <IconX size={60} stroke={1.5} className={s.iconError} />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                      <IconCloudUpload size={60} className={s.icon} />
                    </Dropzone.Idle>
                  </>
                )}
                <Flex
                  direction={'column'}
                  align={'center'}
                  justify={'center'}
                  gap={'xs'}
                >
                  <Text fz={'md'} fw={'bold'} c={'hifivework.6'}>
                    {t('w.importFileGuide')}
                  </Text>
                  <Text fz={'xs'} c={'dimmed'}>
                    {t('w.orClickToBrowseYourFiles')}
                  </Text>
                </Flex>
              </Group>
            </Dropzone>
            {files.length > 0 && (
              <Paper shadow="md" p="xs" withBorder radius={'md'}>
                <Group justify={'space-between'}>
                  <Group gap={'5px'}>
                    {files[0].type === MIME_TYPES.pdf ? (
                      <FileViewer
                        id={`document`}
                        srcUrl={getDocumentUrl()}
                        title={`${files[0].name}.pdf`}
                        size="md"
                      />
                    ) : (
                      <Image height={60} width={60} src={getDocumentUrl()} />
                    )}
                    <IconCircleCheck size={20} className={s.iconCheck} />
                    <Text fz={'xs'}>{files[0].path}</Text>
                  </Group>
                  <Tooltip label={t('w.delete')}>
                    <ActionIcon
                      onClick={resetFile ? resetFile : undefined}
                      size={'sm'}
                    >
                      <IconX />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              </Paper>
            )}
          </Flex>
        );
    }
  }

  return renderDropzoneType();
}
