import { Button, Group } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';

import { useI18n } from '../../../../contexts/I18nProvider';
import EndOfMonthService from '../../../../services/EndOfMonthService';
import type { CompanyResponse } from '../../../../types/api/response/company';
import { SynchroOpenpayeParams } from '../../../../types/api/response/module';
import handleErrorMessage from '../../../../utils/handleErrorMessage';
import CompanyInfoWrapper from '../../components/CompanyInfoWrapper';

type Props = {
  customerCompany: CompanyResponse;
  accountantCompanyId: string;
  openpayeModuleParams: SynchroOpenpayeParams;
};

export default function ChooseDossierToSyncToExistingClient({
  customerCompany,
  accountantCompanyId,
  openpayeModuleParams,
}: Props) {
  const queryClient = useQueryClient();
  const { t } = useI18n();

  const {
    mutate: synchroOpenpayeDossierEmployeesToExistingClientCompany,
    isLoading,
  } = useMutation({
    mutationFn: (payload: {
      accountantCompanyId: string;
      clientCompanyId: string;
      dossierId: string;
    }) =>
      EndOfMonthService.synchroOpenpayeDossierEmployeesToExistingClientCompany(
        payload.accountantCompanyId,
        payload.clientCompanyId,
        payload.dossierId
      ),
    onSuccess: () => {
      showNotification({
        id: 'createClientFromDossier-success',
        message: t('w.success'),
        color: 'green',
        icon: <IconCheck />,
      });

      queryClient.invalidateQueries({
        queryKey: [
          'CharteredAccountantService.accountantCustomersList',
          accountantCompanyId,
        ],
      });

      modals.closeAll();
    },
    onError: (error) => {
      showNotification({
        id: 'createClientCompanyFromOpenpayeDossier-error',
        title: t('w.error'),
        message: handleErrorMessage(error, t),
        icon: <IconCheck />,
        color: 'red',
      });
    },
  });

  return (
    <Group>
      {openpayeModuleParams.dossiers?.map((dossier) => (
        <Button
          key={dossier.dossierId}
          size="xl"
          variant="light"
          radius="xs"
          onClick={() => {
            showNotification({
              id: 'synchroDossierEmployeesToExistingClient-loading',
              message: t('w.treatmentInProgress'),
              color: 'green',
              icon: <IconCheck />,
            });
            synchroOpenpayeDossierEmployeesToExistingClientCompany({
              accountantCompanyId: accountantCompanyId,
              clientCompanyId: customerCompany.id,
              dossierId: dossier.dossierId + '',
            });
          }}
          loading={isLoading}
        >
          <CompanyInfoWrapper
            companyId={dossier.dossierId + ''}
            companyName={`${dossier.dossierId} - ${dossier.code} - ${
              dossier.nomDossier
            } (${dossier.nbEmployees} ${t('w.users').toLowerCase()})`}
          />
        </Button>
      ))}
    </Group>
  );
}
