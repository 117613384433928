import { useQueryClient } from '@tanstack/react-query';
import React from 'react';

import useCompany from '../../../hooks/useCompany';
import useFetchUsers from '../../../hooks/useFetchUsers';
import type {
  UserContractualInfoType,
  UserEmergencyContactType,
  UserPersonalFamily,
  UserPersonalInfo,
  UserPersonalSensitive,
  UserResponse,
} from '../../../types/api/response/user';
import AddEmployeeSteppers from '../../company/employees/components/AddEmployeeSteppers/AddEmployeeSteppers';

type Props = {
  userProfile: UserResponse;
  userContractualInfo: UserContractualInfoType | undefined;
  userFamilySituation: UserPersonalFamily | undefined;
  userPersonalInfo: UserPersonalInfo | undefined;
  userSensitiveInfo: UserPersonalSensitive | undefined;
  userEmergencyContact: UserEmergencyContactType[] | undefined;
  refetchUserEmergencyContact: () => void;
  refetchUserPersonalInfo: () => void;
  refetchUserSensitiveInfo: () => void;
  refetchUserFamilySituation: () => void;
  refetchUserContractualInfo: () => void;
  refetchOBInfos: () => void;
};

export default function UserProfileView({
  userProfile,
  userContractualInfo,
  userFamilySituation,
  userPersonalInfo,
  userSensitiveInfo,
  userEmergencyContact,
  refetchUserEmergencyContact,
  refetchUserContractualInfo,
  refetchUserSensitiveInfo,
  refetchUserPersonalInfo,
  refetchUserFamilySituation,
  refetchOBInfos,
}: Props) {
  const queryClient = useQueryClient();
  const { id: companyId } = useCompany(userProfile);
  const { divisions, roles } = useFetchUsers(companyId);

  function refetchUserProfile() {
    queryClient.invalidateQueries({
      queryKey: ['ProfileService.getUserProfile', userProfile.id],
    });
  }

  return (
    <AddEmployeeSteppers
      divisions={divisions ? divisions : []}
      roles={roles ? roles : []}
      refetch={refetchUserProfile}
      userProfile={userProfile}
      userPersonalInfo={userPersonalInfo}
      userSensitiveInfo={userSensitiveInfo}
      userContractualInfo={userContractualInfo}
      userFamilySituation={userFamilySituation}
      userEmergencyContact={userEmergencyContact}
      refetchUserEmergencyContact={refetchUserEmergencyContact}
      refetchUserPersonalInfo={refetchUserPersonalInfo}
      refetchUserSensitiveInfo={refetchUserSensitiveInfo}
      refetchUserFamilySituation={refetchUserFamilySituation}
      refetchUserContractualInfo={refetchUserContractualInfo}
      refetchOBInfos={refetchOBInfos}
    />
  );
}
