import { Group, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';

import Page, { PageLink } from '../../../components/Page/Page';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import useCompany from '../../../hooks/useCompany';
import useFetchDivisions from '../../../hooks/useFetchDivisions';
import BlockingPeriodsService from '../../../services/BlockingPeriodsService';
import LeaveTypeService from '../../../services/LeaveTypeService';
import MandatoryLeaveService from '../../../services/MandatoryLeaveService';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import BlockingPeriodsStepper from './components/blocking-periods/BlockingPeriodsStepper';
import BlockingPeriodsTable from './components/blocking-periods/BlockingPeriodsTable';
import CompanyMandatoryLeavesStepper from './components/company-mandatory-leaves/CompanyMandatoryLeavesStepper';
import CompanyMandatoryLeavesTable from './components/company-mandatory-leaves/CompanyMandatoryLeavesTable';

export default function Periods() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId, company } = useCompany(user);
  const [activeLink, setActiveLink] = useState(t('w.blockingPeriods'));

  const {
    data: companyBlockingPeriodsData,
    isLoading: isCompanyBlockingPeriodsListLoading,
    refetch: refetchCompanyBlockingPeriodsData,
  } = useQuery({
    queryKey: ['BlockingPeriodsService.list', companyId],
    queryFn: () => BlockingPeriodsService.list(companyId),
    onError: (err) =>
      showNotification({
        id: 'blocking-period-list-error',
        title: t('w.error'),
        message: handleErrorMessage(err, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const {
    data: companyLeavesListData,
    isLoading: isCompanyLeavesListDataLoading,
    refetch: refetchCompanyLeavesListData,
  } = useQuery({
    queryKey: ['MandatoryLeaveService.getCompanyLeaves', companyId],
    queryFn: () => MandatoryLeaveService.getCompanyLeaves(companyId),
    onError: (err) =>
      showNotification({
        id: 'company-mandatory-leaves-list-error',
        title: t('w.error'),
        message: handleErrorMessage(err, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  const { divisions } = useFetchDivisions({ companyId });

  const { data: allCompanyLeaveTypesListData } = useQuery({
    queryKey: ['LeaveTypeService.getLeaveTypes', companyId],
    queryFn: () => LeaveTypeService.getLeaveTypes(companyId),
    onError: (err) =>
      showNotification({
        id: 'get-all-leaves-types-list-error',
        title: t('w.error'),
        message: handleErrorMessage(err, t),
        color: 'red',
        icon: <IconX />,
      }),
  });

  function getLinks() {
    const links: PageLink[] = [
      {
        active: activeLink === t('w.blockingPeriods'),
        label: t('w.blockingPeriods'),
        onClick: () => setActiveLink(t('w.blockingPeriods')),
      },
      {
        active: activeLink === t('w.companyLeaves'),
        label: t('w.companyLeaves'),
        onClick: () => setActiveLink(t('w.companyLeaves')),
      },
    ];

    return links;
  }

  function renderContent() {
    if (!company) return null;

    switch (activeLink) {
      case t('w.blockingPeriods'):
        if (!companyBlockingPeriodsData) return null;

        return (
          <BlockingPeriodsTable
            company={company}
            companyBlockingPeriodsData={companyBlockingPeriodsData}
            isCompanyBlockingPeriodsListLoading={
              isCompanyBlockingPeriodsListLoading
            }
            refetchCompanyBlockingPeriodsData={
              refetchCompanyBlockingPeriodsData
            }
          />
        );
      case t('w.companyLeaves'):
        if (!companyLeavesListData) return null;

        return (
          <CompanyMandatoryLeavesTable
            company={company}
            companyLeavesListData={companyLeavesListData}
            allCompanyLeaveTypesListData={allCompanyLeaveTypesListData}
            isCompanyLeavesListDataLoading={isCompanyLeavesListDataLoading}
            divisions={divisions}
            refetchCompanyLeavesListData={refetchCompanyLeavesListData}
          />
        );
      default:
        return null;
    }
  }

  if (!company) return null;

  return (
    <Page
      title={t('w.periods')}
      parent={{ label: `${t('w.configuration')} : ${activeLink}` }}
      links={getLinks()}
      add={
        activeLink === t('w.blockingPeriods')
          ? {
              label: t('w.addBlockingPeriod'),
              onClick: () => {
                modals.open({
                  modalId: 'addBlockingPeriod-modal',
                  title: (
                    <Title size={'h3'} component="p">
                      {t('w.addBlockingPeriod')}
                    </Title>
                  ),
                  size: 'xl',
                  children: (
                    <BlockingPeriodsStepper
                      divisions={divisions}
                      onClose={() => modals.close('addBlockingPeriod-modal')}
                      refresh={refetchCompanyBlockingPeriodsData}
                    />
                  ),
                });
              },
            }
          : {
              label: t('w.addCompanyLeave'),
              onClick: () => {
                modals.open({
                  modalId: 'addCompanyLeave-modal',
                  title: (
                    <Title size={'h3'} component="p">
                      {t('w.addCompanyLeave')}
                    </Title>
                  ),
                  size: 'xl',
                  children: (
                    <CompanyMandatoryLeavesStepper
                      divisions={divisions}
                      allCompanyLeaveTypesListData={
                        allCompanyLeaveTypesListData
                      }
                      refresh={refetchCompanyLeavesListData}
                      onClose={() => modals.close('addCompanyLeave-modal')}
                    />
                  ),
                });
              },
            }
      }
    >
      <Group grow preventGrowOverflow={false} wrap="nowrap">
        {renderContent()}
      </Group>
    </Page>
  );
}
