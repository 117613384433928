import { DonutChart } from '@mantine/charts';
import {
  Box,
  ColorSwatch,
  Flex,
  HoverCard,
  LoadingOverlay,
  Paper,
  rem,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { Sector } from 'recharts';

import { useI18n } from '../../../contexts/I18nProvider';
import CounterTypeService from '../../../services/CounterTypeService';
import CounterUserService from '../../../services/CounterUserService';
import type {
  AssociateType,
  CounterType,
} from '../../../types/api/response/counterType';
import type { UserResponse } from '../../../types/api/response/user';
import { getTranslatedKey } from '../../../utils/counterTypesFormatter';
import { NO_WORK_UNIT } from '../../../variables/GlobalVariables';

type Data = {
  date: string;
  companyId: string;
  wait: number;
  solde: number;
  counterTypeId: string;
  userId: string;
  value: number;
  consumes: number;
  associateType: AssociateType[];
  counterType: CounterType;
}[];

type Props = {
  userProfile: UserResponse;
};

export default function UserCounterDashboard({ userProfile }: Props) {
  const { t } = useI18n();
  const { data: userCounters, isLoading: isLoadingUserCounters } = useQuery({
    queryKey: ['CounterUserService.getByUser', userProfile.id],
    queryFn: () => CounterUserService.getByUser(userProfile.id),
  });

  const { data: counterTypes, isLoading: isLoadingCounterTypes } = useQuery({
    enabled: !!userProfile.company?.id,
    queryKey: ['CounterTypeService.list', userProfile.company?.id!],
    queryFn: () => CounterTypeService.list(userProfile.company?.id!),
  });

  const data: Data | null = useMemo(() => {
    if (!userCounters?.length || !counterTypes?.length) return null;

    const filtered = counterTypes.filter((ct) =>
      userCounters.some(
        (uc) => uc.counterTypeId === ct.counterType.id && ct.counterType.active
      )
    );

    const second = filtered.map((ctr) => ({
      ...userCounters.find(
        (uc) => ctr.counterType.id === uc.counterTypeId && uc
      ),
      ...ctr,
    }));

    return second as Data;
  }, [userCounters, counterTypes]);

  function getChartData(item: {
    date: string;
    companyId: string;
    wait: number;
    solde: number;
    counterTypeId: string;
    userId: string;
    value: number;
    consumes: number;
  }) {
    if (item.consumes === 0 && item.wait === 0 && item.solde === 0) {
      return [
        {
          name: '',
          value: 1,
          color: '#F1F3F5',
        },
      ];
    }

    return [
      {
        name: t('w.taken'),
        value: item.consumes,
        color: '#10069F',
      },
      {
        name: t('w.pendingValidation'),
        value: item.wait,
        color: '#cde1fc',
      },
      {
        name: t('w.forecast'),
        value: Math.abs(item.solde),
        color: item.solde > 0 ? '#0A9182' : '#E70D4F',
      },
    ];
  }

  function getUnit(rule?: string) {
    if (!rule) return rule;

    return t(`counterType.rule.${rule}`).toLowerCase()[0];
  }

  function renderActiveShape(
    props: any,
    item: {
      date: string;
      companyId: string;
      wait: number;
      solde: number;
      counterTypeId: string;
      counterType?: CounterType;
      userId: string;
      value: number;
      consumes: number;
    }
  ) {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
      props;

    return (
      <g>
        <text
          fontSize="smaller"
          fontWeight="bold"
          x={cx - 70}
          y={cy + 16}
          textAnchor="middle"
        >
          {`${item.value?.toFixed(2)}${getUnit(item.counterType?.rule)}`}
        </text>
        <text
          fontSize="smaller"
          fontWeight="bold"
          x={cx + 70}
          y={cy + 16}
          textAnchor="middle"
        >
          {`${item.solde?.toFixed(2)}${getUnit(item.counterType?.rule)}`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
      </g>
    );
  }

  if (userProfile.division?.name === NO_WORK_UNIT) {
    return (
      <Paper p={'md'} withBorder>
        <Flex justify={'center'}>
          <Title size={'h2'} order={2}>
            {t('NO_WORK_UNIT')}
          </Title>
        </Flex>
      </Paper>
    );
  }

  if (isLoadingUserCounters || isLoadingCounterTypes)
    return (
      <Box pos="relative" h={160}>
        <LoadingOverlay visible={true} />
      </Box>
    );

  return (
    <>
      {data && (
        <SimpleGrid cols={{ base: 1, md: 2, lg: 3, xl: 4 }}>
          {data.map((item) => (
            <Paper
              key={item.counterTypeId}
              shadow="0px 3px 3px #00000029"
              p="xs"
              withBorder
              h={160}
            >
              <Title size={'h6'} component="p">
                {getTranslatedKey(t, item.counterType?.key)}
              </Title>
              <HoverCard offset={-190}>
                <HoverCard.Target>
                  <Stack>
                    <DonutChart
                      withTooltip={false}
                      data={getChartData(item)}
                      startAngle={180}
                      endAngle={0}
                      h={200}
                      pieProps={{
                        activeIndex: 0,
                        dataKey: 'value',
                        activeShape: (props: any) =>
                          renderActiveShape(props, item),
                      }}
                    />
                  </Stack>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Box m="auto">
                    <Text size="xs" fw="bold">
                      {`${t('w.at')} ${dayjs(item.date).format('DD/MM/YYYY')}`}
                    </Text>
                    <Flex align="center" gap="sm">
                      <ColorSwatch
                        color="#10069F"
                        withShadow={false}
                        size={rem(12)}
                      />
                      <Text size="xs">{`${t(
                        'w.taken'
                      )} : ${item.consumes?.toFixed(2)}${getUnit(
                        item.counterType?.rule
                      )}`}</Text>
                    </Flex>
                    <Flex align="center" gap="sm">
                      <ColorSwatch
                        color="#cde1fc"
                        withShadow={false}
                        size={rem(12)}
                      />
                      <Text size="xs">{`${t(
                        'w.pendingValidation'
                      )} : ${item.wait?.toFixed(2)}${getUnit(
                        item.counterType?.rule
                      )}`}</Text>
                    </Flex>
                    <Flex align="center" gap="sm">
                      <ColorSwatch
                        color={item.solde > 0 ? '#0A9182' : '#E70D4F'}
                        withShadow={false}
                        size={rem(12)}
                      />
                      <Text size="xs">{`${t(
                        'w.forecast'
                      )} : ${item.solde?.toFixed(2)}${getUnit(
                        item.counterType?.rule
                      )}`}</Text>
                    </Flex>
                  </Box>
                </HoverCard.Dropdown>
              </HoverCard>
            </Paper>
          ))}
        </SimpleGrid>
      )}
    </>
  );
}
