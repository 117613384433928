import { Group, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';

import InvitationsHistoryPayrollOfficerTable from '../../../components/InvitationsHistoryPayrollOfficerTable';
import Page from '../../../components/Page/Page';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import useCompany from '../../../hooks/useCompany';
import CharteredAccountantService from '../../../services/CharteredAccountantService';
import handleErrorMessage from '../../../utils/handleErrorMessage';
import AddPayrollOfficerForm from './components/AddPayrollOfficerForm';
import PayrollOfficerTable from './components/PayrollOfficerTable';

export default function PayrollOfficer() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const [activeLink, setActiveLink] = useState(t('w.payrollOfficers'));

  const {
    data: invitationAccountantHistoryData,
    isLoading: isInvitationAccountantHistoryLoading,
    refetch: refetchInvitationAccountantHistoryData,
  } = useQuery({
    queryKey: [
      'CharteredAccountantService.invitationAccountantHistory',
      companyId,
    ],
    queryFn: () =>
      CharteredAccountantService.invitationAccountantHistory(companyId),
    onError: (err) => {
      // @ts-ignore
      if (err.response.status !== 403) {
        showNotification({
          id: 'invitation-accountant-history-error',
          title: t('w.error'),
          message: handleErrorMessage(err, t),
          color: 'red',
          icon: <IconX />,
        });
      }
    },
  });

  function renderContent() {
    switch (activeLink) {
      case t('w.payrollOfficers'):
        return <PayrollOfficerTable isDocuments={false} />;
      case t('w.invitationsHistory'):
        return (
          <InvitationsHistoryPayrollOfficerTable
            invitationAccountantHistoryData={invitationAccountantHistoryData}
            isInvitationAccountantHistoryLoading={
              isInvitationAccountantHistoryLoading
            }
            refetchInvitationAccountantHistoryData={
              refetchInvitationAccountantHistoryData
            }
          />
        );
    }
  }

  return (
    <Page
      title={t('w.payrollOfficers')}
      parent={{
        label:
          activeLink === t('w.payrollOfficers')
            ? t('w.configuration')
            : t('w.invitationsHistory'),
      }}
      links={[
        {
          active: activeLink === t('w.payrollOfficers'),
          onClick: () => setActiveLink(t('w.payrollOfficers')),
          label: t('w.payrollOfficers'),
        },
        {
          active: activeLink === t('w.invitationsHistory'),
          onClick: () => setActiveLink(t('w.invitationsHistory')),
          label: t('w.invitationsHistory'),
        },
      ]}
      add={{
        label: t('w.invitePayrollOfficer'),
        onClick: () => {
          modals.open({
            modalId: 'invitePayrollOfficer-modal',
            title: (
              <Title size={'h3'} component="p">
                {t('w.invitePayrollOfficer')}
              </Title>
            ),
            size: 'xl',
            children: (
              <AddPayrollOfficerForm
                focusOnInvitationsHistory={() =>
                  setActiveLink(t('w.invitationsHistory'))
                }
                onClose={() => modals.close('invitePayrollOfficer-modal')}
                companyId={companyId}
              />
            ),
          });
        },
      }}
    >
      <Group grow preventGrowOverflow={false} wrap="nowrap">
        {renderContent()}
      </Group>
    </Page>
  );
}
