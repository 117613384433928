import { Group, Title } from '@mantine/core';
import { modals } from '@mantine/modals';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo } from 'react';

import Page from '../../../components/Page/Page';
import { useAuth } from '../../../contexts/AuthProvider';
import { useI18n } from '../../../contexts/I18nProvider';
import useCompany from '../../../hooks/useCompany';
import useFetchUsers from '../../../hooks/useFetchUsers';
import ShuttleSheetService from '../../../services/ShuttleSheetService';
import { ShuttleSheetType } from '../../../types/types';
import ShuttleSheetTypeForm from './components/ShuttleSheetTypeForm';
import ShuttleSheetTypeTable from './components/ShuttleSheetTypeTable';

export default function ShuttleSheetTypes() {
  const { t } = useI18n();
  const { user } = useAuth();
  const { id: companyId } = useCompany(user);
  const { roles } = useFetchUsers(companyId);

  const {
    data: shuttleSheetTypeListData,
    isLoading: isShuttleSheetTypeListLoading,
    refetch,
  } = useQuery({
    queryKey: ['ShuttleSheetService.shuttleSheetTypeList', companyId],
    queryFn: () => ShuttleSheetService.shuttleSheetTypeList(companyId),
  });

  const shuttleSheetTypeList: ShuttleSheetType[] = useMemo(() => {
    if (!shuttleSheetTypeListData) {
      return [];
    }
    return shuttleSheetTypeListData;
  }, [shuttleSheetTypeListData]);

  return (
    <Page
      title={t('w.shuttleSheetTypes')}
      parent={{ label: t('w.configuration') }}
      add={{
        label: t('shuttleSheetType.add'),
        onClick: () => {
          modals.open({
            modalId: 'add-shuttle-sheet-type-modal',
            title: (
              <Title size={'h3'} component="p">
                {t('shuttleSheetType.add')}
              </Title>
            ),
            size: '90%',
            children: (
              <ShuttleSheetTypeForm
                shuttleSheetType={{
                  id: '',
                  created: '',
                  updated: '',
                  companyId: '',
                  name: '',
                  deletable: false,
                  transmittedToAccountant: false,
                  usableBy: [],
                  fields: [],
                  availableChoiceName: '',
                }}
                roles={roles}
                companyId={companyId}
                refetch={refetch}
                isEditable
              />
            ),
          });
        },
      }}
    >
      <Group grow preventGrowOverflow={false} wrap="nowrap">
        <ShuttleSheetTypeTable
          rows={shuttleSheetTypeList}
          isLoading={isShuttleSheetTypeListLoading}
          companyId={companyId}
          roles={roles}
          refetch={refetch}
        />
      </Group>
    </Page>
  );
}
