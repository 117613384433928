import { LoadingOverlay, Text } from '@mantine/core';
import * as React from 'react';

import { useI18n } from '../contexts/I18nProvider';

export default function ConfidentialOverlay() {
  const { t } = useI18n();
  return (
    <LoadingOverlay
      visible
      zIndex={9}
      overlayProps={{ blur: 10 }}
      loaderProps={{
        children: (
          <Text fw="bold" c="red">
            {t('w.confidential')}
          </Text>
        ),
      }}
    />
  );
}
