import type { Dayjs } from 'dayjs';
import React from 'react';

import { Address, DateTimeStringRequest } from './api/common';
import type {
  DivisionDetails,
  LeaveDetails,
  UserDetails,
} from './api/response/dailyWork';
import type { DivisionResponse } from './api/response/division';
import type { IdentifiantCompany } from './api/response/user';

export const ACCOUNTANT = 'ACCOUNTANT';
export const ACCOUNTANT_MODE = 'accountant';
export const COMPANY_MODE = 'company';

export type CompanyLeave = {
  created: number;
  id: string;
  updated: number;
  companyId: string;
  description: string;
  excludeDivisions: DivisionResponse[];
  leaveTypeId: string;
  name: string;
  period: {
    end: string;
    endDate: string;
    start: string;
    startDate: string;
    valid: boolean;
  };
};

type Comment = {
  date: number;
  comment: string;
  username: string;
};

export enum ExpenseStatusState {
  WAITING = 'WAITING',
  VALID = 'VALID',
  PROCESSED = 'PROCESSED',
  REFUNDED = 'REFUNDED',
  REJECTED = 'REJECTED',
}

export type ExpenseReportStatus = {
  state: ExpenseStatusState;
  comment?: Comment;
  stateDate?: string;
};

export enum ExpenseReportLabel {
  FUEL = 'FUEL',
  PLANE = 'PLANE',
  TRAIN = 'TRAIN',
  TAXI = 'TAXI',
  TOLL = 'TOLL',
  PARKING = 'PARKING',
  CARRENTAL = 'CARRENTAL',
  BIKERENTAL = 'BIKERENTAL',
  TRANSITPASS = 'TRANSITPASS',
  KILOMETRICALLOWANCE = 'KILOMETRICALLOWANCE',
  MEAL = 'MEAL',
  LODGING = 'LODGING',
  OTHER = 'OTHER',
}

export enum ExpenseReportType {
  DISPLACEMENT = 'DISPLACEMENT',
  MISSION = 'MISSION',
  OTHER = 'OTHER',
  RECEPTION = 'RECEPTION',
}

export type ExpenseReportObject = {
  type: ExpenseReportType;
  comment: string;
  currency: string;
  status: ExpenseReportStatus;
  companyId: string;
  label: ExpenseReportLabel;
  creator: string;
  dateOfExpense: string;
  amount: number;
  additionVATList: number[];
  additionValueVATList: number[];
  id: string;
  created: number;
  updated: number;
  attachment?: string;
  external?: string;
  internal?: string;
  distance?: number;
  placeOfDeparture?: string;
  placeOfArrival?: string;
};

export type UserExpenseReportSummary = {
  waiting: number;
  waitingAmount: string;
  refundProcessing: number;
  refundProcessingAmount: string;
  refunded: number;
  refundedAmount: string;
  rejected: number;
  rejectedAmount: string;
  processed: number;
  processedAmount: string;
  details: UserExpenseReportSummaryDetail[];
};

export type UserExpenseReportSummaryDetail = {
  month: string;
  processed: number;
  processedAmount: string;
  refundProcessing: number;
  refundProcessingAmount: string;
  refunded: number;
  rejected: number;
  solde: string;
  totalTTC: string;
  waiting: number;
  values: ExpenseReportObject[];
};

export type Link = {
  label: string | React.ReactNode;
  icon?: React.FC<any>;
  link?: string;
  divider?: boolean;
  links?: Link[];
  isDisplayed?: boolean;
};

export type OauthUserProfile = {
  id: string;
  phone: string;
  firstname: string;
  lastname: string;
  currentProfile: string;
  profileIds: string[];
  username: string;
};

export type PublicHoliday = {
  date: string;
  key: string;
  worked: boolean;
};

export type BreakDay = {
  end: string;
  start: string;
  valid: boolean;
};

export type BreakDays = {
  userId: string;
  color: string;
  breakDays: BreakDay[];
};

export type PaidFeatures = {
  name: string;
  active: boolean;
};

export type PayslipGma = {
  id: string;
  idStr: string;
  gmaPayslipId: string;
  month: number;
  tmp: boolean;
  visible: boolean;
  year: number;
};

export type PayslipsFromGma = {
  accessToken: string;
  payslips: PayslipGma[];
  refreshToken: string;
  spaceId: string;
};

export type PayslipUser = {
  year: number;
  month: number;
  gmaPayslipId: string;
  id: string;
  created: number;
  updated: number;
};

export type PayslipMonthResponse = {
  id: string;
  created: number;
  updated: number;
  companyId: string;
  year: number;
  month: number;
  sourceKey: {
    date: number;
  }[];
  ok: UserPayslipResponse[];
  new: UserPayslipResponse[];
  notFound: UserPayslipResponse[];
};

export type PayslipMonthResponseFront = {
  id: string;
  created: number;
  updated: number;
  companyId: string;
  year: number;
  month: number;
  sourceKey: {
    date: number;
  }[];
  ok: (UserPayslipResponse & { division?: DivisionResponse })[];
  new: (UserPayslipResponse & { division?: DivisionResponse })[];
  notFound: (UserPayslipResponse & { division?: DivisionResponse })[];
};

export type UserPayslipResponse = {
  userId: string;
  siret: number;
  netAmount: number;
  grossAmount: number;
  visible: boolean;
  divisionId: string;
  lastname: string;
  firstname: string;
  identifiantsCompany: IdentifiantCompany[];
  fullname: string;
  status: UserPayslipResponseStatus;
  notification?: number;
  counter: {
    [name: string]: number;
  };
};

export enum UserPayslipResponseStatus {
  OK = 'OK',
  NEW = 'NEW',
  NOTFOUND = 'NOTFOUND',
}

type PayslipsMonthTreatmentError = {
  msg: string;
  key: string;
};

export enum PayslipsMonthTreatmentState {
  SUCCESS = 'SUCCESS',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
}

export type PayslipsMonthTreatment = {
  id: string;
  state: PayslipsMonthTreatmentState;
  year: number;
  month: number;
  companyId: string;
  created: number;
  updated: number;
  filename: string;
  error: {
    errors: PayslipsMonthTreatmentError[];
  };
};

export type PaymentCompany = {
  id: string;
  active: boolean;
  mail: string;
  name: string;
  createdBy?: any;
};

export type PaymentAuthorizedUser = {
  mail: string;
  secretToken?: string; // check if deprecated in back
  confirmationToken?: string; // check if deprecated in back
};

export type PaymentCard = {
  brand: string;
  country: string;
  expMonth: number;
  expYear: number;
  funding: PaymentCardFunding;
  last4: string;
};

enum PaymentCardFunding {
  credit = 'credit',
  debit = 'debit',
}

export type PaymentSepaDebit = {
  bankCode: string;
  country: string;
  last4: string;
};

export type PaymentMethod = {
  id: string;
  name: string;
  card?: PaymentCard;
  sepaDebit?: PaymentSepaDebit;
};

export type PaymentAccount = {
  created: string;
  updated: string;
  companies: PaymentCompany[];
  active: boolean;
  label: string;
  firstBilling: string;
  billing: {
    customerId?: string;
    histories: any[];
  };
  asset: number;
  address: Address;
  siret: string;
  owner: PaymentAuthorizedUser;
  payment: {
    customerId?: string;
    defaultPaymentMethodId?: string;
  };
  users: PaymentAuthorizedUser[];
  billingPlanId: string;
  properties: {
    unitPrice: number;
    gma: boolean;
    whiteLabel: boolean;
  };
  id: string;
  defaultPayment?: PaymentMethod;
  nbActiveUsers: number;
  totalHT: number;
  nbDailyWorkActiveUsers: number;
  nbActiveUsersForGma: number;
};

export type BillingHistory = {
  created: string; // "2023-06-30T15:48:47.639",
  updated: string; // "2023-06-30T15:48:47.639",
  paymentAccountId: string;
  paymentIntentId: string;
  invoicePath: string;
  invoiceRef: string;
  status: BillingHistoryStatus;
  amountTTC: number;
  amountHT: number;
  paymentIntentDate: string; // "2020-03-01"
  lastPaymentErrorCode?: string;
  declineCode?: string;
  clientSecret?: string;
  lastPaymentErrorMethodId?: string;
  paymentHistories: PaymentHistory[];
  declineMessage?: string;
  id: string;
};

export enum BillingHistoryStatus {
  pending = 'pending',
  failed = 'failed',
  succeeded = 'succeeded',
  requires_payment_method = 'requires_payment_method',
}

export type PaymentHistory = {
  paymentIntentId: string;
  status: string;
  paymentIntentDate?: any; // "2020-03-01"
};

export type WhiteLabel = {
  id: string;
  paymentAccountId: string;
  dns?: string;
  logoPath?: string;
  primaryColor?: string;
  secondaryColor?: string;
  favicon?: string;
  name?: string;
  mail?: string;
  activeModules: string[];
};

export type Notification = {
  key: string;
  id: string;
  mail: {
    enabled: boolean;
    value: boolean;
  };
  app: {
    enabled: boolean;
    value: boolean;
  };
  mobile: {
    enabled: boolean;
    value: boolean;
  };
};

export type NotificationCompany = {
  myCompany: boolean;
  companyId: string;
  companyName: string;
  companyCustomDefinition: Notification[];
};

export type NotificationUser = {
  created: string;
  updated: string;
  userId: string;
  title: string;
  content: string;
  viewed: boolean;
  link: string | null;
  tag: string[];
  id: string;
};

export type CreateCompanyPaymentAccountPayload = {
  mail: string;
  companyName: string;
  address: string;
  zipCode: string;
  city: string;
  siret: string;
};

export enum Provider {
  Google = 'Google',
  GMA = 'GMA',
  Microsoft = 'Microsoft',
}

export type OauthProvider = {
  provider: Provider;
  mail: string;
};

export type Period = {
  start: Dayjs;
  end: Dayjs;
};

export type PlanningUser = {
  user: UserDetails;
  periods: LeaveDetails[];
};

export type PlanningObject = {
  blockingPeriods: LeaveDetails[];
  division: DivisionDetails;
  users: PlanningUser[];
};

export type AzureSyncResult = {
  newDivision: string[];
  userNoMail: string[];
  userNoOfficeLocation: string[];
  userCreate: string[];
  userUpdate: string[];
  userUnactive: string[];
};

export enum FieldType {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  CALENDAR = 'CALENDAR',
  FILE = 'FILE',
  USER = 'USER',
  SELECT = 'SELECT',
  MULTIPLESELECT = 'MULTIPLESELECT',
}

export type ShuttleSheetTypeField = {
  required: boolean;
  editableBy: string[];
  name: string;
  type: FieldType;
  availableChoices: string[];
  value?: any;
};

export type ShuttleSheetType = {
  companyId: string;
  name: string;
  deletable: boolean;
  transmittedToAccountant: boolean;
  usableBy: string[] | null;
  fields: ShuttleSheetTypeField[];
  availableChoiceName?: string;
  id?: string;
  created?: string;
  updated?: string;
};

export type ShuttleSheetComment = {
  date: number;
  username: string;
  comment: string;
};

export enum ShuttleSheetRequestStatus {
  NEW = 'NEW',
  WAITING = 'WAITING',
  INPROGRESS = 'INPROGRESS',
  REJECTED = 'REJECTED',
  VALID = 'VALID',
}

export enum ShuttleSheetRequestStatusOwner {
  EMPLOYEE = 'EMPLOYEE',
  MANAGER = 'MANAGER',
  DIRECTION = 'DIRECTION',
  ADMINISTRATOR = 'ADMINISTRATOR',
  ACCOUNTANT = 'ACCOUNTANT',
}

export type ShuttleSheetRequestData = {
  field: ShuttleSheetTypeField;
  value: any;
};

export type ShuttleSheetRequest = {
  id?: string;
  comments?: ShuttleSheetComment[];
  companyId: string;
  creatorId: string;
  divisionId: string;
  created?: number;
  shuttleSheetType: ShuttleSheetType;
  status?: ShuttleSheetRequestStatus;
  statusOwner?: ShuttleSheetRequestStatusOwner;
  statusUpdatedDate?: number;
  updated?: number;
  data?: ShuttleSheetRequestData[];
};

export type ShuttleSheetRequestPayload = {
  divisionId: string;
  creatorId: string;
  comments?: ShuttleSheetComment[];
  shuttleSheetTypeId: string;
  status?: ShuttleSheetRequestStatus;
  statusOwner?: ShuttleSheetRequestStatusOwner;
  stringFields?: {
    field: ShuttleSheetTypeField;
    value: string;
  }[];
  numberFields?: {
    field: ShuttleSheetTypeField;
    value: number;
  }[];
  booleanFields?: {
    field: ShuttleSheetTypeField;
    value: boolean;
  }[];
  dateFields?: {
    field: ShuttleSheetTypeField;
    value: string; // (ex: '2022-03-10')
  }[];
  dateTimeFields?: {
    field: ShuttleSheetTypeField;
    value: string; // (ex: "2022-03-10T12:15:50")
  }[];
  calendarFields?: {
    field: ShuttleSheetTypeField;
    value: {
      label: string; // (ex: 'MONDAY')
      periods: [
        {
          start: string;
          end: string;
          valid: boolean;
        }
      ];
    }[];
  }[];
  fileFields?: {
    field: ShuttleSheetTypeField;
    value: string;
  }[];
  userFields?: {
    field: ShuttleSheetTypeField;
    value: string;
  }[];
  selectFields?: {
    field: ShuttleSheetTypeField;
    value: string;
  }[];
  multipleSelectFields?: {
    field: ShuttleSheetTypeField;
    value: string[];
  }[];
};

export type DocumentMeta = {
  title: string;
  comment: string;
};

export enum DocumentPathType {
  FOLDER = 'FOLDER',
  PDF = 'PDF',
  IMAGE = 'IMAGE',
  DOC = 'DOC',
  XLS = 'XLS',
  UNKNOWN = 'UNKNOWN',
}

export enum DocumentPathStorage {
  S3 = 'S3',
  SAFE_SAE = 'SAFE_SAE',
}

export type DocumentPath = {
  systemPath: string;
  appPath: string;
  parentId: string;
  fileSize: number;
  type: DocumentPathType;
  extension: string;
  storage: DocumentPathStorage;
};

export enum DocumentPropertySpace {
  PERSONAL = 'PERSONAL',
  SHARED = 'SHARED',
  ACCOUNTANT = 'ACCOUNTANT',
}

export type DocumentProperty = {
  space: DocumentPropertySpace;
  creator: string;
  updater: string;
  personalUserId: string;
};

export type DocumentView = {
  id: string;
  created: string;
  updated: string;
  meta: DocumentMeta;
  path: DocumentPath;
  property: DocumentProperty;
  companyId: string;
};

export type CommonEntity = {
  id: string;
  created?: string;
  updated?: string;
};

export type ExtractLocation = {
  companyId: string;
  name?: PayslipLocation;
  periodImported?: PayslipLocation;
  matricule?: PayslipLocation;
  codeCompany?: PayslipLocation;
  siret?: PayslipLocation;
  grossAmount?: PayslipLocation;
  netAmount?: PayslipLocation;
  counter?: PayslipLocation[];
};

export type PayslipExtractLocation = ExtractLocation & CommonEntity;

export type PayslipExtractLocationPayload = ExtractLocation & {
  id?: string;
};

export type PayslipLocation = {
  key: string;
  counterTypeId?: string;
  location?: CkRectangle;
};

export type CkRectangle = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type CompanyPayslipExtractLocationTemplate = CommonEntity & {
  name: string;
  payslipSoft: string;
  template: PayslipExtractLocation;
};

export type CompanyPayslipExtractLocationTemplatePayload = {
  id?: string;
  name: string;
  payslipSoft: string;
  template: PayslipExtractLocationPayload;
};

export type ValidatePayslipPayload = {
  employees: UserPayslipRequest[];
};

export type UserPayslipRequest = {
  userId: string;
  counter: CounterStr[];
};

export type CounterStr = {
  counter: string; // counter value
  key: string;
  counterTypeId: string;
};

export type TimeRecorder = CommonEntity & {
  start: string;
  duration: number;
  end?: string;
  history?: History[];
  leaveId?: string;
  status: Status;
  userId: string;
  workUnitId: string;
};

export type TimeRecorderRequest = {
  startDate: DateTimeStringRequest;
  endDate: DateTimeStringRequest;
  workUnitId: string;
};

export type History = {
  start: string;
  end: string;
  userId: string;
};

enum Status {
  START = 'START',
  STOP = 'STOP',
}
